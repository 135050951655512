import { FunctionComponent } from "react";

import { Select as CharkaSelect } from "@chakra-ui/react";

import { IOptionItem } from "@tether-web-portal/types/common";

interface ISelectProps {
  options: IOptionItem[];
  width?: number;
  placeholder?: string;
  value?: string;
  onChange?: (selectedOption: IOptionItem | null, event: React.ChangeEvent<HTMLSelectElement>) => void;
}
export const Select: FunctionComponent<ISelectProps> = (props: ISelectProps) => {
  const { options, placeholder, value, onChange, width = 130 } = props;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find((x) => x.value === selectedValue);
    if (onChange) {
      onChange(selectedOption ?? null, e);
    }
  };

  return (
    <CharkaSelect value={value} onChange={handleChange} bg="white" placeholder={placeholder} width={width}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label ?? option.value}
        </option>
      ))}
    </CharkaSelect>
  );
};
