const GlanceIcon = ({ size, color = "#595F6A" }: { size: number; color: string }) => (
  <svg width={size} height={size} viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3023 0.487239C19.3023 0.218144 19.521 0 19.7907 0H25.4884C25.7581 0 25.9767 0.218144 25.9767 0.487239V26.4733H25V0.974478H20.2791V26.4733H19.3023V0.487239ZM11.1628 7.38979C11.1628 7.1207 11.3814 6.90255 11.6512 6.90255H17.3488C17.6186 6.90255 17.8372 7.1207 17.8372 7.38979V26.4733H16.8605V7.87703H12.1395V26.4733H11.1628V7.38979ZM3.02325 15.9165C3.02325 15.6474 3.24191 15.4292 3.51163 15.4292H9.2093C9.47902 15.4292 9.69767 15.6474 9.69767 15.9165V26.4733H8.72093V16.4037H4V26.4733H3.02325V15.9165Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.988373 26.4733C0.988373 26.2042 1.20702 25.9861 1.47674 25.9861H27.5233C27.793 25.9861 28.0116 26.2042 28.0116 26.4733C28.0116 26.7424 27.793 26.9606 27.5233 26.9606H1.47674C1.20702 26.9606 0.988373 26.7424 0.988373 26.4733Z"
      fill={color}
    />
  </svg>
);

export default GlanceIcon;
