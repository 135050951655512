import { FunctionComponent, useMemo } from "react";

import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { formatDeviceName } from "@tether-web-portal/utils/formatDeviceName";
import { ACCEPTED_DEVICE_TYPES } from "@tether-web-portal/types/DeviceType";

interface DeviceIconProps {
  type?: string;
  size?: number;
}
export const DeviceIcon: FunctionComponent<DeviceIconProps> = ({ type, size = 40 }: DeviceIconProps) => {
  const { imageName, deviceName } = useMemo(() => {
    return {
      imageName: getDeviceTypeIconName(type as DeviceType),
      deviceName: !type ? "Device not found" : formatDeviceName(type),
    };
  }, [type]);

  return <img style={{ maxWidth: size }} src={`/assets/devices/${imageName}`} alt={deviceName} />;
};

export const getDeviceTypeIconName = (deviceType: DeviceType) => {
  const unkownIcon = "unknown.png";

  if (!deviceType) {
    return unkownIcon;
  }

  if (!ACCEPTED_DEVICE_TYPES.has(deviceType)) {
    return unkownIcon;
  }

  switch (deviceType) {
    case DeviceType.MILESIGHT_CT:
    case DeviceType.HOTDROP: {
      return "hotdrop.svg";
    }

    case DeviceType.EASTRON_POWER: {
      return "eastron_power.svg";
    }

    case DeviceType.MOKO_LW005:
    case DeviceType.MILESIGHT_WS523: {
      return "plug-anz.svg";
    }

    case DeviceType.ADEUNIS_PULSE:
    case DeviceType.ADEUNIS_PULSE_WATER: {
      return "pulse.svg";
    }

    case DeviceType.AIRWITS:
    case DeviceType.AIRWITS_IAQ:
    case DeviceType.AIRWITS_CO2:
    case DeviceType.AIRWITS_CO2_PLUS: {
      return "iaq.svg";
    }

    case DeviceType.MILESIGHT_EM300:
    case DeviceType.MILESIGHT_AM103:
    case DeviceType.MILESIGHT_WS202:
    case DeviceType.EMS2DESK:
    case DeviceType.ERS2EYE: {
      return `${deviceType.toLowerCase()}.png`;
    }

    case DeviceType.ROOM_RADAR:
    case DeviceType.ROOM_RADAR_LINE:
    case DeviceType.ROOM_RADAR_REGION: {
      return "room-radar.svg";
    }

    case DeviceType.VOLTDROP:
    case DeviceType.VOLTDROP_PHASE: {
      return "voltdrop.svg";
    }

    case DeviceType.FLEXSENSE:
    case DeviceType.FLEXSENSE_PROBE: {
      return "flex-sense.png";
    }

    case DeviceType.ENVIROQ:
    case DeviceType.THERMALQ: {
      return "enviroq.svg";
    }

    default: {
      return deviceType.replaceAll(" ", "-").replaceAll("_", "-").toLowerCase() + ".svg";
    }
  }
};
