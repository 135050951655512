import { SVGProps, useState, useRef, useEffect } from "react";

type PointerState = {
  x: number;
  y: number;
  scrollLeft: number;
  scrollTop: number;
};

export const usePointerMoveScroll = ({
  onStart,
  onMove,
  onHover,
  onEnd,
  viewWidth,
  viewHeight,
  scrollLeft,
  scrollTop,
  scrollWidth,
  scrollHeight,
}: {
  onStart: () => void;
  onMove: (scrollLeft: number, scrollTop: number) => void;
  onHover: (event: React.PointerEvent<SVGRectElement>) => void;
  onEnd: () => void;
  viewWidth: number;
  viewHeight: number;
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  scrollHeight: number;
}): [PointerState | undefined, SVGProps<SVGRectElement>] => {
  const [pointerState, setPointerState] = useState<PointerState | undefined>(undefined);

  const onPointerDown = (event: React.PointerEvent<SVGRectElement>) => {
    setPointerState({
      x: "clientX" in event ? event.clientX : 0,
      y: "clientY" in event ? event.clientY : 0,
      scrollLeft,
      scrollTop,
    });
    onStart();
  };

  const xMax = scrollWidth - viewWidth;
  const yMax = scrollHeight - viewHeight;

  const movePosition = (event: React.PointerEvent<SVGRectElement>) => {
    if (!pointerState) return;
    const x = "clientX" in event ? event.clientX : 0;
    const y = "clientY" in event ? event.clientY : 0;

    const deltaX = pointerState.x - x;
    let offsetX = pointerState.scrollLeft + deltaX;
    offsetX = Math.max(0, Math.min(offsetX, xMax));

    const deltaY = pointerState.y - y;
    let offsetY = pointerState.scrollTop + deltaY;
    offsetY = Math.max(0, Math.min(offsetY, yMax));

    onMove(offsetX, offsetY);
  };

  const onPointerMove = (event: React.PointerEvent<SVGRectElement>) => {
    if (pointerState) return movePosition(event);
    return onHover(event);
  };

  const onPointerUp = useRef<() => void | undefined>();
  onPointerUp.current = () => {
    setPointerState(undefined);
    onEnd();
  };
  useEffect(() => {
    const pointerup = () => {
      onPointerUp.current?.();
    };
    window.addEventListener("pointerup", pointerup);
    return () => {
      window.removeEventListener("pointerup", pointerup);
    };
  }, []);

  return [
    pointerState,
    {
      onPointerLeave: () => onEnd(),
      onPointerMove,
      onPointerDown,
    },
  ];
};
