import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface ReadingRowProps {
  icon?: JSX.Element;
  rowTitle: string;
  value: string;
  showBorderTop?: boolean;
  isHighRisk?: boolean;
}

export const ReadingRow = ({ icon, rowTitle, value, showBorderTop = true, isHighRisk }: ReadingRowProps) => (
  <HStack mx={4} borderTopColor="rowBorderColor" borderTopWidth={showBorderTop ? 1 : 0} py={4}>
    <Box flexDirection="row" alignItems="center">
      {Boolean(icon) && (
        <Box width={24} mr={8} alignItems="center">
          {icon}
        </Box>
      )}
      <Box w={210}>
        <Text color="tangaroa" fontSize={14} fontWeight={800}>
          {rowTitle}
        </Text>
      </Box>
    </Box>
    <Box w={210}>
      <Text color={isHighRisk ? "torchRed" : "mortar"} fontSize={14} textOverflow="clip">
        {value}
      </Text>
    </Box>
  </HStack>
);

export default ReadingRow;
