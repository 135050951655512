import _ from "lodash";

import { PIDeviceTypes } from "@tether-web-portal/types/PropertyInfo";
import { DeviceType } from "@tether-web-portal/types/DeviceType";

export const formatDeviceName = (
  deviceType: string | DeviceType | PIDeviceTypes,
  doNotUseDefault?: boolean
): string => {
  if (!deviceType) return "";

  const dType = deviceType.toString().toUpperCase();

  if (dType === DeviceType.AIRWITS || dType === DeviceType.AIRWITS_IAQ) {
    return "Airwits IAQ";
  }

  if (dType === DeviceType.AIRWITS_CO2) {
    return "Airwits CO2";
  }

  if (dType === DeviceType.AIRWITS_CO2_PLUS) {
    return "Airwits CO2 +";
  }

  if (dType === DeviceType.AIRWITS_PM) {
    return "Airwits PM";
  }

  if (dType === DeviceType.DETECTIFY) {
    return "Detectify";
  }

  if (dType === DeviceType.HOTDROP) {
    return "HotDrop";
  }

  if (dType === DeviceType.ENVIROQ) {
    return "EnviroQ";
  }

  if (dType === DeviceType.THERMALQ) {
    return "ThermalQ";
  }

  if (dType === DeviceType.ADEUNIS_PULSE_WATER) {
    return "Adeunis Pulse Water Meter";
  }

  if (dType === DeviceType.ADEUNIS_PULSE) {
    return "Adeunis Pulse Meter";
  }

  if (dType === DeviceType.FLEXSENSE || dType === DeviceType.FLEXSENSE_PROBE) {
    return "FlexSense";
  }
  if (dType === DeviceType.VOLTDROP || dType === DeviceType.VOLTDROP_PHASE) {
    return "VoltDrop";
  }
  if (
    dType === DeviceType.ROOM_RADAR ||
    dType === DeviceType.ROOM_RADAR_LINE ||
    dType === DeviceType.ROOM_RADAR_REGION
  ) {
    return "Room Radar";
  }
  if (dType === DeviceType.MOKO_LW005) {
    return "PowerQ";
  }
  if (dType === DeviceType.GATEWAY) {
    return "Gateway";
  }

  if (doNotUseDefault) {
    return "";
  }

  return _.upperFirst(_.lowerCase(dType));
};
