import { useMemo } from 'react';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { getStringWidth } from '@visx/text';
import { SVGMeasure, useSVGMeasure } from '@tether-web-portal/components/charts/SVGMeasure';
import { ColumnLabels } from '@tether-web-portal/components/heat-map/v2/ColumnLabels';
import { RowLabels } from '@tether-web-portal/components/heat-map/v2/RowLabels';
import { Cells } from '@tether-web-portal/components/heat-map/v2/Cells';
import { ScaleBand, ScaleLinear } from 'd3';
import { ColumnAxisBottomLabel } from '@tether-web-portal/components/heat-map/v2/ColumnAxisBottomLabel';
import { ColumnBackground } from '@tether-web-portal/components/heat-map/v2/ColumnBackground';
import { useInteractionRect } from "@tether-web-portal/components/heat-map/v2/useInteractionRect";
import { HeatMapTooltip } from "@tether-web-portal/components/heat-map/v2/tooltip/HeatMapTooltip";
import { TooltipDisplayComponent } from "@tether-web-portal/components/charts/types";

export type HeatMapDataPoint<T = unknown> = {
  row: number;
  column: number;
  value?: number;
  data?: T;
}

export type HeatMapColumn = {
  key: number;
  label: string;
  timestamp: number;
  faded?: boolean;
}

export type HeatMapRow = {
  key: number;
  label: string;
  timestamp: number;
  benchmark?: {
    start: boolean;
    end: boolean;
  }
}

export type HeatMapXScale = ScaleBand<number>;
export type HeatMapYScale = ScaleBand<number>;
export type HeatMapColorScale = ScaleLinear<string, string, never>;
export type HeatMapDataMap<T> = Record<number, Record<number, HeatMapDataPoint<T>>>;

interface HeatMapProps<T> {
  columns: HeatMapColumn[];
  rows: HeatMapRow[];
  data: HeatMapDataPoint<T>[];
  /** Use this to set a suggested maximum value to calculate colour against in case data set is all small values */
  maxValue?: number;
  colorScale: HeatMapColorScale;
  rowAxisLabel: string;
  columnsAxisLabel: string;
  TooltipDisplay?: TooltipDisplayComponent<T>;
}

const labelTopMargin = 10;
const labelLineHeight = 14;

const columnAxisLabelHeight = 40;
const rowAxisLabelWidth = 20;

const rowLabelStyle = {
  lineHeight: `${labelLineHeight}px`,
  fontSize: 'var(--chakra-fontSizes-xs)',
  fontWeight: 700,
  fontFamily: 'var(--chakra-fonts-body)',
}

const columnLabelStyle = {
  lineHeight: `${labelLineHeight}px`,
  fontSize: 'var(--chakra-fontSizes-xs)',
  fontWeight: 500,
  fontFamily: 'var(--chakra-fonts-body)',
}

const HeatMapContents = <T,>({
  data,
  columns,
  rows,
  colorScale,
  rowAxisLabel,
  columnsAxisLabel,
  TooltipDisplay,
  maxValue,
}: HeatMapProps<T>) => {
  const { width } = useSVGMeasure();

  const benchmarkIconWidth = useMemo(
    () => {
      const hasBenchmarks = rows.some(({ benchmark }) => benchmark?.start || benchmark?.end);
      return hasBenchmarks ? 10 : 0;
    },
    [rows]
  );

  const rowLabelWidth = useMemo(
    () => {
      const maxRowLabelWidth = 100;
      const longest = Math.max(
        ...rows.map(({ label }) => getStringWidth(label, rowLabelStyle) || 0)
      );
      return Math.min(longest, maxRowLabelWidth);
    },
    [rows]
  );

  const dataMap = useMemo<HeatMapDataMap<T>>(
    () => {
      const dataMap: HeatMapDataMap<T> = {};
      for (const item of data) {
        const { row, column } = item;
        if (!dataMap[column]) dataMap[column] = {};
        dataMap[column][row] = item;
      }
      return dataMap;
    },
    [data]
  );

  const minColumnWidth = 34;
  const columnGap = 5;

  const rowHeight = 22;
  const rowGap = 5;

  const rowAxisWidth = rowAxisLabelWidth + rowLabelWidth + benchmarkIconWidth;

  const xMax = width - rowAxisWidth;
  const yMax = (rows.length * (rowHeight + rowGap)) - rowGap;

  const columnLabelHeight = labelTopMargin + labelLineHeight;
  const height = yMax + columnLabelHeight + columnAxisLabelHeight;

  const columnXMax = (columns.length * (minColumnWidth + columnGap)) - columnGap;

  const xScale = useMemo(
    (): HeatMapXScale => scaleBand({
      range: [0, columnXMax],
      domain: columns.map(({ key }) => key),
      padding: columnGap / minColumnWidth
    }),
    [columns, columnXMax, minColumnWidth, columnGap]
  );

  const yScale = useMemo(
    (): HeatMapYScale => {
      return scaleBand({
        range: [0, yMax],
        domain: rows.map(({ key }) => key),
        padding: rowGap / rowHeight
      })
    },
    [rowGap, rows, yMax]
  );


  const [
    rectProps,
    {
      hoveredCell,
      scrollOffsetX,
      scrollOffsetY
    }
  ] = useInteractionRect<T>({
    xMax,
    yMax,
    xScale,
    yScale,
    dataMap
  });

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Group left={rowAxisWidth} >
        <Group left={scrollOffsetX} >
          <ColumnBackground
            columns={columns}
            xScale={xScale}
            columnXMax={columnXMax}
            xMax={xMax}
            backgroundHeight={columnLabelHeight + yMax}
          />
        </Group>
      </Group>

      <Group top={columnLabelHeight} left={rowAxisWidth}>
        <Group left={scrollOffsetX} >
          <Cells<T>
            columns={columns}
            rows={rows}
            dataMap={dataMap}
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
          />

          <HeatMapTooltip<T>
            data={hoveredCell}
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            TooltipDisplay={TooltipDisplay}
            maxValue={maxValue}
          />
        </Group>
      </Group>

      <Group left={rowAxisWidth} >
        <Group left={scrollOffsetX} >
          <ColumnLabels
            columns={columns}
            xScale={xScale}
            labelLineHeight={labelLineHeight}
            labelTopMargin={labelTopMargin}
            labelStyle={columnLabelStyle}
          />
        </Group>

        <ColumnAxisBottomLabel
          xMax={xMax}
          backgroundHeight={columnLabelHeight + yMax}
          labelStyle={columnLabelStyle}
          axisLabelHeight={columnAxisLabelHeight}
        >
          {columnsAxisLabel}
        </ColumnAxisBottomLabel>
      </Group>

      <Group top={columnLabelHeight} >
        <RowLabels
          rows={rows}
          yScale={yScale}
          labelWidth={rowLabelWidth}
          axisLabelWidth={rowAxisLabelWidth}
          axisLabel={rowAxisLabel}
          benchmarkIconWidth={benchmarkIconWidth}
          columnXMax={columnXMax}
          yMax={yMax}
          labelStyle={rowLabelStyle}
        />
      </Group>

      <rect
        width={rowAxisWidth + 2}
        height={columnLabelHeight + 2}
        fill={'white'}
      />

      <Group top={columnLabelHeight} left={rowAxisWidth} >
        <rect
          fill={'transparent'}
          width={xMax}
          height={yMax}
          {...rectProps}
        />
      </Group>
    </svg>
  )
};

export const HeatMap = <T,>(props: HeatMapProps<T>) => (
  <SVGMeasure measureWidth >
    <HeatMapContents<T> {...props} />
  </SVGMeasure>
)
