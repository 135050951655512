import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useRef } from "react";
import { CircuitNode } from "@tether-web-portal/types/CircuitNode";
import { Outside } from "@tether-web-portal/types/Outside";
import { GraphColorDetails, Room } from "@tether-web-portal/types/Room";
import { flattenCircuitNodesWithBreadcrumbs } from "@tether-web-portal/utils/circuitNodeUtils";
import AutoCompleteInput from "../autocomplete-input/AutoCompleteInput";
import { RoomIcon } from "../create-room/room-icon/RoomIcon";

export const AreaPickerItem = ({ area, label }: { area: Room | Outside | CircuitNode; label: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const showTooltip = Boolean(
    containerRef.current && containerRef.current.scrollWidth > containerRef.current.clientWidth
  );
  return (
    <Flex ref={containerRef} alignItems="center" overflow="hidden" width="100%">
      <Box mr={2}>
        {(area as Room).type && <RoomIcon type={(area as Room).type} />}
        {!(area as Room).type && (
          <Box
            width={4}
            height={4}
            p={2}
            borderRadius={5}
            bg={GraphColorDetails[area.color]?.color || "transparent"}
          ></Box>
        )}
      </Box>
      <Box pt={2} width="100%">
        <Tooltip label={showTooltip ? label : ""}>
          <Text
            noOfLines={1}
            fontWeight={400}
            display="inline-block"
            textOverflow="ellipsis"
            maxWidth="calc(100% - 40px)"
            whiteSpace="nowrap"
            overflowY="visible"
          >
            {label}
          </Text>
        </Tooltip>
      </Box>
    </Flex>
  );
};

interface AreaPickerProps {
  rooms: Room[];
  outsides: Outside[];
  circuitNodes: CircuitNode[];
  selectedArea?: Room | Outside | CircuitNode;
  onAreaSelected: (area: Room | Outside | CircuitNode, areaType: AreaPickerAreaType) => void;
  placeholder?: string;
  renderCustomButton?: (onClick: () => void) => JSX.Element;
}

export type AreaPickerAreaType = "room" | "outside" | "circuitNode";

export const AreaPicker = ({
  rooms,
  outsides,
  circuitNodes,
  selectedArea,
  onAreaSelected,
  placeholder,
  renderCustomButton,
}: AreaPickerProps) => {
  const flattenedNodes = flattenCircuitNodesWithBreadcrumbs(circuitNodes, selectedArea?.id);

  const items = [
    ...(rooms || []).map((rl) => ({
      value: rl.id,
      label: rl.name,
      area: rl,
      areaType: "room" as AreaPickerAreaType,
      group: "Insides",
    })),
    ...(outsides || []).map((o) => ({
      value: o.id,
      label: o.name,
      area: o,
      areaType: "outside" as AreaPickerAreaType,
      group: "Outsides",
    })),
    ...(flattenedNodes || []).map((c) => ({
      value: c.id,
      label: c.breadcrumb,
      area: c.node,
      areaType: "circuitNode" as AreaPickerAreaType,
      group: "Circuit Locations",
    })),
  ];

  return (
    <AutoCompleteInput
      width="250px"
      dropdownAsOverlay
      items={items}
      placeholder={placeholder}
      value={""}
      selectedItemValue={selectedArea?.id}
      renderSelectedItem
      onChange={() => {}}
      onSelectedItem={(item) => {
        onAreaSelected(item.area, item.areaType);
      }}
      shouldFilter={false}
      disableTextInput
      renderItem={(item) => {
        return <AreaPickerItem label={item.label} area={item.area} />;
      }}
      renderCustomButton={renderCustomButton}
    />
  );
};
