export enum PulseMeterType {
  Water = "WATER",
  Gas = "GAS",
  Power = "POWER",
}

export interface PulseMeter {
  deviceId: string;
  type: PulseMeterType;
  pulsesPerUnit: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}
