import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface ProgressBarProps {
  width: number;
  height: number;
  progress: number;
  colors: string[];
  id?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ width, height, progress, colors, id }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const radius = height / 2;
    const gradientId = [id, "progress-gradient"].filter((x) => x).join("-");
    const svg = d3.select(svgRef.current);

    // Clear existing content
    svg.selectAll("*").remove();

    // Define linear gradient
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", gradientId)
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "0%")
      .attr("y2", "0%");

    if (colors.length === 1) {
      gradient.append("stop").attr("offset", "0%").attr("stop-color", colors[0]);
      gradient.append("stop").attr("offset", "100%").attr("stop-color", colors[0]);
    } else {
      const percentageStep = 100 / (colors.length - 1);
      for (let i = 0; i < colors.length; i++) {
        gradient
          .append("stop")
          .attr("offset", `${i * percentageStep}%`)
          .attr("stop-color", colors[i]);
      }
    }

    // Add background bar
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .attr("rx", radius)
      .attr("fill", "#F3F3F3");

    // Add progress bar
    const progressBar = svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0) // Start with 0 width for animation
      .attr("height", height)
      .attr("rx", radius)
      .attr("fill", `url(#${gradientId})`);

    // Animate the progress bar width
    progressBar
      .transition()
      .duration(500) // Animation duration in milliseconds
      .ease(d3.easeCubicInOut) // Easing function for smooth animation
      .attr("width", (progress / 100) * width);
  }, [width, height, progress, id, colors]);

  return <svg id={id} ref={svgRef} width={width} height={height}></svg>;
};

export default ProgressBar;
