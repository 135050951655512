import { useMemo, FC } from 'react';
import { Popover, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react";
import { HeatMapDataPoint, HeatMapXScale, HeatMapYScale, HeatMapColorScale } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { color } from "d3";
import { TooltipDisplayComponent } from "@tether-web-portal/components/charts/types";

export const HeatMapTooltip = <T,>({
  data,
  xScale,
  yScale,
  colorScale,
  TooltipDisplay,
  maxValue,
}: {
  data?: HeatMapDataPoint<T>,
  xScale: HeatMapXScale,
  yScale: HeatMapYScale,
  colorScale: HeatMapColorScale,
  TooltipDisplay?: TooltipDisplayComponent<T>
  maxValue?: number
}) => {

  const {
    column,
    row,
    value = 0
  } = data || {};

  const x = column !== undefined ? xScale(column) : 0;
  const y = row !== undefined ? yScale(row) : 0;

  const stroke: string | undefined = useMemo(
    () => {
      if (value === undefined) return 'transparent';
      const stroke = colorScale(value);
      if (!stroke) return 'transparent';
      return String(color(stroke)?.darker(1));
    },
    [value, colorScale]
  );

  if (!TooltipDisplay) return null;
  if (!data) return null;
  return (
    <Popover
      isOpen={!!value}
      closeOnBlur={false}
      closeOnEsc={false}
      autoFocus={false}
      placement='right'
    >
      <PopoverTrigger>
        <rect
          fill={'transparent'}
          stroke={stroke}
          strokeWidth={3}
          rx={5}
          x={x}
          y={y}
          width={xScale.bandwidth()}
          height={yScale.bandwidth()}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent style={{ width: "auto", overflow: "hidden" }}>
          <TooltipDisplay data={data?.data} colorScale={colorScale} maxValue={maxValue} value={value} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
