import { ComponentProps } from 'react';
import { MenuButton } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/button";

export const MenuButtonAsButton = (props: ComponentProps<typeof MenuButton>) => (
  <MenuButton
    as={Button}
    {...props}
  />
)
