import { useMemo, FC } from "react";
import { Popover, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react";
import { color } from "d3";
import {
  HorizontalBarChartColorScale,
  HorizontalBarChartDataPoint,
  HorizontalBarChartXScale,
  HorizontalBarChartYScale,
} from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { TooltipDisplayComponent } from "@tether-web-portal/components/charts/types";

export const HorizontalBarChartTooltip = <T,>({
  data,
  xScale,
  yScale,
  yMax,
  colorScale,
  TooltipDisplay,
  maxValue,
}: {
  data?: HorizontalBarChartDataPoint<T>;
  yMax: number;
  xScale: HorizontalBarChartXScale;
  yScale: HorizontalBarChartYScale;
  colorScale: HorizontalBarChartColorScale;
  TooltipDisplay?: TooltipDisplayComponent<T>;
  maxValue?: number;
}) => {
  const { key = "", label = "", value = 0 } = data || {};

  const barWidth = xScale.bandwidth();
  const barHeight = yMax - (yScale(value) ?? 0);
  const barX = xScale(key);
  const barY = yMax - barHeight;

  const stroke: string | undefined = useMemo(() => {
    if (value === undefined) return "transparent";
    const stroke = colorScale(value);
    if (!stroke) return "transparent";
    return String(color(stroke)?.darker(1));
  }, [value, colorScale]);

  if (!TooltipDisplay) return null;
  if (!data) return null;
  return (
    <Popover isOpen={!!value} closeOnBlur={false} closeOnEsc={false} autoFocus={false} placement="right">
      <PopoverTrigger>
        <rect
          data-value={value}
          data-name={label}
          x={barX}
          y={barY}
          width={barWidth}
          height={barHeight}
          fill={"transparent"}
          rx={barWidth / 2}
          stroke={stroke}
          strokeWidth={3}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent style={{ width: "auto", overflow: "hidden" }}>
          <TooltipDisplay data={data?.data} colorScale={colorScale} maxValue={maxValue} value={value} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
