import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { HStack, VStack } from "@chakra-ui/react";

import { Select, Button, Typography, Loader } from "@tether-web-portal/components/common";
import { IDateRange, IOptionItem } from "@tether-web-portal/types/common";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { DateRangePicker } from "@tether-web-portal/components/datepicker/DateRangePicker";
import dayjs from "@tether-web-portal/dayjs-setup";
import { useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { useOccupancyExportData } from "@tether-web-portal/hooks/useOccupancyExportData";
import { makeFloorLevelSpaceName, OccupancyFilterType } from "@tether-web-portal/web-workers/occupancy";

export const OccupancyPageHeader: FunctionComponent = () => {
  const {
    dataLastUpdatedAt,
    dataLoading,
    isLiveView,
    pageFilters,
    utilisationTableData,
  } = useOccupancyContext();
  const {
    metricsCurrentDataInterval,
    setMetricsInterval,
    setCustomMetricsInterval,
    metricsTimeStamps,
  } = useMetricsInterval();
  const { onExportData, isExportAble, loading: exportLoading } = useOccupancyExportData();

  const [dataLastUpdatedLabel, setDataLastUpdatedLabel] = useState<string>();

  const headerTitle = useMemo(() => {
    let updatedHeaderTitle: string | null = null;
    if (pageFilters?.spaceType === OccupancyFilterType.FLOOR) {
      updatedHeaderTitle = makeFloorLevelSpaceName(pageFilters.floorIndex ?? 0);
    } else if (pageFilters?.spaceType === OccupancyFilterType.ROOM) {
      updatedHeaderTitle =
        (utilisationTableData ?? []).find((x) => x.spaceId === pageFilters.spaceId)?.spaceName ?? null;
    }

    return updatedHeaderTitle || "Occupancy";
  }, [pageFilters, utilisationTableData]);

  const { maxDate, minDate } = useMemo(
    () => ({
      maxDate: dayjs().endOf("day"),
      minDate: dayjs().subtract(10, "years").startOf("day"),
    }),
    []
  );
  const timeFrameOptions = useMemo<IOptionItem[]>(
    () => [
      { value: MetricDataInterval.MINUTES_15, label: "Live" },
      { value: MetricDataInterval.HOURS_24, label: "Daily" },
      { value: MetricDataInterval.DAYS_7, label: "Weekly" },
      { value: MetricDataInterval.DAYS_30, label: "Monthly" },
      { value: MetricDataInterval.MONTHS_3, label: "Quarterly" },
      { value: MetricDataInterval.CUSTOM, label: "Custom" },
    ],
    []
  );

  const handleTimeFrameChanged = (option: IOptionItem | null) => {
    if (!option) {
      return;
    }

    if (option.value === MetricDataInterval.CUSTOM) {
      setCustomMetricsInterval(metricsTimeStamps.fromTimestamp, metricsTimeStamps.toTimestamp);
    } else {
      setMetricsInterval(option.value as MetricDataInterval);
    }
  };

  const handleCustomDateRangeChange = (dateRange: IDateRange) => {
    if (dateRange.startDate) {
      const endDate = dateRange.endDate ? dayjs(dateRange.endDate) : dayjs(dateRange.startDate);
      setCustomMetricsInterval(dateRange.startDate.toISOString(), endDate.endOf("day").toISOString());
    }
  };

  useEffect(() => {
    if (!dataLastUpdatedAt) {
      return;
    }

    setDataLastUpdatedLabel(`Data updated ${dayjs(dataLastUpdatedAt).fromNow()}`);
  }, [dataLastUpdatedAt]);

  return (
    <HStack justifyContent="space-between" alignItems="center">
      <VStack alignItems="flex-start">
        <Typography size="2xl" fontWeight="bold">
          {headerTitle}
        </Typography>
        {dataLastUpdatedLabel && (
          <Typography size="sm" color="gray">
            {dataLastUpdatedLabel}
          </Typography>
        )}
      </VStack>

      <HStack alignItems="center" columnGap={2}>
        <Loader size="sm" isLoading={dataLoading} />

        <Select
          options={timeFrameOptions}
          onChange={handleTimeFrameChanged}
          value={metricsCurrentDataInterval}
        />

        <DateRangePicker
          startDate={metricsTimeStamps.fromTimestamp}
          endDate={metricsTimeStamps.toTimestamp}
          showDateRangeOnButton
          onDatesChange={handleCustomDateRangeChange}
          maxSelectedDays={100}
          maxBookingDate={maxDate}
          minBookingDate={minDate}
          horizontalOffset={-390} // This is terrible, but entire daterange picker needs a re-write...
        />

        {!isLiveView && (
          <Button onClick={onExportData} loading={exportLoading} disabled={dataLoading || !isExportAble}>
            Export
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
