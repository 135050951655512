import { useQuery, UseQueryOptions } from "react-query";
import { COVID_SAMPLE_REFRESH_INTERVAL } from "../../utils/covid-care";
import { api } from "../api";
import { AlarmHistoryExperience } from "../../types/Alarm";
import { Device } from "../../types/Device";
import { Sample } from "../../types/Sample";

enum QueryKeys {
  DEVICE_LATEST = "device-latest",
  GATEWAY_DEVICES = "GATEWAY_DEVICES",
  DEVICE = "DEVICE",
}

export const useGetLatestSampleForDevice = (id: string) => {
  const { data: latestSample, ...rest } = useQuery<Sample>(
    [QueryKeys.DEVICE_LATEST, id],
    () => api.get(`/v2/device/${id}/latest`),
    {
      enabled: Boolean(id),
      staleTime: COVID_SAMPLE_REFRESH_INTERVAL,
      refetchInterval: COVID_SAMPLE_REFRESH_INTERVAL,
    }
  );

  return { latestSample, ...rest };
};

export interface GatewaySeenDevice {
  deviceId: string;
  lastSeenTimestamp: string;
}

export const useGetGatewaySeenDevices = (deviceId: string) => {
  const { data, isLoading, refetch, error } = useQuery<Record<string, string>>(
    [QueryKeys.GATEWAY_DEVICES, deviceId],
    () => api.get(`/v2/gateway/${deviceId}/seendevices`),
    {
      enabled: Boolean(deviceId),
      staleTime: 1000 * 60 * 1,
    }
  );

  if (!data) {
    return { seenDevices: [], isLoading };
  }

  const devices: GatewaySeenDevice[] = Object.keys(data).map((deviceId) => {
    return {
      deviceId,
      lastSeenTimestamp: data[deviceId],
    };
  });

  return {
    data: devices,
    isLoading,
    refetch,
    error,
  };
};

export const useGetDevice = (deviceId: string) => {
  return useQuery<Device | undefined>([QueryKeys.DEVICE, deviceId], () => api.get(`/v2/device/${deviceId}`));
};
