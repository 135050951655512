import { useCallback, useMemo } from "react";
import {
  IOccupancyBreakdownDataPoint,
  IOccupancyBreakdownFilters,
  IOccupancyBreakdownSummary,
} from "@tether-web-portal/web-workers/occupancy";

import {
  OCCUPANCY_OPTIMAL_THRESHOLD,
  OCCUPANCY_OPTIMAL,
} from "@tether-web-portal/components/occupancy/occupancy.colors";

import { DayOfWeek } from "@tether-web-portal/types/MonitoringHours";
import { dayjs, Dayjs } from "@tether-web-portal/utils/dayjs-setup";
import {
  HorizontalBarChartColorScale,
  HorizontalBarChartDataPoint,
} from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { NumberValue } from "d3";
import { TickFormatter } from "@visx/axis";
import { AxisConfig, ChartBenchmarkType } from "@tether-web-portal/components/charts/types";
import { IOccupancyChartMetaDataPayload } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyChartMetaData";

const labelLineHeight = 14;

const xAxisHeightConfig = new AxisConfig({
  lineHeight: labelLineHeight,
  title: labelLineHeight,
  gap: 32,
  label: labelLineHeight * 2,
  innerMargin: labelLineHeight,
});

const yAxisWidthConfig = new AxisConfig({
  lineHeight: labelLineHeight,
  title: labelLineHeight,
  gap: 24,
  label: 32,
  innerMargin: 12,
});

interface UseOccupancyBarChartPropsPayload {
  data: HorizontalBarChartDataPoint<IOccupancyBreakdownDataPoint>[];
  maxValue: number;
  colorScale: HorizontalBarChartColorScale;
  valueAxisLabel: string;
  barAxisLabel: string;
  yAxisFormatter: TickFormatter<NumberValue>;
  benchmarks: ChartBenchmarkType[];
  xAxisHeightConfig: AxisConfig;
  yAxisWidthConfig: AxisConfig;
}

interface UseOccupancyBarChartPropsProps {
  lowResBreakdownData: IOccupancyBreakdownDataPoint[];
  breakdownFilters: IOccupancyBreakdownFilters;
  timezone?: string;
}

export const useOccupancyBarChartProps = (
  { lowResBreakdownData, breakdownFilters, timezone }: UseOccupancyBarChartPropsProps,
  { daysOfWeekSet, daysBetween, maxValue, colorScale }: IOccupancyChartMetaDataPayload,
): UseOccupancyBarChartPropsPayload => {
  const { metricToDisplay } = breakdownFilters;

  const { valueAxisLabel, yAxisFormatter } = useMemo((): Pick<
    UseOccupancyBarChartPropsPayload,
    "valueAxisLabel" | "yAxisFormatter"
  > => {
    const percentage = {
      yAxisFormatter: (value: NumberValue) => `${value}%`,
    };
    switch (metricToDisplay) {
      case "occupancyCount":
        return {
          valueAxisLabel: "Total Occupancy Count",
          yAxisFormatter: String,
        };
      case "entered":
        return {
          valueAxisLabel: "Total Traffic Entering",
          yAxisFormatter: String,
        };
      case "exited":
        return {
          valueAxisLabel: "Total Traffic Exiting",
          yAxisFormatter: String,
        };
      case "footTraffic":
        return {
          valueAxisLabel: "Total Foot Traffic",
          yAxisFormatter: String,
        };
      case "usagePercentage":
        return {
          ...percentage,
          valueAxisLabel: "Percentage of Usage",
        };
      case "occupancyLimit":
      case "capacityPercentage":
        return {
          ...percentage,
          valueAxisLabel: "Percentage of Capacity",
        };
      case "capacityUtilisationLevel":
        return {
          ...percentage,
          valueAxisLabel: "Percentage of Utilisation",
        };
    }
  }, [metricToDisplay]);

  const benchmarks = useMemo((): ChartBenchmarkType[] => {
    const optimalRange: [number, number] = [OCCUPANCY_OPTIMAL_THRESHOLD * maxValue, maxValue];
    switch (metricToDisplay) {
      case "capacityPercentage":
      case "occupancyCount":
        return [
          {
            type: "line",
            label: "Max",
            value: maxValue,
            color: "#FB0C49",
            labelColor: "#FB0C49",
          },
          {
            type: "above",
            label: "Over",
            value: maxValue,
            color: "#D39125",
            labelColor: "#D39125",
          },
          {
            type: "range",
            label: "Optimal",
            range: optimalRange,
            color: OCCUPANCY_OPTIMAL,
            labelColor: OCCUPANCY_OPTIMAL,
          },
        ];
    }
    return [];
  }, [metricToDisplay, maxValue]);

  const barAxisLabel = useMemo((): string => {
    if (daysBetween > 8) return "Days of the Month";
    if (daysBetween > 1) return "Days of the Week";
    return "Hours of the day";
  }, [daysBetween]);

  const getPointValue = useCallback(
    (breakdown: IOccupancyBreakdownDataPoint["breakdown"]): number | undefined => {
      if (!breakdown) return;
      const value = breakdown[metricToDisplay] as number | null;
      if (value === null) return;

      switch (metricToDisplay) {
        case "capacityPercentage":
        case "usagePercentage":
          return Math.round(value * 100);
        default:
          return value;
      }
    },
    [metricToDisplay],
  );

  const data = useMemo<HorizontalBarChartDataPoint<IOccupancyBreakdownDataPoint>[]>(() => {
    let labelDateFormat = (date: Dayjs) => `${date.format("D")}\n${date.format("dd")[0]}`;
    if (daysBetween <= 1) {
      labelDateFormat = (date: Dayjs) => date.format("HH:mm");
    }

    return lowResBreakdownData.map((dataPoint) => {
      const { startTimestamp, breakdown } = dataPoint;
      const startTime = dayjs(startTimestamp).tz(timezone);
      return {
        key: startTimestamp,
        label: labelDateFormat(startTime),
        value: getPointValue(breakdown),
        data: dataPoint,
        ...(daysBetween > 1 && {
          faded: !daysOfWeekSet.has(startTime.format("dddd").toLowerCase() as DayOfWeek),
        }),
      };
    });
  }, [lowResBreakdownData, timezone, daysOfWeekSet, daysBetween, getPointValue]);

  return {
    colorScale,
    maxValue,
    data,
    valueAxisLabel,
    barAxisLabel,
    yAxisFormatter,
    benchmarks,
    xAxisHeightConfig,
    yAxisWidthConfig,
  };
};
