import { FunctionComponent, useMemo } from "react";
import styles from "./styling/OccupancyUtilisationTable.module.scss";

import { Skeleton, SkeletonText, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { Typography } from "@tether-web-portal/components/common";
import {
  OccupancyFilterType,
  OccupancyUtilisationRowType,
  OccupancyUtilisationSpaceChildType,
} from "@tether-web-portal/web-workers/occupancy";

import { OccupancyUtilisationTableRow } from "./OccupancyUtilisationTableRow";

export const OccupancyUtilisationTable: FunctionComponent = () => {
  const { isLiveView, utilisationTableData, utilisationLoading, pageFilters } = useOccupancyContext();

  const filteredTableData = useMemo(() => {
    if (!pageFilters?.spaceType) {
      return utilisationTableData;
    } else if (pageFilters.spaceType === OccupancyFilterType.FLOOR) {
      return utilisationTableData.filter((x) => x.rowType !== OccupancyUtilisationRowType.BUILDING);
    } else {
      return utilisationTableData.filter((x) => x.rowType === OccupancyUtilisationRowType.ROOM);
    }
  }, [utilisationTableData, pageFilters]);

  const { showTotalInOut, showDwellTime } = useMemo(() => {
    const showTotalInOut =
      !isLiveView &&
      utilisationTableData.some((row) =>
        row.spaceChildren?.some((x) => x.childType === OccupancyUtilisationSpaceChildType.LINE)
      );
    const showDwellTime = utilisationTableData.some((row) =>
      row.spaceChildren?.some((x) => x.childType === OccupancyUtilisationSpaceChildType.REGION)
    );

    return {
      showTotalInOut,
      showDwellTime,
    };
  }, [utilisationTableData, isLiveView]);

  const tableHeaders = useMemo(() => {
    const updatedHeaders: { label: string; width?: number }[] = [
      { label: "Type" },
      { label: "Area", width: 10000 },
      { label: "Usage" },
      { label: "Occupancy" },
    ];

    if (isLiveView) {
      updatedHeaders.push(...[{ label: "People In" }, { label: "People Out" }, { label: "Foot-Traffic" }]);
    } else {
      updatedHeaders.push(...[{ label: "Min People" }, { label: "Max People" }, { label: "Avg People" }]);
    }

    if (showDwellTime) {
      updatedHeaders.push(...[{ label: "Min Dwell" }, { label: "Max Dwell" }, { label: "Avg Dwell" }]);
    }

    if (showTotalInOut) {
      updatedHeaders.push(...[{ label: "Total In" }, { label: "Total Out" }]);
    }

    updatedHeaders.push({ label: "Devices" });

    return updatedHeaders;
  }, [isLiveView, showDwellTime, showTotalInOut]);

  return (
    <Table className={styles.occupancyUtilisationTable}>
      <Thead>
        <Tr>
          {tableHeaders.map((header) => (
            <Th key={header.label} width={header.width}>
              {header.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {!utilisationLoading && filteredTableData.length < 1 ? (
          <Tr>
            <Td colSpan={tableHeaders.length} paddingY={10}>
              <Typography textAlign="center" size="lg" fontStyle="italic" color="gray">
                There are no spaces matching your filters...
              </Typography>
            </Td>
          </Tr>
        ) : utilisationLoading ? (
          Array.from({ length: 8 }).map((_, rowIndex) => (
            <Tr key={rowIndex}>
              {Array.from({ length: tableHeaders.length }).map((_, columnIndex) => (
                <Td key={columnIndex}>
                  <Skeleton height={23}>
                    <SkeletonText noOfLines={1} />
                  </Skeleton>
                </Td>
              ))}
            </Tr>
          ))
        ) : (
          filteredTableData.map((row, index) => (
            <OccupancyUtilisationTableRow
              key={index}
              row={row}
              isLiveView={isLiveView}
              showDwellTime={showDwellTime}
              showTotalInOut={showTotalInOut}
            />
          ))
        )}
      </Tbody>
    </Table>
  );
};
