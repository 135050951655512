import React, { useEffect, useMemo } from "react";

import { MetricsIntervalContext } from "lib/contexts";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";

export const useMetricsInterval = (initialDataInterval?: MetricDataInterval) => {
  const context = React.useContext(MetricsIntervalContext);

  const initialDataIntervalToUse = useMemo(() => initialDataInterval, [initialDataInterval]);

  useEffect(() => {
    if (initialDataIntervalToUse && context.metricsCurrentDataInterval !== initialDataIntervalToUse) {
      context.setMetricsInterval(initialDataIntervalToUse);
    }

    context.setInitialized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataIntervalToUse]);

  return context;
};
