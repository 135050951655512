import React, { createContext, useContext, useState } from "react";
import { Box, Flex, Stack, Tooltip, Spinner } from "@chakra-ui/react";
import { Center, Divider } from "@chakra-ui/layout";
import { RouterLink } from "@tether-web-portal/components/common";
import { BiHelpCircle } from "react-icons/bi";
import { TetherLogo } from "../logo/TetherLogo";
import HouseSideBarIcon from "./sidebar-icons/HouseSideBarIcon";
import TetherSideBarIcon from "./sidebar-icons/TetherSideBarIcon";
import MembersSideBarIcon from "./sidebar-icons/MembersSideBarIcon";
import SpeedometerSidebarIcon from "./sidebar-icons/SpeedometerSidebarIcon";
import AlertSidebarIcon from "./sidebar-icons/AlertSidebarIcon";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { FaVirus, FaWifi } from "react-icons/fa";
import { SidebarNavItem } from "./SidebarNavItem";
import { useAuth, useShouldShowTetherTick } from "../../lib/auth/useAuth";
import { useGetOrganisations } from "@tether-web-portal/api/organisations/hooks";

import { AccountSwitcherContainer } from "./AccountSwitcherContainer";
import { useQueryClient } from "react-query";
import { userRoleHasPermission } from "@tether-web-portal/services/PermissionService";
import { motion } from "framer-motion";
import { useHotkeys } from "react-hotkeys-hook";
import { GlobalPropertySearch } from "../global-property-search/GlobalPropertySearch";
import { useLocation } from "react-router-dom";

interface AuthenticatedSidebarLayoutProps {
  children: React.ReactNode;
  openMenuIconColor?: string;
}

interface SidebarProps {
  isOpen: boolean;
  onToggleSidebar: () => void;
}

export const expandedMenuItemVariants = {
  expanded: {
    opacity: 1,
    display: "flex",
  },
  collapsed: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
};

const Sidebar = ({ isOpen, onToggleSidebar }: SidebarProps) => {
  const { organisations } = useGetOrganisations();
  const location = useLocation();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const { shouldShowTetherTick } = useShouldShowTetherTick();
  const user = auth.user!;

  const pathname = location.pathname;

  return (
    <Box height="100vh" w={"full"} bg="gray.900" color="white" fontSize="sm">
      <Flex h="full" direction="column" px="4" pt="4">
        <Box position="relative">
          <Box position="absolute" right={"12px"} top={"8px"}>
            <Tooltip label="Toggle sidebar (ctrl+b)" fontSize="md">
              <Box cursor="pointer" onClick={() => onToggleSidebar()}>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  {isOpen ? (
                    <AiOutlineMenuFold fill="white" size={24} />
                  ) : (
                    <AiOutlineMenuUnfold fill="white" size={24} />
                  )}
                </motion.div>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box height="60px">
          <motion.div variants={expandedMenuItemVariants}>
            <Box mb="20px" mt={2} mr="24px">
              {isOpen && (
                <RouterLink to="/">
                  <TetherLogo height={40} width={150} fill="white" />
                </RouterLink>
              )}
            </Box>
          </motion.div>
        </Box>

        <Stack mt="16px" spacing="8px" flex="1" overflowX="hidden">
          <GlobalPropertySearch isCollapsed={!isOpen} />
          <SidebarNavItem
            isActive={pathname === "/"}
            hasPermission={true}
            href={"/"}
            icon={<HouseSideBarIcon />}
            label="Properties"
            isCollapsed={!isOpen}
          />
          <SidebarNavItem
            isActive={pathname === "/dashboard"}
            hasPermission={true}
            href="/dashboard"
            icon={<SpeedometerSidebarIcon />}
            label="Portfolio Dashboard"
            isCollapsed={!isOpen}
          />
          <SidebarNavItem
            isActive={pathname === "/alerts"}
            hasPermission={true}
            href="/alerts"
            icon={<AlertSidebarIcon />}
            label="Alerts"
            isCollapsed={!isOpen}
          />
          <SidebarNavItem
            hasPermission={userRoleHasPermission(user.currentOrganisationRoleId, "MANAGE_DEVICES")}
            isActive={pathname === "/device-management"}
            href="/device-management"
            icon={<FaWifi color="#fff" />}
            label="Devices"
            isCollapsed={!isOpen}
          />

          <SidebarNavItem
            hasPermission={userRoleHasPermission(user.currentOrganisationRoleId, "MANAGE_USERS")}
            isActive={pathname === "/members"}
            href="/members"
            icon={<MembersSideBarIcon />}
            label="Members"
            isCollapsed={!isOpen}
          />
          <Divider style={{ borderColor: "gray" }} />

          <SidebarNavItem
            hasPermission={true}
            isActive={pathname === "/airborne-index/organisation"}
            href="/airborne-index/organisation"
            icon={<FaVirus size={18} color="white" />}
            label="Airborne Index"
            isCollapsed={!isOpen}
          />
          {Boolean(shouldShowTetherTick) && (
            <SidebarNavItem
              hasPermission={userRoleHasPermission(user.currentOrganisationRoleId, "MANAGE_TETHER_TICK")}
              isActive={pathname === "/tether-tick"}
              href="/tether-tick"
              icon={<TetherSideBarIcon />}
              label="Tether Tick"
              isCollapsed={!isOpen}
            />
          )}

          <SidebarNavItem
            hasPermission={true}
            isActive={false}
            href="https://partners.tetherhq.com/en/knowledge-base"
            icon={<BiHelpCircle color="white" />}
            label="Help"
            isExternal={true}
            isCollapsed={!isOpen}
          />
        </Stack>

        <Box mx={-10} px={10} pt={1} borderTopWidth="thin" borderTopColor="whiteAlpha.300">
          <AccountSwitcherContainer
            isCollapsed={!isOpen}
            onLogout={() => {
              queryClient.cancelQueries();
              queryClient.removeQueries();
              queryClient.resetQueries();
              queryClient.clear();
              auth.signOut();
            }}
            user={user}
            organisations={organisations}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const MotionBox = motion(Box);

const AuthenticatedSidebarLayout = ({ children }: AuthenticatedSidebarLayoutProps) => {
  const { sidebarIsOpen, toggleSidebar } = useSidebarLayout();
  useHotkeys("ctrl+b, cmd+b", (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSidebar();
  });

  const variants = {
    collapsed: { width: sideBarWidths.collapsed },
    expanded: { width: sideBarWidths.expanded },
  };

  const sidebarState = sidebarIsOpen ? "expanded" : "collapsed";

  return (
    <Box
      height="100vh"
      width="100%"
      overflow="hidden"
      position="relative"
      sx={{
        "@media print": {
          height: "100%",
        },
      }}
    >
      <Flex h="full" id="app-container">
        <MotionBox
          key="motionSidebar"
          initial={false}
          animate={sidebarState}
          variants={variants}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <Sidebar isOpen={sidebarIsOpen} onToggleSidebar={toggleSidebar} />
        </MotionBox>
        <Box id="sidebarContainer" height="100%" bg="#F3F3F3" overflow="auto" flex="1">
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

interface SidebarLayoutProps {
  children: React.ReactNode;
}

interface SidebarContextData {
  sidebarIsOpen: boolean;
  toggleSidebar: () => void;
  currentSidebarWidth: number;
}
export const sideBarWidths = {
  collapsed: 80,
  expanded: 256,
};

export const SidebarContext = createContext({} as SidebarContextData);

export function useSidebarLayout() {
  return useContext(SidebarContext);
}

export const SidebarLayout = ({ children }: SidebarLayoutProps) => {
  const { user } = useAuth();
  const sidebarWasOpen = localStorage.getItem("sidebarIsOpen") === "true";
  const defaultSidebarIsOpen = sidebarWasOpen ? (window.innerWidth > 1200 ? true : false) : false;
  const [sidebarIsOpen, setSidebarIsOpen] = useState(defaultSidebarIsOpen);
  const toggleSidebar = () => {
    localStorage.setItem("sidebarIsOpen", (!sidebarIsOpen).toString());
    setSidebarIsOpen(!sidebarIsOpen);

    // Trigger a resize event to resize the graphs (done here rather than on animation complete to allow for
    // a smoother transition).
    window.dispatchEvent(new UIEvent("resize"));
    // Trigger a second one to make the "More" tag on the property tags rerender after the animation.
    setTimeout(() => window.dispatchEvent(new UIEvent("resize")), 200);
  };

  if (!user) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <SidebarContext.Provider
      value={{
        sidebarIsOpen,
        toggleSidebar,
        currentSidebarWidth: sidebarIsOpen ? sideBarWidths.expanded : sideBarWidths.collapsed,
      }}
    >
      <AuthenticatedSidebarLayout>{children}</AuthenticatedSidebarLayout>
    </SidebarContext.Provider>
  );
};
