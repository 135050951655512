import { SVGProps, useState, useMemo, useRef } from "react";
import { useSVGWheelListener } from "@tether-web-portal/components/charts/interaction/useSVGWheelListener";
import { usePointerMoveScroll } from "@tether-web-portal/components/charts/interaction/usePointerMoveScroll";
import {
  HorizontalBarChartDataPoint,
  HorizontalBarChartXScale,
  HorizontalBarChartYScale,
} from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { scaleBandInvert, scaleBandWidth } from "@tether-web-portal/components/charts/utils/scale-band-tools";

export const useInteractionRect = <T>({
  xMax,
  yMax,
  xScale,
  yScale,
  data,
}: {
  xMax: number;
  yMax: number;
  xScale: HorizontalBarChartXScale;
  yScale: HorizontalBarChartYScale;
  data: HorizontalBarChartDataPoint<T>[];
}): [
  SVGProps<SVGRectElement>,
  {
    hoveredBar?: HorizontalBarChartDataPoint<T>;
    scrollOffsetX: number;
    scrollOffsetY: number;
  },
] => {
  const ref = useRef<SVGRectElement>(null);
  const [hoveredBar, setHoveredBar] = useState<HorizontalBarChartDataPoint<T> | undefined>();

  const xScaleInvert = useMemo(() => scaleBandInvert<string>(xScale), [xScale]);

  const hoverOverCell = (event: React.PointerEvent<SVGRectElement>) => {
    if (!ref.current) return setHoveredBar(undefined);
    const { left, top } = ref.current.getBoundingClientRect();

    const containerX = ("clientX" in event ? event.clientX : 0) - left;
    const containerY = ("clientY" in event ? event.clientY : 0) - top;

    const barKey = xScaleInvert(containerX - scrollOffsetX);
    if (barKey === undefined) return setHoveredBar(undefined);

    const mouseValue = yScale.invert(containerY - scrollOffsetY);

    const hoveredBar = data.find(({ key, value }) => {
      if (key != barKey) return;
      if (!value) return;
      return value >= mouseValue;
    });
    setHoveredBar(hoveredBar);
  };

  const [scrollOffsetX, setScrollOffsetX] = useState<number>(0);
  const [scrollOffsetY, setScrollOffsetY] = useState<number>(0);

  const xWidth = useMemo(() => scaleBandWidth<string>(xScale), [xScale]);
  const yHeight = useMemo(() => Math.max(...yScale.range()), [yScale]);

  if (xWidth <= xMax && scrollOffsetX !== 0) {
    setScrollOffsetX(0);
  }
  if (yHeight <= yMax && scrollOffsetY !== 0) {
    setScrollOffsetY(0);
  }
  const canScroll = xWidth > xMax || yHeight > yMax;

  const onScrollPosition = (scrollLeft: number, scrollTop: number) => {
    setHoveredBar(undefined);
    setScrollOffsetX(-scrollLeft);
    setScrollOffsetY(-scrollTop);
  };

  const svgScrollView = {
    viewWidth: xMax,
    viewHeight: yMax,
    scrollWidth: xWidth,
    scrollHeight: yHeight,
    scrollLeft: -scrollOffsetX,
    scrollTop: -scrollOffsetY,
  };

  useSVGWheelListener(ref, svgScrollView, onScrollPosition);

  const [pointerState, svgProps] = usePointerMoveScroll({
    onStart: () => setHoveredBar(undefined),
    onMove: onScrollPosition,
    onHover: hoverOverCell,
    onEnd: () => setHoveredBar(undefined),
    ...svgScrollView,
  });

  return [
    {
      ref,
      style: {
        cursor: canScroll ? (pointerState ? "grabbing" : "grab") : hoveredBar ? "pointer" : "",
        userSelect: "none",
        overscrollBehaviorBlock: "contain",
        overflow: "scroll",
      },
      ...svgProps,
    },
    {
      hoveredBar,
      scrollOffsetX,
      scrollOffsetY,
    },
  ];
};
