import { ReactNode, useMemo } from "react";
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon, CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { Box, Center, Heading, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { HeatMapDataPoint, HeatMapColorScale } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { OCCUPANCY_OPTIMAL_THRESHOLD } from "@tether-web-portal/components/occupancy/occupancy.colors";
import { IOccupancyBreakdownDataPoint } from "@tether-web-portal/web-workers/occupancy";
import styles from "./TooltipDisplay.module.scss";
import { SingleBarChart } from "@tether-web-portal/components/charts/single-bar/SingleBarChart";
import { dayjs } from "@tether-web-portal/utils/dayjs-setup";
import { TooltipDisplayComponent } from "@tether-web-portal/components/charts/types";
import { HorizontalBarChartDataPoint } from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";

const TimestampDisplay = ({
  dateString,
  timeString,
}: {
  dateString: string,
  timeString: string,
}) => (
  <Box p={2} >
    <Flex direction={'column'} gap={'6px'} >
      <Text fontSize={'xs'}>Time</Text>
      <Flex gap={'4px'} alignItems={'center'} >
        <Center>
          <CalendarIcon boxSize={'12px'} />
        </Center>
        <Heading size='xs' fontSize='xs'>
          {dateString}
        </Heading>
      </Flex>
      <Flex gap={'4px'} alignItems={'center'} >
        <Center>
          <TimeIcon boxSize={'12px'} />
        </Center>
        <Heading size='xs' fontSize='xs'>
          {timeString}
        </Heading>
      </Flex>
    </Flex>
  </Box>
);

const BasicNumberDisplay = ({
  title,
  value,
  icon
}: {
  title: ReactNode,
  value: ReactNode,
  icon: ReactNode
}) => (
  <Box p={2} >
    <Text fontSize={'xs'}>{title}</Text>
    <Flex gap={'4px'} alignItems={'center'} >
      <Center>
        {icon}
      </Center>
      <Heading size='xs' fontSize={'sm'}>
        {value}
      </Heading>
    </Flex>
  </Box>
);

export const TooltipDisplay: TooltipDisplayComponent<IOccupancyBreakdownDataPoint> = ({
  data: breakdownDataPoint,
  value = 0,
  colorScale,
  maxValue = 0,
}: {
  data?: IOccupancyBreakdownDataPoint;
  value?: number;
  colorScale: HeatMapColorScale;
  maxValue?: number;
}) => {
  const { startTimestamp, breakdown } = breakdownDataPoint || {};

  const {
    occupancyCount,
    entered,
    exited,
    footTraffic,
    usagePercentage,
    occupancyLimit,
    capacityPercentage,
    capacityUtilisationLevel,
  } = breakdown || {};

  const {
    dateString,
    timeString
  } = useMemo(
    () => {
      const date = dayjs(startTimestamp);
      return {
        dateString: date.format('L'),
        timeString: date.format('LT')
      }
    },
    [startTimestamp]
  );

  let occupancyPercent = 0;
  let occupancyValue = 0;
  if (occupancyCount && occupancyLimit) {
    occupancyPercent = (occupancyCount / occupancyLimit) * 100;
    occupancyValue = (occupancyCount / occupancyLimit) * maxValue;
  }

  let title = 'Low Capacity';
  if (value >= (maxValue * OCCUPANCY_OPTIMAL_THRESHOLD)) {
    title = 'Optimal Capacity';
  }
  if (value > maxValue) {
    title = 'Over Capacity';
  }

  return (
    <Flex direction={'column'}>
      <Center height={'22px'} fontSize={'sm'} bg={colorScale(value)} color={'white'} >
        {title}
      </Center>
      <Box p={2} >
        <Grid
          width={'auto'}
          templateRows='repeat(2, auto)'
          templateColumns='repeat(6, 1fr)'
          gap={2}
        >
          <GridItem className={styles['occupancy-tool-tip__graph-card']} colSpan={3} >
            <Box p={2} >
              <Flex direction={'column'} gap={'6px'} >
                <Text fontSize={'xs'}>Occupancy</Text>
                <Flex gap={'2px'} direction={'column'} >
                  <Heading size='xs' fontSize={'md'}>
                    {occupancyCount}/{occupancyLimit}
                  </Heading>
                  <SingleBarChart
                    value={occupancyValue}
                    maxValue={maxValue}
                    colorScale={colorScale}
                    height={3}
                  />
                </Flex>
                <Flex gap={'4px'} alignItems={'center'} >
                  {/* <Center>
                    <TimeIcon boxSize={'12px'} />
                  </Center> */}
                  <Heading size='xs' fontSize='xs'>
                    {occupancyPercent.toFixed(0)}% capacity
                  </Heading>
                </Flex>
              </Flex>
            </Box>
          </GridItem>
          <GridItem className={styles['occupancy-tool-tip__graph-card']} colSpan={3} >
            <TimestampDisplay
              dateString={dateString}
              timeString={timeString}
            />
          </GridItem>
          <GridItem className={styles['occupancy-tool-tip__graph-card']} colSpan={2} >
            <BasicNumberDisplay
              title={'Entered'}
              value={entered}
              icon={<ArrowUpIcon boxSize={'12px'} />}
            />
          </GridItem>
          <GridItem className={styles['occupancy-tool-tip__graph-card']} colSpan={2} >
            <BasicNumberDisplay
              title={'Exited'}
              value={exited}
              icon={<ArrowDownIcon boxSize={'12px'} />}
            />
          </GridItem>
          <GridItem className={styles['occupancy-tool-tip__graph-card']} colSpan={2} >
            <BasicNumberDisplay
              title={'Foot traffic'}
              value={footTraffic}
              icon={<ArrowUpDownIcon boxSize={'12px'} />}
            />
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
}
