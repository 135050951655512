import { useEffect, useMemo, useRef, useState } from "react";
import { Box, HStack, Tooltip, Text } from "@chakra-ui/react";

import { useGetOrganisationTags } from "@tether-web-portal/api/properties/hooks";
import { getOrganisationTagIconForIconName } from "./OrganisationTagIcons";

interface Tag {
  id: string;
  label: string;
  icon: JSX.Element;
}

interface OrganisationTagsDisplayProps {
  tagIds: string[];
  overflow?: "visible" | "grouped";
}
export const OrganisationTagsDisplay = ({
  tagIds = [],
  overflow = "visible",
}: OrganisationTagsDisplayProps) => {
  const { organisationTags } = useGetOrganisationTags();
  const tagListContainerRef = useRef<HTMLDivElement>(null);
  const [overflowCount, setOverflowCount] = useState(0);

  const tags = useMemo<Tag[]>(() => {
    return organisationTags
      ? organisationTags.map((tag) => {
          return {
            id: tag.id,
            label: tag.label,
            icon: getOrganisationTagIconForIconName(tag.icon, 20, "black"),
          };
        })
      : [];
  }, [organisationTags]);

  const tagsToDisplay = useMemo<Tag[]>(() => {
    return !tagIds ? [] : tags.filter((t) => tagIds.includes(t.id));
  }, [tags, tagIds]);

  useEffect(() => {
    if (!tagListContainerRef?.current) {
      return;
    }

    const calculateOverflowCount = () => {
      // Calculate offset and count for "+X More" tag if tags overflow and overflow=grouped is set
      let count = 0;
      if (overflow === "grouped" && tagListContainerRef.current?.children.length) {
        for (let i = 0; i < tagListContainerRef.current.children.length; i++) {
          const curr = tagListContainerRef.current.children[i] as HTMLDivElement;
          if (curr.offsetTop > 1) {
            // Element has been wrapped
            count++;
          }
        }
      }

      setOverflowCount(count);
    };

    const resizeObserver = new ResizeObserver(() => calculateOverflowCount());

    resizeObserver.observe(tagListContainerRef.current);
    calculateOverflowCount();

    return () => {
      resizeObserver.disconnect();
    };
  }, [tagListContainerRef, overflow]);

  return (
    <Box position="relative" overflow="visible" display="flex" justifyContent="flex-end">
      <Box
        ref={tagListContainerRef}
        display="flex"
        height={overflow === "visible" ? "auto" : 7}
        overflow={overflow === "visible" ? "visible" : "hidden"}
        justifyContent="flex-end"
        alignItems="flex-start"
        flexWrap="wrap"
        gap={2}
      >
        {tagsToDisplay.map((tag) => (
          <Box key={tag.id}>
            <Tooltip label={tag.label} hasArrow>
              <Box borderRadius={8} p={1} bg="gray.50">
                {tag.icon}
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Box>
      {overflowCount > 0 && (
        <Tooltip
          label={tags
            .slice(tags.length - overflowCount)
            .map((t) => t.label)
            .join(", ")}
        >
          <Box pl={2}>
            <HStack bg="black" borderRadius={5} height={7} pl={2} pr={2}>
              <Text whiteSpace="nowrap" color="white">
                +{overflowCount} More
              </Text>
            </HStack>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
