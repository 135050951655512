export const EntryExitIcon = ({ size, color = "black" }: { size: number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M15 12.75H3M3 12.75L6 9.75M3 12.75L6 15.75M3 5.25H15M15 5.25L12 2.25M15 5.25L12 8.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
