/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { QueryKey, QueryStatus, useQueries, UseQueryOptions } from "react-query";

export type AggregatedQueriesResponse<TData, TError> = {
  data: TData[];
  errors: TError[];
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  isRefetchError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  status: QueryStatus;
  dataUpdatedAt: number;
};

export function useAggregatedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queries: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[]
): AggregatedQueriesResponse<TData, TError> {
  // Memoize the queries to avoid creating new references on each render
  const stableQueries = useMemo(() => queries, [queries]);

  // Execute the queries
  const queryResults = useQueries(stableQueries);

  // Extract relevant states and useMemo to stabilize derived states
  const aggregatedState = useMemo(() => {
    const data = queryResults.map((query) => query.data as TData);
    const errors = queryResults.map((query) => query.error as TError).filter((error) => error !== undefined && error !== null);

    const isError = queryResults.some((query) => query.isError);
    const isIdle = queryResults.every((query) => query.isIdle);
    const isLoading = queryResults.some((query) => query.isLoading);
    const isSuccess = queryResults.every((query) => query.isSuccess);
    const isLoadingError = queryResults.some((query) => query.isLoadingError);
    const isRefetchError = queryResults.some((query) => query.isRefetchError);
    const isFetching = queryResults.some((query) => query.isFetching);
    const dataUpdatedAt = queryResults.map((query) => query.dataUpdatedAt).sort()[0];

    const status: QueryStatus = isSuccess ? "success" : isError ? "error" : isLoading ? "loading" : "idle";

    return {
      data,
      errors,
      isError,
      isIdle,
      isLoading,
      isLoadingError,
      isRefetchError,
      isSuccess,
      isFetching,
      status,
      dataUpdatedAt,
    };
  }, [
    queryResults.map((query) => query.data).join(","),
    queryResults.map((query) => query.error).join(","),
    queryResults.map((query) => query.isError).join(","),
    queryResults.map((query) => query.isIdle).join(","),
    queryResults.map((query) => query.isLoading).join(","),
    queryResults.map((query) => query.isSuccess).join(","),
    queryResults.map((query) => query.isLoadingError).join(","),
    queryResults.map((query) => query.isRefetchError).join(","),
    queryResults.map((query) => query.isFetching).join(","),
    queryResults.map((query) => query.dataUpdatedAt).join(","),
  ]);

  return aggregatedState;
}
