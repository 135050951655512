import React from "react";
import { NavItem } from "./NavItem";
import { RouterLink } from "@tether-web-portal/components/common";

interface SidebarNavItemProps {
  href: string;
  icon: JSX.Element;
  label: string;
  isActive: boolean;
  hasPermission: boolean;
  isExternal?: boolean;
  isCollapsed: boolean;
}

export const SidebarNavItem = ({
  isActive,
  href,
  icon,
  label,
  hasPermission,
  isExternal,
  isCollapsed,
}: SidebarNavItemProps) => {
  if (!hasPermission) {
    return (
      <NavItem
        isCollapsed={isCollapsed}
        disabled={true}
        active={false}
        icon={icon}
        label={label + " (Disabled)"}
      />
    );
  }

  return (
    <RouterLink aria-disabled={!hasPermission} to={href} isExternal={isExternal} _focus={{ outline: "none" }} _hover={{ textDecoration: "none" }}>
      <NavItem isCollapsed={isCollapsed} active={isActive} icon={icon} label={label} />
    </RouterLink>
  );
};
