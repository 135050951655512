import dayjs from "@tether-web-portal/dayjs-setup";
import { DeviceType } from "./DeviceType";
import { GraphColor } from "./Room";

/**
 * A physical circuit on a property.
 * A property can have more than one circuit associated.
 */
export interface Circuit {
  id: string;
  propertyId: string;
  circuitNodeId: string;
  installationLocationId?: string;
  name: string;
  color: GraphColor;
  type: "TOTAL" | "PHASE";
  phase: 1 | 2 | 3 | null;
}

export interface CircuitDeviceStatus {
  hotdropId?: string;
  oldFirmware?: boolean;
  network?: "SPARK" | "GATEWAY";
  rssi?: number;
  snr?: number;
  averageMessagePeriod?: number;
  gatewayEui?: string;
}

export interface CircuitDeviceStatusWithReading extends CircuitDeviceStatus {
  battery?: number;
  signal?: number;
  lastMessageReceivedAt?: string;
  lastSeenAt?: string;
}

export interface CircuitWithReading extends Circuit {
  image?: string;
  device?: {
    id: string;
    type: DeviceType;
    hardwareVersion: string;
    shortSerial?: string;
    serial?: string;
    sku?: string;
    createdAt: string;
    status: CircuitDeviceStatusWithReading;
    subDeviceIndex: number;
  };
}

export interface CircuitWithoutReading extends Circuit {
  image?: string;
  device?: {
    id: string;
    type: DeviceType;
    hardwareVersion: string;
    shortSerial?: string;
    sku?: string;
    createdAt: string;
    status: CircuitDeviceStatus;
    subDeviceIndex: number;
  };
}

export enum CircuitDeviceConnectionStatus {
  NEVER_ONLINE = "Never Seen",
  OFFLINE = "Offline",
  CHARGING = "Charging...",
  ONLINE = "Online",
}

export const getCircuitDeviceConnectionStatus = (
  hotdropStatus: CircuitDeviceStatusWithReading
): CircuitDeviceConnectionStatus => {
  if (!hotdropStatus.lastMessageReceivedAt && !hotdropStatus.lastSeenAt) {
    return CircuitDeviceConnectionStatus.NEVER_ONLINE;
  }

  if (dayjs(hotdropStatus.lastMessageReceivedAt).isAfter(dayjs().subtract(2, "hour"))) {
    return CircuitDeviceConnectionStatus.ONLINE;
  }

  // if last seen on network in last 2 hours, but not message received in last 2 hours its charging up
  if (hotdropStatus.lastSeenAt && dayjs(hotdropStatus.lastSeenAt).isAfter(dayjs().subtract(2, "hour"))) {
    return CircuitDeviceConnectionStatus.CHARGING;
  }

  return CircuitDeviceConnectionStatus.OFFLINE;
};
