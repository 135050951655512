import { Button, ButtonProps } from "@chakra-ui/button";
interface SecondaryButtonProps extends ButtonProps {
  height?: number;
  title: string;
  width?: number;
  fontSize?: string;
  onClick?: () => void;
  loading?: boolean;
  size?: string;
  colorScheme?: string;
  leftIcon?: React.ReactElement;
  borderColor?: string;
  textColor?: string;
}
export const SecondaryButton = ({
  title,
  onClick,
  loading,
  size = "sm",
  colorScheme = "black",
  leftIcon,
  textColor,
  borderColor,
  fontSize,
  ...others
}: SecondaryButtonProps) => {
  return (
    <Button
      leftIcon={leftIcon}
      size={size}
      colorScheme={colorScheme}
      variant="outline"
      onClick={onClick}
      isLoading={loading}
      bg="white"
      textColor={textColor}
      borderColor={borderColor}
      borderWidth={1}
      fontSize={fontSize}
      {...others}
    >
      {title}
    </Button>
  );
};
