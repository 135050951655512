import { CircuitWithReading } from "./Circuit";
import { GraphColor } from "./Room";

export interface CircuitNode {
  id: string;
  propertyId: string;
  type: CircuitNodeType;
  tagIds: string[];
  parentId: string | null;
  color: GraphColor;
  name: string;
  children: CircuitNode[];
  circuits: CircuitWithReading[];
  createdAt: string;
}

export interface CircuitNodeWithUsage extends CircuitNode {
  usage: { totalkWh: number } | null;
  children: CircuitNodeWithUsage[];
}

export interface CircuitNodeUsage {
  startTimestamp: string;
  endTimestamp: string;
  totalkWh: number;
}

export const CircuitNodeTypes = ["MAINS", "BOARD", "CIRCUIT", "LOAD", "OTHER"] as const;
export type CircuitNodeType = typeof CircuitNodeTypes[number];
export const CircuitNodeTypeDescription: Record<CircuitNodeType, string> = {
  ["MAINS"]: "Mains",
  ["BOARD"]: "Distribution Board",
  ["LOAD"]: "Asset",
  ["CIRCUIT"]: "Circuit",
  ["OTHER"]: "Other",
};

export const getCircuitNodeFromTree = (
  circuitNodeTree: CircuitNode[],
  circuitNodeId: string
): CircuitNode | undefined => {
  if (!circuitNodeId) return undefined;
  if (!circuitNodeTree) return undefined;
  const circuitNode = circuitNodeTree?.find((circuitNode) => circuitNode.id === circuitNodeId);
  if (circuitNode) {
    return circuitNode;
  }
  for (const circuitNode of circuitNodeTree) {
    const childCircuitNode = getCircuitNodeFromTree(circuitNode.children, circuitNodeId);
    if (childCircuitNode) {
      return childCircuitNode;
    }
  }
  return undefined;
};
