import { FunctionComponent } from "react";
import styles from "./styling/OccupancyUtilisationTableRow.module.scss";

import { Td, Tr, HStack } from "@chakra-ui/react";
import { FaLink } from "react-icons/fa6";

import {
  IOccupancyUtilisationRow,
  IOccupancyUtilisationSpaceChildRow,
  OccupancyUtilisationSpaceChildType,
} from "@tether-web-portal/web-workers/occupancy";

import { Tag, Typography } from "@tether-web-portal/components/common";
import { EntryExitIcon, RegionIcon } from "@tether-web-portal/components/icons/occupancy";
import { DeviceIcon } from "@tether-web-portal/components/device/DeviceIcon";
import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { RoomIcon } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";

import { EmptyTableDataBlock } from "./EmptyTableDataBlock";
import { OccupancyUsageFrequencyIndicator } from "./OccupancyUsageFrequencyIndicator";
import { OccupancySpaceNameLink } from "./OccupancySpaceNameLink";

interface IOccupancyUtilisationChildTableRowProps {
  row: IOccupancyUtilisationSpaceChildRow;
  parentRow: IOccupancyUtilisationRow;
  renderAsRoom?: boolean;
  showDwellTime?: boolean;
  showTotalInOut?: boolean;
  isLiveView?: boolean;
}
export const OccupancyUtilisationChildTableRow: FunctionComponent<IOccupancyUtilisationChildTableRowProps> = (
  props: IOccupancyUtilisationChildTableRowProps
) => {
  const { row, showDwellTime, showTotalInOut, isLiveView, parentRow, renderAsRoom } = props;

  const formatDwellTime = (dwellTimeSeconds: number): string => {
    if (row.childType !== OccupancyUtilisationSpaceChildType.REGION) return "";

    const hours = Math.floor(dwellTimeSeconds / (60 * 60));
    const minutes = Math.floor(dwellTimeSeconds / 60) - hours * 60;
    const seconds = dwellTimeSeconds - minutes * 60 - hours * 60 * 60;

    const formattedParts: string[] = [];
    if (hours > 0) formattedParts.push(`${hours}h`);
    if (minutes > 0 || hours > 0) formattedParts.push(`${minutes}m`);

    formattedParts.push(`${seconds}s`);

    return formattedParts.join(" ");
  };

  return (
    <Tr className={styles.occupancyUtilisationTableRow}>
      {/* TYPE */}
      <Td>
        {renderAsRoom ? (
          <RoomIcon type={parentRow.spaceType!} />
        ) : row.childType === OccupancyUtilisationSpaceChildType.REGION ? (
          <RegionIcon size={23} />
        ) : (
          <EntryExitIcon size={23} />
        )}
      </Td>

      {/* SPACE NAME */}
      <Td>
        <OccupancySpaceNameLink utilisationRow={renderAsRoom ? parentRow : undefined}>
          <HStack justifyContent="space-between">
            <Typography>{row.name}</Typography>

            <Tag
              label={row.childType === OccupancyUtilisationSpaceChildType.REGION ? "Region" : "Entry/Exit"}
            />
          </HStack>
        </OccupancySpaceNameLink>
      </Td>

      {/* USAGE FREQUENCY */}
      {row.childType === OccupancyUtilisationSpaceChildType.REGION ? (
        <Td>
          <OccupancyUsageFrequencyIndicator usage={row.usage} isLiveView={isLiveView} />
        </Td>
      ) : (
        <EmptyTableDataBlock />
      )}

      {/* OCCUPANCY */}
      <EmptyTableDataBlock />

      {/* PEOPLE COUNT (MIN - MAX - AVG) */}
      {row.childType === OccupancyUtilisationSpaceChildType.REGION ? (
        <>
          <Td>{row.occupants.min}</Td>
          <Td>{row.occupants.max}</Td>
          <Td>{row.occupants.mean}</Td>
        </>
      ) : isLiveView && row.childType === OccupancyUtilisationSpaceChildType.LINE ? (
        <>
          <Td>{row.occupants.entered}</Td>
          <Td>{row.occupants.exited}</Td>
          <EmptyTableDataBlock />
        </>
      ) : (
        <EmptyTableDataBlock emptyBlocksCount={3} />
      )}

      {/* DWELL TIME (MIN - MAX - AVG) */}
      {row.childType === OccupancyUtilisationSpaceChildType.REGION ? (
        [row.dwellTime.min, row.dwellTime.max, row.dwellTime.mean].map((x, index) => (
          <Td key={index} className={styles.noWrap}>
            {formatDwellTime(x)}
          </Td>
        ))
      ) : showDwellTime ? (
        <EmptyTableDataBlock emptyBlocksCount={3} />
      ) : null}

      {/* TOTAL IN & OUT */}
      {!isLiveView && row.childType === OccupancyUtilisationSpaceChildType.LINE ? (
        <>
          <Td>{row.occupants.entered}</Td>
          <Td>{row.occupants.exited}</Td>
        </>
      ) : showTotalInOut ? (
        <EmptyTableDataBlock emptyBlocksCount={2} />
      ) : null}

      {/* DEVICES */}
      <Td>
        <HStack>
          <DeviceIcon type={DeviceType.ROOM_RADAR} size={24} />
          {renderAsRoom ? <Typography>1</Typography> : <FaLink />}
        </HStack>
      </Td>
    </Tr>
  );
};
