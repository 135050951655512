import { Image } from "@chakra-ui/image";
import { Box, HStack, Text } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/tooltip";
import { useEffect, useMemo, useState } from "react";
import {
  CircuitWithReading,
  getCircuitDeviceConnectionStatus,
  CircuitDeviceConnectionStatus,
} from "@tether-web-portal/types/Circuit";
import SignalStrengthIndicator from "../../indicators/SignalStrengthIndicator";
import { ManageRoomLocationMenu } from "../../property/room/ManageRoomLocationMenu";
import { CircuitModal } from "./CircuitModal";
import { LocationType, useGetPropertyDetail } from "@tether-web-portal/api/properties/hooks";
import { ManageOutsideOrCircuitMenu } from "../../property/ManageOutsideOrCircuitMenu";
import { CircuitNode } from "@tether-web-portal/types/CircuitNode";
import HotDropBolt from "./ChargingBolt";
import PhaseIcon from "../../circuitNodes/PhaseIcon";
import { formatCircuitPhaseTitle } from "./formatCircuitPhaseTitle";
import { DeviceIcon } from "@tether-web-portal/components/device/DeviceIcon";

interface CircuitRowProps {
  circuit: CircuitWithReading;
  circuitNode?: CircuitNode;
  propertyId: string;
}

export const CircuitRow = ({ circuit, circuitNode, propertyId }: CircuitRowProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [imageLoadError, setImageLoadError] = useState(false);
  const hotdropStatus = useMemo(
    () => (circuit.device ? getCircuitDeviceConnectionStatus(circuit.device.status) : null),
    [circuit]
  );

  const CircuitPhaseIcon = () => {
    if (!circuit?.device?.status) return null;

    const hotdropStatus = getCircuitDeviceConnectionStatus(circuit.device.status);

    if (hotdropStatus === CircuitDeviceConnectionStatus.ONLINE) {
      return <PhaseIcon size={23} fill="#6DC96E" />;
    }
    if (hotdropStatus === CircuitDeviceConnectionStatus.OFFLINE) {
      return <PhaseIcon size={23} fill="#FB5169" />;
    }
    if (hotdropStatus === CircuitDeviceConnectionStatus.NEVER_ONLINE) {
      return <PhaseIcon size={23} fill="#FB5169" />;
    }
    if (hotdropStatus === CircuitDeviceConnectionStatus.CHARGING) {
      return <HotDropBolt height={23} width={23} fill={"#595F6A"} />;
    }
    return <PhaseIcon size={23} fill={"black"} />;
  };

  const { propertyInfo } = useGetPropertyDetail(circuit.propertyId);
  const circuitFromPropertyInfo = useMemo(() => propertyInfo?.circuits?.find((c) => c.id === circuit.id), [
    propertyInfo,
    circuit,
  ]);

  useEffect(() => {
    setImageLoadError(false);
  }, [circuitFromPropertyInfo?.image]);

  return (
    <>
      {isOpen && (
        <CircuitModal
          circuit={circuit}
          onClose={() => {
            onClose();
          }}
          isOpen={isOpen}
          propertyId={propertyId}
          circuitNode={circuitNode}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        boxShadow="lg"
        py={1}
        px={3}
        bg="white"
        borderRadius={8}
        _hover={
          circuit.device
            ? {
                ":not(:has(.chakra-button:hover))": {
                  bg: "gray.50",
                  borderColor: "gray.400",
                  cursor: "pointer",
                  ".room-location-row": {
                    bg: "#f6f6f6",
                  },
                },
              }
            : {}
        }
        cursor={Boolean(circuit.device) ? "pointer" : "default"}
        onClick={() => {
          if (Boolean(circuit.device)) {
            onOpen();
          }
        }}
        borderWidth={1}
        borderColor={hotdropStatus === CircuitDeviceConnectionStatus.OFFLINE ? "#FB5169" : "white"}
      >
        <Box pb={0} display="flex" flexDirection="row" alignItems="center">
          {Boolean(circuit.device) && <DeviceIcon type={circuit.device?.type} />}
          {Boolean(!circuit.device) && (
            <Tooltip placement="bottom" hasArrow label={"No device assigned"}>
              <Box mr={4}>
                <HotDropBolt height={23} width={23} fill={"#595F6A"} />
              </Box>
            </Tooltip>
          )}
          <Text fontWeight="bold">
            {!circuit.device ? "No device assigned" : formatCircuitPhaseTitle(circuit)}
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {Boolean(hotdropStatus) && (
            <>
              {Boolean(circuit.device?.status.signal) && (
                <Box pr={4}>
                  <SignalStrengthIndicator signalStrength={circuit.device!.status.signal!} />
                </Box>
              )}
              <Tooltip placement="bottom" hasArrow label={hotdropStatus}>
                <Box>
                  <CircuitPhaseIcon />
                </Box>
              </Tooltip>
            </>
          )}
          {Boolean(circuitFromPropertyInfo?.image) && !imageLoadError && (
            <Image
              fallbackSrc="https://via.placeholder.com/150"
              onClick={() => window.open(circuitFromPropertyInfo!.image, "_blank")}
              onError={() => setImageLoadError(true)}
              alt="Circuit image"
              cursor="pointer"
              src={circuitFromPropertyInfo!.image}
              borderRadius="4px"
              height="32px"
              width="32px"
              ml="16px"
            />
          )}

          <HStack ml={2}>
            {Boolean(circuit.device) && (
              <ManageRoomLocationMenu
                propertyId={propertyId}
                locationId={circuit.id}
                locationType={LocationType.Circuit}
                locationName={circuit.name}
                hasDevice={true}
                onEditClick={() => {
                  onOpen();
                }}
                onViewDetailsClick={() => {
                  onOpen();
                }}
              />
            )}
            {Boolean(!circuit.device) && (
              <>
                <ManageOutsideOrCircuitMenu
                  location={circuit}
                  locationType={LocationType.Circuit}
                  propertyId={propertyId}
                />
              </>
            )}
          </HStack>
        </Box>
      </Box>
    </>
  );
};
