import { FullOutsideReading } from "@tether-web-portal/components/property-graph/GraphUtils";

export type CondensationRiskReading = [number, boolean | null];

export function calculateCondensationRiskReadings(
  dewPointReadings: [number, number][],
  outsides: FullOutsideReading[],
  milliseconds?: boolean
): CondensationRiskReading[] {
  const anHour = 3600 * (milliseconds ? 1000 : 1);

  const firstOutsideWithReadings = outsides.find(
    (o) => o.readings.temperature && o.readings.temperature.length > 0
  );

  if (!firstOutsideWithReadings) {
    return [];
  }

  const sortedDewPoint = dewPointReadings.sort((a, b) => a[0] - b[0]);
  const sortedOutside = [...firstOutsideWithReadings.readings.temperature].sort((a, b) => b[0] - a[0]);

  const results: [number, boolean | null][] = [];

  let previousTemp = sortedOutside.pop();
  sortedDewPoint.forEach((dp) => {
    // Skip forward until the next reading in the array is after the current dewpoint reading was taken
    while (sortedOutside.length > 0 && sortedOutside[sortedOutside.length - 1]![0] <= dp[0]) {
      previousTemp = sortedOutside.pop();
    }

    let temp: number | null;

    // Only use previous reading if it was less than an hour ago
    if (previousTemp && dp[0] - previousTemp[0] < anHour) {
      if (previousTemp[0] === dp[0]) {
        temp = previousTemp[1];
      } else if (sortedOutside.length > 0) {
        const nextTemp = sortedOutside[sortedOutside.length - 1];

        // No next temp or too far in future, use previous temp
        if (!nextTemp || nextTemp[0] - dp[0] > anHour) {
          temp = previousTemp[1];
        } else {
          // Use a linear time scale between the two temp points to estimate temperature at the dewpoint data point
          const y1 = nextTemp[1] - previousTemp[1];
          const x1 = nextTemp[0] - previousTemp[0];
          const x2 = dp[0] - previousTemp[0];
          temp = previousTemp[1] + x2 * (y1 / x1);
        }
      } else {
        temp = previousTemp[1];
      }
    } else {
      if (sortedOutside.length > 0) {
        const nextTemp = sortedOutside[sortedOutside.length - 1];

        // No next temp or too far in future, no temp available
        if (!nextTemp || nextTemp[0] - dp[0] > anHour) {
          temp = null;
        } else {
          temp = nextTemp[1];
        }
      } else {
        temp = null;
      }
    }

    const condensationRisk = temp === null ? null : temp < dp[1];
    results.push([dp[0], condensationRisk]);
  });
  return results;
}
