import { Stack } from "@chakra-ui/react";
import { FunctionComponent, useMemo } from "react";

import { BREAKPOINTS } from "@tether-web-portal/constants/breakpoints";

interface IContainerProps {
  children: React.ReactNode;
  maxWidth?: keyof typeof BREAKPOINTS;
  rowGap?: number;
  marginTop?: number;
  marginBottom?: number;
}
export const Container: FunctionComponent<IContainerProps> = (props: IContainerProps) => {
  const { children, maxWidth, rowGap, marginTop, marginBottom } = props;

  const margin = useMemo(() => {
    const marginTopVar = marginTop ? `var(--chakra-space-${marginTop})` : "0";
    const marginBottomVar = marginBottom ? `var(--chakra-space-${marginBottom})` : "0";
    return `${marginTopVar} auto ${marginBottomVar} auto`;
  }, [marginTop, marginBottom]);

  return (
    <Stack
      className="container"
      direction="column"
      rowGap={rowGap}
      sx={{
        maxWidth: BREAKPOINTS[maxWidth ?? "xl"],
        margin: margin, // Center content horizontally
        px: 2, // Add some horizontal padding for smaller screens
      }}
    >
      {children}
    </Stack>
  );
};
