import { RoomWithoutLocationReadings } from "@tether-web-portal/types/Room";
import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { MonitoringHours } from "@tether-web-portal/types/MonitoringHours";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";

import {
  IOccupancyPageFilters,
  IRoomOccupancyAggregates,
  OccupancyFilterType,
  OCCUPANCY_DEVICE_TYPES,
  OCCUPANCY_CHILDREN_DEVICE_TYPES,
  RoomAggregatedData,
} from "@tether-web-portal/web-workers/occupancy/types/OccupancyWorker";
import { OccupancyUtilisationLevels } from "@tether-web-portal/web-workers/occupancy/types/OccupancyUtilisation";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { RoomLocationWithReading } from "@tether-web-portal/types/RoomLocation";
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

export const applyPageLevelFilters = (
  rooms: IRoomOccupancyAggregates[],
  filters: IOccupancyPageFilters | undefined
): IRoomOccupancyAggregates[] => {
  if (!filters) {
    return rooms;
  }

  const { spaceType, floorIndex, spaceId } = filters;
  if (spaceType === OccupancyFilterType.FLOOR) return rooms.filter((x) => x.floorIndex === floorIndex!);
  if (spaceType === OccupancyFilterType.ROOM) return rooms.filter((x) => x.id === spaceId!);
  return rooms;
};

export const makeFloorLevelSpaceName = (floorIndex: number): string =>
  floorIndex === 0 ? "Ground Level" : `Level ${floorIndex}`;

export const roundPercentage = (val: number): number => Math.ceil(val * 1000) / 1000;

export const isRoomCapacityAble = (room?: RoomWithoutLocationReadings): boolean => {
  if (!room) {
    return false;
  }
  return room.roomLocations.some((x) => x.device?.type === DeviceType.ROOM_RADAR);
};

export const checkIfRoomLocationHasActiveOccupancyChildrenDevice = (
  roomLocation: RoomLocationWithReading
): boolean => {
  return (
    roomLocation.device &&
    OCCUPANCY_CHILDREN_DEVICE_TYPES.includes(roomLocation.device.type as DeviceType) &&
    Object.keys(roomLocation.device.lastSample?.sample ?? {}).length > 0
  );
};

export const checkIfRoomHasOccupancyDevices = (room?: RoomWithoutLocationReadings) => {
  return (room?.roomLocations ?? []).some(
    (x) => x.device && OCCUPANCY_DEVICE_TYPES.includes(x.device.type as DeviceType)
  );
};

export const getUtilisationLevel = (percentage: number | null): OccupancyUtilisationLevels => {
  if (!percentage) return OccupancyUtilisationLevels.LOW;
  else if (percentage > 1) return OccupancyUtilisationLevels.OVER;
  else if (percentage >= 0.4) return OccupancyUtilisationLevels.OPTIMAL;

  return OccupancyUtilisationLevels.LOW;
};

export const getRoomsWithAggregates = (
  rooms: RoomWithoutLocationReadings[] = [],
  roomAggregates: RoomAggregatedData[]
): IRoomOccupancyAggregates[] => {
  return rooms.filter(checkIfRoomHasOccupancyDevices).map(
    (r) =>
      ({
        ...r,
        aggregations: roomAggregates.find((x) => x?.roomId === r.id)?.aggregatedData ?? [],
      } as IRoomOccupancyAggregates)
  );
};

interface IFilterableData {
  startTimestamp: string;
  endTimestamp: string;
}
export function filterOutNonMonitoredHours<T extends IFilterableData>(
  data: T[],
  monitoringHours: MonitoringHours[],
  propertyTimezone: string
): T[] {
  const relativeMonitoringHours = monitoringHours.map((x) => {
    const relativeStartTime = dayjs.tz(x.startTime, DATE_FORMATS.TIME_24_HOUR, x.timezone);
    const relativeEndTime = dayjs.tz(x.endTime, DATE_FORMATS.TIME_24_HOUR, x.timezone);

    const propertyTZStartTime = dayjs.utc(relativeStartTime).tz(propertyTimezone);
    const propertyTZEndTime = dayjs.utc(relativeEndTime).tz(propertyTimezone);

    return {
      dayOfWeek: x.dayOfWeek,
      startTimeMinutes: propertyTZStartTime.hour() * 60 + propertyTZStartTime.minute(),
      endTimeMinutes: propertyTZEndTime.hour() * 60 + propertyTZEndTime.minute(),
    };
  });

  return data.filter((agg) => {
    const startTime = dayjs(agg.startTimestamp).tz(propertyTimezone);
    const endTime = dayjs(agg.endTimestamp).tz(propertyTimezone);

    const startTimeDayOfWeek = startTime.format(DATE_FORMATS.DAY_FULL).toLowerCase();
    const endTimeDayOfWeek = endTime.format(DATE_FORMATS.DAY_FULL).toLowerCase();

    const startTimeMinutes = startTime.hour() * 60 + startTime.minute();
    const endTimeMinutes = endTime.hour() * 60 + endTime.minute();

    return relativeMonitoringHours.some((x) => {
      const dayOfWeekMatch = startTimeDayOfWeek === x.dayOfWeek || endTimeDayOfWeek === x.dayOfWeek;
      const startTimeInRange = startTimeMinutes >= x.startTimeMinutes && startTimeMinutes <= x.endTimeMinutes;
      const endTimeInRange = endTimeMinutes >= x.startTimeMinutes && endTimeMinutes <= x.endTimeMinutes;
      return dayOfWeekMatch && (startTimeInRange || endTimeInRange);
    });
  });
}
