import { useDisclosure } from "@chakra-ui/hooks";
import React, { useMemo } from "react";
import { Organisation } from "@tether-web-portal/types/Organisation";
import { AuthUser } from "../../lib/auth/AuthProvider";
import SwitchOrganisationModal from "../switch-organisation/SwitchOrganisationModal";
import { AccountSwitcher } from "./AccountSwitcher";

interface AccountSwitcherContainerProps {
  user: AuthUser;
  organisations: Organisation[];
  onLogout: () => void;
  isCovidCareHeader?: boolean;
  isCollapsed?: boolean;
}

export const AccountSwitcherContainer = ({
  onLogout,
  organisations,
  user,
  isCovidCareHeader,
  isCollapsed,
}: AccountSwitcherContainerProps) => {
  const organisationName = useMemo(() => {
    return organisations.find((o) => o.id === user.currentOrganisationId)?.name;
  }, [organisations, user]);

  const {
    isOpen: isOrganisationSwitcherOpen,
    onOpen: onOpenOrganisationSwitcher,
    onClose: onCloseOrganisationSwitcher,
  } = useDisclosure();
  return (
    <>
      <SwitchOrganisationModal
        currentOrganisationId={user.currentOrganisationId}
        onClose={onCloseOrganisationSwitcher}
        isOpen={isOrganisationSwitcherOpen}
        organisations={organisations}
        isCovidCareHeader={isCovidCareHeader}
      />
      <AccountSwitcher
        onClickChangeOrganisation={onOpenOrganisationSwitcher}
        organisationName={organisationName}
        user={user}
        onLogout={onLogout}
        isCovidCareHeader={isCovidCareHeader}
        isCollapsed={isCollapsed}
      />
    </>
  );
};
