import { FunctionComponent, useMemo } from "react";

import { useLocation } from "react-router-dom";

import { RouterLink } from "@tether-web-portal/components/common";
import {
  IOccupancyUtilisationRow,
  OccupancyFilterKeyNames,
  OccupancyFilterType,
  OccupancyUtilisationRowType,
} from "@tether-web-portal/web-workers/occupancy";

interface IOccupancySpaceNameLinkProps {
  utilisationRow?: IOccupancyUtilisationRow;
  children: JSX.Element;
}
export const OccupancySpaceNameLink: FunctionComponent<IOccupancySpaceNameLinkProps> = (
  props: IOccupancySpaceNameLinkProps
) => {
  const { utilisationRow, children } = props;

  const location = useLocation();

  const linkHref = useMemo(() => {
    if (!utilisationRow) return null;
    switch (utilisationRow.rowType) {
      case OccupancyUtilisationRowType.BUILDING:
        return null;
      case OccupancyUtilisationRowType.LEVEL: {
        const spaceTypeFilter =
          utilisationRow.rowType === OccupancyUtilisationRowType.LEVEL
            ? OccupancyFilterType.FLOOR
            : OccupancyFilterType.ROOM;
        return [
          location.pathname,
          "?",
          `${OccupancyFilterKeyNames.SPACE_TYPE}=${spaceTypeFilter}`,
          "&",
          `${OccupancyFilterKeyNames.FLOOR_INDEX}=${utilisationRow.spaceFloorIndex || "0"}`,
        ].join("");
      }
      case OccupancyUtilisationRowType.ROOM:
        return `/area/room/${utilisationRow.spaceId}/occupancy`;
    }
  }, [utilisationRow, location]);

  if (!linkHref) {
    return children;
  }

  return <RouterLink to={linkHref}>{children}</RouterLink>;
};
