import { Group } from "@visx/group";
import { CSSProperties, ReactNode } from "react";

export const ColumnAxisBottomLabel = ({
  backgroundHeight,
  labelStyle,
  xMax,
  children,
  axisLabelHeight,
}: {
  backgroundHeight: number,
  xMax: number,
  children: ReactNode,
  axisLabelHeight: number,
  labelStyle: CSSProperties
}) => {
  return (
    <Group top={backgroundHeight} >
      <foreignObject
        x={0}
        y={0}
        height={axisLabelHeight}
        width={xMax}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            alignContent: 'center',
            textAlign: 'center',
            ...labelStyle
          }}
        >
          {children}
        </div>
      </foreignObject>
    </Group>
  );
}
