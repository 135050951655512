import { Box } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import React from "react";
import FullBatteryAreaIcon from "../areas/areas-icons/FullBatteryAreaIcon";
import LowBatteryAreasIcons from "../areas/areas-icons/LowBatteryAreasIcons";
import MidBatteryAreasIcon from "../areas/areas-icons/MidBatteryAreasIcon";
import HighBatteryAreaIcon from "../areas/areas-icons/HighBatteryAreaIcon";
import UpperBatteryAreasIcon from "../areas/areas-icons/UpperBatteryAreasIcon";

interface NewBatteryLevelIndicatorProps {
  /** Battery level between 0 and 100 */
  batteryLevel: number;
  tooltipLabel: string;
  isOffline?: boolean;
}

export const NewBatteryLevelIndicator = ({
  batteryLevel,
  tooltipLabel,
  isOffline,
}: NewBatteryLevelIndicatorProps) => {
  return (
    <>
      <Tooltip placement="bottom" hasArrow label={tooltipLabel} aria-label={tooltipLabel}>
        <Box opacity={isOffline ? 0.5 : 1}>
          {batteryLevel < 0.2 && <LowBatteryAreasIcons width={25} height={25} />}
          {batteryLevel >= 0.2 && batteryLevel < 0.3 && <MidBatteryAreasIcon width={25} height={25} />}
          {batteryLevel >= 0.3 && batteryLevel < 0.7 && <UpperBatteryAreasIcon width={25} height={25} />}
          {batteryLevel >= 0.7 && batteryLevel <= 0.9 && <HighBatteryAreaIcon width={25} height={25} />}
          {batteryLevel > 0.9 && <FullBatteryAreaIcon width={25} height={25} />}
        </Box>
      </Tooltip>
    </>
  );
};
