import React from "react";
import ArtRoomIcon from "../classroom-room-icons/ArtRoomIcon";
import ClassroomIcon from "../classroom-room-icons/ClassroomIcon";
import DormRoomIcon from "../classroom-room-icons/DormRoomIcon";
import HallIcon from "../classroom-room-icons/HallIcon";
import LabRoomIcon from "../classroom-room-icons/LabRoomIcon";
import LibraryIcon from "../classroom-room-icons/LibraryIcon";
import LockerRoomIcon from "../classroom-room-icons/LockerRoomIcon";
import MusicRoomIcon from "../classroom-room-icons/MusicRoomIcon";
import StaffRoomIcon from "../classroom-room-icons/StaffRoomIcon";
import TechnologyRoomIcon from "../classroom-room-icons/TechnologyRoomIcon";
import BreakRoomIcon from "../commercial-room-icons/BreakRoomIcon";
import ConferenceRoomIcon from "../commercial-room-icons/ConferenceRoomIcon";
import KitchenIcon from "../commercial-room-icons/KitchenIcon";
import MeetingRoomIcon from "../commercial-room-icons/MeetingRoomIcon";
import OfficeIcon from "../commercial-room-icons/OfficeIcon";
import OtherRoomIcon from "../commercial-room-icons/OtherRoomIcon";
import PrinterRoomIcon from "../commercial-room-icons/PrinterRoomIcon";
import ReceptionIcon from "../commercial-room-icons/ReceptionIcon";
import BoxingStudio from "../gym-room-icons/BoxingStudio";
import ClassStudio from "../gym-room-icons/ClassStudio";
import CycleStudio from "../gym-room-icons/CycleStudio";
import FunctionalStudio from "../gym-room-icons/FunctionalStudio";
import WeightsIcon from "../gym-room-icons/WeightsIcon";
import YogaStudio from "../gym-room-icons/YogaStudio";
import AttachedGarage from "../residential-room-icons/AttachedGarage";
import AtticIcon from "../residential-room-icons/AtticIcon";
import BathroomIcon from "../residential-room-icons/BathroomIcon";
import BedroomIcon from "../residential-room-icons/BedroomIcon";
import DiningIcon from "../residential-room-icons/DiningIcon";
import Garage from "../residential-room-icons/Garage";
import LaundryIcon from "../residential-room-icons/LaundryIcon";
import OpenPlanIcon from "../residential-room-icons/OpenPlanIcon";
import StairwellIcon from "../residential-room-icons/StairwellIcon";
import ToiletIcon from "../residential-room-icons/ToiletIcon";

export enum RoomType {
  Bedroom = "bedroom",
  Bathroom = "bathroom",
  Kitchen = "kitchen",
  Office = "office",
  Meeting = "meeting",
  Conference = "conference",
  Reception = "reception",
  Break = "break",
  Printer = "printer",
  Other = "other",
  Openplan = "openplan",
  Living = "living",
  Dining = "dining",
  Toilet = "toilet",
  Laundry = "laundry",
  Attic = "attic",
  Sleepout = "sleepout",
  OpenplanKitchenLivingDining = "openplan-kitchen-living-dining",
  Livingroom = "livingroom",
  Art = "art",
  Staffroom = "staffroom",
  Hall = "hall",
  Dormroom = "dormroom",
  Hallway = "hallway",
  Stairwell = "stairwell",
  AttachedGarage = "attached-garage",
  DetachedGarage = "detached-garage",
  Classroom = "classroom",
  Lockerroom = "lockerroom",
  Lab = "lab",
  Library = "library",
  Technology = "technology",
  Music = "music",
  GymClassStudio = "gym-class-studio",
  Boxing = "boxing",
  FunctionalStudio = "functional-studio",
  YogaStudio = "yoga-studio",
  WeightRoom = "weights-room",
  CycleStudio = "cycle-studio",
}

export type RoomIconSVGProps = {
  width?: number;
  height?: number;
  color?: string;
};

type IconMapProps = Omit<RoomIconProps, "type">;

const roomIconMap: Record<RoomType, (props: IconMapProps) => JSX.Element> = {
  bedroom: (props: IconMapProps) => <BedroomIcon height={23} width={23} {...props} />,
  bathroom: (props: IconMapProps) => <BathroomIcon height={23} width={23} {...props} />,
  office: (props: IconMapProps) => <OfficeIcon height={23} width={23} {...props} />,
  meeting: (props: IconMapProps) => <MeetingRoomIcon height={23} width={23} {...props} />,
  kitchen: (props: IconMapProps) => <KitchenIcon height={23} width={23} {...props} />,
  conference: (props: IconMapProps) => <ConferenceRoomIcon height={23} width={23} {...props} />,
  reception: (props: IconMapProps) => <ReceptionIcon height={23} width={23} {...props} />,
  break: (props: IconMapProps) => <BreakRoomIcon height={23} width={23} {...props} />,
  printer: (props: IconMapProps) => <PrinterRoomIcon height={23} width={23} {...props} />,
  other: (props: IconMapProps) => <OtherRoomIcon height={18} width={18} {...props} />,
  openplan: (props: IconMapProps) => <OpenPlanIcon height={23} width={23} {...props} />,
  "openplan-kitchen-living-dining": (props: IconMapProps) => (
    <OpenPlanIcon height={23} width={23} {...props} />
  ),
  livingroom: (props: IconMapProps) => <OpenPlanIcon height={23} width={23} {...props} />,
  living: (props: IconMapProps) => <OpenPlanIcon height={23} width={23} {...props} />,
  dining: (props: IconMapProps) => <DiningIcon height={23} width={23} {...props} />,
  toilet: (props: IconMapProps) => <ToiletIcon height={23} width={23} {...props} />,
  laundry: (props: IconMapProps) => <LaundryIcon height={23} width={23} {...props} />,
  attic: (props: IconMapProps) => <AtticIcon height={23} width={23} {...props} />,
  sleepout: (props: IconMapProps) => <DormRoomIcon height={23} width={23} {...props} />,
  art: (props: IconMapProps) => <ArtRoomIcon height={23} width={23} {...props} />,
  staffroom: (props: IconMapProps) => <StaffRoomIcon height={23} width={23} {...props} />,
  hall: (props: IconMapProps) => <HallIcon height={23} width={23} {...props} />,
  dormroom: (props: IconMapProps) => <DormRoomIcon height={23} width={23} {...props} />,
  hallway: (props: IconMapProps) => <HallIcon height={23} width={23} {...props} />,
  stairwell: (props: IconMapProps) => <StairwellIcon height={23} width={23} {...props} />,
  "attached-garage": (props: IconMapProps) => <AttachedGarage height={23} width={23} {...props} />,
  "detached-garage": (props: IconMapProps) => <Garage height={23} width={23} {...props} />,
  classroom: (props: IconMapProps) => <ClassroomIcon height={23} width={23} {...props} />,
  lockerroom: (props: IconMapProps) => <LockerRoomIcon height={23} width={23} {...props} />,
  lab: (props: IconMapProps) => <LabRoomIcon height={23} width={23} {...props} />,
  library: (props: IconMapProps) => <LibraryIcon height={23} width={23} {...props} />,
  technology: (props: IconMapProps) => <TechnologyRoomIcon height={23} width={23} {...props} />,
  music: (props: IconMapProps) => <MusicRoomIcon height={23} width={23} {...props} />,
  "gym-class-studio": (props: IconMapProps) => <ClassStudio height={23} width={23} {...props} />,
  boxing: (props: IconMapProps) => <BoxingStudio height={23} width={23} {...props} />,
  "functional-studio": (props: IconMapProps) => <FunctionalStudio height={23} width={23} {...props} />,
  "weights-room": (props: IconMapProps) => <WeightsIcon height={23} width={23} {...props} />,
  "cycle-studio": (props: IconMapProps) => <CycleStudio height={23} width={23} {...props} />,
  "yoga-studio": (props: IconMapProps) => <YogaStudio height={23} width={23} {...props} />,
};

interface RoomIconProps {
  type: RoomType;
  color?: string;
}

export const RoomIcon = ({ type, color }: RoomIconProps) => {
  if (type) {
    if (!roomIconMap[type]) {
      // console.log(type + " not in icon map");
      return null;
    }
    return roomIconMap[type]({ color });
  }
  return null;
};

export const isValidRoomIcon = (type: string) => {
  return Object.keys(roomIconMap).includes(type);
};
