import { FunctionComponent, useMemo } from "react";

import { HStack } from "@chakra-ui/react";

import { Select, Typography } from "@tether-web-portal/components/common";
import { IOptionItem } from "@tether-web-portal/types/common";
import { useOccupancyContext, OccupancyContextActions } from "@tether-web-portal/contexts/OccupancyContext";
import { kebabCaseToStartCase } from "@tether-web-portal/utils/stringUtils";
import {
  IOccupancyUtilisationTableFilters,
  OccupancyFilterType,
} from "@tether-web-portal/web-workers/occupancy";

export const OccupancyUtilisationHeader: FunctionComponent = () => {
  const { availableRoomTypes, utilisationTableFilters, dispatcher, pageFilters } = useOccupancyContext();

  const displayByOptions = useMemo<IOptionItem[]>(
    () => [
      { value: "ALL", label: "All" },
      { value: "LEVEL", label: "Level" },
      { value: "ROOM", label: "Room" },
    ],
    []
  );

  const typeFilterOptions = useMemo<IOptionItem[]>(
    () =>
      availableRoomTypes.map((roomType) => ({
        value: roomType,
        label: kebabCaseToStartCase(roomType),
      })),
    [availableRoomTypes]
  );

  const usageFilterOptions = useMemo<IOptionItem[]>(
    () => [
      { value: "LOW", label: "Low" },
      {
        value: "OPTIMAL",
        label: "Optimal",
      },
    ],
    []
  );

  const capacityFilterOptions = useMemo<IOptionItem[]>(
    () => [
      { value: "LOW", label: "Low" },
      {
        value: "OPTIMAL",
        label: "Optimal",
      },
      { value: "OVER", label: "Over" },
    ],
    []
  );

  const handleFilterChanged = (
    filter: keyof IOccupancyUtilisationTableFilters,
    option: IOptionItem | null
  ) => {
    const updatedFilters = {
      ...utilisationTableFilters,
      [filter]: option?.value ?? "",
    };
    dispatcher(OccupancyContextActions.SET_UTILISATION_TABLE_FILTERS(updatedFilters));
  };

  return (
    <HStack justifyContent="space-between" alignItems="center">
      <Typography size="xl" fontWeight="bold">
        Utilisation
      </Typography>

      {pageFilters?.spaceType !== OccupancyFilterType.ROOM && (
        <HStack columnGap={2}>
          {!pageFilters?.spaceType && (
            <HStack alignItems="center">
              <Typography noWrap>Display by:</Typography>
              <Select
                options={displayByOptions}
                onChange={(option) => handleFilterChanged("displayBy", option)}
                value={utilisationTableFilters.displayBy}
              />
            </HStack>
          )}

          <HStack alignItems="center">
            <Typography>Filter:</Typography>
            <Select
              placeholder="Type"
              options={typeFilterOptions}
              onChange={(option) => handleFilterChanged("type", option)}
              value={utilisationTableFilters.type}
            />
            <Select
              placeholder="Usage"
              options={usageFilterOptions}
              onChange={(option) => handleFilterChanged("usage", option)}
              value={utilisationTableFilters.usage}
            />
            <Select
              placeholder="Occupancy"
              options={capacityFilterOptions}
              onChange={(option) => handleFilterChanged("capacity", option)}
              value={utilisationTableFilters.capacity}
            />
          </HStack>
        </HStack>
      )}
    </HStack>
  );
};
