import {
  HorizontalBarChartColorScale,
  HorizontalBarChartDataPoint,
  HorizontalBarChartXScale,
  HorizontalBarChartYScale,
} from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { AxisConfig } from "@tether-web-portal/components/charts/types";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";

const FadedBackground = ({
  leftRadius,
  rightRadius,
  xWidth,
  xGap,
  height,
  radius,
}: {
  leftRadius: boolean;
  rightRadius: boolean;
  xWidth: number;
  xGap: number;
  height: number;
  radius: number;
}) => {
  return (
    <Group left={-xGap}>
      <rect fill={"#f8f8f8"} height={height} width={xWidth * 0.75} rx={leftRadius ? radius : 0} />
      <rect
        fill={"#f8f8f8"}
        height={height}
        x={xWidth / 4}
        width={xWidth * 0.75}
        rx={rightRadius ? radius : 0}
      />
    </Group>
  );
};

export const Bars = <T,>({
  data,
  yMax,
  xScale,
  yScale,
  colorScale,
  xAxisHeightConfig,
}: {
  data: HorizontalBarChartDataPoint<T>[];
  yMax: number;
  xScale: HorizontalBarChartXScale;
  yScale: HorizontalBarChartYScale;
  colorScale: HorizontalBarChartColorScale;
  xAxisHeightConfig: AxisConfig;
}) => {
  const fadedColumnHeight =
    yMax + xAxisHeightConfig.innerMargin + xAxisHeightConfig.label + xAxisHeightConfig.innerMargin / 2;

  const stepWidth = xScale.step();
  const xGap = (stepWidth - xScale.bandwidth()) / 2;

  return (
    <>
      {data.map(({ value = 0, key, label, faded }, i, arr) => {
        const barWidth = xScale.bandwidth();
        const barHeight = yMax - (yScale(value) ?? 0);
        const barX = xScale(key);
        const barY = yMax - barHeight;
        return (
          <Group key={key} left={barX}>
            {faded && (
              <FadedBackground
                leftRadius={!arr[i - 1]?.faded}
                rightRadius={!arr[i + 1]?.faded}
                xWidth={stepWidth}
                xGap={xGap}
                height={fadedColumnHeight}
                radius={6}
              />
            )}
            <Bar
              data-value={value}
              data-name={label}
              y={barY}
              width={barWidth}
              height={barHeight}
              rx={barWidth / 2}
              fill={colorScale(value)}
            />
          </Group>
        );
      })}
    </>
  );
};
