import { SVGProps, useState, useMemo, useRef } from "react";
import {
  HeatMapDataPoint,
  HeatMapXScale,
  HeatMapYScale,
} from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { useSVGWheelListener } from "@tether-web-portal/components/charts/interaction/useSVGWheelListener";
import { usePointerMoveScroll } from "@tether-web-portal/components/charts/interaction/usePointerMoveScroll";
import { scaleBandInvert, scaleBandWidth } from "@tether-web-portal/components/charts/utils/scale-band-tools";

export const useInteractionRect = <T>({
  xMax,
  yMax,
  xScale,
  yScale,
  dataMap,
}: {
  xMax: number;
  yMax: number;
  xScale: HeatMapXScale;
  yScale: HeatMapYScale;
  dataMap: Record<number, Record<number, HeatMapDataPoint<T>>>;
}): [
  SVGProps<SVGRectElement>,
  {
    hoveredCell?: HeatMapDataPoint<T>;
    scrollOffsetX: number;
    scrollOffsetY: number;
  },
] => {
  const ref = useRef<SVGRectElement>(null);
  const [hoveredCell, setHoveredCell] = useState<HeatMapDataPoint<T> | undefined>();

  const yScaleInvert = useMemo(() => scaleBandInvert<number>(yScale), [yScale]);
  const xScaleInvert = useMemo(() => scaleBandInvert<number>(xScale), [xScale]);
  const hoverOverCell = (event: React.PointerEvent<SVGRectElement>) => {
    if (!ref.current) return setHoveredCell(undefined);
    const { left, top } = ref.current.getBoundingClientRect();

    const containerX = ("clientX" in event ? event.clientX : 0) - left;
    const containerY = ("clientY" in event ? event.clientY : 0) - top;

    const columnKey = xScaleInvert(containerX - scrollOffsetX);
    if (columnKey === undefined) return setHoveredCell(undefined);

    const rowKey = yScaleInvert(containerY - scrollOffsetY);
    if (rowKey === undefined) return setHoveredCell(undefined);

    setHoveredCell(dataMap?.[columnKey]?.[rowKey] || undefined);
  };

  const [scrollOffsetX, setScrollOffsetX] = useState<number>(0);
  const [scrollOffsetY, setScrollOffsetY] = useState<number>(0);

  const xWidth = useMemo(() => scaleBandWidth<number>(xScale), [xScale]);
  const yHeight = useMemo(() => scaleBandWidth<number>(yScale), [yScale]);

  if (xWidth <= xMax && scrollOffsetX !== 0) {
    setScrollOffsetX(0);
  }
  if (yHeight <= yMax && scrollOffsetY !== 0) {
    setScrollOffsetY(0);
  }
  const canScroll = xWidth > xMax || yHeight > yMax;

  const onScrollPosition = (scrollLeft: number, scrollTop: number) => {
    setHoveredCell(undefined);
    setScrollOffsetX(-scrollLeft);
    setScrollOffsetY(-scrollTop);
  };

  const svgScrollView = {
    viewWidth: xMax,
    viewHeight: yMax,
    scrollWidth: xWidth,
    scrollHeight: yHeight,
    scrollLeft: -scrollOffsetX,
    scrollTop: -scrollOffsetY,
  };

  useSVGWheelListener(ref, svgScrollView, onScrollPosition);

  const [pointerState, svgProps] = usePointerMoveScroll({
    onStart: () => setHoveredCell(undefined),
    onMove: onScrollPosition,
    onHover: hoverOverCell,
    onEnd: () => setHoveredCell(undefined),
    ...svgScrollView,
  });

  return [
    {
      ref,
      style: {
        cursor: canScroll ? (pointerState ? "grabbing" : "grab") : hoveredCell ? "pointer" : "",
        userSelect: "none",
        overscrollBehaviorBlock: "contain",
        overflow: "scroll",
      },
      ...svgProps,
    },
    {
      hoveredCell,
      scrollOffsetX,
      scrollOffsetY,
    },
  ];
};
