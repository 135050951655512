import { Box, Spinner } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface ILoaderProps {
  isLoading: boolean;
  size?: "sm" | "md" | "lg";
}
export const Loader: FunctionComponent<ILoaderProps> = (props: ILoaderProps) => {
  const { isLoading, size } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <Box>
      <Spinner size={size} />
    </Box>
  );
};
