import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../api";
import { InvitedUser } from "../../types/InvitedUser";
import { IUserOrganisation } from "../../types/IUserOrganisation";
import { User } from "../../types/User";

export enum UserQueryKeys {
  USER = "user",
  USER_INVITE = "user-invite",
  USER_ORGANISATION = "user-organisation",
}

export const useGetUsers = (currentOrganisationId: string) => {
  const { data: users, ...rest } = useQuery<User[]>(
    [UserQueryKeys.USER, currentOrganisationId],
    () => api.get(`v2/user?organisationId=${currentOrganisationId}`),
    { enabled: !!currentOrganisationId, staleTime: Infinity }
  );

  return { users, ...rest };
};

export const useGetUser = (userId: string | undefined) => {
  const { data: user, ...rest } = useQuery<User>(
    [UserQueryKeys.USER, userId],
    () => api.get(`v2/user/${userId}`),
    {
      staleTime: Infinity,
      enabled: !!userId,
    }
  );

  return { user, ...rest };
};

export const useGetUserInvites = (currentOrganisationId: string) => {
  const { data: userInvites, ...rest } = useQuery<InvitedUser[]>(
    [UserQueryKeys.USER_INVITE, currentOrganisationId],
    () => api.get(`account/v2/invite?organisationId=${currentOrganisationId}`),
    {
      // stale time 1 min
      staleTime: 6000,
    }
  );
  return { userInvites, ...rest };
};

export const useGetUserOrganisationsForCurrentOrg = (currentOrganisationId: string) => {
  const { data: userOrganisations, ...rest } = useQuery<IUserOrganisation[]>(
    [UserQueryKeys.USER_ORGANISATION, currentOrganisationId],
    () => api.get(`v2/userorganisation?organisationId=${currentOrganisationId}`),
    {
      staleTime: Infinity,
    }
  );
  return { userOrganisations, ...rest };
};

export const useGetUserOrganisationsForUser = (userId: string | undefined) => {
  const { data: userOrganisations, ...rest } = useQuery<IUserOrganisation[]>(
    [UserQueryKeys.USER_ORGANISATION, userId],
    () => api.get(`v2/userorganisation?userId=${userId}`),
    {
      staleTime: Infinity,
    }
  );
  return { userOrganisations, ...rest };
};
