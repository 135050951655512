import { Alert, Box, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RoomWithLocationReadings } from "@tether-web-portal/types/Room";
import { makeRoomsByLevel } from "../../property/room/makeRoomsByLevel";
import { RoomsSort } from "../room-sort/types";
import { InsideDetails } from "./InsideDetails";
import { SortedRoomLocations } from "./SortedRoomLocations";

export const AREA_CARD_VERTICAL_PADDING = 4;
export const AREA_CARD_HORIZONTAL_PADDING = 4;

interface InsideDetailsPanelProps {
  rooms: RoomWithLocationReadings[];
  propertyId: string;
  searchText: string;
  sort?: RoomsSort;
  hideLevels?: boolean;
  tagIds?: string[];
}

export const InsideDetailsPanel = ({
  rooms,
  propertyId,
  searchText,
  sort,
  hideLevels,
  tagIds,
}: InsideDetailsPanelProps) => {
  const byLevel = useMemo(() => makeRoomsByLevel<RoomWithLocationReadings>(rooms), [rooms]);

  if (sort) {
    return (
      <Box mt="16px">
        <SortedRoomLocations
          propertyId={propertyId}
          rooms={rooms}
          sort={sort}
          searchText={searchText}
          tagIds={tagIds}
        />
      </Box>
    );
  }

  if (!byLevel.length) {
    return (
      <Alert my={5} colorScheme={"blue"}>
        You do not have inside locations on this property
      </Alert>
    );
  }

  return (
    <Box mt="16px">
      {byLevel.map((level) => (
        <Box key={level.floorIndex} mb="32px">
          <Text mb="8px" fontSize={18} fontWeight="bold">
            {hideLevels ? "" : level.levelLabel}
          </Text>
          {level.rooms
            .filter((r) => {
              if (searchText) {
                return r.name.toLowerCase().includes(searchText.toLowerCase());
              }
              return r;
            })
            .filter((r) => {
              if (!tagIds || tagIds.length < 1) {
                return true;
              }
              return (r.tagIds ?? []).filter((rt) => tagIds.includes(rt)).length > 0;
            })
            .map((r) => (
              <InsideDetails key={r.id} room={r} />
            ))}
        </Box>
      ))}
    </Box>
  );
};
