import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import ReadingRow from "./ReadingRow";
import dayjs from "@tether-web-portal/dayjs-setup";
import numeral from "numeral";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";

type DeviceBaseDetailTableProps = {
  shortSerial?: string;
  sku?: string;
  name?: string;
  imageUrl?: string;
  lastSeenAt?: string;
  lastMessageReceivedAt?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  averageMessagePeriod?: number;
  network?: string;
  signalStrength?: string;
  children?: ReactNode;
};

const DeviceBaseDetailTable = ({
  shortSerial,
  sku,
  name,
  imageUrl,
  lastSeenAt,
  lastMessageReceivedAt,
  firmwareVersion,
  hardwareVersion,
  averageMessagePeriod,
  network,
  signalStrength,
  children,
}: DeviceBaseDetailTableProps) => {
  const [imageLoadError, setImageLoadError] = useState(false);

  const lastSeenFormatted = useMemo(
    () => (lastSeenAt ? dayjs(lastSeenAt).format(DATE_FORMATS.DAY_MONTH_YEAR_TIME_12_HOUR_SLASHED) : "Never"),
    [lastSeenAt]
  );
  const lastHeardFormatted = useMemo(
    () =>
      !!lastMessageReceivedAt
        ? dayjs(lastMessageReceivedAt).format(DATE_FORMATS.DAY_MONTH_YEAR_TIME_12_HOUR_SLASHED)
        : "Never",
    [lastMessageReceivedAt]
  );
  const messagesFrequencyFormatted = useMemo(
    () => (!!averageMessagePeriod ? numeral(averageMessagePeriod / 60).format("0[.]00") + " mins" : "N/A"),
    [averageMessagePeriod]
  );

  useEffect(() => {
    setImageLoadError(false);
  }, [imageUrl]);

  return (
    <Box p={1} display="flex" flexDirection="column" alignItems="center">
      {imageUrl && !imageLoadError && (
        <Image
          onClick={() => window.open(imageUrl, "_blank")}
          onError={() => setImageLoadError(true)}
          cursor="pointer"
          borderRadius="8px"
          alt={`${name} Image`}
          src={imageUrl}
          objectFit="cover"
          height="242px"
          width="100%"
        />
      )}

      <Box borderWidth={1} borderRadius={8} borderColor="#C9CACC" width={"100%"} m={5}>
        <ReadingRow rowTitle="Serial" value={!!shortSerial ? shortSerial : "Not set"} showBorderTop={false} />

        {!!sku && <ReadingRow rowTitle="SKU" value={sku} />}

        <ReadingRow rowTitle="Last Seen" value={lastSeenFormatted} />

        <ReadingRow rowTitle="Last Heard" value={lastHeardFormatted} />

        {children}

        <ReadingRow rowTitle="Network" value={!!network ? network : "N/A"} />

        {!!signalStrength && <ReadingRow rowTitle="Signal Strength" value={signalStrength} />}

        {!!averageMessagePeriod && (
          <ReadingRow rowTitle="Messages Frequency" value={messagesFrequencyFormatted} />
        )}

        <ReadingRow rowTitle="Firmware Version" value={firmwareVersion || "Not available"} />
        <ReadingRow rowTitle="Hardware Version" value={hardwareVersion || "Not available"} />
      </Box>
    </Box>
  );
};

export default DeviceBaseDetailTable;
