export const FootTrafficIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none">
      <path
        d="M6.69517 9.5544L17.1057 9.5544L17.1057 11.265L6.69517 11.265L11.2829 15.8527L10.0735 17.0621L3.42114 10.4097L10.0735 3.75729L11.2829 4.96666L6.69517 9.5544Z"
        fill="#111928"
      />
      <path
        d="M27.3048 15.9021L22.7171 11.3143L23.9264 10.1049L30.5788 16.7573L23.9264 23.4097L22.7171 22.2004L27.3048 17.6126L16.8943 17.6126L16.8943 15.9021L27.3048 15.9021Z"
        fill="#111928"
      />
    </svg>
  );
};
