import { VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import OccupancyBarChart, { OccupancyBarData } from "./charts/OccupancyBarChart";
import { useResizeObserver } from "@tether-web-portal/hooks";
import OccupancyBaseTile, {
  OccupancyBaseTileChildProps,
  Unavailable,
} from "@tether-web-portal/components/occupancy/tiles/OccupancyBaseTile";
import { OccupancyAggregate } from "@tether-web-portal/web-workers/occupancy";

interface OccupancyQuickGlanceTileProps extends OccupancyBaseTileChildProps {
  data?: OccupancyAggregate[];
}

const OccupancyQuickGlanceTile = ({
  data = [],
  loading,
  onAction,
  ...rest
}: OccupancyQuickGlanceTileProps) => {
  const { width, setRef } = useResizeObserver();

  const { chartItems, unavailable } = useMemo(() => {
    if (loading) {
      return {
        chartItems: [],
        isEmpty: true,
      };
    }

    const sanitisedData: OccupancyBarData[] =
      data?.map((item) => {
        return {
          label: item.id,
          value: item.usage,
          tooltip: `${item.id}, Avg occupancy: ${item.usage.toFixed(0)}%`,
        };
      }) || [];

    const isEmpty = sanitisedData.filter((item) => item.value > 0).length === 0;

    return {
      unavailable: {
        icon: "glance",
        enabled: !loading && isEmpty,
        title: "No occupancy data available",
        subtitle: "Occupancy information will appear here once data is collected.",
      } as Unavailable,
      chartItems: sanitisedData,
    };
  }, [data, loading]);

  return (
    <OccupancyBaseTile
      ref={setRef}
      title="Quick Glance"
      loading={loading}
      unavailable={unavailable}
      onAction={unavailable?.enabled ? undefined : onAction}
      {...rest}
    >
      <VStack alignItems="flex-start" spacing={2}>
        <OccupancyBarChart width={width} height={150} data={chartItems} enableXLabels={false} />
      </VStack>
    </OccupancyBaseTile>
  );
};

export default OccupancyQuickGlanceTile;
