import {
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { IReducerAction } from "@tether-web-portal/types/common";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";
import { useGetProperty, useGetPropertyDetailWithReadings } from "@tether-web-portal/api/properties/hooks";
import { SeriesType, SeriesTypeReading } from "@tether-web-portal/components/property-graph/GraphUtils";
import { useGetMonitoringHours } from "@tether-web-portal/api/monitoring-hours/hooks";
import {
  useGetMultipleRoomsAggregatedData,
  useGetMultipleRoomLocationsAggregatedData,
} from "@tether-web-portal/api/roomLocations/hooks";
import { RoomType } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";
import { Property } from "@tether-web-portal/types/Property";

import {
  IOccupancyUtilisationTableFilters,
  IOccupancyUtilisationRow,
  IOccupancyBreakdownDataPoint,
  IOccupancyBreakdownFilters,
  IOccupancyBreakdownSummary,
  OccupancyTilesData,
  checkIfRoomHasOccupancyDevices,
  IOccupancyFloor,
  IOccupancySpace,
  IOccupancyWorkerBaseProps,
  OccupancyExportData,
  useOccupancyBreakdownWebWorker,
  useOccupancyUtilisationWebWorker,
  useOccupancyTilesWebWorker,
  useOccupancyExportDataWebWorker,
  IOccupancyPageFilters,
  checkIfRoomLocationHasActiveOccupancyChildrenDevice,
} from "@tether-web-portal/web-workers/occupancy";
import { MonitoringHours } from "@tether-web-portal/types/MonitoringHours";

type IOccupancyContextActionPayloads = {
  SET_IS_LIVE_VIEW: boolean;
  SET_DATA_LAST_UPDATED_AT: Date | null;
  SET_DATA_LOADING: boolean;
  SET_UTILISATION_TABLE_FILTERS: IOccupancyUtilisationTableFilters;
  SET_UTILISATION_TABLE_DATA: IOccupancyUtilisationRow[];
  SET_UTILISATION_LOADING: boolean;
  SET_AVAILABLE_ROOM_TYPES: RoomType[];
  SET_TILES_LOADING: boolean;
  SET_TILES_DATA: OccupancyTilesData;
  SET_MONITORING_HOURS: MonitoringHours[] | null;
  SET_PROPERTY: Property | null;
  SET_AVAILABLE_SPACES: IOccupancySpace[];
  SET_AVAILABLE_FLOORS: IOccupancyFloor[];
  SET_BREAKDOWN_FILTERS: IOccupancyBreakdownFilters;
  SET_BREAKDOWN_DATA: IOccupancyBreakdownDataPoint[];
  SET_LOW_RES_BREAKDOWN_DATA: IOccupancyBreakdownDataPoint[];
  SET_BREAKDOWN_SUMMARY: IOccupancyBreakdownSummary | null;
  SET_BREAKDOWN_LOADING: boolean;
  SET_EXPORT_DATA: OccupancyExportData | null;
  SET_PAGE_FILTERS: IOccupancyPageFilters | undefined;
};
type TOccupancyContextActionTypes = keyof IOccupancyContextActionPayloads;

export const OccupancyContextActions: {
  [K in TOccupancyContextActionTypes]: (
    payload: IOccupancyContextActionPayloads[K]
  ) => IReducerAction<TOccupancyContextActionTypes>;
} = {
  SET_IS_LIVE_VIEW: (payload: boolean) => ({
    type: "SET_IS_LIVE_VIEW",
    payload,
  }),
  SET_DATA_LAST_UPDATED_AT: (payload: Date | null) => ({
    type: "SET_DATA_LAST_UPDATED_AT",
    payload,
  }),
  SET_AVAILABLE_ROOM_TYPES: (payload: RoomType[]) => ({
    type: "SET_AVAILABLE_ROOM_TYPES",
    payload,
  }),
  SET_UTILISATION_TABLE_FILTERS: (payload: IOccupancyUtilisationTableFilters) => ({
    type: "SET_UTILISATION_TABLE_FILTERS",
    payload,
  }),
  SET_UTILISATION_TABLE_DATA: (payload: IOccupancyUtilisationRow[]) => ({
    type: "SET_UTILISATION_TABLE_DATA",
    payload,
  }),
  SET_UTILISATION_LOADING: (payload: boolean) => ({
    type: "SET_UTILISATION_LOADING",
    payload,
  }),
  SET_DATA_LOADING: (payload: boolean) => ({
    type: "SET_DATA_LOADING",
    payload,
  }),
  SET_TILES_LOADING: (payload: boolean) => ({
    type: "SET_TILES_LOADING",
    payload,
  }),
  SET_TILES_DATA: (payload: OccupancyTilesData) => ({
    type: "SET_TILES_DATA",
    payload,
  }),
  SET_AVAILABLE_SPACES: (payload: IOccupancySpace[]) => ({
    type: "SET_AVAILABLE_SPACES",
    payload,
  }),
  SET_AVAILABLE_FLOORS: (payload: IOccupancyFloor[]) => ({
    type: "SET_AVAILABLE_FLOORS",
    payload,
  }),
  SET_BREAKDOWN_DATA: (payload: IOccupancyBreakdownDataPoint[]) => ({
    type: "SET_BREAKDOWN_DATA",
    payload,
  }),
  SET_LOW_RES_BREAKDOWN_DATA: (payload: IOccupancyBreakdownDataPoint[]) => ({
    type: "SET_LOW_RES_BREAKDOWN_DATA",
    payload,
  }),
  SET_BREAKDOWN_FILTERS: (payload: IOccupancyBreakdownFilters) => ({
    type: "SET_BREAKDOWN_FILTERS",
    payload,
  }),
  SET_MONITORING_HOURS: (payload: MonitoringHours[] | null) => ({
    type: "SET_MONITORING_HOURS",
    payload,
  }),
  SET_PROPERTY: (payload: Property | null) => ({
    type: "SET_PROPERTY",
    payload,
  }),
  SET_BREAKDOWN_SUMMARY: (payload: IOccupancyBreakdownSummary | null) => ({
    type: "SET_BREAKDOWN_SUMMARY",
    payload,
  }),
  SET_BREAKDOWN_LOADING: (payload: boolean) => ({
    type: "SET_BREAKDOWN_LOADING",
    payload,
  }),
  SET_EXPORT_DATA: (payload: OccupancyExportData | null) => ({
    type: "SET_EXPORT_DATA",
    payload,
  }),
  SET_PAGE_FILTERS: (payload: IOccupancyPageFilters | undefined) => ({
    type: "SET_PAGE_FILTERS",
    payload,
  }),
};

interface IOccupancyContextState {
  dispatcher: Dispatch<IReducerAction<TOccupancyContextActionTypes>>;
  propertyId: string;
  monitoringHours: MonitoringHours[] | null;
  property: Property | null;
  isLiveView: boolean;
  dataLastUpdatedAt: Date | null;
  availableRoomTypes: RoomType[];
  dataLoading: boolean;
  utilisationLoading: boolean;
  utilisationTableFilters: IOccupancyUtilisationTableFilters;
  utilisationTableData: IOccupancyUtilisationRow[];
  tilesData: OccupancyTilesData;
  tilesLoading: boolean;
  availableSpaces: IOccupancySpace[];
  availableFloors: IOccupancyFloor[];
  breakdownLoading: boolean;
  breakdownFilters: IOccupancyBreakdownFilters;
  breakdownData: IOccupancyBreakdownDataPoint[];
  lowResBreakdownData: IOccupancyBreakdownDataPoint[];
  breakdownSummary: IOccupancyBreakdownSummary | null;
  exportData: OccupancyExportData | null;
  pageFilters: IOccupancyPageFilters | undefined;
}

const initialState: IOccupancyContextState = {
  dispatcher: () => null,
  propertyId: "",
  monitoringHours: null,
  property: null,
  isLiveView: false,
  dataLastUpdatedAt: null,
  availableRoomTypes: [],
  utilisationLoading: true,
  utilisationTableFilters: {
    displayBy: "ALL",
    sortBy: "DEFAULT",
  },
  utilisationTableData: [],
  dataLoading: true,
  tilesData: {},
  tilesLoading: true,
  availableSpaces: [],
  availableFloors: [],
  breakdownLoading: true,
  breakdownFilters: {
    floorIndexToDisplay: -1,
    spaceToDisplay: "ALL",
    metricToDisplay: "occupancyCount",
  },
  breakdownData: [],
  lowResBreakdownData: [],
  breakdownSummary: null,
  exportData: null,
  pageFilters: undefined,
};

const OccupancyContext = createContext<IOccupancyContextState>(initialState);

const OccupancyContextReducerHandlers: Record<
  TOccupancyContextActionTypes,
  (
    state: IOccupancyContextState,
    action: IReducerAction<TOccupancyContextActionTypes>
  ) => IOccupancyContextState
> = {
  SET_IS_LIVE_VIEW: (state, action) => ({
    ...state,
    isLiveView: action.payload ?? false,
  }),
  SET_DATA_LAST_UPDATED_AT: (state, action) => ({
    ...state,
    dataLastUpdatedAt: action.payload ?? null,
  }),
  SET_AVAILABLE_ROOM_TYPES: (state, action) => ({
    ...state,
    availableRoomTypes: action.payload ?? [],
  }),
  SET_UTILISATION_TABLE_FILTERS: (state, action) => ({
    ...state,
    utilisationTableFilters: action.payload,
  }),
  SET_UTILISATION_TABLE_DATA: (state, action) => ({
    ...state,
    utilisationTableData: action.payload ?? [],
  }),
  SET_UTILISATION_LOADING: (state, action) => ({
    ...state,
    utilisationLoading: action.payload,
  }),
  SET_DATA_LOADING: (state, action) => ({
    ...state,
    dataLoading: action.payload,
  }),
  SET_TILES_LOADING: (state, action) => ({
    ...state,
    tilesLoading: action.payload,
  }),
  SET_TILES_DATA: (state, action) => ({
    ...state,
    tilesData: action.payload,
  }),
  SET_AVAILABLE_SPACES: (state, action) => ({
    ...state,
    availableSpaces: action.payload,
  }),
  SET_AVAILABLE_FLOORS: (state, action) => ({
    ...state,
    availableFloors: action.payload,
  }),
  SET_BREAKDOWN_DATA: (state, action) => ({
    ...state,
    breakdownData: action.payload,
  }),
  SET_LOW_RES_BREAKDOWN_DATA: (state, action) => ({
    ...state,
    lowResBreakdownData: action.payload,
  }),
  SET_BREAKDOWN_FILTERS: (state, action) => ({
    ...state,
    breakdownFilters: action.payload,
  }),
  SET_MONITORING_HOURS: (state, action) => ({
    ...state,
    monitoringHours: action.payload,
  }),
  SET_PROPERTY: (state, action) => ({
    ...state,
    property: action.payload,
  }),
  SET_BREAKDOWN_SUMMARY: (state, action) => ({
    ...state,
    breakdownSummary: action.payload,
  }),
  SET_BREAKDOWN_LOADING: (state, action) => ({
    ...state,
    breakdownLoading: action.payload,
  }),
  SET_EXPORT_DATA: (state, action) => ({
    ...state,
    exportData: action.payload,
  }),
  SET_PAGE_FILTERS: (state, action) => ({
    ...state,
    pageFilters: action.payload,
  }),
};
const OccupancyContextReducer = (
  state: IOccupancyContextState,
  action: IReducerAction<TOccupancyContextActionTypes>
): IOccupancyContextState => {
  const handler = OccupancyContextReducerHandlers[action.type];
  if (typeof handler === "function") {
    return handler(state, action);
  }
  return state;
};

const ROOM_AGGREGATE_FILTER_METRICS = [
  SeriesTypeReading[SeriesType.OccupancyPeopleCount],
  SeriesTypeReading[SeriesType.Occupied],
  SeriesTypeReading[SeriesType.OccupancyTotalIn],
  SeriesTypeReading[SeriesType.OccupancyTotalOut],
  SeriesTypeReading[SeriesType.FootTraffic],
  SeriesTypeReading[SeriesType.OccupancyRegionPeopleCount],
  SeriesTypeReading[SeriesType.DwellTimeMean],
];

interface IOccupancyContextProviderProps {
  propertyId: string;
  filters?: IOccupancyPageFilters;
  children: React.ReactNode;
}
export const OccupancyContextProvider: FunctionComponent<IOccupancyContextProviderProps> = (
  props: IOccupancyContextProviderProps
) => {
  const { propertyId, filters, children } = props;

  const [state, dispatch] = useReducer(OccupancyContextReducer, {
    ...initialState,
    propertyId,
  });

  const {
    initialized: metricsIntervalInitialized,
    metricsTimeStamps,
    metricsNumberOfDaysToShow,
    setMetricsInterval,
    metricsCurrentDataInterval,
  } = useMetricsInterval(MetricDataInterval.HOURS_24);

  const monitoringHoursQuery = useGetMonitoringHours({
    scopeId: propertyId,
    includeParent: true,
    scopeType: "property",
  });
  const propertyQuery = useGetProperty(propertyId);
  const propertyInfoQuery = useGetPropertyDetailWithReadings(propertyId);

  const { roomsIdsWithOccupancyDevices, roomLocationIdsWithActiveDevices } = useMemo(() => {
    const roomsIdsWithOccupancyDevices = (propertyInfoQuery?.propertyInfo?.rooms ?? [])
      .filter(checkIfRoomHasOccupancyDevices)
      .map((x) => x.id);

    const roomLocationIdsWithActiveDevices = (propertyInfoQuery?.propertyInfo?.rooms ?? [])
      .filter(checkIfRoomHasOccupancyDevices)
      .flatMap((x) => x.roomLocations)
      .filter(checkIfRoomLocationHasActiveOccupancyChildrenDevice)
      .map((x) => x.id);

    return {
      roomsIdsWithOccupancyDevices,
      roomLocationIdsWithActiveDevices,
    };
  }, [propertyInfoQuery?.propertyInfo?.rooms]);

  const roomAggregateAPIProps = {
    roomIds: roomsIdsWithOccupancyDevices,
    dateRange: metricsTimeStamps,
    filterMetrics: ROOM_AGGREGATE_FILTER_METRICS,
    keepPreviousData: state.isLiveView,
  };

  const highResolutionRoomsAggregatesQuery = useGetMultipleRoomsAggregatedData({
    ...roomAggregateAPIProps,
    resolution: metricsNumberOfDaysToShow <= 1 ? "fifteenminute" : "hour",
  });

  const lowResolutionRoomsAggregatesQuery = useGetMultipleRoomsAggregatedData({
    ...roomAggregateAPIProps,
    resolution: metricsNumberOfDaysToShow <= 1 ? "hour" : "day",
  });
  const roomLocationsRegionAggregatesQuery = useGetMultipleRoomLocationsAggregatedData({
    roomLocationIds: roomLocationIdsWithActiveDevices,
    dateRange: metricsTimeStamps,
    resolution: metricsNumberOfDaysToShow <= 1 ? "fifteenminute" : "hour",
    filterMetrics: ROOM_AGGREGATE_FILTER_METRICS,
    keepPreviousData: state.isLiveView,
  });

  const occupancyWorkerBaseProps = useMemo<IOccupancyWorkerBaseProps | null>(
    () =>
      !propertyInfoQuery?.propertyInfo || !monitoringHoursQuery.monitoringHours || !propertyQuery.property
        ? null
        : {
            property: propertyQuery.property,
            propertyInfo: propertyInfoQuery?.propertyInfo,
            roomAggregations: highResolutionRoomsAggregatesQuery?.data,
            monitoringHours: monitoringHoursQuery.monitoringHours,
            filters: filters,
          },
    [
      propertyInfoQuery?.propertyInfo,
      highResolutionRoomsAggregatesQuery?.data,
      monitoringHoursQuery.monitoringHours,
      propertyQuery.property,
      filters,
    ]
  );

  const occupancyUtilisationWebWorker = useOccupancyUtilisationWebWorker(
    !occupancyWorkerBaseProps
      ? null
      : {
          ...occupancyWorkerBaseProps,
          roomLocationAggregations: roomLocationsRegionAggregatesQuery?.data ?? [],
          isLiveView: state.isLiveView,
          tableFilters: state.utilisationTableFilters,
        }
  );
  const occupancyBreakdownWebWorker = useOccupancyBreakdownWebWorker(
    !occupancyWorkerBaseProps
      ? null
      : {
          ...occupancyWorkerBaseProps,
          breakdownFilters: state.breakdownFilters,
        }
  );
  const occupancyLowResolutionBreakdownWebWorker = useOccupancyBreakdownWebWorker(
    !occupancyWorkerBaseProps
      ? null
      : {
          ...occupancyWorkerBaseProps,
          roomAggregations: lowResolutionRoomsAggregatesQuery?.data,
          breakdownFilters: state.breakdownFilters,
        }
  );
  const occupancyTilesWebWorker = useOccupancyTilesWebWorker(
    !occupancyWorkerBaseProps
      ? null
      : {
          ...occupancyWorkerBaseProps,
          roomAggregationsStatus: lowResolutionRoomsAggregatesQuery.status,
        }
  );
  const occupancyExportDataWebWorker = useOccupancyExportDataWebWorker(occupancyWorkerBaseProps);

  //#region ============================= UTILISATION EFFECTS =============================
  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_UTILISATION_TABLE_DATA(
        occupancyUtilisationWebWorker.result?.tableData ?? []
      )
    );
    dispatch(
      OccupancyContextActions.SET_AVAILABLE_ROOM_TYPES(
        occupancyUtilisationWebWorker.result?.availableRoomTypes ?? []
      )
    );
    dispatch(
      OccupancyContextActions.SET_AVAILABLE_SPACES(
        occupancyUtilisationWebWorker.result?.availableSpaces ?? []
      )
    );
    dispatch(
      OccupancyContextActions.SET_AVAILABLE_FLOORS(
        occupancyUtilisationWebWorker.result?.availableFloors ?? []
      )
    );
  }, [
    occupancyUtilisationWebWorker.result?.tableData,
    occupancyUtilisationWebWorker.result?.availableRoomTypes,
    occupancyUtilisationWebWorker.result?.availableSpaces,
    occupancyUtilisationWebWorker.result?.availableFloors,
  ]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_UTILISATION_LOADING(
        !occupancyUtilisationWebWorker.result || occupancyUtilisationWebWorker.isLoading || state.dataLoading
      )
    );
  }, [occupancyUtilisationWebWorker.result, occupancyUtilisationWebWorker.isLoading, state.dataLoading]);
  //#endregion ============================================================================

  //#region ============================= BREAKDOWN EFFECTS =============================
  useEffect(() => {
    const highResBreakdownLoading =
      !occupancyBreakdownWebWorker.result || occupancyBreakdownWebWorker.isLoading;
    const lowResBreakdownLoading =
      !occupancyLowResolutionBreakdownWebWorker.result || occupancyLowResolutionBreakdownWebWorker.isLoading;
    dispatch(
      OccupancyContextActions.SET_BREAKDOWN_LOADING(
        highResBreakdownLoading || lowResBreakdownLoading || state.dataLoading
      )
    );
  }, [
    occupancyBreakdownWebWorker.result,
    occupancyBreakdownWebWorker.isLoading,
    occupancyLowResolutionBreakdownWebWorker.result,
    occupancyLowResolutionBreakdownWebWorker.isLoading,
    state.dataLoading,
  ]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_LOW_RES_BREAKDOWN_DATA(
        occupancyLowResolutionBreakdownWebWorker.result?.breakdownData ?? []
      )
    );
  }, [occupancyLowResolutionBreakdownWebWorker.result?.breakdownData]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_BREAKDOWN_DATA(occupancyBreakdownWebWorker.result?.breakdownData ?? [])
    );
    dispatch(
      OccupancyContextActions.SET_BREAKDOWN_SUMMARY(
        occupancyBreakdownWebWorker.result?.breakdownSummary ?? null
      )
    );
  }, [
    occupancyBreakdownWebWorker.result?.breakdownData,
    occupancyBreakdownWebWorker.result?.breakdownSummary,
  ]);
  //#endregion ==========================================================================

  //#region ============================= TILES EFFECTS =============================
  useEffect(() => {
    dispatch(OccupancyContextActions.SET_TILES_DATA(occupancyTilesWebWorker.result ?? {}));
  }, [occupancyTilesWebWorker.result]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_TILES_LOADING(
        state.dataLoading || !state.tilesData?.ready || occupancyTilesWebWorker.isLoading
      )
    );
  }, [state.dataLoading, state.tilesData?.ready, occupancyTilesWebWorker.isLoading]);
  //#endregion ======================================================================

  useEffect(() => {
    dispatch(OccupancyContextActions.SET_PAGE_FILTERS(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(OccupancyContextActions.SET_EXPORT_DATA(occupancyExportDataWebWorker.result ?? null));
  }, [occupancyExportDataWebWorker.result]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_DATA_LOADING(
        monitoringHoursQuery.isLoading ||
          propertyInfoQuery.isLoading ||
          propertyQuery.isLoading ||
          lowResolutionRoomsAggregatesQuery.isLoading ||
          highResolutionRoomsAggregatesQuery.isLoading
      )
    );
  }, [
    propertyInfoQuery.isLoading,
    propertyQuery.isLoading,
    lowResolutionRoomsAggregatesQuery.isLoading,
    highResolutionRoomsAggregatesQuery.isLoading,
    monitoringHoursQuery.isLoading,
  ]);

  useEffect(() => {
    const lastUpdated =
      Math.max(
        lowResolutionRoomsAggregatesQuery.dataUpdatedAt || 0,
        highResolutionRoomsAggregatesQuery.dataUpdatedAt || 0
      ) || 0;
    dispatch(
      OccupancyContextActions.SET_DATA_LAST_UPDATED_AT(lastUpdated > 0 ? new Date(lastUpdated) : null)
    );
  }, [lowResolutionRoomsAggregatesQuery.dataUpdatedAt, highResolutionRoomsAggregatesQuery.dataUpdatedAt]);

  useEffect(() => {
    dispatch(OccupancyContextActions.SET_MONITORING_HOURS(monitoringHoursQuery.monitoringHours ?? null));
  }, [monitoringHoursQuery.monitoringHours]);

  useEffect(() => {
    dispatch(OccupancyContextActions.SET_PROPERTY(propertyQuery.property ?? null));
  }, [propertyQuery.property]);

  useEffect(() => {
    dispatch(
      OccupancyContextActions.SET_IS_LIVE_VIEW(metricsCurrentDataInterval === MetricDataInterval.MINUTES_15)
    );
  }, [metricsCurrentDataInterval]);

  useEffect(() => {
    if (!state.isLiveView) {
      return;
    }

    // Refresh metric interval times every minute on live view...
    const refreshInterval = setInterval(() => {
      setMetricsInterval(MetricDataInterval.MINUTES_15);
    }, 60000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [state.isLiveView, setMetricsInterval]);

  if (!metricsIntervalInitialized) {
    return null;
  }

  return (
    <OccupancyContext.Provider value={{ ...state, dispatcher: dispatch }}>
      {children}
    </OccupancyContext.Provider>
  );
};

export const useOccupancyContext = () => useContext(OccupancyContext);
