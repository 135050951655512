const PeakDayIcon = ({ size, color = "#595F6A" }: { size: number; color: string }) => (
  <svg width={size} height={size} viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.32803C1 3.04331 1.24128 2.8125 1.53892 2.8125H30.4611C30.7587 2.8125 31 3.04331 31 3.32803V27.6095C31 27.8942 30.7587 28.125 30.4611 28.125H1.53892C1.24128 28.125 1 27.8942 1 27.6095V3.32803ZM2.07784 3.84356V27.0939H29.9222V3.84356H2.07784Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.444664C4 0.199083 4.23914 0 4.53412 0H8.42433C8.71932 0 8.95846 0.199083 8.95846 0.444664V3.30534C8.95846 3.55092 8.71932 3.75 8.42433 3.75C8.12934 3.75 7.89021 3.55092 7.89021 3.30534V0.889328H5.06825V3.30534C5.06825 3.55092 4.82911 3.75 4.53412 3.75C4.23914 3.75 4 3.55092 4 3.30534V0.444664ZM23.0415 0.444664C23.0415 0.199083 23.2807 0 23.5757 0H27.4659C27.7609 0 28 0.199083 28 0.444664V3.30534C28 3.55092 27.7609 3.75 27.4659 3.75C27.1709 3.75 26.9317 3.55092 26.9317 3.30534V0.889328H24.1098V3.30534C24.1098 3.55092 23.8707 3.75 23.5757 3.75C23.2807 3.75 23.0415 3.55092 23.0415 3.30534V0.444664Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 18.9012C14.6538 18.9012 13.9677 19.673 13.9677 20.625C13.9677 21.577 14.6538 22.3488 15.5 22.3488C16.3462 22.3488 17.0323 21.577 17.0323 20.625C17.0323 19.673 16.3462 18.9012 15.5 18.9012ZM13 20.625C13 19.0717 14.1193 17.8125 15.5 17.8125C16.8807 17.8125 18 19.0717 18 20.625C18 22.1783 16.8807 23.4375 15.5 23.4375C14.1193 23.4375 13 22.1783 13 20.625Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7.96875C1 7.70987 1.24128 7.5 1.53892 7.5H30.4611C30.7587 7.5 31 7.70987 31 7.96875C31 8.22763 30.7587 8.4375 30.4611 8.4375H1.53892C1.24128 8.4375 1 8.22763 1 7.96875Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 23.4375C18 19.8131 21.134 16.875 25 16.875C28.866 16.875 32 19.8131 32 23.4375C32 23.7236 31.7526 23.9556 31.4474 23.9556C31.1422 23.9556 30.8947 23.7236 30.8947 23.4375C30.8947 20.3854 28.2556 17.9112 25 17.9112C21.7444 17.9112 19.1053 20.3854 19.1053 23.4375C19.1053 26.4896 21.7444 28.9638 25 28.9638C27.2161 28.9638 29.1474 27.8175 30.1548 26.1205C30.3032 25.8704 30.6397 25.7806 30.9064 25.9197C31.1731 26.0588 31.269 26.3743 31.1206 26.6243C29.9261 28.6366 27.6332 30 25 30C21.134 30 18 27.0619 18 23.4375Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7775 20.7455C34.0303 20.9378 34.0742 21.2922 33.8756 21.537L31.7481 24.1595C31.6527 24.2771 31.513 24.3532 31.3597 24.371C31.2064 24.3888 31.0521 24.3469 30.9307 24.2546L28.2225 22.1943C27.9697 22.002 27.9258 21.6476 28.1244 21.4028C28.323 21.158 28.689 21.1154 28.9418 21.3078L31.1921 23.0197L32.9601 20.8405C33.1587 20.5957 33.5246 20.5531 33.7775 20.7455Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 18.9012C6.65376 18.9012 5.96774 19.673 5.96774 20.625C5.96774 21.577 6.65376 22.3488 7.5 22.3488C8.34624 22.3488 9.03226 21.577 9.03226 20.625C9.03226 19.673 8.34624 18.9012 7.5 18.9012ZM5 20.625C5 19.0717 6.11929 17.8125 7.5 17.8125C8.88071 17.8125 10 19.0717 10 20.625C10 22.1783 8.88071 23.4375 7.5 23.4375C6.11929 23.4375 5 22.1783 5 20.625Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 11.4012C6.65376 11.4012 5.96774 12.173 5.96774 13.125C5.96774 14.077 6.65376 14.8488 7.5 14.8488C8.34624 14.8488 9.03226 14.077 9.03226 13.125C9.03226 12.173 8.34624 11.4012 7.5 11.4012ZM5 13.125C5 11.5717 6.11929 10.3125 7.5 10.3125C8.88071 10.3125 10 11.5717 10 13.125C10 14.6783 8.88071 15.9375 7.5 15.9375C6.11929 15.9375 5 14.6783 5 13.125Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 11.4012C14.6538 11.4012 13.9677 12.173 13.9677 13.125C13.9677 14.077 14.6538 14.8488 15.5 14.8488C16.3462 14.8488 17.0323 14.077 17.0323 13.125C17.0323 12.173 16.3462 11.4012 15.5 11.4012ZM13 13.125C13 11.5717 14.1193 10.3125 15.5 10.3125C16.8807 10.3125 18 11.5717 18 13.125C18 14.6783 16.8807 15.9375 15.5 15.9375C14.1193 15.9375 13 14.6783 13 13.125Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11.4012C22.9845 11.4012 22.1613 12.173 22.1613 13.125C22.1613 14.077 22.9845 14.8488 24 14.8488C25.0155 14.8488 25.8387 14.077 25.8387 13.125C25.8387 12.173 25.0155 11.4012 24 11.4012ZM21 13.125C21 11.5717 22.3431 10.3125 24 10.3125C25.6569 10.3125 27 11.5717 27 13.125C27 14.6783 25.6569 15.9375 24 15.9375C22.3431 15.9375 21 14.6783 21 13.125Z"
      fill={color}
    />
  </svg>
);

export default PeakDayIcon;
