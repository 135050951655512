/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, useMemo } from "react";

export interface IWorkerMessage<TInput> {
  taskId: number;
  input: TInput;
}
export interface IWorkerOutput<TOutput> {
  taskId: number;
  result?: TOutput;
  error?: Error;
}

export interface IWebWorkerResult<TOutput> {
  result: TOutput | null;
  error: Error | null;
  isLoading: boolean;
  isError: boolean;
}

export interface IWebWorkerResult<TOutput> {
  result: TOutput | null;
  error: Error | null;
  isError: boolean;
  isLoading: boolean;
}

export function useWebWorker<TInput, TOutput>(
  worker?: Worker,
  workerInput?: TInput
): IWebWorkerResult<TOutput> {
  const [result, setResult] = useState<TOutput | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const webWorkerOutput = useMemo<IWebWorkerResult<TOutput>>(
    () => ({
      result,
      error,
      isLoading,
      isError: Boolean(error),
    }),
    [result, error, isLoading]
  );

  const workerRef = useRef<Worker | null>(null);
  const initInput = useRef<TInput | null>(null);
  const latestTaskIdRef = useRef<number>(0);
  const latestTaskStartTime = useRef<number>(0);

  const run = (input: TInput) => {
    if (!workerRef.current) {
      // Worker not initialized yet, so store init input to run as soon as worker is initialized
      initInput.current = input;
      return;
    }
    setIsLoading(true);
    const taskId = ++latestTaskIdRef.current;
    latestTaskStartTime.current = performance.now();
    workerRef.current?.postMessage({ taskId, input } as IWorkerMessage<TInput>);
  };

  // Run the worker when input changes
  useEffect(() => {
    if (workerInput) {
      run(workerInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerInput]);

  useEffect(() => {
    workerRef.current = worker ?? null;

    if (!workerRef.current) {
      return;
    }

    workerRef.current.onmessage = ({ data }: { data: IWorkerOutput<TOutput> }) => {
      const { taskId, result, error: workerError } = data;

      if (taskId === latestTaskIdRef.current) {
        console.debug(
          `Received response from worker and took ${Math.round(
            performance.now() - latestTaskStartTime.current
          )}ms:`,
          data
        );
        if (workerError) {
          setError(workerError);
          setResult(null);
        } else {
          setError(null);
          setResult(result ?? null);
        }
        setIsLoading(false);
      } else {
        console.debug("Not latest response from worker so ignoring result");
      }
    };

    workerRef.current.onerror = (e) => {
      console.error("Received error from worker:", e);
      setError(new Error(e.message));
      setResult(null);
      setIsLoading(false);
    };

    if (initInput.current) {
      run(initInput.current);
      initInput.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worker]);

  return webWorkerOutput;
}
