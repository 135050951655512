import { RoomType } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";
import { PropertyDetail } from "@tether-web-portal/types/PropertyDetail";
import { RoomLocationAggregate } from "@tether-web-portal/types/RoomLocation";
import { MonitoringHours } from "@tether-web-portal/types/MonitoringHours";
import { Property } from "@tether-web-portal/types/Property";
import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { RoomWithLocationReadings } from "@tether-web-portal/types/Room";

export const OCCUPANCY_DEVICE_TYPES: DeviceType[] = [DeviceType.ROOM_RADAR, DeviceType.DETECTIFY];
export const OCCUPANCY_CHILDREN_DEVICE_TYPES: DeviceType[] = [
  DeviceType.ROOM_RADAR_REGION,
  DeviceType.ROOM_RADAR_LINE,
];

export enum OccupancyFilterType {
  FLOOR = "floor",
  ROOM = "room",
}
export enum OccupancyFilterKeyNames {
  SPACE_TYPE = "spaceType",
  FLOOR_INDEX = "floorIndex",
  SPACE_ID = "spaceId",
}
export interface IOccupancyPageFilters {
  spaceType: OccupancyFilterType;
  floorIndex: number | null;
  spaceId: string | null;
}

export interface IRoomOccupancyAggregates extends RoomWithLocationReadings {
  aggregations: RoomLocationAggregate[];
}

export type RoomAggregatedData = {
  roomId: string;
  aggregatedData: RoomLocationAggregate[];
};

export type RoomLocationAggregatedData = {
  roomLocationId: string;
  aggregatedData: RoomLocationAggregate[];
};

export interface IOccupancySpace {
  id: string;
  name: string;
  type: RoomType;
}

export interface IOccupancyFloor {
  name: string;
  floorIndex: number;
}

export interface IOccupancyWorkerBaseProps {
  property: Property;
  propertyInfo: PropertyDetail;
  roomAggregations: RoomAggregatedData[];
  monitoringHours: MonitoringHours[];
  filters?: IOccupancyPageFilters;
}
