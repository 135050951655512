import {
  HorizontalBarChartDataPoint,
  HorizontalBarChartXScale,
} from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { AxisConfig } from "@tether-web-portal/components/charts/types";
import { Group } from "@visx/group";
import { Text } from "@visx/text";

export const XAxis = <T,>({
  axisHeightConfig,
  label,
  data,
  xMax,
  xScale,
  scrollOffsetX,
}: {
  axisHeightConfig: AxisConfig;
  label: string;
  data: HorizontalBarChartDataPoint<T>[];
  xMax: number;
  scrollOffsetX: number;
  xScale: HorizontalBarChartXScale;
}) => {
  const labelStyles = {
    lineHeight: axisHeightConfig.lineHeight,
    fontSize: "var(--chakra-fontSizes-xs)",
    fontWeight: 500,
    fontFamily: "var(--chakra-fonts-body)",
  };
  return (
    <>
      <Group left={scrollOffsetX} top={axisHeightConfig.innerMargin}>
        {data.map(({ key, label }) => {
          const barWidth = xScale.bandwidth();
          const midWidth = barWidth / 2;
          const barX = xScale(key) ?? 0;
          const lines = label.split(/\r?\n/);
          return (
            <Group key={key} left={barX + midWidth}>
              {lines.map((text, i) => (
                <Group key={text} top={axisHeightConfig.lineHeight * i}>
                  <Text {...labelStyles} textAnchor={"middle"} verticalAnchor={"start"}>
                    {text}
                  </Text>
                </Group>
              ))}
            </Group>
          );
        })}
      </Group>
      <Group top={axisHeightConfig.total - axisHeightConfig.title}>
        <Group top={axisHeightConfig.title / 2} left={xMax / 2}>
          <Text width={xMax} textAnchor={"middle"} verticalAnchor={"middle"} {...labelStyles}>
            {label}
          </Text>
        </Group>
      </Group>
    </>
  );
};
