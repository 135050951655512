import React, { FunctionComponent, useCallback, useState } from "react";

import { MetricDataInterval, MetricDataIntervalTitleMap } from "@tether-web-portal/types/metricsTypes";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";

import dayjs from "@tether-web-portal/dayjs-setup";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";

interface IMetricsIntervalContext {
  initialized: boolean;
  setInitialized: () => void;
  metricsNumberOfDaysToShow: number;
  metricsTimeStamps: TimestampRange;
  metricsCurrentDataInterval: MetricDataInterval;
  metricsCurrentDataIntervalLabel: string;
  setMetricsInterval: (interval: MetricDataInterval) => void;
  setMetricsIntervalFromTimestamp: (fromTimestamp: string) => void;
  setCustomMetricsInterval: (fromTimestamp: string, toTimestamp: string) => void;
}
const contextInitialState = (): IMetricsIntervalContext => ({
  initialized: false,
  setInitialized: () => null,
  metricsNumberOfDaysToShow: 1,
  metricsTimeStamps: {
    fromTimestamp: dayjs().subtract(1, "day").startOf("hour").toISOString(),
    toTimestamp: dayjs().endOf("hour").toISOString(),
  },
  metricsCurrentDataInterval: MetricDataInterval.HOURS_24,
  metricsCurrentDataIntervalLabel: MetricDataIntervalTitleMap[MetricDataInterval.HOURS_24],
  setMetricsInterval: () => null,
  setMetricsIntervalFromTimestamp: () => null,
  setCustomMetricsInterval: () => null,
});

export const MetricsIntervalContext = React.createContext<IMetricsIntervalContext>(contextInitialState());

interface IMetricsIntervalContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
export const MetricsIntervalContextProvider: FunctionComponent<IMetricsIntervalContextProps> = (
  props: IMetricsIntervalContextProps
) => {
  const [contextState, setContextState] = useState<IMetricsIntervalContext>(contextInitialState());

  const setInitialized = useCallback(() => {
    if (!contextState?.initialized) {
      setContextState((state) => ({
        ...state,
        initialized: true,
      }));
    }
  }, [contextState?.initialized]);

  const setMetricsInterval = useCallback((interval: MetricDataInterval) => {
    let toTimestamp = dayjs().endOf("hour").toISOString();
    if (interval === MetricDataInterval.MINUTES_15) {
      toTimestamp = dayjs().endOf("minute").toISOString();
    }

    let numberOfDays = 1;
    switch (interval) {
      case MetricDataInterval.MINUTES_15:
        numberOfDays = 1 * (15 / (24 * 60)); // 15 minutes
        break;
      case MetricDataInterval.HOURS_24:
        numberOfDays = 1;
        break;
      case MetricDataInterval.DAYS_7:
        numberOfDays = 7;
        break;
      case MetricDataInterval.DAYS_30:
        numberOfDays = 30;
        break;
      case MetricDataInterval.MONTHS_3:
        numberOfDays = 90;
        break;
    }

    let rawFromDate = dayjs().subtract(numberOfDays, "days");
    if (interval === MetricDataInterval.MINUTES_15) {
      rawFromDate = dayjs().subtract(15, "minutes");
    }

    let fromTimestamp = rawFromDate.startOf("day").toISOString();
    if (interval === MetricDataInterval.MINUTES_15) {
      fromTimestamp = rawFromDate.startOf("minute").toISOString();
    } else if (interval === MetricDataInterval.HOURS_24) {
      fromTimestamp = rawFromDate.startOf("hour").toISOString();
    }

    setContextState((state) => ({
      ...state,
      metricsNumberOfDaysToShow: numberOfDays,
      metricsFromTimestamp: fromTimestamp,
      metricsToTimestamp: toTimestamp,
      metricsTimeStamps: {
        fromTimestamp,
        toTimestamp,
      },
      metricsCurrentDataInterval: interval,
      metricsCurrentDataIntervalLabel: MetricDataIntervalTitleMap[interval],
    }));
  }, []);

  const setMetricsIntervalFromTimestamp = useCallback(
    (fromTimestamp: string) => {
      const daysBetween = dayjs().diff(fromTimestamp, "day");
      if (daysBetween >= 90) {
        setMetricsInterval(MetricDataInterval.MONTHS_3);
      } else if (daysBetween >= 30) {
        setMetricsInterval(MetricDataInterval.DAYS_30);
      } else if (daysBetween > 1) {
        setMetricsInterval(MetricDataInterval.DAYS_7);
      } else {
        const minutesBetween = dayjs().diff(dayjs(fromTimestamp), "minutes");
        if (minutesBetween <= 15) {
          setMetricsInterval(MetricDataInterval.MINUTES_15);
        } else {
          setMetricsInterval(MetricDataInterval.HOURS_24);
        }
      }
    },
    [setMetricsInterval]
  );

  const setCustomMetricsInterval = useCallback((fromTimestamp: string, toTimestamp: string) => {
    const fromTime = dayjs(fromTimestamp);
    const toTime = dayjs(toTimestamp);

    const daysBetween = dayjs(toTime).diff(fromTime, "day");
    const label = `${dayjs(fromTime).format(DATE_FORMATS.DAY_MONTH_YEAR_SLASHED)}-${dayjs(toTime).format(
      DATE_FORMATS.DAY_MONTH_YEAR_SLASHED
    )}`;

    setContextState((state) => ({
      ...state,
      metricsNumberOfDaysToShow: daysBetween,
      metricsFromTimestamp: fromTimestamp,
      metricsToTimestamp: toTimestamp,
      metricsTimeStamps: {
        fromTimestamp,
        toTimestamp,
      },
      metricsCurrentDataInterval: MetricDataInterval.CUSTOM,
      metricsCurrentDataIntervalLabel: label,
    }));
  }, []);

  return (
    <MetricsIntervalContext.Provider
      value={{
        ...contextState,
        setMetricsInterval,
        setMetricsIntervalFromTimestamp,
        setCustomMetricsInterval,
        setInitialized,
      }}
    >
      {props.children}
    </MetricsIntervalContext.Provider>
  );
};
