import dayjs from "@tether-web-portal/dayjs-setup";
import { DeviceType } from "../types/DeviceType";

interface SampleData {
  timestamp: number | string;
  sample?: {
    co2?: number;
    // installationlocation/id/samples/latest is a number but for the property info api it’s a string
  };
}

export const isSampleRecent = (sample: SampleData, deviceType?: DeviceType | string) => {
  const offlineTimeout = deviceType?.toLowerCase() === "detectify" ? 60 * 9 : 60 * 2;

  const isRecent = sample?.timestamp && !(dayjs().diff(sample?.timestamp, "minute") > offlineTimeout);

  return Boolean(isRecent);
};

export const isSampleRecentWithCO2 = (sample: SampleData, deviceType?: DeviceType | string) => {
  if (!sample?.sample?.co2) return false;
  return isSampleRecent(sample, deviceType);
};

export const isSampleReallyOld = (sample: SampleData) => {
  if (!sample?.timestamp) return false;

  const isOld = sample?.timestamp && !(dayjs().diff(sample?.timestamp, "week") < 2);

  return Boolean(isOld);
};
