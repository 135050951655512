import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface OccupancyGaugeChartProps {
  width: number;
  height: number;
  percentage: number;
  colors: GaugeColor[];
}

export interface GaugeColor {
  color: string;
  offset: string;
  label?: string;
}

const OccupancyGaugeChart = ({ width, height, percentage, colors }: OccupancyGaugeChartProps) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Define the linear gradient for the gauge
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gauge-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    colors?.forEach((color) => {
      gradient.append("stop").attr("offset", color.offset).attr("stop-color", color.color);
    });

    const startAngle = -Math.PI / 2;
    const endAngle = Math.PI / 2;
    const arcHeight = height - 5; //Space for labels
    const radius = Math.min(width, arcHeight / 1.2); // Scale the arc to fit within the SVG

    const arcPositionX = width / 2;
    const arcPositionY = arcHeight / 1.15;

    // Indicator: Calculate the position based on the percentage
    const indicatorAngle = -Math.PI + (Math.PI * percentage) / 100;
    const indicatorX = radius * Math.cos(indicatorAngle);
    const indicatorY = radius * Math.sin(indicatorAngle) + 4;

    // Create the arc generator for the gauge (solid arc)
    const arc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius * 0.9)
      .startAngle(startAngle)
      .endAngle(endAngle);

    // Create the second arc (inner, smaller, and behind the solid one)
    const innerArc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius * 0.83)
      .startAngle(startAngle)
      .endAngle(endAngle);

    // Draw the inner arc with 30% opacity (behind the solid one)
    svg
      .append("path")
      .attr("d", (innerArc as unknown) as string)
      .attr("transform", `translate(${arcPositionX}, ${arcPositionY})`)
      .attr("fill", "url(#gauge-gradient)")
      .attr("opacity", 0.3);

    // Draw the solid gauge arc (on top of the inner one)
    svg
      .append("path")
      .attr("d", (arc as unknown) as string)
      .attr("transform", `translate(${arcPositionX}, ${arcPositionY})`)
      .attr("fill", "url(#gauge-gradient)");

    // Draw the indicator: small square with rounded corners
    svg
      .append("rect")
      .attr("x", arcPositionX + indicatorX - 8)
      .attr("y", arcPositionY + (indicatorY - 8))
      .attr("width", 22)
      .attr("height", 22)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("fill", "#000000")
      .attr(
        "transform",
        `rotate(${((percentage - 50) * 180) / 100}, ${arcPositionX + indicatorX}, ${
          arcHeight / 1.2 + indicatorY
        })`
      ); // Rotate the indicator based on percentage

    const labelValues = [0, 100]; // Start and End percentages for labels
    const extremesColors = [colors[0], colors[colors.length - 1]];

    labelValues.forEach((value, index) => {
      const angle = -Math.PI + (Math.PI * value) / 100;
      const labelX = radius * 0.92 * Math.cos(angle);
      const labelY = radius * 2 * Math.sin(angle) + 20;

      const color = extremesColors[index];

      svg
        .append("text")
        .attr("x", arcPositionX + labelX)
        .attr("y", arcPositionY + labelY)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .text(color.label || "")
        .attr("fill", color.color)
        .attr("font-size", "14px")
        .attr("font-weight", "600");
    });
  }, [width, height, percentage, colors]);

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
    ></svg>
  );
};

export default OccupancyGaugeChart;
