import { Flex, Stack } from "@chakra-ui/react";
import { Typography } from "@tether-web-portal/components/common";
import { OccupancyStatusBadge } from "@tether-web-portal/components/occupancy/tiles";
import { ReactNode } from "react";

interface BreakdownSummaryItemProps {
  title: string;
  children: ReactNode;
  badgeValue: number | null;
}

const BreakdownSummaryItem = ({ title, children, badgeValue }: BreakdownSummaryItemProps) => (
  <Flex direction="column" rowGap={0}>
    <Typography size="sm" fontWeight="semibold">
      {title}
    </Typography>
    <Stack direction="row" alignItems="center" columnGap={1}>
      <Typography size="2xl" fontWeight="bold">
        {children}
      </Typography>
      {badgeValue && <OccupancyStatusBadge value={badgeValue} fontSize="small" />}
    </Stack>
  </Flex>
);

export default BreakdownSummaryItem;
