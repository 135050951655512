import { Box, HStack, Skeleton, VStack } from "@chakra-ui/react";
import React, { forwardRef, useMemo } from "react";
import { Button, Typography } from "@tether-web-portal/components/common";
import { BoxProps } from "@chakra-ui/layout";
import CommercialIcons from "@tether-web-portal/components/create-property/property-icons/CommercialIcons";
import SpacesIcon from "@tether-web-portal/components/icons/occupancy/SpacesIcon";
import GlanceIcon from "@tether-web-portal/components/icons/occupancy/GlanceIcon";
import PeakDayIcon from "@tether-web-portal/components/icons/occupancy/PeakDayIcon";
import PeakHourIcon from "@tether-web-portal/components/icons/occupancy/PeakHourIcon";

const unavailableIcons = {
  spaces: SpacesIcon,
  overall: CommercialIcons,
  glance: GlanceIcon,
  peakDays: PeakDayIcon,
  peakHours: PeakHourIcon,
};

export interface Unavailable {
  icon: "spaces" | "overall" | "glance" | "peakDays" | "peakHours";
  title: string;
  subtitle: string;
  enabled: boolean;
}

export interface OccupancyBaseTileChildProps {
  onAction?: (data?: unknown) => void;
  loading: boolean;
  minWidth?: BoxProps["minWidth"];
  width?: BoxProps["width"];
  margin?: BoxProps["margin"];
  unavailable?: Unavailable;
}

export interface OccupancyBaseTileProps extends OccupancyBaseTileChildProps {
  title: string;
  children?: React.ReactNode;
}

const OccupancyBaseTile = forwardRef<HTMLDivElement, OccupancyBaseTileProps>(
  ({ title, loading, unavailable, onAction, width, margin, minWidth = 300, children }, ref) => {
    const unavailableIcon = useMemo(() => {
      if (!unavailable?.icon) {
        return null;
      }
      const Icon = unavailableIcons[unavailable?.icon];
      return Icon ? <Icon size={50} color={"#595F6A"} /> : null;
    }, [unavailable]);

    return (
      <Box
        ref={ref}
        bg="white"
        flex="1"
        borderRadius="8px"
        shadow="lg"
        borderWidth="1"
        borderColor="white"
        padding={loading ? 0 : 4}
        height={220}
        minWidth={minWidth}
        width={width}
        margin={margin}
      >
        {loading ? (
          <Skeleton w={"100%"} height={"100%"} borderRadius={8} />
        ) : (
          <Box position={unavailable?.enabled ? undefined : "relative"} height="100%">
            <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
              <Typography size="lg" fontWeight="bold">
                {title}
              </Typography>
              {onAction && (
                <Button variant="secondary" onClick={onAction}>
                  View More
                </Button>
              )}
            </HStack>

            {unavailable?.enabled ? (
              <VStack height="100%" alignItems="flex-start" my={5}>
                {unavailableIcon}
                <Typography size={"lg"} fontWeight="semibold" color={"#595F6A"} mt={5}>
                  {unavailable.title}
                </Typography>
                <Typography size={"md"} color={"#595F6A"} mt={5}>
                  {unavailable.subtitle}
                </Typography>
              </VStack>
            ) : (
              children
            )}
          </Box>
        )}
      </Box>
    );
  }
);

OccupancyBaseTile.displayName = "OccupancyBaseTile";

export default OccupancyBaseTile;
