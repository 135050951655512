import { Button } from "@chakra-ui/button";
import { HStack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Input } from "@chakra-ui/react";
import { Tag, TagLabel } from "@chakra-ui/tag";
import React, { useState } from "react";
import { Organisation } from "@tether-web-portal/types/Organisation";
import { useAuth } from "../../lib/auth/useAuth";

interface SwitchOrganisationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organisations: Organisation[];
  currentOrganisationId: string;
  isCovidCareHeader?: boolean;
}

export default function SwitchOrganisationModal({
  isOpen,
  organisations,
  currentOrganisationId,
  onClose,
  isCovidCareHeader,
}: SwitchOrganisationModalProps) {
  const [isSubmittingOrgId, setIsSubmitting] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const { switchOrganisation } = useAuth();

  const handleSwitchOrganisation = async (organisationId: string) => {
    setIsSubmitting(organisationId);
    try {
      const res = await switchOrganisation(organisationId);
      setIsSubmitting(undefined);
      if (res) {
        if (isCovidCareHeader) {
          window.location.href = `/ext/airborne-index/${organisationId}`;
        } else {
          window.location.href = "/";
        }
      }
    } catch (e) {
      console.log("unable to switch to new org");
    }
  };

  const filteredOrganisations = organisations.filter((o) => {
    if (!searchText.length) return true;
    const normalisedName = o.name
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .toLowerCase();
    const normalisedSearch = searchText
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .toLowerCase();
    return normalisedName.includes(normalisedSearch);
  });

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Organisation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {Boolean(organisations.length > 5) && (
              <Input
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                autoFocus
              />
            )}
            {filteredOrganisations.map((o) => (
              <HStack key={o.id} my={6} justifyContent="space-between">
                <Text>{o.name}</Text>

                {Boolean(o.id === currentOrganisationId) && (
                  <Tag size="md" colorScheme="black" borderRadius="full">
                    <TagLabel>Current Organisation</TagLabel>
                  </Tag>
                )}

                {Boolean(o.id !== currentOrganisationId) && (
                  <Button
                    isLoading={isSubmittingOrgId === o.id}
                    onClick={() => handleSwitchOrganisation(o.id)}
                  >
                    Switch
                  </Button>
                )}
              </HStack>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
