import { Stack } from "@chakra-ui/react";

import { Container } from "@tether-web-portal/components/common";

import {
  OccupancyPageHeader,
  OccupancyUtilisationHeader,
  OccupancyUtilisationTable,
  OccupancyTilesGrid,
  OccupancyBreakdownHeatmap,
  OccupancyBreakdownBarChart,
} from "@tether-web-portal/components/occupancy";
import { OccupancyContextProvider } from "@tether-web-portal/contexts/OccupancyContext";
import { IOccupancyPageFilters } from "@tether-web-portal/web-workers/occupancy";

interface OccupancyDashboardProps {
  propertyId: string;
  pageFilters?: IOccupancyPageFilters;
}

export const OccupancyDashboard = ({ propertyId, pageFilters }: OccupancyDashboardProps) => {
  return (
    <OccupancyContextProvider propertyId={propertyId} filters={pageFilters}>
      <Container rowGap={10} marginTop={5}>
        <Stack direction="column" rowGap={3}>
          <OccupancyPageHeader />
          <OccupancyTilesGrid />
        </Stack>

        <Stack direction="column" rowGap={3}>
          <OccupancyUtilisationHeader />
          <OccupancyUtilisationTable />
        </Stack>

        <Stack direction="column" rowGap={3}>
          <OccupancyBreakdownHeatmap />
        </Stack>

        <Stack direction="column" rowGap={3}>
          <OccupancyBreakdownBarChart />
        </Stack>
      </Container>
    </OccupancyContextProvider>
  );
};
