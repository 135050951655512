import { FunctionComponent, ReactNode } from "react";
import { Text as ChakraText, Heading as ChakraHeading, TextProps } from "@chakra-ui/react";

export interface ITypographyProps {
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
  fontWeight?: "normal" | "medium" | "semibold" | "bold";
  fontStyle?: "italic" | "normal";
  isHeading?: boolean;
  noWrap?: boolean;
  color?: TextProps["color"];
  className?: string;
  opacity?: number;
  textAlign?: "left" | "center" | "right";
  children: ReactNode;
  mb?: TextProps["marginBottom"];
  mt?: TextProps["marginTop"];
}
export const Typography: FunctionComponent<ITypographyProps> = (props: ITypographyProps) => {
  const {
    size,
    isHeading,
    fontWeight,
    fontStyle,
    noWrap,
    color,
    opacity,
    children,
    textAlign,
    mb,
    mt,
    className,
  } = props;

  if (isHeading) {
    return (
      <ChakraHeading size={size} fontWeight={fontWeight} color={color} mb={mb} className={className}>
        {children}
      </ChakraHeading>
    );
  }

  return (
    <ChakraText
      fontSize={size}
      fontWeight={fontWeight}
      color={color}
      textAlign={textAlign}
      opacity={opacity}
      fontStyle={fontStyle}
      className={className}
      whiteSpace={noWrap ? "nowrap" : undefined}
      mb={mb}
      marginTop={mt ? mt : "0 !important"} // Stupid ChakraUi forces you to use !important
    >
      {children}
    </ChakraText>
  );
};
