import { HStack, Stack } from "@chakra-ui/react";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";
import dayjs from "@tether-web-portal/dayjs-setup";
import { useMemo, useState } from "react";
import {
  defaultTimeButtons,
  TimeButtonOptions,
  TimeRangeButtons,
} from "@tether-web-portal/components/button/TimeRangeButtons";
import { DateRangePicker } from "@tether-web-portal/components/datepicker/DateRangePicker";
import { ReadingResolution } from "@tether-web-portal/api/readings/hooks";
import { IDateRange } from "@tether-web-portal/types/common";

interface DateRangeControlsProps {
  buttonOptions?: TimeButtonOptions[];
  maxSelectedDays?: number;
}

export interface DateRangeResolution {
  powerResolution: ReadingResolution;
  environmentalResolution: ReadingResolution;
}

export const getResolutionDaysForRange = (dateRange: TimestampRange): DateRangeResolution => {
  if (!dateRange.toTimestamp || !dateRange.fromTimestamp) {
    return {
      powerResolution: "HOURLY",
      environmentalResolution: "HOURLY",
    };
  }

  const days = dayjs(dateRange.toTimestamp).diff(dateRange.fromTimestamp, "day");

  let powerResolution: ReadingResolution = "HOURLY";

  // power

  if (days > 25) {
    powerResolution = "DAILY";
  } else if (days >= 2) {
    powerResolution = "HOURLY";
  } else {
    powerResolution = "FIFTEENMINUTE";
  }

  // environmental
  const environmentalResolution: ReadingResolution = days > 4 ? "HOURLY" : "FIFTEENMINUTE";
  return {
    environmentalResolution,
    powerResolution,
  };
};

const getSelectedButtonFromDateRange = (
  timeStamps: TimestampRange,
  selectedInterval: MetricDataInterval,
  buttonOptions: TimeButtonOptions[]
) => {
  // end date is start date check if is one of the known ranges
  const match = buttonOptions.find((button) => {
    // Check end dates match
    if (
      button.endDate &&
      dayjs(button.endDate).format(DATE_FORMATS.YEAR_MONTH_DAY_DASHED) !==
        dayjs(timeStamps.toTimestamp).format(DATE_FORMATS.YEAR_MONTH_DAY_DASHED)
    ) {
      return false;
    }

    if (!!button.hours) {
      // if we are checking within a day, check the hours match also
      const dateFormat =
        button.hours > 24
          ? DATE_FORMATS.YEAR_MONTH_DAY_DASHED
          : DATE_FORMATS.DAY_MONTH_YEAR_HOUR_12_HOUR_SLASHED;

      // Check start dates match
      const safeEndDate = button.endDate ? dayjs(button.endDate) : dayjs();
      const endDate =
        button.hours < 25 ? dayjs(safeEndDate).endOf("minute") : dayjs(safeEndDate).endOf("day");
      const startDate = dayjs(endDate).subtract(button.hours * 60, "minute");
      const formattedStartDate = dayjs(startDate).format(dateFormat);
      const formattedCurrentFromDate = dayjs(timeStamps.fromTimestamp).format(dateFormat);
      if (formattedStartDate !== formattedCurrentFromDate) {
        return false;
      }

      return true;
    }

    if (button.interval !== MetricDataInterval.CUSTOM && button.interval === selectedInterval) {
      return true;
    }

    return false;
  });

  return match || null;
};

export const maxReadingDays = 366;

export const DateRangeControls = ({
  buttonOptions = defaultTimeButtons,
  maxSelectedDays: maxSelectedDaysProp = maxReadingDays,
}: DateRangeControlsProps) => {
  const {
    metricsTimeStamps,
    metricsCurrentDataInterval,
    setMetricsInterval,
    setCustomMetricsInterval,
  } = useMetricsInterval();

  const selectedButton = useMemo(
    () => getSelectedButtonFromDateRange(metricsTimeStamps, metricsCurrentDataInterval, buttonOptions),
    [buttonOptions, metricsTimeStamps, metricsCurrentDataInterval]
  );

  const maxSelectedDays = useMemo(() => (maxSelectedDaysProp === -1 ? undefined : maxSelectedDaysProp), [
    maxSelectedDaysProp,
  ]);

  const [customDateRange, setCustomDateRange] = useState<IDateRange>({
    startDate: null,
    endDate: null,
  });

  const setNewTimeRangeFromButton = (button: TimeButtonOptions) => {
    if (button.interval !== MetricDataInterval.CUSTOM) {
      setMetricsInterval(button.interval);
    } else if (button.hours) {
      const safeEndDate = button.endDate ? dayjs(button.endDate) : dayjs();
      const endDate =
        button.hours < 25 ? dayjs(safeEndDate).endOf("minute") : dayjs(safeEndDate).endOf("day");
      const startDate = dayjs(endDate).subtract(button.hours * 60, "minute");
      setCustomMetricsInterval(startDate.toISOString(), endDate.toISOString());
    }
  };

  return (
    <HStack justifyContent={"flex-end"}>
      <HStack>
        <Stack bg={"white"} p={1} border="1px" borderColor="gray.200" borderRadius="md">
          <TimeRangeButtons
            onSelect={setNewTimeRangeFromButton}
            overridden={selectedButton === null}
            selectedValue={selectedButton}
            buttons={buttonOptions}
          />
        </Stack>
        <DateRangePicker
          startDate={customDateRange.startDate ? customDateRange.startDate : null}
          endDate={customDateRange.endDate ? customDateRange.endDate : null}
          onDatesChange={(data) => {
            setCustomDateRange({
              startDate: data.startDate,
              endDate: data.endDate && dayjs(data.endDate).endOf("day"),
            });
            if (data.startDate && data.endDate) {
              setCustomMetricsInterval(
                dayjs(data.startDate).startOf("day").toISOString(),
                dayjs(data.endDate).endOf("day").toISOString()
              );
            }
          }}
          bg={selectedButton === null ? "black" : "white"}
          color={selectedButton === null ? "white" : undefined}
          borderColor="gray.100"
          borderRadius={6}
          maxBookingDate={dayjs()}
          horizontalOffset={-460}
          maxSelectedDays={maxSelectedDays}
          showDateRangeOnButton={selectedButton === null}
          onClick={() => {
            // If the custom date button is clicked and a range has already been specified there, select it
            if (customDateRange.startDate && customDateRange.endDate) {
              setCustomMetricsInterval(
                dayjs(customDateRange.startDate).startOf("day").toISOString(),
                dayjs(customDateRange.endDate).endOf("day").toISOString()
              );
            }
          }}
        />
      </HStack>
    </HStack>
  );
};

export default DateRangeControls;
