import { useCallback } from "react";
import { Flex } from "@chakra-ui/react";

import OccupancyOverallTile from "@tether-web-portal/components/occupancy/tiles/OccupancyOverallTile";
import OccupancyQuickGlanceTile from "@tether-web-portal/components/occupancy/tiles/OccupancyQuickGlanceTile";
import OccupancyPeakDaysTile from "@tether-web-portal/components/occupancy/tiles/OccupancyPeakDaysTile";
import OccupancyPeakHoursTile from "@tether-web-portal/components/occupancy/tiles/OccupancyPeakHoursTile";
import OccupancySpacesTile from "@tether-web-portal/components/occupancy/tiles/OccupancySpacesTile";
import OccupancyReadingTile from "@tether-web-portal/components/occupancy/tiles/OccupancyReadingTile";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { OccupancyContextActions, useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { OccupancyUtilisationLevels } from "@tether-web-portal/web-workers/occupancy";

export const OccupancyTilesGrid = () => {
  const { isLiveView, tilesData, tilesLoading, dispatcher, utilisationTableFilters } = useOccupancyContext();
  const { metricsNumberOfDaysToShow } = useMetricsInterval();

  const onSpacesViewMoreClick = useCallback(() => {
    const updatedFilters = {
      ...utilisationTableFilters,
      usage: OccupancyUtilisationLevels.OPTIMAL,
    };
    dispatcher(OccupancyContextActions.SET_UTILISATION_TABLE_FILTERS(updatedFilters));
  }, [dispatcher, utilisationTableFilters]);

  return (
    <Flex wrap="wrap" gap="18px" justify="space-between">
      <OccupancyOverallTile data={tilesData.overall} loading={tilesLoading} />

      {metricsNumberOfDaysToShow < 14 && (
        <OccupancySpacesTile
          data={tilesData.spaces}
          loading={tilesLoading}
          onAction={onSpacesViewMoreClick}
          isLive={isLiveView}
        />
      )}

      {isLiveView ? (
        <>
          <OccupancyReadingTile type="entry" data={tilesData.readingIn} loading={tilesLoading} />
          <OccupancyReadingTile type="exit" data={tilesData.readingOut} loading={tilesLoading} />
          <OccupancyReadingTile type="traffic" data={tilesData.readingTraffic} loading={tilesLoading} />
        </>
      ) : (
        <>
          <OccupancyPeakDaysTile data={tilesData.peakDays} loading={tilesLoading} />
          <OccupancyPeakHoursTile data={tilesData.peakHours} loading={tilesLoading} />
        </>
      )}

      {metricsNumberOfDaysToShow >= 14 && (
        <OccupancyQuickGlanceTile data={tilesData.glance} loading={tilesLoading} />
      )}
    </Flex>
  );
};
