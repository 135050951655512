import { sort as fastSort } from "fast-sort";
import { RoomLocationWithReading } from "@tether-web-portal/types/RoomLocation";
import { isSampleRecent } from "@tether-web-portal/utils/isSampleRecent";
import { RoomsSort } from "./types";

type SampleKey = keyof RoomLocationWithReading["device"]["lastSample"]["sample"];

const getValue = (field: SampleKey) => (l: RoomLocationWithReading): number | boolean | undefined => {
  const isOnline = isSampleRecent(l?.device?.lastSample, l?.device?.type);
  if (!isOnline) {
    return undefined;
  }

  return l?.device?.lastSample?.sample?.[field];
};

export type OrderBy = "asc" | "desc";

type SortByMap = Record<RoomsSort, [OrderBy, SampleKey]>;

const sortMap: SortByMap = {
  highestCO2: ["desc", "co2"],
  lowestCO2: ["asc", "co2"],
  highestTemp: ["desc", "temperature"],
  lowestTemp: ["asc", "temperature"],
  highestHumidity: ["desc", "humidity"],
  lowestHumidity: ["asc", "humidity"],
};

export const getSortedRoomLocations = (roomLocations: RoomLocationWithReading[], sort: RoomsSort) => {
  if (sortMap[sort]) {
    const [orderBy, field] = sortMap[sort];
    return fastSort(roomLocations)[orderBy](getValue(field));
  }

  return roomLocations;
};
