import React, { useMemo } from "react";
import { Box, HStack } from "@chakra-ui/react";

const Bar = ({
  height,
  backgroundColor,
  opacity,
}: {
  height: number;
  backgroundColor?: string;
  opacity?: number;
}) => (
  <Box
    height={`${height}px`}
    flex={1}
    backgroundColor={backgroundColor ?? "#ebeced"}
    marginInlineStart="0px !important"
    borderRadius="4px"
    opacity={opacity}
  />
);

interface NetworkConnectionIconProps {
  connectionPercentage: number;
  size?: number;
  barsCount?: number;
  isOffline?: boolean;
}
export const NetworkConnectionIcon = ({
  connectionPercentage,
  barsCount = 5,
  size = 20,
  isOffline = false,
}: NetworkConnectionIconProps) => {
  const safeSize = useMemo(() => size ?? 20, [size]);
  const safeBarsCount = useMemo(() => barsCount ?? 5, [barsCount]);

  const { connectionBarsCount, connectionBarsColor } = useMemo(() => {
    const connectionBarsCount = Math.max(Math.ceil(safeBarsCount * connectionPercentage), 1);

    let connectionBarsColor = "#fd7e14";
    if (connectionPercentage >= 0.5) {
      connectionBarsColor = "#28a745";
    } else if (connectionPercentage < 0.2) {
      connectionBarsColor = "#dc3545";
    }

    return {
      connectionBarsCount,
      connectionBarsColor,
    };
  }, [connectionPercentage, safeBarsCount]);

  return (
    <HStack
      alignItems="flex-end"
      justifyContent={"space-between"}
      columnGap={`calc(${safeSize}px / 20)`}
      width={`${safeSize}px`}
      height={`${safeSize}px`}
    >
      {Array.from({ length: safeBarsCount }, (_, index) => (
        <Bar
          key={index}
          height={safeSize * ((index + 1) / safeBarsCount)}
          backgroundColor={connectionBarsCount >= index + 1 ? connectionBarsColor : undefined}
          opacity={isOffline ? 0.5 : 1}
        />
      ))}
    </HStack>
  );
};
