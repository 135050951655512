import { SVGMeasure, useSVGMeasure } from "@tether-web-portal/components/charts/SVGMeasure";
import { scaleLinear } from "@visx/scale";
import { ScaleLinear } from "d3";
import { useMemo } from "react";

type SingleBarChartProps = {
  colorScale: ScaleLinear<string, string, never>,
  value: number,
  height?: number,
  maxValue?: number
}

const SingleBarChartContents = ({
  colorScale,
  value,
  height = 8,
  maxValue = 100
}: SingleBarChartProps) => {
  const { width } = useSVGMeasure();
  const xScale = useMemo(
    () => scaleLinear({
      range: [0, width],
      domain: [0, maxValue],
    }),
    [width, maxValue]
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={height}
        rx={height /2}
        fill={'#F3F3F3'}
      />
      <rect
        width={xScale(value)}
        height={height}
        rx={height /2}
        fill={colorScale(value)}
      />
    </svg>
  );
}

export const SingleBarChart = (props: SingleBarChartProps) => (
  <SVGMeasure measureWidth >
    <SingleBarChartContents {...props} />
  </SVGMeasure>
)
