import { Badge } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { BadgeProps } from "@chakra-ui/layout/src/badge";
import {
  OCCUPANCY_LOW,
  OCCUPANCY_OPTIMAL,
  OCCUPANCY_OVER,
} from "@tether-web-portal/components/occupancy/occupancy.colors";

interface StatusBadgeProps extends BadgeProps {
  value: number;
}

export const OccupancyStatusBadge = ({ value, ...props }: StatusBadgeProps) => {
  const values = useMemo(() => {
    if (value <= 40) {
      return {
        color: OCCUPANCY_LOW,
        label: "Low",
      };
    }

    if (value > 100) {
      return {
        color: OCCUPANCY_OVER,
        label: "Over",
      };
    }

    return {
      color: OCCUPANCY_OPTIMAL,
      label: "Optimal",
    };
  }, [value]);

  return (
    <Badge
      {...props}
      bg={values.color}
      fontSize={props?.fontSize ?? "xl"}
      textTransform="none"
      color="white"
      mt={1}
      mb={2}
      px={2.5}
      py={1}
      borderRadius={"3xl"}
    >
      {values.label}
    </Badge>
  );
};
