interface Props {
  height?: number;
  width?: number;
  fill?: string;
}

const HotDropBolt = ({ height, width, fill }: Props) => (
  <svg height={height} width={width} fill="none" viewBox={`0 0 42 42`} preserveAspectRatio="xMinYMin slice">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.373 9.018c.203.06.334.253.314.46l-1 10.195h6.271c.165 0 .316.09.392.234a.432.432 0 0 1-.03.452l-8.799 12.454a.445.445 0 0 1-.519.158.436.436 0 0 1-.282-.458l.937-7.895h-5.215a.443.443 0 0 1-.378-.21.431.431 0 0 1-.014-.428l7.806-14.746a.444.444 0 0 1 .517-.216Zm-7.202 14.727h4.982c.126 0 .246.054.33.147a.433.433 0 0 1 .109.34l-.79 6.66 7.31-10.347H21.2a.444.444 0 0 1-.327-.143.433.433 0 0 1-.113-.335l.829-8.446-6.418 12.124Z"
      fill={fill}
    />
    <path d="m15 24 7-13.5-1 10h6.5l-8 11.5 1-8H15Z" fill={fill} />
    <circle cx={21} cy={21} r={20.5} stroke={fill} strokeDasharray="5 5" />
  </svg>
);

export default HotDropBolt;
