/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Text,
  HStack,
  ModalBody,
  Box,
} from "@chakra-ui/react";
import { PropertyReadingStruct } from "components/property-graph/GraphUtils";
import dayjs from "@tether-web-portal/dayjs-setup";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";
import { api } from "lib/api/api";
import { useCallback, useEffect, useState } from "react";

interface RoomRadarLiveCountsModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomLocationId: string;
}
export const RoomRadarLiveCountsModal = ({
  isOpen,
  onClose,
  roomLocationId,
}: RoomRadarLiveCountsModalProps) => {
  const [readings, setReadings] = useState<PropertyReadingStruct>();
  const [totalCounts, setTotalCounts] = useState<{ totalIn: number; totalOut: number }>({
    totalIn: 0,
    totalOut: 0,
  });

  const [fromTimestamp, setFromTimestamp] = useState(dayjs().subtract(60, "minute").toISOString());
  const [initialCounts, setInitialCounts] = useState<{ totalIn: number; totalOut: number } | null>(null);
  const [changedItems, setChangedItems] = useState<("totalIn" | "totalOut")[]>([]);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState("");

  const refreshReadings = useCallback(async () => {
    const results = await api.get(`property/v2/roomlocation/${roomLocationId}/readings`, {
      fromTimestamp,
      toTimestamp: dayjs().toISOString(),
      resolution: "HOURLY",
      milliseconds: "true",
    });

    setReadings(results);
  }, [roomLocationId, fromTimestamp]);

  useEffect(() => {
    if (!readings) {
      return;
    }

    let totalInCount = 0;
    let totalOutCount = 0;

    for (const key in readings) {
      if (key.endsWith("_total_in")) {
        totalInCount += ((readings as any)[key] as [number, number][])
          .map((x) => x[1])
          .reduce((a, b) => a + b, 0);
      } else if (key.endsWith("_total_out")) {
        totalOutCount += ((readings as any)[key] as [number, number][])
          .map((x) => x[1])
          .reduce((a, b) => a + b, 0);
      }
    }

    const changedItems: ("totalIn" | "totalOut")[] = [];
    if (totalInCount !== totalCounts.totalIn) {
      changedItems.push("totalIn");
    }
    if (totalOutCount !== totalCounts.totalOut) {
      changedItems.push("totalOut");
    }

    setTotalCounts({
      totalIn: totalInCount,
      totalOut: totalOutCount,
    });
    setLastDataRefreshTime(dayjs().format(DATE_FORMATS.TIME_WITH_SECONDS_12_HOUR));
    if (!initialCounts) {
      setInitialCounts({
        totalIn: totalInCount,
        totalOut: totalOutCount,
      });
    }

    setChangedItems(changedItems);
    setTimeout(() => {
      setChangedItems([]);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readings]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setFromTimestamp(dayjs().subtract(60, "minute").toISOString());
    setInitialCounts(null);

    refreshReadings();
    const interval = setInterval(() => {
      refreshReadings();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomLocationId, isOpen]);

  return (
    <Modal size="xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton mt={"2.5"} />
        <Box mt={6} ml={6}>
          <Text fontSize="xl" fontWeight={"bold"}>
            Room Radar Live Counts
          </Text>
        </Box>

        <ModalBody pt={4}>
          <VStack rowGap={4}>
            <Text>
              Walk underneath the room radar device either going in or out, and then wait for the numbers
              underneath to change
            </Text>

            <HStack columnGap={4} width="100%" py={8}>
              <VStack rowGap={0} justifyContent="center" alignItems="center" flex={1}>
                <Text fontSize="3xl" className={changedItems.includes("totalIn") ? "pulse-green" : ""}>
                  {!initialCounts
                    ? "+0"
                    : `${totalCounts.totalIn - initialCounts.totalIn < 0 ? "-" : "+"}${Math.abs(
                        totalCounts.totalIn - initialCounts.totalIn
                      )}`}
                </Text>
                <Text fontSize="2xl" fontWeight={500}>
                  Total In
                </Text>
              </VStack>

              <VStack rowGap={0} justifyContent="center" alignItems="center" flex={1}>
                <Text fontSize="3xl" className={changedItems.includes("totalOut") ? "pulse-green" : ""}>
                  {!initialCounts
                    ? "+0"
                    : `${totalCounts.totalOut - initialCounts.totalOut < 0 ? "-" : "+"}${Math.abs(
                        totalCounts.totalOut - initialCounts.totalOut
                      )}`}
                </Text>
                <Text fontSize="2xl" fontWeight={500}>
                  Total Out
                </Text>
              </VStack>
            </HStack>

            <VStack justifyContent="center">
              <Text fontSize="sm">Last Data Refresh: {lastDataRefreshTime}</Text>
              <Text fontSize="xs">
                Real-time view of people that enters and exits this area since you've opened this modal.
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
