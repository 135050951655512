export const RegionIcon = ({ size, color = "black" }: { size: number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M17.025 8.625L15.5254 10.125L14.025 8.625M15.7088 9.75C15.7361 9.50378 15.75 9.2535 15.75 9C15.75 5.27208 12.728 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.728 5.27208 15.75 9 15.75C11.1205 15.75 13.0125 14.7722 14.25 13.243M9 5.25V9L11.25 10.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
