import {
  DeviceType,
  EnvironmentalDeviceTypes,
  OccupancyDeviceTypes,
  RoomRadarDeviceTypes,
} from "@tether-web-portal/types/DeviceType";

export const deviceTypeIsEnvironmental = (deviceType: DeviceType | string = "") => {
  return EnvironmentalDeviceTypes.has(deviceType.toUpperCase() as DeviceType);
};

export const deviceTypeIsRoomRadar = (deviceType: DeviceType | string = "") => {
  return RoomRadarDeviceTypes.has(deviceType.toUpperCase() as DeviceType);
};

export const deviceTypeIsOccupancy = (deviceType: DeviceType | string = "") => {
  return OccupancyDeviceTypes.has(deviceType.toUpperCase() as DeviceType);
};
