import { useEffect, useState, useRef, useCallback, useMemo } from "react";

export const useResizeObserver = () => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);

  const calculateAvailableSize = useCallback((element: HTMLDivElement) => {
    const computedStyle = getComputedStyle(element);
    const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

    const availableWidth = element.clientWidth - paddingX;
    const availableHeight = element.clientHeight - paddingY;

    setWidth(availableWidth);
    setHeight(availableHeight);
  }, []);

  const resizeObserver = useMemo<ResizeObserver>(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const element = entry.target as HTMLDivElement;
          calculateAvailableSize(element);
        }
      }),
    [calculateAvailableSize]
  );

  const setRef = useCallback(
    (element) => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }

      ref.current = element;
      if (!element) return;

      calculateAvailableSize(element);
      resizeObserver.observe(element);
    },
    [resizeObserver, calculateAvailableSize]
  );

  return {
    width,
    height,
    setRef,
  };
};
