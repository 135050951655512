import { TickFormatter } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { NumberValue, ScaleBand, ScaleLinear } from "d3";
import { useMemo } from "react";
import { SVGMeasure, useSVGMeasure } from "@tether-web-portal/components/charts/SVGMeasure";
import {
  AxisConfig,
  ChartBenchmarkType,
  TooltipDisplayComponent,
} from "@tether-web-portal/components/charts/types";
import { Bars } from "./Bars";
import { HorizontalBarChartBenchmarks } from "./HorizontalBarChartBenchmarks";
import { HorizontalBarChartTooltip } from "./tooltip/HorizontalBarChartTooltip";
import { useInteractionRect } from "./useInteractionRect";
import { ViewboxBorder } from "./ViewboxBorder";
import { XAxis } from "./XAxis";
import { YAxis } from "./YAxis";

export type HorizontalBarChartDataPoint<T> = {
  key: string;
  label: string;
  value?: number;
  data?: T;
  faded?: boolean;
};

export type HorizontalBarChartXScale = ScaleBand<string>;
export type HorizontalBarChartYScale = ScaleLinear<number, number, never>;
export type HorizontalBarChartColorScale = ScaleLinear<string, string, never>;

const marginTop = 8;
const minBarWidth = 12;
const barGap = 26;
const labelLineHeight = 14;

const defaultXAxisHeightConfig = new AxisConfig({
  lineHeight: labelLineHeight,
  title: labelLineHeight,
  gap: 24,
  label: 32,
  innerMargin: 8,
});

const defaultYAxisWidthConfig = new AxisConfig({
  lineHeight: labelLineHeight,
  title: labelLineHeight,
  gap: 32,
  label: labelLineHeight,
  innerMargin: 12,
});

interface HorizontalBarChartProps<T> {
  data: HorizontalBarChartDataPoint<T>[];
  maxValue: number;
  colorScale: HorizontalBarChartColorScale;
  valueAxisLabel: string;
  barAxisLabel: string;
  TooltipDisplay?: TooltipDisplayComponent<T>;
  yAxisFormatter?: TickFormatter<NumberValue>;
  yAxisWidthConfig?: AxisConfig;
  xAxisHeightConfig?: AxisConfig;
  benchmarks?: ChartBenchmarkType[];
}

const HorizontalBarChartContents = <T,>({
  colorScale,
  data,
  maxValue,
  valueAxisLabel,
  barAxisLabel,
  yAxisFormatter,
  TooltipDisplay,
  yAxisWidthConfig = defaultYAxisWidthConfig,
  xAxisHeightConfig = defaultXAxisHeightConfig,
  benchmarks,
}: HorizontalBarChartProps<T>) => {
  const { width, height } = useSVGMeasure();

  const xMax = width - yAxisWidthConfig.total;
  const yMax = height - xAxisHeightConfig.total - marginTop;

  const xScale = useMemo(() => {
    const barsXMax = data.length * (minBarWidth + barGap) + barGap;
    return scaleBand<string>({
      range: [0, Math.max(barsXMax, xMax)],
      round: true,
      domain: data.map(({ key }) => key),
      padding: barGap / (minBarWidth + barGap),
    });
  }, [data, xMax]);

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(({ value = 0 }) => value), maxValue)],
      }),
    [yMax, data, maxValue],
  );

  const [rectProps, { hoveredBar, scrollOffsetX, scrollOffsetY }] = useInteractionRect<T>({
    xMax,
    yMax,
    xScale,
    yScale,
    data,
  });

  if (!height || !width) return null;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Group top={marginTop}>
        <Group left={yAxisWidthConfig.total}>
          <Group left={scrollOffsetX} data-name={"bars"}>
            {data.length > 0 && (
              <Bars
                data={data}
                yMax={yMax}
                xScale={xScale}
                yScale={yScale}
                colorScale={colorScale}
                xAxisHeightConfig={xAxisHeightConfig}
              />
            )}

            <HorizontalBarChartTooltip<T>
              data={hoveredBar}
              yMax={yMax}
              xScale={xScale}
              yScale={yScale}
              colorScale={colorScale}
              TooltipDisplay={TooltipDisplay}
              maxValue={maxValue}
            />
          </Group>

          <ViewboxBorder xMax={xMax} yMax={yMax + 1} />
        </Group>

        <Group left={yAxisWidthConfig.total} top={yMax}>
          {data.length > 0 && (
            <XAxis
              scrollOffsetX={scrollOffsetX}
              axisHeightConfig={xAxisHeightConfig}
              label={barAxisLabel}
              xMax={xMax}
              xScale={xScale}
              data={data}
            />
          )}
        </Group>

        <rect data-name={"hides-overflow"} width={yAxisWidthConfig.total} height={height} fill={"white"} />

        <HorizontalBarChartBenchmarks
          yAxisWidthConfig={yAxisWidthConfig}
          benchmarks={benchmarks}
          yScale={yScale}
          xMax={xMax}
          yMax={yMax}
          maxValue={maxValue}
        />

        <Group>
          {data.length > 0 && (
            <YAxis
              yAxisFormatter={yAxisFormatter}
              axisWidthConfig={yAxisWidthConfig}
              label={valueAxisLabel}
              yMax={yMax}
              yScale={yScale}
            />
          )}
        </Group>

        <Group left={yAxisWidthConfig.total}>
          <rect fill={"transparent"} width={xMax} height={yMax} {...rectProps} />
        </Group>
      </Group>
    </svg>
  );
};

export const HorizontalBarChart = <T,>(props: HorizontalBarChartProps<T>) => (
  <SVGMeasure measureWidth measureHeight>
    <HorizontalBarChartContents<T> {...props} />
  </SVGMeasure>
);
