import { HeatMapRow, HeatMapYScale } from "@tether-web-portal/components/heat-map/v2/HeatMap"
import { Group } from "@visx/group"
import { Line } from "@visx/shape";
import { CSSProperties } from "react"

const BenchmarkIcon = ({ size = 0 }) => (
  <path
    d={`M0 0 L${size} ${size/2} L0 ${size} z`}
    fill={"#595F6A"}
  />
);

const BenchmarkLine = ({
  width,
  y
}: {
  width: number;
  y: number;
}) => (
  <Line
    strokeWidth={2}
    stroke={'#595F6A'}
    strokeDasharray={'2 2'}
    from={{
      x: 0,
      y
    }}
    to={{
      x: width,
      y
    }}
  />
);

export const RowLabels = ({
  rows,
  yScale,
  labelWidth,
  axisLabelWidth,
  axisLabel,
  columnXMax,
  yMax,
  benchmarkIconWidth,
  labelStyle
}: {
  rows: HeatMapRow[]
  yScale: HeatMapYScale,
  labelWidth: number,
  axisLabelWidth: number,
  axisLabel: string,
  benchmarkIconWidth: number,
  columnXMax: number,
  yMax: number,
  labelStyle: CSSProperties
}) => {

  const labelHeight = yScale.bandwidth();

  return (
    <Group data-name={'heat-map-row-labels'} >
      <rect
        width={labelWidth + axisLabelWidth + benchmarkIconWidth + 2}
        height={yMax}
        fill={'white'}
      />

      <Group top={yMax} >
        <g transform={`rotate(-90)`} >
          <foreignObject
            x={0}
            y={0}
            width={yMax}
            height={axisLabelWidth}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                alignContent: 'center',
                textAlign: 'center',
                ...labelStyle
              }}
            >
              {axisLabel}
            </div>
          </foreignObject>
        </g>
      </Group>

      <Group left={axisLabelWidth}>
        {rows.map(
          ({ label, key, benchmark }) => {
            const top = yScale(key);
            const hasBenchmark = benchmark?.start || benchmark?.end;

            let benchmarkY = 0;
            if (benchmark?.start) benchmarkY = - 2;
            if (benchmark?.end) benchmarkY = yScale.bandwidth() + 2;

            return (
              <Group key={key} top={top} >
                {hasBenchmark && (
                  <Group top={(labelHeight / 2) - 4}>
                    <BenchmarkIcon size={8} />
                  </Group>
                )}

                <Group left={benchmarkIconWidth}>
                  <foreignObject
                    x={0}
                    y={0}
                    height={labelHeight}
                    width={labelWidth}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        alignContent: 'center',
                        textAlign: 'end',
                        ...labelStyle
                      }}
                    >
                      {label}
                    </div>
                  </foreignObject>
                </Group>

               {hasBenchmark && (
                  <Group left={benchmarkIconWidth + labelWidth} >
                    <BenchmarkLine
                      width={columnXMax}
                      y={benchmarkY}
                    />
                  </Group>
                )}
              </Group>
            );
          }
        )}
      </Group>
    </Group>
  )
}
