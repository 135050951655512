import { Box, Button, HStack, Text, Flex } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { LocationType } from "@tether-web-portal/api/properties/hooks";
import { Circuit } from "@tether-web-portal/types/Circuit";
import { CircuitNode } from "@tether-web-portal/types/CircuitNode";
import { Outside } from "@tether-web-portal/types/Outside";
import { Property } from "@tether-web-portal/types/Property";
import { PropertyDetail } from "@tether-web-portal/types/PropertyDetail";
import { ComfortScore, Room } from "@tether-web-portal/types/Room";
import { UserPinnedEntityType } from "@tether-web-portal/types/UserPinnedEntity";
import formatAddress from "@tether-web-portal/utils/formatAddress";
import { BackButton } from "../button/BackButton";
import { PinButton } from "../button/PinButton";
import { isValidRoomIcon, RoomIcon } from "../create-room/room-icon/RoomIcon";
import { OrganisationTagsDisplay } from "../organisation-tag/OrganisationTagsDisplay";
import { AreaPicker, AreaPickerAreaType } from "../property/AreaPicker";
import { ComfortScoreTag } from "../property/ComfortScoreTag";
import { ManageOutsideOrCircuitMenu } from "../property/ManageOutsideOrCircuitMenu";
import { ManageRoomMenu } from "../property/ManageRoomMenu";
import { useSidebarLayout } from "../sidebar/SidebarLayout";
import { Helmet } from "react-helmet";
import { RouterLink } from "@tether-web-portal/components/common";
import { useNavigate } from "react-router-dom";
import { useGetOrganisationFeatureFlag } from "@tether-web-portal/api/organisations/hooks";
import { checkIfRoomHasOccupancyDevices } from "@tether-web-portal/web-workers/occupancy";

type AreaType = "room" | "circuit" | "outside" | "circuitNode";

type TabSlugs = "" | "areas" | "reports" | "tethertick" | "alerts" | "compare" | "occupancy";

interface AreaTabs {
  slug: TabSlugs;
  title: string;
  excludedAreaTypes?: AreaType[];
}

type SimpleAreaType = (Room | Circuit | Outside | CircuitNode) & {
  tagIds?: string[];
};

interface AreaPageHeaderProps {
  area: SimpleAreaType;
  areaType: AreaType;
  propertyInfo: PropertyDetail;
  circuitNodeTree: CircuitNode[];
  comfortScore?: ComfortScore;
  property: Property;
  activeTab: TabSlugs;
  dateRangeControl?: JSX.Element;
}

export const buildAreaUrl = (
  areaType: AreaType | UserPinnedEntityType | AreaPickerAreaType,
  area: Room | Circuit | Outside | CircuitNode,
  slug?: string
) => {
  const lowAreaType = areaType.toLowerCase();
  if (lowAreaType === "circuitnode") {
    return `/area/circuitnode/${area.id}`;
  }
  return `/area/${lowAreaType}/${area.id}${slug ? `/${slug}` : ""}`;
};

export const AreaPageHeader: React.FC<AreaPageHeaderProps> = ({
  activeTab,
  area,
  areaType,
  propertyInfo,
  circuitNodeTree,
  comfortScore,
  property,
  dateRangeControl,
  children,
}) => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const navigate = useNavigate();

  const { currentSidebarWidth } = useSidebarLayout();

  const { value: occupancyFF } = useGetOrganisationFeatureFlag("OCCUPANCY");
  const shouldShowOccupancyTab = useMemo(() => {
    return occupancyFF && (propertyInfo?.rooms ?? []).some(checkIfRoomHasOccupancyDevices);
  }, [occupancyFF, propertyInfo]);

  const tabs = useMemo(
    (): AreaTabs[] => [
      {
        slug: "",
        title: "Details",
      },
      {
        slug: "alerts",
        title: "Alerts",
        excludedAreaTypes: ["circuit", "circuitNode", "outside"],
      },
      ...(shouldShowOccupancyTab
        ? [
            {
              slug: "occupancy" as TabSlugs,
              title: "Occupancy",
            },
          ]
        : []),
    ],
    [shouldShowOccupancyTab]
  );

  const handleStickyHeader = () => {
    const container = document.getElementById("sidebarContainer");
    const headerHeight = 250;

    if (!container) {
      return;
    }

    if (container!.scrollTop >= headerHeight) {
      setShowStickyHeader(true);
    }
    if (container!.scrollTop < headerHeight) {
      setShowStickyHeader(false);
    }
  };

  // Sticky Menu Area
  useEffect(() => {
    const container = document.getElementById("sidebarContainer");
    if (!container) {
      return;
    }
    container.addEventListener("scroll", handleStickyHeader, false);
    return () => container.removeEventListener("scroll", handleStickyHeader);
  }, []);

  const getAreaTitle = () => {
    if (areaType === "circuitNode") {
      return "CIRCUIT LOCATION";
    }
    return areaType.toUpperCase();
  };

  return (
    <>
      <Helmet>
        <title>Tether {area ? `| ${area.name}` : ""}</title>
      </Helmet>

      <Box>
        {Boolean(showStickyHeader) && (
          <Box
            style={{ position: "fixed" }}
            width="100%"
            backgroundColor={"white"}
            py={2}
            pl={6}
            pr={currentSidebarWidth + 14}
            sx={{ zIndex: 20, opacity: 0.85 }}
          >
            <HStack justifyContent={{ base: "space-around", md: "space-between" }} flexWrap="wrap" rowGap={2}>
              <Box>
                {tabs.map((t) => (
                  <RouterLink key={t.slug} to={buildAreaUrl(areaType, area, t.slug)}>
                    <Button
                      color={activeTab === t.slug ? "white" : "black"}
                      borderRadius={8}
                      colorScheme="gray"
                      variant={activeTab === t.slug ? "solid" : "ghost"}
                      backgroundColor={activeTab === t.slug ? "black" : undefined}
                      fontWeight="600"
                      fontSize="14px"
                      mr={1}
                    >
                      {t.title}
                    </Button>
                  </RouterLink>
                ))}
              </Box>
              <Box justifyContent={"flex-end"} pr={"6px"}>
                {dateRangeControl}
              </Box>
            </HStack>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          bgPosition="center"
          bgRepeat="no-repeat"
          backgroundSize="cover"
          height="100%"
        >
          <Box bgGradient={"linear(45deg, gray.900,gray.900)"} p={6} pb={4}>
            <BackButton text={formatAddress(property)} href={`/property/${area.propertyId}/areas`} />
            <Flex dir="row" alignItems="center" justifyContent="space-between" mb={10}>
              <Flex flexDir="column" mt={5}>
                <Flex alignItems="center">
                  {(area && isValidRoomIcon((area as Room).type) && (
                    <Box p={2} bg="white" borderRadius={5}>
                      <RoomIcon type={(area as Room).type} />
                    </Box>
                  )) || <Box width={8} height={8} p={2} borderRadius={5} bg={"#000"}></Box>}
                  <Flex ml={2} flexDir="column">
                    <Text fontSize="xl" fontWeight="bold" color="white">
                      {area.name}
                    </Text>
                    <Text color="gray.100" letterSpacing="0.05em" fontSize="sm">
                      {getAreaTitle()}
                    </Text>
                  </Flex>
                  {comfortScore !== undefined && (
                    <ComfortScoreTag comfortScore={comfortScore} lightenColor={20} mr={0} />
                  )}
                  <Box ml={4}>
                    <AreaPicker
                      placeholder={"Switch Area"}
                      rooms={
                        propertyInfo?.rooms.filter((r) =>
                          r.roomLocations.find((rl) => rl.device !== undefined)
                        ) || []
                      }
                      outsides={propertyInfo?.outsides || []}
                      circuitNodes={circuitNodeTree || []}
                      selectedArea={
                        propertyInfo?.rooms.find((r) => r.id === area.id) ||
                        propertyInfo?.outsides.find((o) => o.id === area.id) ||
                        propertyInfo?.circuits.find((c) => c.id === area.id)
                      }
                      onAreaSelected={(rl, apAreaType) => {
                        navigate(buildAreaUrl(apAreaType, rl, activeTab));
                      }}
                      renderCustomButton={(onClick) => (
                        <Button onClick={onClick} variant="outline" color="white">
                          Switch Area
                        </Button>
                      )}
                    />
                  </Box>
                </Flex>

                {area.tagIds && area.tagIds.length > 0 && (
                  <Flex justifyContent="flex-start" mt={4}>
                    <OrganisationTagsDisplay tagIds={area.tagIds} overflow="grouped" />
                  </Flex>
                )}
              </Flex>
              <Flex>
                {areaType === "room" && (
                  <PinButton
                    entityId={area.id}
                    entityType={UserPinnedEntityType.Room}
                    propertyId={area.propertyId}
                    color="white"
                  />
                )}
                {areaType === "room" && (
                  <ManageRoomMenu
                    color="white"
                    roomId={area.id}
                    propertyId={area.propertyId}
                    roomName={area.name}
                    //   insights={room.roomLocations[0]?.insights}
                  />
                )}
                {["circuit", "outside"].includes(areaType) && (
                  <ManageOutsideOrCircuitMenu
                    location={area}
                    locationType={areaType === "circuit" ? LocationType.Circuit : LocationType.Outside}
                    propertyId={property.id}
                    color="white"
                  />
                )}
              </Flex>
            </Flex>

            <HStack justifyContent="space-between" flexWrap="wrap" rowGap={2} position="relative">
              <Box>
                {tabs.map((t) => {
                  if (t.excludedAreaTypes?.includes(areaType) === true) {
                    return false;
                  }

                  return (
                    <RouterLink
                      key={t.slug}
                      to={buildAreaUrl(areaType, area, t.slug)}
                    >
                      <Button
                        textColor="white"
                        borderRadius={8}
                        colorScheme="whiteAlpha"
                        variant={activeTab === t.slug ? "solid" : "ghost"}
                        fontWeight="600"
                        mr={1}
                      >
                        {t.title}
                      </Button>
                    </RouterLink>
                  );
                })}
              </Box>
              {Boolean(!showStickyHeader) && (
                <Box
                  position={{ base: "relative", md: "absolute" }}
                  justifyContent={"flex-end"}
                  right={{ base: "auto", md: -1 }}
                  top={{ base: "auto", md: 90 }}
                >
                  {dateRangeControl}
                </Box>
              )}
            </HStack>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </>
  );
};
