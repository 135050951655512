import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React, { useState } from "react";
import { useGetProperties } from "@tether-web-portal/api/properties/hooks";
import { Property } from "@tether-web-portal/types/Property";
import { NavItem } from "../sidebar/NavItem";
import { GloablPropertySearchModal } from "./GlobalPropertySearchModal";
import { useNavigate } from "react-router-dom";

interface GlobalPropertySearchProps {
  isCollapsed: boolean;
}

export const GlobalPropertySearch = ({ isCollapsed }: GlobalPropertySearchProps) => {
  const [queryPropertiesEnabled, setEnableQueryProperties] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { data: properties = [] } = useGetProperties({
    enabled: queryPropertiesEnabled,
  });

  const handleOpenProperty = (property: Property) => {
    navigate(`/property/${property.id}`);
  };

  const groupStyle = !isCollapsed
    ? {}
    : {
        width: "40px",
        marginleft: "2px",
      };

  const searchStyle = !isCollapsed
    ? {}
    : {
        WebkitPaddingEnd: 0,
        paddingInlineEnd: 0,
      };

  const handleOpenModalFromInputEvent = (
    e:
      | React.FocusEvent<HTMLElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setEnableQueryProperties(true);
    setIsOpen(true);

    try {
      (e.target as HTMLElement).blur();
    } catch (error) {
      console.log("Could not blur search", error);
    }
  };

  return (
    <>
      {!isCollapsed && (
        <InputGroup style={groupStyle} borderColor="gray.800" mb={1}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="search"
            style={searchStyle}
            onClick={handleOpenModalFromInputEvent}
            onKeyPress={handleOpenModalFromInputEvent}
            value=""
            placeholder="Search Properties..."
            readOnly
          />
        </InputGroup>
      )}

      {isCollapsed && (
        <NavItem
          isCollapsed={isCollapsed}
          disabled={false}
          active={false}
          onClick={handleOpenModalFromInputEvent}
          icon={<SearchIcon />}
          label={"Search"}
        />
      )}
      <GloablPropertySearchModal
        properties={properties}
        onItemSelected={handleOpenProperty}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => {
          setIsOpen(true);
          setEnableQueryProperties(true);
        }}
      />
    </>
  );
};
