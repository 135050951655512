import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import { AreaType, useAreaPageData } from "../../lib/hooks/useAreaPageData";
import { CircuitRow } from "./circuits/CircuitsRow";
import { RoomLocationReading } from "./room-location-reading/RoomLocationReading";
import {
  getCircuitDeviceConnectionStatus,
  CircuitDeviceConnectionStatus,
} from "@tether-web-portal/types/Circuit";

const AreaPageStatusCard = ({ areaType, id }: { areaType: AreaType; id: string }) => {
  const { loading, error, area, outsideInfo, circuitInfo, roomLocations, circuitNode } = useAreaPageData(
    areaType as AreaType,
    id as string
  );

  const hotDropStatus =
    circuitInfo && circuitInfo.device
      ? getCircuitDeviceConnectionStatus(circuitInfo.device.status)
      : undefined;

  if (!area) {
    return null;
  }

  return (
    <Box>
      {!loading && !error && !!roomLocations?.length && (
        <Box p={5}>
          <Box p={5} bg="white" borderRadius="md">
            {roomLocations.map((l) => (
              <Box key={l.id}>
                <RoomLocationReading
                  showCovidRiskLevel={true}
                  roomLocation={l}
                  device={l.device}
                  locationType="ROOMLOCATION"
                  propertyId={area.propertyId}
                  showRoomName={roomLocations.length > 1 || area.name !== l.name}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {!loading && !error && outsideInfo && (
        <Box p={5}>
          <Box p={5} bg="white" borderRadius="md">
            <RoomLocationReading
              showCovidRiskLevel={true}
              roomLocation={outsideInfo}
              device={outsideInfo.device}
              locationType="OUTSIDE"
              propertyId={area.propertyId}
              showRoomName={false}
            />
          </Box>
        </Box>
      )}
      {!loading &&
        hotDropStatus &&
        [CircuitDeviceConnectionStatus.NEVER_ONLINE, CircuitDeviceConnectionStatus.OFFLINE].indexOf(
          hotDropStatus
        ) !== -1 && (
          <Alert status="error">
            <AlertIcon />
            {hotDropStatus === CircuitDeviceConnectionStatus.OFFLINE && <span>Device is offline</span>}
            {hotDropStatus === CircuitDeviceConnectionStatus.NEVER_ONLINE && (
              <span>Device offline: Waiting for the first message</span>
            )}
          </Alert>
        )}
      {!loading && !error && circuitInfo && (
        <Box p={5}>
          <CircuitRow propertyId={area.propertyId} circuit={circuitInfo} circuitNode={circuitNode} />
        </Box>
      )}
    </Box>
  );
};

export default AreaPageStatusCard;
