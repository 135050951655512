import { Box, HStack, Icon, ListItem, Stack, Text, useMenuDescendant, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { useKeyPressEvent } from "react-use";
import { Property } from "@tether-web-portal/types/Property";
import formatAddress from "@tether-web-portal/utils/formatAddress";
import { PropertyIcon } from "../create-property/property-icons/PropertyIcon";

interface PropertySearchMenuItemProps {
  property: Property;
  onClick: () => void;
  focusedIndex: number;
}

export const PropertySearchMenuItem = ({ property, onClick, focusedIndex }: PropertySearchMenuItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();

  const { register, index } = useMenuDescendant();

  useEffect(() => {
    if (ref && ref.current) {
      register(ref.current);
    }
  }, [ref, register]);

  const isFocused = focusedIndex === index;

  useKeyPressEvent("Enter", () => {
    if (isFocused) {
      onClick();
    }
  });

  const title = formatAddress(property);

  return (
    <ListItem
      ref={ref}
      px={3}
      py={3}
      bg={isFocused ? "blackAlpha.50" : "transparent"}
      _hover={{ bg: "blackAlpha.50" }}
      _dark={{
        bg: isFocused ? "whiteAlpha.50" : "transparent",
        _hover: {
          bg: "whiteAlpha.50",
        },
      }}
      cursor="pointer"
      onClick={onClick}
      rounded="md"
    >
      <HStack justifyContent="space-between">
        <HStack>
          <PropertyIcon type={property.type} />
          <Stack ml={2} dir="column" spacing={0}>
            <Text fontSize="md">{title}</Text>
            {Boolean(property.customPropertyId) && (
              <Box>
                <Text fontSize="sm">{property.customPropertyId}</Text>
              </Box>
            )}
          </Stack>
        </HStack>
        {isFocused && (
          <HStack spacing={1}>
            <Text fontSize="xs">Enter</Text>
            <Icon as={BsArrowReturnLeft} h={3} />
          </HStack>
        )}
      </HStack>
    </ListItem>
  );
};
