import { useNavigate } from "react-router-dom";

export default function useNavigateBackOrHome() {
  const navigate = useNavigate();

  return {
    navigateBackOrHome: (homeUrl?: string) => {
      navigate(homeUrl || "/");
    },
  };
}
