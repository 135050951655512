import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../api";
import {
  EditedMonitoringHours,
  MonitoringHours,
  OrganisationMonitoringHours,
} from "../../types/MonitoringHours";
import dayjs from "@tether-web-portal/dayjs-setup";
import { useAuth } from "../../auth/useAuth";

export enum MonitoringHoursQueryKeys {
  MonitoringHours = "alarmMonitoringHours",
}

export const useGetMonitoringHours = ({
  scopeId,
  scopeType,
  includeParent,
}: { scopeId?: string; scopeType?: "organisation" | "property" | "alarm"; includeParent?: boolean } = {}) => {
  const { user } = useAuth();
  const params: { [key: string]: string } = {};
  if (scopeType) {
    params["scopeType"] = scopeType;
  }
  if (includeParent !== undefined) {
    params["includeParent"] = includeParent ? "true" : "false";
  }

  const targetId = scopeId || user?.currentOrganisationId;

  if (!targetId) {
    throw new Error("No scopeId provided");
  }

  params.scopeId = targetId || "";

  const { data: monitoringHours, ...rest } = useQuery<MonitoringHours[], Error>(
    [MonitoringHoursQueryKeys.MonitoringHours, params],
    () => api.get(`v2/monitoringhours`, params),
    {
      staleTime: 300000,
      enabled: !!targetId,
    }
  );
  return {
    monitoringHours,
    ...rest,
  };
};

export const useSaveMonitoringHours = () => {
  const queryClient = useQueryClient();

  const handleSave = async ({
    propertyId,
    organisationId,
    monitoringHours,
    timezone,
  }: {
    propertyId?: string;
    organisationId: string;
    monitoringHours: EditedMonitoringHours[];
    timezone?: string;
  }) => {
    try {
      const input = monitoringHours.map((mh) => ({
        ...mh,
        organisationId,
        scopeType: propertyId ? "property" : "organisation",
        scopeId: propertyId || organisationId,
        timezone: timezone || mh.timezone || dayjs.tz.guess(),
      }));

      return api.put(`v2/monitoringhours`, input, { scopeId: propertyId || organisationId });
    } catch (e) {
      console.log("failed to save");
    }
  };

  return useMutation(handleSave, {
    onSuccess: () => {
      queryClient.invalidateQueries(MonitoringHoursQueryKeys.MonitoringHours);
    },
  });
};

export const useGetAllMonitoringHoursForOrganisation = () => {
  return useQuery<OrganisationMonitoringHours, Error>(
    [MonitoringHoursQueryKeys.MonitoringHours, "ALL"],
    () => api.get(`experience/v1/monitoringhours`),
    {
      staleTime: 300000,
    }
  );
};
