import { OccupancyUtilisationLevels } from "@tether-web-portal/web-workers/occupancy/types/OccupancyUtilisation";

export const OCCUPANCY_EMPTY = "#dadada";
export const OCCUPANCY_OVER = "#2DA5FF";
export const OCCUPANCY_OPTIMAL = "#6DC96E";
export const OCCUPANCY_LOW = "#7133C3";

export const OCCUPANCY_OPTIMAL_THRESHOLD = 0.4;

export const OCCUPANCY_SPACE_EMPTY_COLOR_RANGE = [OCCUPANCY_EMPTY];
export const OCCUPANCY_SPACE_OVER_COLOR_RANGE = [OCCUPANCY_OVER];
export const OCCUPANCY_SPACE_OPTIMAL_COLOR_RANGE = [OCCUPANCY_OPTIMAL, "#6DC9A6"];
export const OCCUPANCY_SPACE_LOW_COLOR_RANGE = [OCCUPANCY_LOW, "#5E3DC9"];

export const OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE = [OCCUPANCY_EMPTY];
export const OCCUPANCY_HEATMAP_LOW_COLOR_RANGE = ["#D4B9F8", "#b48ee7", "#9462d5", OCCUPANCY_LOW];
export const OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE = ["#b6cbb6", "#9dc99d", "#80c781", OCCUPANCY_OPTIMAL];
export const OCCUPANCY_HEATMAP_OVER_COLOR_RANGE = [OCCUPANCY_OVER];

export const getColorByPercentage = (percentage: number): string => {
  if (percentage <= 10) return OCCUPANCY_EMPTY;
  if (percentage > 100) return OCCUPANCY_OVER;
  if (percentage < (OCCUPANCY_OPTIMAL_THRESHOLD * 100)) return OCCUPANCY_LOW;
  return OCCUPANCY_OPTIMAL;
};

export const getUtilisationLevelColor = (utilisationLevel: OccupancyUtilisationLevels): string => {
  switch (utilisationLevel) {
    case OccupancyUtilisationLevels.LOW:
      return OCCUPANCY_LOW;
    case OccupancyUtilisationLevels.OPTIMAL:
      return OCCUPANCY_OPTIMAL;
    case OccupancyUtilisationLevels.OVER:
      return OCCUPANCY_OVER;
  }
  return OCCUPANCY_EMPTY;
};
