import { Box, Menu, MenuItem, MenuList, Text, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  LocationType,
  useDeleteLocationMutation,
} from "@tether-web-portal/api/properties/hooks";
import { CircuitWithReading } from "@tether-web-portal/types/Circuit";
import { DeviceWithLastSample } from "@tether-web-portal/types/DeviceWithLastSample";
import { OutsideWithReading } from "@tether-web-portal/types/OutsideWithReading";
import { useAuth } from "../../lib/auth/useAuth";
import { userRoleHasPermission } from "@tether-web-portal/services/PermissionService";
import { CircuitModal } from "../areas/circuits/CircuitModal";
import { RoomLocationModal } from "../areas/room-location-reading/RoomLocationModal";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import { DefaultModal } from "../modals/DefaultModal";
import { makeSampleBreakdown } from "../areas/room-location-reading/makeSampleBreakdown";
import { useNavigate } from "react-router-dom";
import { MenuButtonAsButton } from "@tether-web-portal/components/common";

interface ManageOutsideOrCircuitMenuProps {
  location:
    | Pick<OutsideWithReading, "id" | "name" | "device">
    | Pick<CircuitWithReading, "id" | "name" | "device">;
  locationType: LocationType.Circuit | LocationType.Outside;
  propertyId: string;
  device?: DeviceWithLastSample;
  enableViewDetails?: boolean;
  color?: string;
}

export const ManageOutsideOrCircuitMenu = ({
  location,
  locationType,
  propertyId,
  device,
  enableViewDetails,
  color,
}: ManageOutsideOrCircuitMenuProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const locationTypeName = locationType === LocationType.Circuit ? "circuit" : "area";

  const breakdown = device ? makeSampleBreakdown(device, false) : undefined;

  const deleteLocationMutation = useDeleteLocationMutation(() => {
    setShowConfirmDelete(false);
    navigate("/property/" + propertyId + "/areas");
  });

  const actuallyDeleteOutside = async () => {
    deleteLocationMutation.mutate({
      input: {
        locationId: location.id,
        propertyId,
        locationType: locationType,
      },
    });
  };

  const modifyAreaPermission = userRoleHasPermission(user!.currentOrganisationRoleId, "MODIFY_AREA");

  return (
    <>
      {Boolean(showConfirmDelete) && !location.device && (
        <ConfirmationModal
          isOpen={Boolean(showConfirmDelete)}
          onClose={() => {
            setShowConfirmDelete(false);
          }}
          onConfirm={async () => {
            actuallyDeleteOutside();
          }}
          confirmButtonColorScheme="red"
          title={`Delete ${locationTypeName[0].toUpperCase()}${locationTypeName.substring(1)}: ${
            location.name
          }`}
          confirmationText={`Are you sure you want to delete this ${locationTypeName}? Any historic readings will be lost.`}
          isLoading={deleteLocationMutation.isLoading}
        />
      )}
      {Boolean(showConfirmDelete) && Boolean(location.device) && (
        <DefaultModal
          isOpen={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          title={`Unable to Delete '${location.name}'`}
          hideCancel
        >
          <Text>
            There is a device attached to this {locationTypeName}, you must remove it before the{" "}
            {locationTypeName} can be deleted.
          </Text>
        </DefaultModal>
      )}
      {showEdit && locationType === LocationType.Outside && (
        <RoomLocationModal
          roomLocation={location as OutsideWithReading}
          onClose={() => {
            setShowEdit(false);
          }}
          isOpen={showEdit}
          locationType="OUTSIDE"
          propertyId={propertyId}
          editMode={true}
          hasDevice={Boolean(device)}
          breakdown={breakdown}
        />
      )}
      {showEdit && locationType === LocationType.Circuit && (
        <CircuitModal
          circuit={location as CircuitWithReading}
          onClose={() => {
            setShowEdit(false);
          }}
          isOpen={showEdit}
          propertyId={propertyId}
        />
      )}
      <Box alignItems="center" display="flex" position="relative">
        <Menu isOpen={isOpen} onClose={() => setTimeout(() => setIsOpen(false))}>
          <MenuButtonAsButton
            disabled={!modifyAreaPermission}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <BsThreeDots size={24} color={color || "var(--chakra-colors-blue-900)"} />
          </MenuButtonAsButton>
          {isOpen && (
            <MenuList borderWidth={1} boxShadow="lg" borderColor="gray.50">
              {enableViewDetails && (
                <MenuItem
                  onClick={(e) => {
                   navigate(`/location/${locationType.toLowerCase()}/${location.id}`);
                  }}
                >
                  <Link>View Readings</Link>
                </MenuItem>
              )}
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEdit(true);
                }}
              >
                <Link>Edit</Link>
              </MenuItem>
              <MenuItem
                color="red.500"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowConfirmDelete(true);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Box>
    </>
  );
};
