import { DeviceNetworkType } from "@tether-web-portal/types/DeviceNetworkType";
import { DeviceListObject } from "@tether-web-portal/api/properties/hooks";
import { DeviceWithLastSample } from "@tether-web-portal/types/DeviceWithLastSample";
import { CovidCo2RiskLevel, getCovidRiskLevel } from "@tether-web-portal/utils/covid-care";
import { isSampleReallyOld, isSampleRecent } from "@tether-web-portal/utils/isSampleRecent";

interface ReadingValueBreakdown {
  value: number;
  label: string;
  isHealthy: boolean;
  ignoreHealthReading?: boolean;
}

type HealthMetricReadingField = "temp" | "co2" | "humidity";

const healthFields: HealthMetricReadingField[] = ["co2", "humidity", "temp"];

export interface SampleBreakdown {
  temp?: ReadingValueBreakdown;
  co2?: ReadingValueBreakdown;
  humidity?: ReadingValueBreakdown;
  pressure?: ReadingValueBreakdown;
  light?: ReadingValueBreakdown;
  sound?: ReadingValueBreakdown;
  healthyBreakdownText?: string;
  signalStrengthPercentage?: number;
  occupied?: ReadingValueBreakdown;
  battery?: number;
  isOnline: boolean;
  isReallyOld: boolean;
  covidCare?: {
    riskLevel: CovidCo2RiskLevel;
    riskLevelLabel: string;
    riskLevelColor: string;
  };
  pm1?: ReadingValueBreakdown;
  pm2_5?: ReadingValueBreakdown;
  pm10?: ReadingValueBreakdown;
  tvoc?: ReadingValueBreakdown;
  maxTVOC?: ReadingValueBreakdown;
  minTVOC?: ReadingValueBreakdown;
}

function getCovidRiskLevelColor(riskLevel: CovidCo2RiskLevel): string {
  switch (riskLevel) {
    case CovidCo2RiskLevel.LOW:
      return "green.500";
    case CovidCo2RiskLevel.MODERATE:
      return "orange.500";
    case CovidCo2RiskLevel.HIGH:
    default:
      return "red.500";
  }
}

const isTempUnhealthy = (temp: number) => temp < 18 || temp > 24;
const isHumidityUnhealthy = (humidity: number) => humidity! >= 60;

export const makeSampleBreakdown = (
  device: DeviceWithLastSample | DeviceListObject | undefined,
  showCovidRiskLevel: boolean
): SampleBreakdown | undefined => {
  const latestSample = device?.lastSample;

  if (!device || !latestSample) {
    return undefined;
  }

  const isOnline = isSampleRecent(latestSample, device.type);
  const isReallyOld = isSampleReallyOld(latestSample);
  const breakdown: SampleBreakdown = { isOnline, isReallyOld };

  if (latestSample?.sample?.temperature !== undefined) {
    breakdown.temp = {
      value: latestSample?.sample?.temperature,
      label: `${latestSample?.sample?.temperature.toFixed(2)}°C`,
      isHealthy: !isTempUnhealthy(latestSample?.sample?.temperature),
    };
  }

  if (latestSample?.sample?.humidity !== undefined) {
    breakdown.humidity = {
      value: latestSample.sample.humidity,
      label: `${latestSample.sample.humidity}%`,
      isHealthy: !isHumidityUnhealthy(latestSample.sample.humidity),
    };
  }

  if (latestSample?.sample?.pressure !== undefined) {
    breakdown.pressure = {
      value: latestSample.sample.pressure,
      label: `${latestSample.sample.pressure}Pa`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }

  if (latestSample?.sample?.light !== undefined) {
    breakdown.light = {
      value: latestSample.sample.light,
      label: `${latestSample.sample.light}Lux`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }

  if (latestSample?.sample?.sound !== undefined) {
    breakdown.sound = {
      value: latestSample.sample.sound,
      label: `${latestSample.sample.sound}dB`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }
  if (latestSample?.sample?.pm1 !== undefined) {
    breakdown.pm1 = {
      value: latestSample.sample.pm1,
      label: `${latestSample.sample.pm1}µg/m3`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }
  if (latestSample?.sample?.pm2_5 !== undefined) {
    breakdown.pm2_5 = {
      value: latestSample.sample.pm2_5,
      label: `${latestSample.sample.pm2_5}µg/m3`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }

  if (latestSample?.sample?.pm10 !== undefined) {
    breakdown.pm10 = {
      value: latestSample.sample.pm10,
      label: `${latestSample.sample.pm10}µg/m3`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }
  if (latestSample?.sample?.tvoc) {
    breakdown.tvoc = {
      value: latestSample.sample.tvoc,
      label: `${latestSample.sample.tvoc}ppb`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }
  if (latestSample?.sample?.minTVOC) {
    breakdown.minTVOC = {
      value: latestSample.sample.minTVOC,
      label: `${latestSample.sample.minTVOC}ppb`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }
  if (latestSample?.sample?.maxTVOC) {
    breakdown.maxTVOC = {
      value: latestSample.sample.maxTVOC,
      label: `${latestSample.sample.maxTVOC}ppb`,
      isHealthy: true,
      ignoreHealthReading: true,
    };
  }

  // occupancy (tmp for now)
  if (latestSample?.sample?.occupied !== undefined) {
    const occupied = latestSample.sample.occupied;
    breakdown.occupied = {
      value: occupied ? 1 : 0,
      label: occupied ? "Occupied" : "Unoccupied",
      isHealthy: occupied ? false : true,
      ignoreHealthReading: true,
    };
  }

  if (latestSample?.sample?.co2 !== undefined) {
    const co2Value = latestSample.sample.co2;
    const riskLevel = getCovidRiskLevel(co2Value);

    breakdown.co2 = {
      value: co2Value,
      label: `${latestSample.sample.co2}ppm`,
      isHealthy: riskLevel !== CovidCo2RiskLevel.HIGH,
    };

    if (showCovidRiskLevel) {
      breakdown.covidCare = {
        riskLevel,
        riskLevelColor: getCovidRiskLevelColor(riskLevel),
        riskLevelLabel: `${riskLevel.toString()[0]}${riskLevel.substr(1).toLowerCase()} Risk`,
      };
    }
  }
  breakdown.battery = latestSample?.sample?.battery ? latestSample.sample.battery / 100 : undefined;

  breakdown.signalStrengthPercentage =
    device.network === DeviceNetworkType.MQTT ? 1 : device.signalStrengthPercentage?.percentage;

  const fieldsWithValue = healthFields.filter((f) => breakdown[f] && !breakdown[f]!.ignoreHealthReading);
  const fieldsWithHealthyValue = fieldsWithValue.filter((f) => breakdown[f]?.isHealthy);

  const healthyBreakdownText = `${fieldsWithHealthyValue.length}/${fieldsWithValue.length} health metrics are in healthy range`;
  breakdown.healthyBreakdownText = fieldsWithValue.length === 0 ? "" : healthyBreakdownText;

  return breakdown;
};
