import dayjs from "@tether-web-portal/dayjs-setup";

import { MonitoringHours } from "../types/MonitoringHours";
import { Reading } from "../types/Reading";
import { isWithinMonitoringHours } from "./getReadingsWithinMonitoringHours";

export enum CovidCo2RiskLevel {
  LOW = "Low",
  MODERATE = "Mid",
  HIGH = "High",
  OFFLINE = "Offline",
}

export const covidRiskLevelImage: Record<CovidCo2RiskLevel, string> = {
  [CovidCo2RiskLevel.LOW]: "/assets/low_simple.png",
  [CovidCo2RiskLevel.MODERATE]: "/assets/moderate_simple.png",
  [CovidCo2RiskLevel.HIGH]: "/assets/high_simple.png",
  [CovidCo2RiskLevel.OFFLINE]: "/assets/offline_t.png",
};

export enum CovidCo2RiskThreshold {
  LOW = 0,
  MODERATE = 1000,
  HIGH = 1250,
}

export function getCovidRiskLevel(co2: number): CovidCo2RiskLevel {
  if (co2 < CovidCo2RiskThreshold.MODERATE) {
    return CovidCo2RiskLevel.LOW;
  }

  if (co2 < CovidCo2RiskThreshold.HIGH) {
    return CovidCo2RiskLevel.MODERATE;
  }

  return CovidCo2RiskLevel.HIGH;
}

export function getCovidRiskLevelColor(riskLevel: CovidCo2RiskLevel): string {
  switch (riskLevel) {
    case CovidCo2RiskLevel.LOW:
      return "green";
    case CovidCo2RiskLevel.MODERATE:
      return "orange";
    case CovidCo2RiskLevel.HIGH:
    default:
      return "red";
  }
}

/**
 * Calculation for rebreathed air: https://docs.google.com/spreadsheets/d/1AjFzhqM_NILYvZjgE8n0CvGZzYh04JpF_DO0phrOcFw/edit#gid=0
 * @param reading
 * @returns
 */
export const calculateBreathPercentage = (reading: Reading | undefined) => {
  if (!reading) {
    return null;
  }
  const co2Reading = reading[1];
  const outdoorCO2Base = Math.min(co2Reading, 420);
  const constantCo2VolumeFraction = 38000;
  const percent = (co2Reading - outdoorCO2Base) / constantCo2VolumeFraction;
  if (percent === 0) {
    return 0;
  }
  return Math.round(1 / percent);
};

export const calculateRiskPercentage = (readings: Reading[], riskLevel: CovidCo2RiskLevel) => {
  const atRisk = readings.filter((reading) => getCovidRiskLevel(reading[1]) === riskLevel);
  return ((100 * atRisk.length) / readings.length).toFixed(0);
};

export const calculateTimeAtRisk = (
  readings: Reading[],
  monitoringHours: MonitoringHours[],
  riskLevel: CovidCo2RiskLevel
): { value: number; unit: string } => {
  const sorted = readings.sort((a, b) => a[0] - b[0]);
  const totalTime = sorted.reduce((acc, curr, index) => {
    if (index > 0) {
      const currentLevel = getCovidRiskLevel(curr[1]);
      const prevLevel = getCovidRiskLevel(readings[index - 1][1]);
      const timeBetween = curr[0] - readings[index - 1][0];

      const riskLevelCount = +(currentLevel === riskLevel) + +(prevLevel === riskLevel);
      const monitoringHoursCount =
        +isWithinMonitoringHours(dayjs.unix(curr[0]), monitoringHours) +
        +isWithinMonitoringHours(dayjs.unix(readings[index - 1][0]), monitoringHours);
      const coeff = (riskLevelCount / 2.0) * (monitoringHoursCount / 2.0);

      return acc + timeBetween * coeff;
    }
    return acc;
  }, 0);

  if (Math.floor(totalTime / (5 * 86400))) {
    // if more than 2 days show days
    const days = Math.round(totalTime / 86400);

    return {
      value: days,
      unit: days === 1 ? "day" : "days",
    };
  }

  if (Math.floor(totalTime / (2 * 3600))) {
    // if more than 2 hours show hours
    const hours = Math.round(totalTime / 3600);
    return {
      value: hours,
      unit: hours === 1 ? "hour" : "hours",
    };
  }

  const minutes = Math.round(totalTime / 60); // otherwise show minutes
  return {
    value: minutes,
    unit: minutes === 1 ? "min" : "mins",
  };
};

export const COVID_SAMPLE_REFRESH_INTERVAL = 60000;
