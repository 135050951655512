import { Box, VStack, Spacer, Skeleton } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { FootTrafficIcon } from "@tether-web-portal/components/icons/FootTrafficIcon";
import { BoxProps } from "@chakra-ui/layout";
import { Typography } from "@tether-web-portal/components/common";

const ENTRY = "entry";
const EXIT = "exit";
const TRAFFIC = "traffic";

interface OccupancyReadingTileProps extends BoxProps {
  type: "entry" | "exit" | "traffic";
  data?: number;
  loading: boolean;
}

const OccupancyReadingTile = ({ type, data: value = 0, loading, ...rest }: OccupancyReadingTileProps) => {
  const data = useMemo(() => {
    if (!type) {
      return {
        title: "",
      };
    }

    switch (type) {
      case ENTRY: {
        return {
          title: "People In",
          icon: <ArrowUpIcon width={30} height={30} />,
        };
      }
      case EXIT: {
        return {
          title: "People Out",
          icon: <ArrowDownIcon width={30} height={30} />,
        };
      }
      case TRAFFIC: {
        return {
          title: "Foot-Traffic",
          message: "Total people movement",
          icon: <FootTrafficIcon size={35} />,
        };
      }
    }
  }, [type]);

  if (!type) {
    return null;
  }

  return (
    <Box
      bg="white"
      borderRadius="8px"
      padding={loading ? 0 : 4}
      height={220}
      shadow="lg"
      borderWidth="1"
      borderColor="white"
      minWidth={200}
      {...rest}
    >
      {loading ? (
        <Skeleton w={"100%"} height={"100%"} borderRadius={8} />
      ) : (
        <VStack alignItems="flex-start" height="100%" spacing={4}>
          <Typography size="lg" fontWeight="bold" mb={5}>
            {data.title}
          </Typography>

          <Typography size={"3xl"} fontWeight="semibold" className={"pulse-green"}>
            {value < 0 ? 0 : value}
          </Typography>

          {data.message && (
            <Typography size={"md"} color={"#595F6A"}>
              {data.message}
            </Typography>
          )}
          <Spacer />

          {data?.icon}
        </VStack>
      )}
    </Box>
  );
};

export default OccupancyReadingTile;
