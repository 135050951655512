import { FunctionComponent } from "react";
import styles from "./styling/OccupancyUsageFrequencyIndicator.module.scss";

import { Box, HStack } from "@chakra-ui/react";

import { Typography } from "@tether-web-portal/components/common";
import {
  IOccupancyUtilisationUsage,
  OccupancyUtilisationLevels,
} from "@tether-web-portal/web-workers/occupancy";

import { getUtilisationLevelColor } from "../occupancy.colors";
import { OccupancyUtilisationIndicator } from "./OccupancyUtilisationIndicator";

interface IOccupancyUsageFrequencyIndicatorProps {
  usage: IOccupancyUtilisationUsage;
  isLiveView?: boolean;
}
export const OccupancyUsageFrequencyIndicator: FunctionComponent<IOccupancyUsageFrequencyIndicatorProps> = (
  props: IOccupancyUsageFrequencyIndicatorProps
) => {
  const { usage, isLiveView } = props;

  return isLiveView ? (
    <HStack alignItems="center" className={styles.occupancyUsageFrequencyIndicator}>
      {usage.isOccupied ? (
        <Box
          className={styles.statusDot}
          sx={{
            backgroundColor: getUtilisationLevelColor(OccupancyUtilisationLevels.OPTIMAL),
          }}
        ></Box>
      ) : null}

      <Typography
        color={usage.isOccupied ? getUtilisationLevelColor(OccupancyUtilisationLevels.OPTIMAL) : "gray.600"}
        opacity={usage.isOccupied ? 1 : 0.6}
        fontWeight="semibold"
      >
        {usage.isOccupied ? "Occupied" : "Unoccupied"}
      </Typography>
    </HStack>
  ) : (
    <Box className={styles.occupancyUsageFrequencyIndicator}>
      <OccupancyUtilisationIndicator
        percentage={usage.percentage}
        utilisationLevel={usage.utilisationLevel}
        displayType="PERCENTAGE"
      />
    </Box>
  );
};
