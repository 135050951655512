import { HorizontalBarChartYScale } from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { AxisConfig } from "@tether-web-portal/components/charts/types";
import { AxisLeft, TickFormatter } from "@visx/axis";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { NumberValue } from "d3";

export const YAxis = ({
  axisWidthConfig,
  label,
  yMax,
  yScale,
  yAxisFormatter,
}: {
  axisWidthConfig: AxisConfig;
  label: string;
  yMax: number;
  yScale: HorizontalBarChartYScale;
  yAxisFormatter?: TickFormatter<NumberValue>;
}) => {
  const labelStyles = {
    lineHeight: axisWidthConfig.lineHeight,
    fontSize: "var(--chakra-fontSizes-xs)",
    fontWeight: 500,
    fontFamily: "var(--chakra-fonts-body)",
  };
  return (
    <>
      <Group left={axisWidthConfig.title / 2} top={yMax / 2}>
        <Group transform="rotate(-90)">
          <Text width={yMax} textAnchor={"middle"} verticalAnchor={"middle"} {...labelStyles}>
            {label}
          </Text>
        </Group>
      </Group>

      <Group left={axisWidthConfig.total - axisWidthConfig.innerMargin}>
        <AxisLeft
          scale={yScale}
          hideTicks
          hideAxisLine
          labelOffset={0}
          tickLength={0}
          tickLabelProps={labelStyles}
          tickFormat={yAxisFormatter}
        />
      </Group>
    </>
  );
};
