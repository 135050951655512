import { OrgSettingsTypes } from "@tether-web-portal/api/auth/OrgSettingService";
import { useGetInstallationDetail } from "@tether-web-portal/api/properties/hooks";
import { InstallationDetailType } from "@tether-web-portal/types/InstallationDetail";
import { useMemo } from "react";
import { useAuth } from "../auth/useAuth";

type PropertyDetailWithOrgFallbackType = InstallationDetailType.CO2_PER_KWH | InstallationDetailType.COST_PER_KWH;

const ORG_EQUIVALENT_SETTING: Record<PropertyDetailWithOrgFallbackType, OrgSettingsTypes> = {
  [InstallationDetailType.CO2_PER_KWH]: OrgSettingsTypes.CO2_PER_KWH,
  [InstallationDetailType.COST_PER_KWH]: OrgSettingsTypes.COST_PER_KWH,
};

export function usePropertyDetailWithOrgFallback<T = string | number>(
  propertyId: string,
  detailToGet: PropertyDetailWithOrgFallbackType
): T {
  const { orgSettings } = useAuth();

  const { data: propertyLevelDetail } = useGetInstallationDetail(propertyId, detailToGet);

  return useMemo<T>(() => {
    if (propertyLevelDetail) {
      return propertyLevelDetail.value as T;
    }

    const orgSetting = ORG_EQUIVALENT_SETTING[detailToGet];
    return orgSettings[orgSetting] as T;
  }, [propertyLevelDetail, orgSettings, detailToGet]);
}
