import { VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import OccupancyBarChart, { OccupancyBarData } from "./charts/OccupancyBarChart";
import { useResizeObserver } from "@tether-web-portal/hooks";
import OccupancyBaseTile, {
  OccupancyBaseTileChildProps,
  Unavailable,
} from "@tether-web-portal/components/occupancy/tiles/OccupancyBaseTile";
import { Typography } from "@tether-web-portal/components/common";
import { OccupancyAggregate } from "@tether-web-portal/web-workers/occupancy";

export const PEAK_HOURS_OF_DAY = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const convertTo12HourFormat = (hour: number): string => {
  const isAM = hour < 12;
  const hourIn12 = hour % 12 || 12; // Convert 0 to 12 for '12am'
  return `${hourIn12}${isAM ? "am" : "pm"}`;
};

const sanitize = (data: OccupancyAggregate[] = []): OccupancyBarData[] => {
  return PEAK_HOURS_OF_DAY.map((hour: string) => {
    const peakHour = data.find((peakHour) => peakHour.id === hour);
    const value = peakHour?.usage ?? 0;
    const id = parseInt(hour, 10);
    const label = convertTo12HourFormat(id);
    return {
      id,
      label,
      value,
      tooltip: `${convertTo12HourFormat(id - 1)} - ${label}, Avg occupancy: ${value.toFixed(0)}%`,
    };
  });
};

const getPeakLabel = (data: OccupancyBarData[]): string => {
  let maxSum = 0;
  let peakHourLabel = "";

  for (let i = 0; i < data.length; i++) {
    // 1-hour range (single hour)
    const oneHourSum = data[i].value;
    if (oneHourSum > maxSum) {
      maxSum = oneHourSum;
      peakHourLabel = `${data[i].label}`;
    }

    // 2-hour range (i, i+1)
    if (i + 1 < data.length) {
      const twoHourSum = oneHourSum + data[i + 1].value;
      if (twoHourSum > maxSum) {
        maxSum = twoHourSum;
        peakHourLabel = `${data[i].label} - ${data[i + 1].label}`;
      }
    }

    // 3-hour range (i, i+1, i+2)
    if (i + 2 < data.length) {
      const threeHourSum = oneHourSum + data[i + 1].value + data[i + 2].value;
      if (threeHourSum > maxSum) {
        maxSum = threeHourSum;
        peakHourLabel = `${data[i].label} - ${data[i + 2].label}`;
      }
    }
  }

  return peakHourLabel;
};

const getBusiestHour = (data: OccupancyBarData[]): OccupancyBarData =>
  data.reduce((max, current) => (current.value > max.value ? current : max));

interface OccupancyPeakHoursTileProps extends OccupancyBaseTileChildProps {
  data?: OccupancyAggregate[];
}

const OccupancyPeakHoursTile = ({ data, loading, ...rest }: OccupancyPeakHoursTileProps) => {
  const { width, setRef } = useResizeObserver();

  const { peakLabel, busiestLabel, chartItems, selected, unavailable } = useMemo(() => {
    if (loading) {
      return {
        peakLabel: "",
        busiestLabel: "",
        chartItems: [],
      };
    }

    const sanitizedData = sanitize(data);

    const peakLabel = getPeakLabel(sanitizedData);
    let busiestLabel;
    let selected = undefined;

    if (peakLabel) {
      const busiestDay = getBusiestHour(sanitizedData);
      busiestLabel = `Busiest Hour: ${busiestDay.tooltip}`;
      selected = sanitizedData.findIndex((item) => item.label === busiestDay.label);
    }

    return {
      unavailable: {
        icon: "peakHours",
        enabled: !loading && !peakLabel,
        title: "No hourly trend data available",
        subtitle: "Hourly occupancy trends will be displayed here once data is collected.",
      } as Unavailable,
      peakLabel,
      busiestLabel,
      chartItems: sanitizedData,
      selected,
    };
  }, [data, loading]);

  return (
    <OccupancyBaseTile ref={setRef} title="Peak Hours" unavailable={unavailable} loading={loading} {...rest}>
      <VStack alignItems="flex-start" spacing={0}>
        <Typography size={"3xl"} fontWeight="semibold">
          {peakLabel}
        </Typography>
        <Typography size={"sm"} color={"#595F6A"}>
          {busiestLabel}
        </Typography>
      </VStack>
      <OccupancyBarChart
        width={width}
        height={90}
        data={chartItems}
        selectedBar={selected}
        enableXLabels={false}
      />
    </OccupancyBaseTile>
  );
};

export default OccupancyPeakHoursTile;
