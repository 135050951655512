import { RoomType } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";
import { ComfortScore2, ComfortScoreColor2 } from "@tether-web-portal/components/overview-tiles/types/types";
import { RoomLocationWithoutReading, RoomLocationWithReading } from "./RoomLocation";
import { startCase } from "lodash";

export interface Room {
  id: string;
  propertyId: string;
  type: RoomType;
  name: string;
  color: GraphColor;
  floorIndex: number;
  isMainLivingArea: boolean;
  capacity?: number;
  tagIds: string[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  customId?: string | null;
}

export interface RoomWithLocationReadings extends Room {
  roomLocations: RoomLocationWithReading[];
  image?: string;
}

export interface RoomWithoutLocationReadings extends Room {
  roomLocations: RoomLocationWithoutReading[];
  image?: string;
}

export enum GraphColor {
  Info = "info",
  Danger = "danger",
  Success = "success",
  Primary = "primary",
  Hint = "hint",
  BrandBlue = "brandBlue",
  BrandGreen = "brandGreen",
  Heading = "heading",
}

export enum ComfortScore {
  VeryHot = "Very Hot",
  Hot = "Hot",
  Warm = "Warm",
  PerfectComfort = "Perfect Comfort",
  Cool = "Cool",
  Cold = "Cold",
  VeryCold = "Very Cold",
  MuggyAndVeryHot = "Muggy and Very Hot",
  MuggyAndHot = "Muggy and Hot",
  MuggyAndWarm = "Muggy and Warm",
  Muggy = "Muggy",
  Humid = "Humid",

  /** Temporary, can be removed once the backend is changed from Muggy to Humid for cold conditions */
  MuggyAndCool = "Muggy and Cool",
  MuggyAndCold = "Muggy and Cold",
  MuggyAndVeryCold = "Muggy and Very Cold",

  HumidAndCool = "Humid and Cool",
  HumidAndCold = "Humid and Cold",
  HumidAndVeryCold = "Humid and Very Cold",
  VeryMuggyAndVeryHot = "Very Muggy and Very Hot",
  VeryMuggyAndHot = "Very Muggy and Hot",
  VeryMuggy = "Very Muggy",
  DryAndVeryHot = "Dry and Very Hot",
  DryAndHot = "Dry and Hot",
  DryAndWarm = "Dry and Warm",
  Dry = "Dry",
  DryAndCool = "Dry and Cool",
  DryAndCold = "Dry and Cold",
  DryAndVeryCold = "Dry and Very Cold",
  VeryDryAndVeryHot = "Very Dry and Very Hot",
  VeryDryAndHot = "Very Dry and Hot",
  VeryDryAndWarm = "Very Dry and Warm",
  TooDry = "Too Dry",
  VeryDryAndCool = "Very Dry and Cool",
  VeryDryAndCold = "Very Dry and Cold",
  VeryDryAndVeryCold = "Very Dry and Very Cold",
}

export enum ComfortScoreColor {
  "Very Hot" = "#F84061",
  "Hot" = "#FB0C49",
  "Warm" = "#EC622D",
  "Perfect Comfort" = "#6DC96E",
  "Cool" = "#01ACE2",
  "Cold" = "#04538C",
  "Very Cold" = "#0069E2",
  "Muggy and Very Hot" = "#F84061",
  "Muggy and Hot" = "#F84098",
  "Muggy and Warm" = "#C55226",
  "Muggy" = "#7133C3",
  "Humid" = "#7133C3",

  /** Temporary, can be removed once the backend is changed from Muggy to Humid for cold conditions */
  "Muggy and Cool" = "#018FBC",
  "Muggy and Cold" = "#1A6FD4",
  "Muggy and Very Cold" = "#0058BC",

  "Humid and Cool" = "#018FBC",
  "Humid and Cold" = "#1A6FD4",
  "Humid and Very Cold" = "#0058BC",
  "Dry and Very Hot" = "#FB9FB0",
  "Dry and Hot" = "#EC622D",
  "Dry and Warm" = "#F9CBB9",
  "Dry" = "#F2A63D",
  "Dry and Cool" = "#AAE3F5",
  "Dry and Cold" = "#11A9C8",
  "Dry and Very Cold" = "#AACDF5",
  //Unused
  "Very Muggy and Very Hot" = "green",
  "Very Muggy and Hot" = "green",
  "Very Muggy" = "green",
  "Too Muggy" = "green",
  "Too Dry" = "green",
  "Very Dry and Very Hot" = "green",
  "Very Dry and Hot" = "green",
  "Very Dry and Warm" = "green",
  "Very Dry and Cool" = "green",
  "Very Dry and Cold" = "green",
  "Very Dry and Very Cold" = "green",
}

export enum ComfortScoreColorBg {
  "Very Hot" = "#F8406120",
  "Hot" = "#FB0C4920",
  "Warm" = "#EC622D20",
  "Perfect Comfort" = "#85AB1920",
  "Cool" = "#01ACE220",
  "Cold" = "#04538C20",
  "Very Cold" = "#0069E220",
  "Muggy and Very Hot" = "#F8406120",
  "Muggy and Hot" = "#F8409820",
  "Muggy and Warm" = "#C5522620",
  "Muggy" = "#7133C320",
  "Humid" = "#7133C320",

  /** Temporary, can be removed once the backend is changed from Muggy to Humid for cold conditions */
  "Humid and Cool" = "#018FBC20",
  "Humid and Cold" = "#1A6FD420",
  "Humid and Very Cold" = "#0058BC20",

  "Muggy and Cool" = "#018FBC20",
  "Muggy and Cold" = "#1A6FD420",
  "Muggy and Very Cold" = "#0058BC20",
  "Dry and Very Hot" = "#FB9FB020",
  "Dry and Hot" = "#EC622D20",
  "Dry and Warm" = "#F9CBB920",
  "Dry" = "#F2A63D20",
  "Dry and Cool" = "#AAE3F520",
  "Dry and Cold" = "#11A9C820",
  "Dry and Very Cold" = "#AACDF520",
  //Unused
  "Very Muggy and Very Hot" = "green.100",
  "Very Muggy and Hot" = "green.100",
  "Very Muggy" = "green.100",
  "Too Muggy" = "green.100",
  "Too Dry" = "green.100",
  "Very Dry and Very Hot" = "green.100",
  "Very Dry and Hot" = "green.100",
  "Very Dry and Warm" = "green.100",
  "Very Dry and Cool" = "green.100",
  "Very Dry and Cold" = "green.100",
  "Very Dry and Very Cold" = "green.100",
}

export enum ComfortScoreEmojis {
  "Very Hot" = "🥵",
  "Hot" = "🔥",
  "Warm" = "🔥",
  "Perfect Comfort" = "👌",
  "Cool" = "❄",
  "Cold" = "❄",
  "Very Cold" = "🥶",
  "Muggy and Very Hot" = "🥵 💧",
  "Muggy and Hot" = "🔥 💧",
  "Muggy and Warm" = "🔥 💧",
  "Muggy" = "💧",
  "Humid" = "💧",

  /** Temporary, can be removed once the backend is changed from Muggy to Humid for cold conditions */
  "Muggy and Cool" = "💧 ❄",
  "Muggy and Cold" = "💧 ❄",
  "Muggy and Very Cold" = "💧 🥶",

  "Humid and Cool" = "💧 ❄",
  "Humid and Cold" = "💧 ❄",
  "Humid and Very Cold" = "💧 🥶",
  "Dry and Very Hot" = "🥵 💨",
  "Dry and Hot" = "🔥 💨",
  "Dry and Warm" = "🔥 💨",
  "Dry" = "💨",
  "Dry and Cool" = "❄ 💨",
  "Dry and Cold" = "❄ 💨",
  "Dry and Very Cold" = "🥶 💨",
  //Unused
  "Very Muggy and Very Hot" = "",
  "Very Muggy and Hot" = "",
  "Very Muggy" = "",
  "Too Muggy" = "",
  "Too Dry" = "",
  "Very Dry and Very Hot" = "",
  "Very Dry and Hot" = "",
  "Very Dry and Warm" = "",
  "Very Dry and Cool" = "",
  "Very Dry and Cold" = "",
  "Very Dry and Very Cold" = "",
}

const _comfortScoreDisplayTextOverrides: Partial<Record<ComfortScore, string>> = {
  [ComfortScore.DryAndHot]: "Hot & Dry",
  [ComfortScore.MuggyAndHot]: "Hot & Muggy",
  [ComfortScore.MuggyAndCold]: "Cold & Humid",
  [ComfortScore.HumidAndCold]: "Cold & Humid",
  [ComfortScore.DryAndCold]: "Cold & Dry",
  [ComfortScore.PerfectComfort]: "Perfect",
};

const _comfortScoreRangeDisplayTextOverrides: Partial<Record<ComfortScore, string>> = {
  [ComfortScore.DryAndHot]: "Temp above 24°C & Dew point below 10°C",
  [ComfortScore.MuggyAndHot]: "Temp above 24°C & Dew point above 15°C",
  [ComfortScore.MuggyAndCold]: "Temp below 20°C & Dew point above 15°C",
  [ComfortScore.HumidAndCold]: "Temp below 20°C & Dew point above 15°C",
  [ComfortScore.DryAndCold]: "Temp below 20°C & Dew point below 10°C",
  [ComfortScore.PerfectComfort]: "Temp 20°C & Dew Point between 10°C-15°C",
  [ComfortScore.Cold]: "Temp below 20°C & Dew Point between 10°C-15°C",
  [ComfortScore.Muggy]: "Temp 20°C & Dew Point between 10°C-15°C",
  [ComfortScore.Dry]: "Temp 20°C & Dew Point below 10°C",
  [ComfortScore.Hot]: "Temp above 24°C & Dew Point between 10°C-15°C",
};

export const getComfortScoreDisplayText = (comfortScore: ComfortScore): string => {
  return _comfortScoreDisplayTextOverrides[comfortScore] || comfortScore;
};

export const getComfortRangeDisplayText = (comfortScore: ComfortScore): string => {
  return _comfortScoreRangeDisplayTextOverrides[comfortScore] || comfortScore;
};

export const getComfortScoreColor = (comfortScore: ComfortScore) => {
  return ComfortScoreColor[comfortScore];
};

export const getComfortScoreColor2 = (comfortScore: string) => {
  // Insights comes in camel case, convert it to start case
  const key = startCase(comfortScore) as ComfortScore2;

  return ComfortScoreColor2[key];
};

export const getComforScoreBGColor = (comfortScore: ComfortScore) => {
  return ComfortScoreColorBg[comfortScore];
};

type GraphColorDetail = {
  title: string;
  color: string;
};

export const GraphColorDetails: Record<GraphColor, GraphColorDetail> = {
  [GraphColor.Primary]: {
    title: "aqua",
    color: "#85AB19",
  },
  [GraphColor.Success]: {
    title: "pink",
    color: "#EA6BA6",
  },
  [GraphColor.Danger]: {
    title: "orange",
    color: "#EC622D",
  },
  [GraphColor.Info]: {
    title: "yellow",
    color: "#FFCE1F",
  },
  [GraphColor.Heading]: {
    title: "black",
    color: "#2B3459",
  },

  [GraphColor.Hint]: {
    title: "purple",
    color: "#7133C3",
  },
  [GraphColor.BrandBlue]: {
    title: "lightBlue",
    color: "#11A9C8",
  },
  [GraphColor.BrandGreen]: {
    title: "lightGreen",
    color: "#3BD392",
  },
};

export const getColor = (value: GraphColor) => {
  return GraphColorDetails[value];
};

export const Colors = Object.keys(GraphColorDetails).map((graphColor: string) => {
  return {
    value: graphColor as GraphColor,
    title: GraphColorDetails[graphColor as GraphColor].title,
    color: GraphColorDetails[graphColor as GraphColor].color,
  };
});

export const getRandomColor = () => {
  const allColors = Colors.map((c) => c.value);
  return allColors[Math.floor(Math.random() * allColors.length)];
};
