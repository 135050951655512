import { FunctionComponent } from "react";
import styles from "./styling/EmptyTableDataBlock.module.scss";

import { Td } from "@chakra-ui/react";

interface IEmptyTableDataBlockProps {
  emptyBlocksCount?: number;
}
export const EmptyTableDataBlock: FunctionComponent<IEmptyTableDataBlockProps> = (
  props: IEmptyTableDataBlockProps
) => {
  const { emptyBlocksCount } = props;

  return (
    <>
      {Array.from({ length: emptyBlocksCount ?? 1 }, (_, index) => (
        <Td key={index} className={styles.emptyTableDataBlock}>
          -
        </Td>
      ))}
    </>
  );
};
