import { FunctionComponent, useMemo } from "react";
import { HStack, Progress } from "@chakra-ui/react";
import numeral from "numeral";

import { Typography } from "@tether-web-portal/components/common";

import { getUtilisationLevelColor } from "../occupancy.colors";
import { OccupancyUtilisationLevels } from "@tether-web-portal/web-workers/occupancy";

interface IOccupancyUtilisationIndicatorProps {
  percentage: number;
  displayType: "PERCENTAGE" | "COUNTS";
  latestCount?: number;
  maxSpace?: number;
  utilisationLevel: OccupancyUtilisationLevels;
}
export const OccupancyUtilisationIndicator: FunctionComponent<IOccupancyUtilisationIndicatorProps> = (
  props: IOccupancyUtilisationIndicatorProps
) => {
  const { percentage, utilisationLevel, displayType, latestCount, maxSpace } = props;

  const colorToUse = useMemo(() => getUtilisationLevelColor(utilisationLevel), [utilisationLevel]);

  const formattedText = useMemo(() => {
    if (displayType === "PERCENTAGE") {
      return numeral(percentage).format("0%");
    } else if (displayType === "COUNTS" && maxSpace) {
      return `${latestCount ?? 0}/${maxSpace}`;
    }

    return "";
  }, [displayType, percentage, latestCount, maxSpace]);

  return (
    <HStack columnGap={1} alignItems="center" flex={1}>
      <Typography fontWeight="semibold" color={colorToUse}>
        {formattedText}
      </Typography>

      <Progress
        size="sm"
        value={percentage * 100}
        borderRadius="lg"
        flex={1}
        sx={{
          '& div[role="progressbar"]': {
            backgroundColor: colorToUse,
          },
        }}
      />
    </HStack>
  );
};
