import dayjs from "@tether-web-portal/dayjs-setup";

import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { SeriesTypeReading } from "@tether-web-portal/components/property-graph/GraphUtils";
import { getQueryParamsFromObject } from "../../utils/getQueryParamsFromObject";
import { api } from "../api";
import { RoomLocation, RoomLocationAggregate } from "../../types/RoomLocation";
import { TimestampRange } from "../../types/TimestampRange";
import { useAggregatedQueries } from "lib/hooks/useAggregatedQueries";
import { RoomAggregatedData, RoomLocationAggregatedData } from "@tether-web-portal/web-workers/occupancy";

enum QueryKeys {
  ROOM_LOCATIONS = "room-locations",
  ROOM_LOCATION_AGGREGATES = "room-location-aggregates",
  ROOM_AGGREGATES = "room-aggregates",
}

export type RoomLocationAggregateResolution =
  | "minute"
  | "fifteenminute"
  | "thirtyminute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "total";

export const useGetRoomLocationAggregatedData = (
  roomLocationId: string | undefined,
  dateRange: TimestampRange,
  resolution: RoomLocationAggregateResolution
) => {
  const { data: roomLocationAggregatedData, ...rest } = useQuery<RoomLocationAggregate[]>(
    [QueryKeys.ROOM_LOCATION_AGGREGATES, roomLocationId, JSON.stringify(dateRange), resolution],
    () =>
      api.get(`property/v2/roomlocation/${roomLocationId}/aggregates`, {
        fromTimestamp: dateRange.fromTimestamp,
        toTimestamp: dateRange.toTimestamp,
        resolution: resolution,
      }),
    {
      enabled: Boolean(roomLocationId) && Boolean(dateRange) && Boolean(resolution),
      staleTime: Infinity,
    }
  );
  return { roomLocationAggregatedData, ...rest };
};

const getRoomAggregateResolutionFromDateRange = (
  dateRange: TimestampRange
): RoomLocationAggregateResolution => {
  const dateRangeDiff = dayjs(dateRange.toTimestamp).diff(dateRange.fromTimestamp, "day");
  if (dateRangeDiff <= 1) {
    return "fifteenminute";
  } else if (dateRangeDiff <= 7) {
    return "hour";
  } else if (dateRangeDiff < 365) {
    return "day";
  } else {
    return "month";
  }
};

export const useGetMultipleRoomLocationsAggregatedData = ({
  roomLocationIds,
  dateRange,
  filterMetrics,
  resolution,
  enabled,
  keepPreviousData,
}: {
  roomLocationIds: string[];
  dateRange: TimestampRange;
  filterMetrics?: string[];
  resolution?: RoomLocationAggregateResolution;
  enabled?: boolean;
  keepPreviousData?: boolean;
}) => {
  const queries = useMemo<
    UseQueryOptions<RoomLocationAggregate[], Error, RoomLocationAggregatedData>[]
  >(() => {
    const queryParams: Record<string, string> = {
      fromTimestamp: dateRange.fromTimestamp,
      toTimestamp: dateRange.toTimestamp,
      resolution: resolution ?? getRoomAggregateResolutionFromDateRange(dateRange),
    };

    if (filterMetrics) {
      queryParams["filterMetrics"] = filterMetrics.join(",");
    }

    return (roomLocationIds ?? []).map((roomLocationId) => ({
      queryKey: [QueryKeys.ROOM_LOCATION_AGGREGATES, roomLocationId, JSON.stringify(queryParams)],
      queryFn: () => api.get(`property/v2/roomlocation/${roomLocationId}/aggregates`, queryParams),
      select: (res: RoomLocationAggregate[]) =>
        ({
          roomLocationId: roomLocationId,
          aggregatedData: res,
        } as RoomLocationAggregatedData),
      enabled: enabled !== false && Boolean(dateRange) && Boolean(resolution),
      keepPreviousData,
      staleTime: Infinity,
    }));
  }, [roomLocationIds, dateRange, filterMetrics, resolution, enabled, keepPreviousData]);

  return useAggregatedQueries<RoomLocationAggregate[], Error, RoomLocationAggregatedData>(queries);
};

export const useGetMultipleRoomsAggregatedData = ({
  roomIds,
  dateRange,
  filterMetrics,
  resolution,
  enabled,
  keepPreviousData,
}: {
  roomIds: string[];
  dateRange: TimestampRange;
  filterMetrics?: string[];
  resolution?: RoomLocationAggregateResolution;
  enabled?: boolean;
  keepPreviousData?: boolean;
}) => {
  const queries = useMemo<UseQueryOptions<RoomLocationAggregate[], Error, RoomAggregatedData>[]>(() => {
    const queryParams: Record<string, string> = {
      fromTimestamp: dateRange.fromTimestamp,
      toTimestamp: dateRange.toTimestamp,
      resolution: resolution ?? getRoomAggregateResolutionFromDateRange(dateRange),
    };

    if (filterMetrics) {
      queryParams["filterMetrics"] = filterMetrics.join(",");
    }

    return (roomIds ?? []).map((id) => ({
      queryKey: [QueryKeys.ROOM_AGGREGATES, id, JSON.stringify(queryParams)],
      queryFn: () => api.get(`property/v2/room/${id}/aggregates`, queryParams),
      select: (res: RoomLocationAggregate[]) =>
        ({
          roomId: id,
          aggregatedData: res,
        } as RoomAggregatedData),
      enabled: enabled !== false && Boolean(dateRange) && Boolean(resolution),
      keepPreviousData,
      staleTime: Infinity,
    }));
  }, [roomIds, dateRange, filterMetrics, resolution, enabled, keepPreviousData]);

  return useAggregatedQueries<RoomLocationAggregate[], Error, RoomAggregatedData>(queries);
};

export const useGetRoomAggregatedData = ({
  roomId,
  dateRange,
  resolution,
  filterMetrics,
  enabled,
}: {
  roomId: string | undefined;
  dateRange: TimestampRange;
  resolution: RoomLocationAggregateResolution;
  filterMetrics?: string[];
  enabled?: boolean;
}) => {
  const queryParams: Record<string, string> = {
    fromTimestamp: dateRange.fromTimestamp,
    toTimestamp: dateRange.toTimestamp,
    resolution: resolution,
  };

  if (filterMetrics) {
    queryParams["filterMetrics"] = filterMetrics.join(",");
  }

  const { data: roomAggregatedData, ...rest } = useQuery<RoomLocationAggregate[]>(
    [QueryKeys.ROOM_AGGREGATES, roomId, JSON.stringify(queryParams)],
    () => api.get(`property/v2/room/${roomId}/aggregates`, queryParams),
    {
      enabled: Boolean(roomId) && Boolean(dateRange) && Boolean(resolution) && enabled !== false,
      staleTime: Infinity,
    }
  );
  return { roomAggregatedData, ...rest };
};

export const useGetRoomRadarHeatmapData = (roomId: string | undefined, dateRange: TimestampRange) => {
  const roomAggregateDataResolution = useMemo(() => getRoomAggregateResolutionFromDateRange(dateRange), [
    dateRange,
  ]);

  const { roomAggregatedData, isLoading } = useGetRoomAggregatedData({
    roomId,
    dateRange,
    resolution: roomAggregateDataResolution,
    filterMetrics: [
      SeriesTypeReading.footTraffic,
      SeriesTypeReading.totalIn,
      SeriesTypeReading.totalOut,
      SeriesTypeReading.occupancyPeopleCount,
      "dwellTime",
    ],
  });

  return {
    roomAggregatedData,
    roomAggregateDataResolution,
    isLoading,
  };
};

export const useGetRoomLocation = (roomLocationId: string) => {
  const { data: roomLocation, ...rest } = useQuery<RoomLocation>(
    [QueryKeys.ROOM_LOCATIONS, roomLocationId],
    () => api.get(`property/v2/roomlocation/${roomLocationId}`),
    {
      enabled: Boolean(roomLocationId),
      staleTime: Infinity,
    }
  );
  return { roomLocation, ...rest };
};

export const useGetRoomLocations = (roomId: string, name?: string) => {
  const { data: roomLocations, ...rest } = useQuery<RoomLocation[]>(
    [QueryKeys.ROOM_LOCATIONS, roomId],
    () => api.get(`property/v2/roomlocation?roomId=${roomId}${name !== undefined ? `name=${name}` : ""}`),
    {
      enabled: Boolean(roomId),
      staleTime: Infinity,
    }
  );
  return { roomLocations, ...rest };
};

type SearchRoomLocationsOptions = {
  roomId?: string;
  installationLocationId?: string;
  name?: string;
};
export const useSearchRoomLocations = (options: SearchRoomLocationsOptions) => {
  const { data: roomLocations, ...rest } = useQuery<RoomLocation[]>(
    [QueryKeys.ROOM_LOCATIONS, ...Object.values(options)],
    () => api.get(`property/v2/roomlocation?${getQueryParamsFromObject(options)}`),
    {
      staleTime: Infinity,
    }
  );
  return { roomLocations, ...rest };
};
