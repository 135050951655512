import { VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import ProgressBar from "./charts/ProgressBar";
import { OccupancyStatusBadge } from "./OccupancyStatusBadge";
import { useResizeObserver } from "@tether-web-portal/hooks";
import OccupancyBaseTile, {
  OccupancyBaseTileChildProps,
  Unavailable,
} from "@tether-web-portal/components/occupancy/tiles/OccupancyBaseTile";
import {
  OCCUPANCY_SPACE_LOW_COLOR_RANGE,
  OCCUPANCY_SPACE_OPTIMAL_COLOR_RANGE,
} from "@tether-web-portal/components/occupancy/occupancy.colors";
import { Typography } from "@tether-web-portal/components/common";
import { OccupancyCapacityTileData } from "@tether-web-portal/web-workers/occupancy";

interface OccupancySpacesTileProps extends OccupancyBaseTileChildProps {
  data?: OccupancyCapacityTileData;
  isLive?: boolean;
}

const OccupancySpacesTile = ({ data, loading, onAction, isLive, ...rest }: OccupancySpacesTileProps) => {
  const { width, setRef } = useResizeObserver();

  const { usage, usageLabel, occupiedLabel, colors, unavailable } = useMemo(() => {
    let usage = 0;
    let usageLabel = "No areas in use";
    let occupiedLabel = "";
    let colors = OCCUPANCY_SPACE_LOW_COLOR_RANGE;

    if (loading) {
      return {
        usage,
        occupiedLabel,
        usageLabel,
        colors,
      };
    }

    const { total = 0, occupied = 0 } = data || {};
    if (total > 0) {
      usage = (occupied / total) * 100;
      colors = usage > 40 ? OCCUPANCY_SPACE_OPTIMAL_COLOR_RANGE : OCCUPANCY_SPACE_LOW_COLOR_RANGE;
      occupiedLabel = `${occupied}/${total} areas ${isLive ? "are" : "have been"} occupied`;
      if (usage > 0) {
        usageLabel = `${usage.toFixed(0)}%`;
      }
    }

    return {
      unavailable: {
        icon: "spaces",
        enabled: !loading && total <= 0,
        title: "No area usage data available",
        subtitle: "Information about areas in use will appear here once data is collected.",
      } as Unavailable,
      usage,
      occupiedLabel,
      usageLabel,
      colors,
    };
  }, [data, isLive, loading]);

  return (
    <OccupancyBaseTile
      ref={setRef}
      title="Areas Usage"
      loading={loading}
      unavailable={unavailable}
      onAction={unavailable?.enabled ? undefined : onAction}
      {...rest}
    >
      <VStack alignItems="flex-start" spacing={2}>
        <Typography size={"3xl"} fontWeight="semibold">
          {usageLabel}
        </Typography>

        <ProgressBar width={width} height={16} progress={usage > 5 ? usage : 5} colors={colors} />

        <Typography size={"md"} color={"#595F6A"} mt={1}>
          {occupiedLabel}
        </Typography>

        <OccupancyStatusBadge value={usage} />
      </VStack>
    </OccupancyBaseTile>
  );
};

export default OccupancySpacesTile;
