import { Box, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  useCreatePinnedEntityMutation,
  useDeletePinnedEntityMutation,
  useGetPinnedEntitiesForProperty,
} from "@tether-web-portal/api/pinned-entities/hooks";
import { useDeleteRoomMutation, useGetPropertyDetail } from "@tether-web-portal/api/properties/hooks";
import { UserPinnedEntityType } from "@tether-web-portal/types/UserPinnedEntity";
import { useAuth } from "../../lib/auth/useAuth";
import { userRoleHasPermission } from "@tether-web-portal/services/PermissionService";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import { DefaultModal } from "../modals/DefaultModal";
import { RouterLink } from "@tether-web-portal/components/common";
import { MenuButtonAsButton } from "@tether-web-portal/components/common";

interface ManageRoomMenuProps {
  roomId: string;
  propertyId: string;
  roomName: string;
  hasDevice?: boolean;
  showBorder?: boolean;
  href?: string;
  color?: string;
}

export const ManageRoomMenu = ({
  roomId,
  propertyId,
  roomName,
  showBorder,
  hasDevice = false,
  href = `/area/room/${roomId}/edit`,
  color,
}: ManageRoomMenuProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { propertyInfo } = useGetPropertyDetail(propertyId);
  const { pinnedEntities } = useGetPinnedEntitiesForProperty(propertyId);
  const pinnedEntity = pinnedEntities?.find((pinnedEntity) => pinnedEntity.entityId === roomId);
  const createPinnedEntityMutation = useCreatePinnedEntityMutation();
  const deletePinnedEntityMutation = useDeletePinnedEntityMutation();

  const deleteRoomMutation = useDeleteRoomMutation(() => {
    setShowConfirmDelete(false);
  });

  const actuallyDeleteRoom = async () => {
    deleteRoomMutation.mutate({
      input: {
        roomId,
        propertyId,
      },
    });
  };

  const modifyAreaPermission = userRoleHasPermission(user!.currentOrganisationRoleId, "MODIFY_AREA");

  const room = propertyInfo ? propertyInfo.rooms.find((r) => r.id === roomId) : null;
  const locationsWithDevice = room ? room.roomLocations.filter((loc) => loc.device) : false;
  const hasLocationsWithDevice = Boolean(locationsWithDevice && locationsWithDevice.length);

  return (
    <>
      {Boolean(showConfirmDelete) && !hasLocationsWithDevice && (
        <ConfirmationModal
          isOpen={Boolean(showConfirmDelete)}
          onClose={() => {
            setShowConfirmDelete(false);
          }}
          onConfirm={async () => {
            actuallyDeleteRoom();
          }}
          confirmButtonColorScheme="red"
          title={`Delete Area: ${roomName}`}
          confirmationText={"Are you sure you want to delete this area? Any historic readings will be lost."}
          isLoading={deleteRoomMutation.isLoading}
        />
      )}
      {Boolean(showConfirmDelete) && hasLocationsWithDevice && (
        <DefaultModal
          isOpen={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          title={`Unable to Delete '${roomName}'`}
          hideCancel
        >
          <Text>
            {locationsWithDevice && locationsWithDevice.length > 1
              ? "There are devices attached to this area, you must remove these before the area can be deleted."
              : "There is a device attached to this area, you must remove it before the area can be deleted."}
          </Text>
        </DefaultModal>
      )}
      <Box alignItems="center" display="flex" position="relative">
        <Menu isOpen={isOpen} onClose={() => setTimeout(() => setIsOpen(false))}>
          {!showBorder ? (
            <MenuButtonAsButton
              disabled={!modifyAreaPermission}
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
            >
              <BsThreeDots size={24} color={color ?? "var(--chakra-colors-blue-900)"} />
            </MenuButtonAsButton>
          ) : (
            <>
              <MenuButton
                zIndex={-1}
                position="absolute"
                bg="yellow"
                width={10}
                height={10}
                opacity={0}
              />
              <MenuButton
                disabled={!modifyAreaPermission}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(!isOpen);
                }}
              >
                <BsThreeDots size={24} color={color ?? "var(--chakra-colors-blue-900)"} />
              </MenuButton>
            </>
          )}
          {/* Only show menu list when isOpen is true or it can render hidden off the side of the screen and cause side scrolling */}
          {isOpen && (
            <MenuList borderWidth={1} boxShadow="lg" borderColor="gray.50">
              <RouterLink to={href}>
                <MenuItem>
                  Edit area
                </MenuItem>
              </RouterLink>
              {pinnedEntity && (
                <MenuItem
                  onClick={async () => {
                    if (pinnedEntity) {
                      await deletePinnedEntityMutation.mutateAsync({ pinnedEntity });
                    }
                  }}
                >
                  Unpin
                </MenuItem>
              )}
              {!pinnedEntity && hasDevice && (
                <MenuItem
                  onClick={async () => {
                    await createPinnedEntityMutation.mutateAsync({
                      entityId: roomId,
                      entityType: UserPinnedEntityType.Room,
                      propertyId,
                    });
                  }}
                >
                  Pin to top
                </MenuItem>
              )}
              <MenuItem
                color="red.500"
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              >
                Delete area
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Box>
    </>
  );
};
