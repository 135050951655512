import dayjs from "@tether-web-portal/dayjs-setup";

import { OrgSettings, OrgSettingsTypes } from "@tether-web-portal/api/auth/OrgSettingService";
import { CircuitNode, getCircuitNodeFromTree } from "@tether-web-portal/types/CircuitNode";
import { CurrencyCode } from "@tether-web-portal/types/CurrencyCode";
import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { PropertyDetail } from "@tether-web-portal/types/PropertyDetail";
import { ComfortScore, GraphColor, GraphColorDetails } from "@tether-web-portal/types/Room";
import { calculateCondensationRiskReadings } from "@tether-web-portal/utils/calculateCondensationRiskReadings";
import { SelectedGraphType } from "../property/graph/PropertyReadingsGraph";
import { formatAsCurrency, getCurrency } from "../currency/CurrencyFormat";
import { DeviceWithLastSample } from "@tether-web-portal/types/DeviceWithLastSample";
import { PulseMeterType } from "@tether-web-portal/types/PulseMeter";
import { InstallationDetail, InstallationDetailType } from "@tether-web-portal/types/InstallationDetail";

export enum SeriesType {
  Temperature = "temperature",
  Humidity = "humidity",
  Co2 = "co2",
  Light = "light",
  Pressure = "pressure",
  Power = "power",
  PowerPerSqm = "powerPerSqm",
  PowerCost = "powerCost",
  PowerCarbon = "powerCarbon",
  AmpHours = "ampHours",
  MaxCurrent = "maxCurrent",
  MinCurrent = "minCurrent",
  Dewpoint = "dewPoint",
  Sound = "sound",
  SoundMax = "soundMax",
  Tvoc = "tvoc",
  MinTvoc = "minTVOC",
  MaxTvoc = "maxTVOC",
  Pm1 = "pm1",
  Pm2_5 = "pm2_5",
  Pm10 = "pm10",
  Occupied = "occupied",
  Water = "waterUsage",
  Gas = "gasUsage",
  AverageCurrent = "averageCurrent",
  PowerFactor = "powerFactor",
  ReferenceVoltage = "referenceVoltage",
  Voltage = "voltage",
  MouldRisk = "mouldRisk",
  HealthScore = "healthScore",
  OccupancyPeopleCount = "occupancyPeopleCount",
  OccupancyRegionPeopleCount = "occupancyRegionPeopleCount",
  DwellTimeMax = "dwellTimeMax",
  DwellTimeMean = "dwellTimeMean",
  OccupancyTotalIn = "totalIn",
  OccupancyTotalOut = "totalOut",
  FootTraffic = "footTraffic",
}

export const SeriesTypeReading: Record<SeriesType, string> = {
  temperature: "temperature",
  humidity: "humidity",
  co2: "co2",
  light: "light",
  pressure: "pressure",
  power: "power",
  powerPerSqm: "powerPerSqm",
  ampHours: "power",
  powerCost: "power",
  powerCarbon: "power",
  dewPoint: "dewPoint",
  sound: "sound",
  soundMax: "soundMax",
  minCurrent: "minCurrent",
  maxCurrent: "maxCurrent",
  tvoc: "tvoc",
  minTVOC: "minTVOC",
  maxTVOC: "maxTVOC",
  pm1: "pm1",
  pm2_5: "pm2_5",
  pm10: "pm10",
  occupied: "occupied",
  waterUsage: "waterUsage",
  gasUsage: "gasUsage",
  averageCurrent: "averageCurrent",
  powerFactor: "powerFactor",
  referenceVoltage: "referenceVoltage",
  voltage: "voltage",
  mouldRisk: "mouldRisk",
  healthScore: "healthScore",
  footTraffic: "inOutSum",
  occupancyPeopleCount: "occupancy",
  occupancyRegionPeopleCount: "count",
  dwellTimeMax: "dwellTime",
  dwellTimeMean: "dwellTime",
  totalIn: "totalIn",
  totalOut: "totalOut",
};

export const SeriesTypeYAxisGroup: Record<SeriesType, string> = {
  temperature: "temperature",
  humidity: "humidity",
  co2: "co2",
  light: "light",
  pressure: "pressure",
  power: "kwh",
  powerPerSqm: "kwh/m2",
  ampHours: "ampHours",
  powerCost: "$/kwh",
  powerCarbon: "kgCO2e/kwh",
  dewPoint: "dewPoint",
  sound: "sound",
  soundMax: "sound",
  tvoc: "ppb",
  minTVOC: "ppb",
  maxTVOC: "ppb",
  maxCurrent: "current",
  minCurrent: "current",
  pm1: "ppm",
  pm2_5: "ppm",
  pm10: "ppm",
  occupied: "occupied",
  waterUsage: "waterUsage",
  gasUsage: "gasUsage",
  averageCurrent: "current",
  powerFactor: "powerFactor",
  referenceVoltage: "referenceVoltage",
  voltage: "voltage",
  mouldRisk: "mouldRisk",
  healthScore: "healthScore",
  occupancyPeopleCount: "occupancy",
  occupancyRegionPeopleCount: "count",
  footTraffic: "In/Out",
  dwellTimeMax: "seconds",
  dwellTimeMean: "seconds",
  totalIn: "totalIn",
  totalOut: "totalOut",
};

export const SeriesTitle: Record<SeriesType, string> = {
  temperature: "Temp",
  humidity: "RH",
  co2: "CO2",
  light: "Light",
  pressure: "Pressure",
  power: "Power (kWh)",
  powerPerSqm: "PowerPerSqm",
  ampHours: "Power (Amp Hours)",
  powerCost: "Power Cost",
  powerCarbon: "Power (Carbon Emissions)",
  minCurrent: "Current (Min)",
  maxCurrent: "Current (Max)",
  dewPoint: "Dew Point",
  sound: "Ambient Noise",
  soundMax: "Sound (Max)",
  tvoc: "TVOC",
  minTVOC: "Min TVOC",
  maxTVOC: "Max TVOC",
  pm1: "PM 1",
  pm2_5: "PM 2.5",
  pm10: "PM 10",
  occupied: "Occupancy",
  waterUsage: "Water (L)",
  gasUsage: "Gas (m³)",
  averageCurrent: "Average Current",
  powerFactor: "Power Factor",
  referenceVoltage: "Reference Voltage",
  voltage: "Voltage",
  mouldRisk: "Mould Risk",
  healthScore: "Health Score",
  footTraffic: "Foot Traffic (In & Out)",
  occupancyPeopleCount: "Occupancy (Max within period)",
  occupancyRegionPeopleCount: "Occupancy (Max within period)",
  dwellTimeMax: "Dwell Time (Max)",
  dwellTimeMean: "Dwell Time (Average)",
  totalIn: "Occupancy: Total In",
  totalOut: "Occupancy: Total Out",
};

export function getSeriesLabel(series: SeriesType, value?: number | string) {
  if (value === undefined) return undefined;

  switch (series) {
    case SeriesType.Occupied:
      return value ? "Occupied" : "Unoccupied";
    case SeriesType.MouldRisk:
      return getPlotBandForValue(series, +value)?.description || "";
    default:
      return value;
  }
}

export function getSeriesMin(series: SeriesType) {
  switch (series) {
    case SeriesType.Temperature:
      return 0;
    case SeriesType.Humidity:
      return 0;
    case SeriesType.Co2:
      return 300;
    case SeriesType.Tvoc:
      return 0;
    case SeriesType.Light:
      return 0;
    case SeriesType.Pressure:
      return 80000;
    case SeriesType.Dewpoint:
      return 0;
    case SeriesType.Power:
      return 0;
    case SeriesType.PowerCarbon:
      return 0;
    case SeriesType.PowerFactor:
      return 80;
    case SeriesType.FootTraffic:
      return 0;
    default:
      return 0;
  }
}

export function getSeriesMax(series: SeriesType) {
  switch (series) {
    case SeriesType.Temperature:
      return 30;
    case SeriesType.Humidity:
      return 100;
    case SeriesType.Co2:
      return 3000;
    case SeriesType.Tvoc:
      return;
    case SeriesType.MinTvoc:
      return 500;
    case SeriesType.MaxTvoc:
      return 500;
    case SeriesType.Light:
      return 2000;
    case SeriesType.Pressure:
      return 110000;
    case SeriesType.Dewpoint:
      return 30;
    case SeriesType.Occupied:
      return 1;
    case SeriesType.Power:
      return;
    case SeriesType.MouldRisk:
      return 1;
    case SeriesType.HealthScore:
      return 10;
    case SeriesType.PowerFactor:
      return 100;
    default:
      return;
  }
}

/**
 * Returns whether to use soft or hard min value for the series
 */
export function getSeriesMinType(series: SeriesType) {
  switch (series) {
    case SeriesType.Temperature:
    case SeriesType.PowerFactor:
      return "soft";
    case SeriesType.Humidity:
    case SeriesType.Co2:
    case SeriesType.Tvoc:
    case SeriesType.MinTvoc:
    case SeriesType.MaxTvoc:
    case SeriesType.Light:
    case SeriesType.Pressure:
    case SeriesType.Dewpoint:
    case SeriesType.PowerCarbon:
    case SeriesType.FootTraffic:
    case SeriesType.MouldRisk:
      return "hard";
    default:
      return "soft";
  }
}

/**
 * Returns whether to use soft or hard max value for the series
 */
export function getSeriesMaxType(series: SeriesType) {
  switch (series) {
    case SeriesType.Humidity:
    case SeriesType.Occupied:
    case SeriesType.HealthScore:
    case SeriesType.PowerFactor:
      return "hard";
    default:
      return "soft";
  }
}

export function getYAxisTitle(series: SeriesType, currencySymbol?: string) {
  switch (series) {
    case SeriesType.Temperature:
      return "Temperature (°C)";
    case SeriesType.Humidity:
      return "Humidity %";
    case SeriesType.Co2:
      return "CO2 ppm";
    case SeriesType.Tvoc:
      return "TVOC (ppb)";
    case SeriesType.MinTvoc:
      return "TVOC (ppb)";
    case SeriesType.MaxTvoc:
      return "TVOC (ppb)";
    case SeriesType.Light:
      return "Light";
    case SeriesType.Pressure:
      return "Pressure";
    case SeriesType.Dewpoint:
      return "Dew Point (°C)";
    case SeriesType.Sound:
      return "Decibels";
    case SeriesType.SoundMax:
      return "Decibels";
    case SeriesType.Power:
      return "Power (kWh)";
    case SeriesType.AmpHours:
      return "Power (Ah)";
    case SeriesType.PowerCost:
      return `Cost (${currencySymbol || "$"})`;
    case SeriesType.PowerCarbon:
      return "Carbon (kgCO2e)";
    case SeriesType.MaxCurrent:
      return "Current (Max)";
    case SeriesType.MinCurrent:
      return "Current (Min)";
    case SeriesType.Pm1:
      return "PM 1.0";
    case SeriesType.Pm2_5:
      return "PM 2.5";
    case SeriesType.Pm10:
      return "PM 10";
    case SeriesType.Occupied:
      return "Occupancy";
    case SeriesType.Water:
      return "Water (L)";
    case SeriesType.Gas:
      return "Gas (m³)";
    case SeriesType.MouldRisk:
      return "Mould Risk";
    case SeriesType.HealthScore:
      return "Health Score";
    case SeriesType.AverageCurrent:
      return "Current (Average)";
    case SeriesType.PowerFactor:
      return "Power Factor";
    case SeriesType.ReferenceVoltage:
      return "Reference Voltage";
    case SeriesType.Voltage:
      return "Voltage";
    default:
      return "";
  }
}

export function getChartTitle(series: SeriesType) {
  switch (series) {
    case SeriesType.Temperature:
      return "Temperature";
    case SeriesType.Humidity:
      return "Humidity";
    case SeriesType.Co2:
      return "CO\u2082";
    case SeriesType.Tvoc:
      return "TVOC";
    case SeriesType.MinTvoc:
      return "Min TVOC";
    case SeriesType.MaxTvoc:
      return "Max TVOC";
    case SeriesType.Light:
      return "Light";
    case SeriesType.Pressure:
      return "Pressure";
    case SeriesType.Dewpoint:
      return "Dew Point";
    case SeriesType.Sound:
      return "Sound";
    case SeriesType.SoundMax:
      return "Sound (Max)";
    case SeriesType.Power:
      return "Power (kWh)";
    case SeriesType.AmpHours:
      return "Power (Amp Hours)";
    case SeriesType.PowerCost:
      return "Power Cost";
    case SeriesType.PowerCarbon:
      return "Power (Carbon Emissions)";
    case SeriesType.MinCurrent:
      return "Current (Min)";
    case SeriesType.MaxCurrent:
      return "Current (Max)";
    case SeriesType.Pm1:
      return "Particulate Matter 1.0";
    case SeriesType.Pm2_5:
      return "Particulate Matter 2.5";
    case SeriesType.Pm10:
      return "Particulate Matter 10";
    case SeriesType.Occupied:
      return "Occupancy";
    case SeriesType.Water:
      return "Water (L)";
    case SeriesType.Gas:
      return "Gas (m³)";
    case SeriesType.AverageCurrent:
      return "Current (Average)";
    case SeriesType.PowerFactor:
      return "Power Factor";
    case SeriesType.ReferenceVoltage:
      return "Reference Voltage";
    case SeriesType.Voltage:
      return "Voltage";
    case SeriesType.MouldRisk:
      return "Mould Risk";
    case SeriesType.HealthScore:
      return "Health Score";
    case SeriesType.FootTraffic:
      return "Foot Traffic";
    case SeriesType.OccupancyPeopleCount:
      return "People Count";
    case SeriesType.OccupancyTotalIn:
      return "Occupancy: Total In";
    case SeriesType.OccupancyTotalOut:
      return "Occupancy: Total Out";
    case SeriesType.DwellTimeMax:
      return "Dwell Time (Max)";
    case SeriesType.DwellTimeMean:
      return "Dwell Time (Average)";
    default:
      return "";
  }
}

export function formatValue(value: number | string | undefined, series: SeriesType, currency?: CurrencyCode) {
  if (value === undefined) {
    return "";
  }

  switch (series) {
    case SeriesType.Temperature:
      return `${value}°C`;
    case SeriesType.Humidity:
      return `${value}%`;
    case SeriesType.Co2:
      return `${value}ppm`;
    case SeriesType.Tvoc:
      return `${value}ppb`;
    case SeriesType.MinTvoc:
      return `${value}ppb`;
    case SeriesType.MaxTvoc:
      return `${value}ppb`;
    case SeriesType.Light:
      return `${value}`;
    case SeriesType.Pressure:
      return `${value}`;
    case SeriesType.Dewpoint:
      return `${value}°C`;
    case SeriesType.Sound:
      return `${value}db`;
    case SeriesType.SoundMax:
      return `${value}db`;
    case SeriesType.Power:
      return `${value}kWh`;
    case SeriesType.AmpHours:
      return `${value}Ah`;
    case SeriesType.MinCurrent:
      return `${value}Amps`;
    case SeriesType.MaxCurrent:
      return `${value}Amps`;
    case SeriesType.Pm1:
    case SeriesType.Pm2_5:
    case SeriesType.Pm10:
      return `${value}ug/m3`;
    case SeriesType.PowerCarbon:
      return `${value}kgCO2e`;
    case SeriesType.PowerCost:
      return formatAsCurrency(value, currency || "NZD");
    case SeriesType.Water:
      return `${value}L`;
    case SeriesType.Gas:
      return `${value}m³`;
    case SeriesType.Occupied:
      return value ? "Occupied" : "Unoccupied";
    case SeriesType.AverageCurrent:
      return `${value}Amps`;
    case SeriesType.PowerFactor:
      // if it's % then multiply by 100
      if (value !== undefined && value >= 0 && value <= 1) {
        return `${String((value as number) * 100)}%`;
      }
      return `${value}%`;
    case SeriesType.ReferenceVoltage:
      return `${value} V`;
    case SeriesType.Voltage:
      return `${value} V`;
    default:
      return `${value} `;
  }
}

export interface PlotBand {
  color: string;
  from: number;
  to: number;
  description: string;
}

export function getPlotBandForValue(series: SeriesType, value: number): PlotBand | null {
  const bands = getPlotBands(series);
  return bands.find((band) => value >= band.from && value <= band.to) || null;
}

export function getPlotBands(series: SeriesType): PlotBand[] {
  switch (series) {
    case SeriesType.Temperature:
      return [
        {
          color: "#CDECD4",
          from: 18,
          to: 24,
          description: "Healthy",
        },
      ];
    case SeriesType.Humidity:
      return [
        {
          color: "#CDECD4",
          from: 40,
          to: 60,
          description: "Healthy",
        },
      ];
    case SeriesType.Co2:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 800,
          description: "Healthy",
        },
      ];
    case SeriesType.Tvoc:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 440,
          description: "Healthy",
        },
      ];
    case SeriesType.MinTvoc:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 440,
          description: "Healthy",
        },
      ];
    case SeriesType.MaxTvoc:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 440,
          description: "Healthy",
        },
      ];
    case SeriesType.Pm1:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 25,
          description: "Healthy",
        },
      ];
    case SeriesType.Pm2_5:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 25,
          description: "Healthy",
        },
      ];
    case SeriesType.Pm10:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 50,
          description: "Healthy",
        },
      ];
    case SeriesType.Dewpoint:
      return [];
    case SeriesType.MouldRisk:
      return [
        {
          color: "#CDECD4",
          from: 0,
          to: 0.5,
          description: "Very Low Risk",
        },
        {
          color: "#E4EFDA",
          from: 0.5,
          to: 1.25,
          description: "Low Risk",
        },
        {
          color: "#FDF0E1",
          from: 1.25,
          to: 2.25,
          description: "Moderate Risk",
        },
        {
          color: "#FEEAE7",
          from: 2.25,
          to: 3.25,
          description: "High Risk",
        },
        {
          color: "#FEE4E7",
          from: 3.25,
          to: 20,
          description: "Very High Risk",
        },
      ];
    case SeriesType.HealthScore:
      return [
        {
          color: "#CDECD4",
          from: 8,
          to: 10,
          description: "Healthy",
        },
      ];
    default:
      return [];
  }
}

export function getChartType(series: SeriesType, graphTypeOverride?: SelectedGraphType) {
  if (graphTypeOverride) {
    if (graphTypeOverride === "LINE") return "line";
    if (graphTypeOverride === "BAR") return "column";
  }

  switch (series) {
    case SeriesType.Power:
    case SeriesType.AmpHours:
    case SeriesType.PowerCost:
    case SeriesType.PowerCarbon:
    case SeriesType.PowerPerSqm:
    case SeriesType.Water:
    case SeriesType.Gas:
      return "column";
    default:
      return "line";
  }
}

export function transformValue(
  series: SeriesType,
  value: number | undefined,
  orgSettings: OrgSettings,
  propertyDetails: InstallationDetail<number | boolean | string>[]
) {
  if (value === undefined) return value;

  switch (series) {
    case SeriesType.AmpHours:
      return +(value / 0.24).toFixed(2);
    case SeriesType.PowerCost: {
      const costPerKwh = Number(
        propertyDetails?.find((x) => x.name === InstallationDetailType.COST_PER_KWH)?.value ??
          orgSettings[OrgSettingsTypes.COST_PER_KWH]
      );
      return +(value * costPerKwh).toFixed(2);
    }
    case SeriesType.PowerCarbon: {
      const co2PerKwh = Number(
        propertyDetails?.find((x) => x.name === InstallationDetailType.CO2_PER_KWH)?.value ??
          orgSettings[OrgSettingsTypes.CO2_PER_KWH]
      );
      return +(value * co2PerKwh).toFixed(2);
    }
    case SeriesType.PowerFactor:
      // if it's % then multiply by 100
      if (value >= 0 && value <= 1) {
        return +(value * 100).toFixed(3);
      }
      return value;
    default:
      return value;
  }
}

export function getDewPointBands(combinedReadings: CombinedPropertyReadings): PlotBand[] {
  const plotbandColor = "#f8d7da";

  const condensationRisks = combinedReadings.roomLocations.map((loc) =>
    loc.readings.dewPoint
      ? calculateCondensationRiskReadings(loc.readings.dewPoint, combinedReadings.outsides, true)
      : []
  );

  let openPlotband: Partial<PlotBand> | null;

  const plotbands: PlotBand[] = [];

  const sortedCondensationRisks = condensationRisks.flat().sort();
  sortedCondensationRisks.forEach((reading, index) => {
    if (index === 0 && reading[1]) {
      openPlotband = {
        from: 0,
        color: plotbandColor,
        description: "Condensation Risk",
      };
      return;
    }

    if (openPlotband) {
      if (!reading[1]) {
        plotbands.push({ ...openPlotband, to: reading[0] } as PlotBand);
        openPlotband = null;
        return;
      }

      return;
    }

    if (reading[1]) {
      openPlotband = {
        from: reading[0],
        color: plotbandColor,
      };
      return;
    }

    return;
  });

  return plotbands;
}

export const getDataStartTimeForSeries = (seriesType: SeriesType, r: POWER_READING): keyof POWER_READING => {
  let preferredTimestamp: keyof POWER_READING = "startTimestamp";

  if (seriesType === SeriesType.MaxCurrent) preferredTimestamp = "maxCurrentTimestamp";
  if (seriesType === SeriesType.MinCurrent) preferredTimestamp = "minCurrentTimestamp";

  if (typeof r[preferredTimestamp] !== "undefined") return preferredTimestamp;

  return "startTimestamp";
};

const getPowerSeriesValForSeriesType = (seriesType: SeriesType): keyof POWER_READING => {
  if (!seriesIsPower(seriesType)) throw new Error("Invalid series type for power series: " + seriesType);

  switch (seriesType) {
    case SeriesType.Power:
    case SeriesType.AmpHours:
    case SeriesType.PowerCost:
    case SeriesType.PowerCarbon:
    case SeriesType.PowerPerSqm:
      return "totalkWh";
    default:
      return seriesType as keyof POWER_READING;
  }
};

const getWaterSeriesValForSeriesType = (seriesType: SeriesType) => {
  if (!seriesIsWater(seriesType)) throw new Error("Invalid series type for water series: " + seriesType);

  return "water";
};

export const filterVoltDropTotals = (circuit: CircuitReading, circuitNodeTree: CircuitNode[] | undefined) => {
  // this is a way to hide totals if we are showing voltdrops with both total and phase data
  // this checks if we want to show a total, but the node also has 3 phases attached. If so hide the Total.
  if (circuit.type === "TOTAL") {
    const circuitNode = circuitNodeTree
      ? getCircuitNodeFromTree(circuitNodeTree, circuit.circuitNodeId)
      : undefined;
    if (circuitNode && circuitNode.circuits.filter((c) => c.type === "PHASE").length === 3) {
      return false;
    }
  }
  return true;
};

// if we are rendering a node with a voltdrop on it, it will have both a "TOTAL" and a "PHASE" circuit.
// we need to render one or the other not both.
export const getCircuitsToGraphFromNode = (circuitNode: CircuitNode) => {
  // if this is a voltdrop with totals and phases we only display one or the other.
  if (circuitNode.circuits.length === 4) {
    const countOfTotal = circuitNode.circuits.filter((c) => c.type === "TOTAL").length;
    const countOfPhase = circuitNode.circuits.filter((c) => c.type === "PHASE").length;
    if (countOfTotal === 1 && countOfPhase === 3) {
      return circuitNode.circuits.filter((c) => c.type !== "TOTAL");
    }
  }
  return circuitNode.circuits;
};

export const getCircuitSeries = (
  circuitNodeTree: CircuitNode[] | undefined,
  circuit: CircuitReading,
  seriesType: SeriesType,
  excludedLocationIds: string[],
  yAxis: number,
  orgSettings: OrgSettings,
  propertyDetails: InstallationDetail<number | boolean | string>[],
  graphTypeOverride: SelectedGraphType | undefined
): Highcharts.SeriesOptionsType => {
  const currency = getCurrency(orgSettings);
  const data = seriesIsPower(seriesType)
    ? circuit.readings
        .filter((r) => {
          const seriesVal = getPowerSeriesValForSeriesType(seriesType);
          return r[seriesVal] !== undefined && r[seriesVal] !== null;
        })
        .map((r) => {
          // const
          return [
            dayjs(r[getDataStartTimeForSeries(seriesType, r)]!).valueOf(),
            transformValue(
              seriesType,
              r[getPowerSeriesValForSeriesType(seriesType)] as number,
              orgSettings,
              propertyDetails
            ),
          ];
        })
    : [];

  const circuitNode = circuitNodeTree
    ? getCircuitNodeFromTree(circuitNodeTree, circuit.circuitNodeId)
    : undefined;
  const phaseDescriptor = Boolean(circuit?.phase === null && circuit?.type === "TOTAL")
    ? " Total"
    : " Phase " + circuit?.phase;
  const circuitNodeName = circuitNode ? circuitNode.name + phaseDescriptor : circuit.name;

  const chartType = getChartType(seriesType, graphTypeOverride);
  return {
    name: circuitNodeName,
    custom: { locationId: circuit.id },
    type: chartType,
    data,
    visible: !excludedLocationIds.includes(circuit.id),
    showInLegend: isStackedBarGraph(seriesType),
    // stacking: isStackedBarGraph(seriesType) ? "normal" : undefined,
    pointInterval: 36000000,
    zIndex: 1,
    pointStart: 0,
    tooltip: {
      xDateFormat: "%A, %b %e, %l:%M%P", // displays the date and time (12 hr)
      pointFormatter: function () {
        return (
          `${getChartTitle(seriesType)}<br/><b>${this.series.name}:</b> ${formatValue(
            this.y,
            seriesType,
            currency
          )} ` +
          (isStackedBarGraph(seriesType) && chartType === "column"
            ? `<br /> <b>Total:</b> ${formatValue(this.total, seriesType, currency)} `
            : ``)
        );
      },
    },
    color: circuit.color && GraphColorDetails[circuit.color]?.color,
    yAxis,
  };
};

export const getWaterSeries = (
  waterOutside: FullWaterReading,
  seriesType: SeriesType,
  excludedLocationIds: string[],
  yAxis: number,
  orgSettings: OrgSettings,
  graphTypeOverride: SelectedGraphType | undefined
): Highcharts.SeriesOptionsType => {
  const currency = getCurrency(orgSettings);
  const data = seriesIsWater(seriesType)
    ? waterOutside.waterUsage.map((r) => {
        return [dayjs(r["startTimestamp"]).valueOf(), r["waterUsage"]];
      })
    : [];

  const chartType = getChartType(seriesType, graphTypeOverride);

  return {
    name: waterOutside.name,
    custom: { locationId: waterOutside.id },
    type: chartType,
    data,
    visible: !excludedLocationIds.includes(waterOutside.id),
    showInLegend: isStackedBarGraph(seriesType),
    stacking: isStackedBarGraph(seriesType) ? "normal" : undefined,
    pointInterval: 36000000,
    zIndex: 1,
    pointStart: 0,
    tooltip: {
      xDateFormat: "%A, %b %e, %l:%M%P", // displays the date and time (12 hr)
      pointFormatter: function () {
        return (
          `${getChartTitle(seriesType)}<br/><b>${this.series.name}:</b> ${formatValue(
            this.y,
            seriesType,
            currency
          )} ` +
          (isStackedBarGraph(seriesType) && chartType === "column"
            ? `<br /> <b>Total:</b> ${formatValue(this.total, seriesType, currency)} `
            : ``)
        );
      },
    },
    color: waterOutside.color && GraphColorDetails[waterOutside.color]?.color,
    yAxis,
  };
};

export const getGasSeries = (
  gasOutside: FullGasReading,
  seriesType: SeriesType,
  excludedLocationIds: string[],
  yAxis: number,
  orgSettings: OrgSettings,
  graphTypeOverride: SelectedGraphType | undefined
): Highcharts.SeriesOptionsType => {
  const currency = getCurrency(orgSettings);
  const data = seriesIsGas(seriesType)
    ? gasOutside.gasUsage.map((r) => {
        return [dayjs(r["startTimestamp"]).valueOf(), r["gasUsage"]];
      })
    : [];
  console.log({ data });
  const chartType = getChartType(seriesType, graphTypeOverride);

  return {
    name: gasOutside.name,
    custom: { locationId: gasOutside.id },
    type: chartType,
    data,
    visible: !excludedLocationIds.includes(gasOutside.id),
    showInLegend: isStackedBarGraph(seriesType),
    stacking: isStackedBarGraph(seriesType) ? "normal" : undefined,
    pointInterval: 36000000,
    zIndex: 1,
    pointStart: 0,
    tooltip: {
      xDateFormat: "%A, %b %e, %l:%M%P", // displays the date and time (12 hr)
      pointFormatter: function () {
        return (
          `${getChartTitle(seriesType)}<br/><b>${this.series.name}:</b> ${formatValue(
            this.y,
            seriesType,
            currency
          )} ` +
          (isStackedBarGraph(seriesType) && chartType === "column"
            ? `<br /> <b>Total:</b> ${formatValue(this.total, seriesType, currency)} `
            : ``)
        );
      },
    },
    color: gasOutside.color && GraphColorDetails[gasOutside.color]?.color,
    yAxis,
  };
};

export const isStackedBarGraph = (seriesType: SeriesType) => {
  const allPowerSeriesTypes = [
    SeriesType.Power,
    SeriesType.AmpHours,
    SeriesType.PowerCost,
    SeriesType.PowerPerSqm,
    SeriesType.PowerCarbon,
    SeriesType.Water,
  ];
  return allPowerSeriesTypes.indexOf(seriesType) !== -1;
};

export const seriesIsPower = (seriesType: SeriesType) => {
  const allPowerSeriesTypes = [
    SeriesType.Power,
    SeriesType.AmpHours,
    SeriesType.PowerCost,
    SeriesType.PowerPerSqm,
    SeriesType.PowerCarbon,
    SeriesType.MaxCurrent,
    SeriesType.MinCurrent,
    SeriesType.AverageCurrent,
    SeriesType.PowerFactor,
    SeriesType.ReferenceVoltage,
    SeriesType.Voltage,
  ];
  return allPowerSeriesTypes.indexOf(seriesType) !== -1;
};

export const seriesIsOccupancy = (seriesType: SeriesType) => {
  const allOccupancySeriesTypes = [
    SeriesType.Occupied,
    SeriesType.FootTraffic,
    SeriesType.OccupancyTotalIn,
    SeriesType.OccupancyTotalOut,
    SeriesType.OccupancyPeopleCount,
    SeriesType.DwellTimeMean,
  ];
  return (
    allOccupancySeriesTypes.includes(seriesType) ||
    seriesType.toString() === "count" ||
    // Checks that series type starts with either "line" or "region", has "_NUMBER_" and then some final suffix e.g. "total_in". This is also occupancy data
    /^(line|region)_([0-9]{1,})_([a-z_]{1,})$/.test(seriesType.toString())
  );
};

export const seriesIsWater = (seriesType: SeriesType) => {
  const allWaterSeriesTypes = [SeriesType.Water];
  return allWaterSeriesTypes.indexOf(seriesType) !== -1;
};

export const seriesIsGas = (seriesType: SeriesType) => {
  const allGasSeriesTypes = [SeriesType.Gas];
  return allGasSeriesTypes.indexOf(seriesType) !== -1;
};

export const getDeviceTypesByLocationId = (propertyInfo: PropertyDetail | undefined) => {
  const deviceTypesByLocationId: Record<string, string | undefined> = {};
  propertyInfo?.circuits.forEach((circuit) => {
    deviceTypesByLocationId[circuit.id] = circuit.device?.type;
  });
  propertyInfo?.rooms
    .flatMap((r) => r.roomLocations)
    .flatMap((rl) => {
      deviceTypesByLocationId[rl.id] = rl.device?.type;
    });
  propertyInfo?.outsides.map((o) => {
    deviceTypesByLocationId[o.id] = o.device?.type;
  });
  return deviceTypesByLocationId;
};

export const deviceIsPulseMeter = (deviceType?: DeviceType | string) => {
  return (
    deviceType === DeviceType.ADEUNIS_PULSE ||
    deviceType === DeviceType.MILESIGHT_EM300 ||
    deviceType === DeviceType.ADEUNIS_PULSE_WATER ||
    deviceType === DeviceType.ADEUNIS_PULSE_GAS
  );
};

export const deviceIsWater = (deviceType?: DeviceType | string, pulseMeterType?: PulseMeterType) => {
  if (!deviceType) return false;

  if (pulseMeterType) {
    return deviceIsPulseMeter(deviceType) && pulseMeterType === PulseMeterType.Water;
  }

  return deviceIsPulseMeter(deviceType);
};

export const deviceIsGas = (deviceType?: DeviceType | string, pulseMeterType?: PulseMeterType) => {
  if (!deviceType) return false;

  if (pulseMeterType) {
    return deviceIsPulseMeter(deviceType) && pulseMeterType === PulseMeterType.Gas;
  }

  return deviceIsPulseMeter(deviceType);
};

export const getFilterMetricsForSeries = (seriesTypes: SeriesType[]): string[] => {
  const filterMetrics: string[] = [];

  seriesTypes.forEach((seriesType) => {
    switch (seriesType) {
      case SeriesType.DwellTimeMax:
        filterMetrics.push(SeriesTypeReading[SeriesType.Occupied]);
        filterMetrics.push(SeriesTypeReading[SeriesType.OccupancyPeopleCount]);
        filterMetrics.push(SeriesTypeReading[SeriesType.DwellTimeMax]);
        break;
      case SeriesType.DwellTimeMean:
        filterMetrics.push(SeriesTypeReading[SeriesType.Occupied]);
        filterMetrics.push(SeriesTypeReading[SeriesType.OccupancyPeopleCount]);
        filterMetrics.push(SeriesTypeReading[SeriesType.DwellTimeMean]);
        break;
      default:
        filterMetrics.push(SeriesTypeReading[seriesType]);
    }
  });

  return filterMetrics;
};

type READING = [number, number][];

export type POWER_READING = {
  startTimestamp: string;
  endTimestamp: string;
  maxCurrent?: number;
  minCurrent?: number;
  maxCurrentTimestamp?: string;
  minCurrentTimestamp?: string;
  averageCurrent?: number;
  powerFactor?: number;
  referenceVoltage?: number;
  voltage?: number;
  temperature?: number;
  totalkWh: number;
};

export type WATER_READING = {
  startTimestamp: string;
  endTimestamp: string;
  waterUsage: number;
};

export type GAS_READING = {
  startTimestamp: string;
  endTimestamp: string;
  gasUsage: number;
};

export type PULSE_METER_READING = {
  waterUsage: WATER_READING[];
  gasUsage: GAS_READING[];
  powerUsage: POWER_READING[];
};

export type PropertyReadingStruct = {
  [key in SeriesType]: READING;
};

export type FullPropertyRoomReading = {
  id: string;
  roomId: string;
  name: string;
  parentRoomLocationId: string | null;
  color?: GraphColor;
  readings: PropertyReadingStruct;
  insights: RoomInsights;
  device?: DeviceWithLastSample;
};

export type FullOutsideReading = {
  id: string;
  propertyId: string;
  name: string;
  color?: GraphColor;
  readings: PropertyReadingStruct;
};

export type FullPropertyReadings = {
  roomLocations: FullPropertyRoomReading[];
  outsides: FullOutsideReading[];
  insights?: RoomInsights;
};

export type CombinedPropertyReadings = {
  circuits: CircuitReading[];
  roomLocations: FullPropertyRoomReading[];
  mainsCircuit: CircuitReading | null;
  outsides: FullOutsideReading[];
  overallCircuitInsights?: CircuitInsights;
  overallRoomInsights?: RoomInsights;
  waterOutsides: FullWaterReading[];
  gasOutsides: FullGasReading[];
};

export interface CircuitInsights {
  totalEnergyUsageKwh: number;
  totalCO2e: number;
  CO2ENumberOfTrees: number;
  estimatedEnergyCost: number;
  energyUseIntensityKBtu: number;
  energyUseIntensityKwh: number;
}

export interface RoomInsights {
  healthScore: number;
  comfortScore: ComfortScore;
  daysToMouldRisk: number;
}

export type CircuitReading = {
  id: string;
  propertyId: string;
  circuitNodeId: string;
  phase: number;
  type: "TOTAL" | "PHASE";
  name: string;
  color?: GraphColor;
  readings: POWER_READING[];
  insights: CircuitInsights;
};

export type FullCircuitReading = {
  circuits: CircuitReading[];
  insights: CircuitInsights;
  mainsCircuit: CircuitReading | null;
};

export interface WaterInsights {
  totalWaterUsageLitres: number;
}

export type PropertyWaterReading = {
  outsides: FullWaterReading[];
  insights: WaterInsights;
};

export interface PulseMeterInsights {
  totalWaterUsageLitres: number;
  totalGasUsage: number;
  totalkWh: number;
}

export type PropertyPulseReading = {
  outsides: FullPulseReading[];
  insights: PulseMeterInsights;
};

export type FullWaterReading = {
  id: string;
  propertyId: string;
  name: string;
  color?: GraphColor;
  waterUsage: WATER_READING[];
  insights: WaterInsights;
};

export type FullGasReading = {
  id: string;
  propertyId: string;
  name: string;
  color?: GraphColor;
  gasUsage: GAS_READING[];
  insights: WaterInsights;
};

export type FullPulseReading = {
  id: string;
  propertyId: string;
  name: string;
  color?: GraphColor;
  gasUsage: GAS_READING[];
  waterUsage: WATER_READING[];
  powerUsage: POWER_READING[];
  insights: WaterInsights;
};
