/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useMemo, useState } from "react";
import { Button as ChakraButton } from "@chakra-ui/button";
import { ThemingProps } from "@chakra-ui/react";

interface IButtonProps {
  variant?: "primary" | "secondary";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<any>;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  disabled?: boolean;
  children: any;
}
export const Button: FunctionComponent<IButtonProps> = (props: IButtonProps) => {
  const { variant, onClick, type, loading, size, disabled, children } = props;

  const [processing, setProcessing] = useState(false);

  const { colorScheme, componentVariant, backgroundColor } = useMemo(() => {
    let colorScheme: ThemingProps<"Button">["colorScheme"] = "blue";
    let componentVariant: ThemingProps<"Button">["variant"] = "solid";
    let backgroundColor: string | undefined = undefined;

    switch (variant) {
      case "secondary":
        colorScheme = "black";
        componentVariant = "outline";
        backgroundColor = "white";
        break;
    }

    return {
      colorScheme,
      componentVariant,
      backgroundColor,
    };
  }, [variant]);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const result = onClick && onClick(e);
    if (result instanceof Promise) {
      try {
        setProcessing(true);
        await result;
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <ChakraButton
      isLoading={loading || processing}
      onClick={handleClick}
      size={size}
      disabled={disabled || processing || loading}
      type={type}
      variant={componentVariant}
      colorScheme={colorScheme}
      minWidth="auto"
      bg={backgroundColor}
    >
      {children}
    </ChakraButton>
  );
};
