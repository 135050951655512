import { useMemo } from "react";
import {
  IOccupancyBreakdownDataPoint,
  IOccupancyBreakdownFilters,
  IOccupancyBreakdownSummary,
} from "@tether-web-portal/web-workers/occupancy";

import {
  OCCUPANCY_HEATMAP_LOW_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OVER_COLOR_RANGE,
  OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE,
  OCCUPANCY_OPTIMAL_THRESHOLD,
} from "@tether-web-portal/components/occupancy/occupancy.colors";

import { DayOfWeek, MonitoringHours } from "@tether-web-portal/types/MonitoringHours";
import { dayjs, Dayjs } from "@tether-web-portal/utils/dayjs-setup";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";
import { scaleLinear } from "@visx/scale";
import { ScaleLinear } from "d3";

const generateColorRange = () => {
  const colorSteps = (from: number, to: number, colors: string[]) => {
    const step = 1 / (colors.length - 1);
    const offset = to - from;
    return colors.map((color, i) => ({
      color,
      value: from + offset * i * step,
    }));
  };

  return [
    {
      color: OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE[0],
      value: 0,
    },
    ...colorSteps(0 + Number.EPSILON, OCCUPANCY_OPTIMAL_THRESHOLD, OCCUPANCY_HEATMAP_LOW_COLOR_RANGE),
    ...colorSteps(OCCUPANCY_OPTIMAL_THRESHOLD + Number.EPSILON, 1, OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE),
    {
      value: 1 + Number.EPSILON,
      color: OCCUPANCY_HEATMAP_OVER_COLOR_RANGE[0],
    },
  ];
};

export interface IOccupancyChartMetaDataPayload {
  daysOfWeekSet: Set<DayOfWeek>;
  daysBetween: number;
  maxValue: number;
  startDateUTC: Dayjs;
  endDateUTC: Dayjs;
  colorScale: ScaleLinear<string, string, never>;
}

export const useOccupancyChartMetaData = ({
  metricsTimeStamps,
  breakdownData,
  breakdownSummary,
  breakdownFilters,
  monitoringHours,
}: {
  metricsTimeStamps: TimestampRange;
  breakdownData: IOccupancyBreakdownDataPoint[];
  breakdownSummary: IOccupancyBreakdownSummary | null;
  breakdownFilters: IOccupancyBreakdownFilters;
  monitoringHours: MonitoringHours[] | null;
}): IOccupancyChartMetaDataPayload => {
  const { startDateUTC, endDateUTC } = useMemo(
    () => ({
      startDateUTC: dayjs(metricsTimeStamps.fromTimestamp),
      endDateUTC: dayjs(metricsTimeStamps.toTimestamp),
    }),
    [metricsTimeStamps],
  );

  const { metricToDisplay } = breakdownFilters;
  const { occupancyLimit } = breakdownSummary || {};

  const maxValue = useMemo((): number => {
    switch (metricToDisplay) {
      case "occupancyCount":
        return occupancyLimit || 0;
      case "entered":
        return Math.max(occupancyLimit || 0, ...breakdownData.map(({ breakdown: { entered } }) => entered));
      case "exited":
        return Math.max(occupancyLimit || 0, ...breakdownData.map(({ breakdown: { exited } }) => exited));
      case "footTraffic":
        return Math.max(
          occupancyLimit || 0,
          ...breakdownData.map(({ breakdown: { footTraffic } }) => footTraffic),
        );
      case "usagePercentage":
      case "occupancyLimit":
      case "capacityPercentage":
      case "capacityUtilisationLevel":
      default:
        return 100;
    }
  }, [metricToDisplay, occupancyLimit, breakdownData]);

  const colorScale = useMemo((): ScaleLinear<string, string, never> => {
    const steppedColors = generateColorRange().map(({ value, ...rest }) => ({
      ...rest,
      value: value * maxValue,
    }));
    return scaleLinear({
      range: steppedColors.map(({ color }) => color),
      domain: steppedColors.map(({ value }) => value),
    });
  }, [maxValue]);

  const daysBetween = useMemo<number>(() => {
    return endDateUTC.diff(startDateUTC, "day");
  }, [startDateUTC, endDateUTC]);

  const daysOfWeekSet = useMemo<Set<DayOfWeek>>(
    () => new Set(monitoringHours?.map(({ dayOfWeek }) => dayOfWeek)),
    [monitoringHours],
  );

  return {
    daysOfWeekSet,
    daysBetween,
    maxValue,
    startDateUTC,
    endDateUTC,
    colorScale,
  };
};
