import { FunctionComponent } from "react";
import { Box, Center, Spinner, Stack } from "@chakra-ui/react";

import { HeatMap } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { OccupancyBreakdownCardWrapper } from "./OccupancyBreakdownCardWrapper";
import numeral from "numeral";
import { IOccupancyBreakdownDataPoint } from "@tether-web-portal/web-workers/occupancy";
import BreakdownSummaryItem from "@tether-web-portal/components/occupancy/breakdown/BreakdownSummaryItem";

import { Typography } from "@tether-web-portal/components/common";
import { useOccupancyHeatmapProps } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyHeatmapProps";
import { TooltipDisplay } from "@tether-web-portal/components/occupancy/breakdown/tooltip/TooltipDisplay";
import { useOccupancyChartMetaData } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyChartMetaData";

export const OccupancyBreakdownHeatmap: FunctionComponent = () => {
  const {
    breakdownLoading,
    breakdownData,
    breakdownSummary,
    breakdownFilters,
    property,
    isLiveView,
    monitoringHours,
  } = useOccupancyContext();

  const { metricsTimeStamps } = useMetricsInterval();

  const occupancyChartMetaData = useOccupancyChartMetaData({
    metricsTimeStamps,
    breakdownData,
    breakdownSummary,
    breakdownFilters,
    monitoringHours,
  });

  const heatmapProps = useOccupancyHeatmapProps(
    {
      breakdownData,
      breakdownSummary,
      breakdownFilters,
      timezone: property?.timezone,
      monitoringHours,
    },
    occupancyChartMetaData,
  );

  if (isLiveView) {
    return <></>;
  }

  const { occupancyMaxCount, occupancyLimit, capacityPercentage, usagePercentage } = breakdownSummary || {};
  return (
    <>
      <Typography size="xl" fontWeight="bold">
        Utilisation Breakdown
      </Typography>
      <OccupancyBreakdownCardWrapper>
        {breakdownSummary && (
          <Stack direction="row" columnGap={3} alignItems="center">
            {(occupancyMaxCount || occupancyLimit) && (
              <BreakdownSummaryItem
                title={"Occupancy"}
                badgeValue={capacityPercentage == null ? null : capacityPercentage * 100}
              >
                {occupancyMaxCount ?? 0}
                {Boolean(occupancyLimit) && `/${occupancyLimit}`}
              </BreakdownSummaryItem>
            )}
            {capacityPercentage !== null && (
              <BreakdownSummaryItem
                title={"Capacity %"}
                badgeValue={capacityPercentage == null ? null : capacityPercentage * 100}
              >
                {numeral(capacityPercentage).format("0%")}
              </BreakdownSummaryItem>
            )}
            <BreakdownSummaryItem
              title={"Usage %"}
              badgeValue={usagePercentage == null ? null : usagePercentage * 100}
            >
              {numeral(usagePercentage).format("0%")}
            </BreakdownSummaryItem>
          </Stack>
        )}

        <Box position="relative">
          <HeatMap<IOccupancyBreakdownDataPoint> {...heatmapProps} TooltipDisplay={TooltipDisplay} />

          {breakdownLoading && (
            <Center
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <Spinner mr={2} /> Loading...
            </Center>
          )}
        </Box>
      </OccupancyBreakdownCardWrapper>
    </>
  );
};
