/* eslint-disable @typescript-eslint/no-explicit-any */
import { getPersistedTokens } from "../auth/authPersist";
import { LoginErrorResponse, LoginSuccessResponse } from "../types/LoginSuccessResponse";
import APIService from "./api-service";
import { baseURL, appClientDetails } from "./api-types";
import * as Sentry from '@sentry/react';

export class NotFoundError extends Error {
  private is404: boolean;
  constructor(...args: any[]) {
    super(...args);
    this.is404 = true;
  }
}
const switchOrganisation = (
  refreshToken: string,
  organisationId: string
): Promise<LoginSuccessResponse | LoginErrorResponse> => {
  const body = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    scope: `organisationId=${organisationId}`,
    ...appClientDetails,
  };
  return fetch(`${baseURL}/oauth/token`, {
    method: "POST",
    credentials: "same-origin", // for westpac proxy
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((r) => r.json())
    .catch((e) => {
      Sentry.captureException(e);
      throw new Error("Unable to switch org");
    });
};

const getCustomerAuth = (
  refreshToken: string,
  customerId: string
): Promise<LoginSuccessResponse | LoginErrorResponse> => {
  const body = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    scope: `customerId=${customerId}`,
    ...appClientDetails,
  };
  return fetch(`${baseURL}/oauth/token`, {
    method: "POST",
    credentials: "same-origin", // for westpac proxy
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((r) => r.json())
    .catch((e) => {
      Sentry.captureException(e);
      throw new Error("Unable to switch org");
    });
};

const makeApiService = () => {
  const { token, refreshToken, scope } = getPersistedTokens();

  const _api = new APIService();
  if (token) {
    _api.setToken(token);
  }

  if (refreshToken) {
    _api.setRefreshToken(refreshToken);
  }

  if (scope) {
    _api.setScope(scope);
  }

  return _api;
};

const api = makeApiService();

export { api, switchOrganisation, getCustomerAuth };
