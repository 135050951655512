import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RoomWithLocationReadings } from "@tether-web-portal/types/Room";
import { UserPinnedEntityType } from "@tether-web-portal/types/UserPinnedEntity";
import { isSampleReallyOld } from "@tether-web-portal/utils/isSampleRecent";
import { PinButton } from "../../button/PinButton";
import { RoomIcon } from "../../create-room/room-icon/RoomIcon";
import { OrganisationTagsDisplay } from "../../organisation-tag/OrganisationTagsDisplay";
import { ComfortScoreTag } from "../../property/ComfortScoreTag";
import { ManageRoomMenu } from "../../property/ManageRoomMenu";
import { RoomLocationReading } from "../room-location-reading/RoomLocationReading";
import { RouterLink } from "@tether-web-portal/components/common";
import { useNavigate } from "react-router-dom";

export const AREA_CARD_VERTICAL_PADDING = 4;
export const AREA_CARD_HORIZONTAL_PADDING = 4;

interface InsideDetailsProps {
  room: RoomWithLocationReadings;
}

export const InsideDetails = ({ room }: InsideDetailsProps) => {
  const navigate = useNavigate();

  const { roomLocationsWithDevice, roomLocationsWithNoDevices, hasDevicesWithReadings } = useMemo(
    () => ({
      roomLocationsWithDevice: room.roomLocations.filter((l) => l.device),
      roomLocationsWithNoDevices: room.roomLocations.filter((l) => !l.device),
      hasDevicesWithReadings: room.roomLocations.some((rl) => rl.device?.lastSample),
    }),
    [room.roomLocations]
  );

  return (
    <Box
      borderWidth={1}
      borderColor="gray.50"
      key={room.id}
      py={AREA_CARD_VERTICAL_PADDING}
      shadow="lg"
      mb="8px"
      borderRadius={6}
      bg="white"
    >
      <Flex
        px={AREA_CARD_HORIZONTAL_PADDING}
        dir="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={6}
      >
        <Flex flexShrink={0}>
          <RoomIcon type={room.type} />
          <RouterLink
            ml={2}
            mb={roomLocationsWithDevice.length > 0 ? 2 : 1}
            fontWeight={700}
            to={`/area/room/${room.id}`}
          >
            {room.name}
          </RouterLink>
        </Flex>
        <Flex alignItems="center" columnGap={2}>
          <OrganisationTagsDisplay tagIds={room.tagIds} overflow="grouped" />

          {roomLocationsWithDevice.length > 0 &&
            roomLocationsWithDevice[0].insights?.comfortScore !== undefined &&
            !isSampleReallyOld(roomLocationsWithDevice[0].device?.lastSample) && (
              <ComfortScoreTag comfortScore={roomLocationsWithDevice[0].insights?.comfortScore} />
            )}

          {roomLocationsWithDevice.length > 0 && (
            <PinButton
              entityId={room.id}
              entityType={UserPinnedEntityType.Room}
              propertyId={room.propertyId}
              size={21}
            />
          )}
          {hasDevicesWithReadings && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigate(`/area/room/${room.id}`)}
              mx={2}
              flexShrink={0}
            >
              View Readings
              <Icon boxSize={6} as={ChevronRightIcon} />
            </Button>
          )}
          <ManageRoomMenu
            roomId={room.id}
            propertyId={room.propertyId}
            roomName={room.name}
            hasDevice={roomLocationsWithDevice.length > 0}
          />
        </Flex>
      </Flex>

      {roomLocationsWithDevice.map((l) => (
        <Box key={`room-location-row-${l.id}`} className="room-location-row chakra-button">
          <RoomLocationReading
            showCovidRiskLevel={true}
            roomLocation={l}
            device={l.device}
            locationType="ROOMLOCATION"
            propertyId={room.propertyId}
            showRoomName={roomLocationsWithDevice.length > 1 || room.name !== l.name}
          />
        </Box>
      ))}
      {roomLocationsWithNoDevices.map((l) => (
        <Box key={`room-location-row-${l.id}`} className="room-location-row chakra-button">
          <RoomLocationReading
            showCovidRiskLevel={true}
            roomLocation={l}
            device={l.device}
            locationType="ROOMLOCATION"
            propertyId={room.propertyId}
            showRoomName={roomLocationsWithDevice.length > 1 || room.name !== l.name}
          />
        </Box>
      ))}
    </Box>
  );
};
