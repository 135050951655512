import { Group } from "@visx/group";
import { Line } from "@visx/shape";

const sharedLineProps = {
  stroke: "var(--chakra-colors-gray-50)",
  strokeWidth: 2,
};

export const ViewboxBorder = ({ xMax, yMax }: { xMax: number; yMax: number }) => {
  return (
    <Group data-name={"viewbox-border"}>
      <Line
        from={{
          x: 0,
          y: 0,
        }}
        to={{
          x: 0,
          y: yMax,
        }}
        {...sharedLineProps}
      />

      <Line
        from={{
          x: 0,
          y: yMax,
        }}
        to={{
          x: xMax,
          y: yMax,
        }}
        {...sharedLineProps}
      />

      <Line
        from={{
          x: xMax,
          y: 0,
        }}
        to={{
          x: xMax,
          y: yMax,
        }}
        {...sharedLineProps}
      />
    </Group>
  );
};
