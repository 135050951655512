import { FunctionComponent, useMemo } from "react";
import styles from "./styling/OccupancyUtilisationTableRow.module.scss";

import { Button, HStack, Td, Tr } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa6";

import {
  IOccupancyUtilisationRow,
  OccupancyUtilisationRowType,
  OccupancyUtilisationSpaceChildType,
} from "@tether-web-portal/web-workers/occupancy";
import { PropertyIcon } from "@tether-web-portal/components/create-property/property-icons/PropertyIcon";
import { RoomIcon } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";
import { RouterLink, Typography } from "@tether-web-portal/components/common";
import { DeviceIcon } from "@tether-web-portal/components/device/DeviceIcon";
import { classNames } from "@tether-web-portal/utils";

import { OccupancySpaceNameLink } from "./OccupancySpaceNameLink";
import { OccupancyUtilisationIndicator } from "./OccupancyUtilisationIndicator";
import { OccupancyUtilisationChildTableRow } from "./OccupancyUtilisationChildTableRow";
import { EmptyTableDataBlock } from "./EmptyTableDataBlock";
import { OccupancyUsageFrequencyIndicator } from "./OccupancyUsageFrequencyIndicator";

interface IOccupancyUtilisationTableRowProps {
  row: IOccupancyUtilisationRow;
  showTotalInOut?: boolean;
  showDwellTime?: boolean;
  isLiveView?: boolean;
}
export const OccupancyUtilisationTableRow: FunctionComponent<IOccupancyUtilisationTableRowProps> = (
  props: IOccupancyUtilisationTableRowProps
) => {
  const { row, isLiveView, showDwellTime, showTotalInOut } = props;

  const { shouldShowChildren, renderOnlyChildAsRoom } = useMemo(() => {
    const shouldShowChildren =
      (row.spaceChildren ?? []).length > 1 ||
      row.spaceChildren?.some((x) => x.childType === OccupancyUtilisationSpaceChildType.REGION);

    const renderOnlyChildAsRoom =
      shouldShowChildren &&
      row.spaceChildren.length === 1 &&
      row.spaceChildren[0].childType === OccupancyUtilisationSpaceChildType.REGION;

    return {
      shouldShowChildren,
      renderOnlyChildAsRoom,
    };
  }, [row]);

  return (
    <>
      <Tr
        className={classNames(styles.occupancyUtilisationTableRow, styles[row.rowType], {
          [styles.hidden]: renderOnlyChildAsRoom,
        })}
      >
        {/* TYPE */}
        <Td>
          {row.rowType === OccupancyUtilisationRowType.BUILDING ? (
            <PropertyIcon type="commercial" size={23} />
          ) : row.rowType === OccupancyUtilisationRowType.LEVEL ? (
            <FaArrowRight />
          ) : row.spaceType ? (
            <RoomIcon type={row.spaceType} />
          ) : null}
        </Td>

        {/* SPACE NAME */}
        <Td>
          <OccupancySpaceNameLink utilisationRow={row}>
            <Typography
              size={row.rowType !== OccupancyUtilisationRowType.ROOM ? "lg" : "md"}
              fontWeight={row.rowType !== OccupancyUtilisationRowType.ROOM ? "semibold" : "normal"}
            >
              {row.spaceName}
            </Typography>
          </OccupancySpaceNameLink>
        </Td>

        {/* USAGE FREQUENCY */}
        <Td>
          <OccupancyUsageFrequencyIndicator usage={row.usage} isLiveView={isLiveView} />
        </Td>

        {/* OCCUPANCY */}
        <Td>
          <HStack columnGap={0.5} alignItems="center" className={styles.indicator}>
            {!row.spaceCapacity && row.spaceId ? (
              <RouterLink to={`/area/room/${row.spaceId}/edit`} className={styles.tableLink}>
                <Button variant="secondary" size="sm">
                  Add Capacity
                </Button>
              </RouterLink>
            ) : row.isCapacityAble && row.capacityUsage && row.capacityUsage.percentage !== null ? (
              <OccupancyUtilisationIndicator
                percentage={row.capacityUsage.percentage}
                utilisationLevel={row.capacityUsage.utilisationLevel}
                latestCount={row.capacityUsage.latestCount ?? undefined}
                maxSpace={row.spaceCapacity}
                displayType={isLiveView ? "COUNTS" : "PERCENTAGE"}
              />
            ) : null}
          </HStack>
        </Td>

        {/* PEOPLE COUNT (MIN - MAX - AVG) */}
        {isLiveView ? (
          <>
            <Td>{row.capacityUsage?.entered ?? ""}</Td>
            <Td>{row.capacityUsage?.exited ?? ""}</Td>
            <Td>{row.capacityUsage?.footTraffic ?? ""}</Td>
          </>
        ) : (
          <>
            <Td>{row?.occupants?.min ?? ""}</Td>
            <Td>{row?.occupants?.max ?? ""}</Td>
            <Td>{row?.occupants?.mean ?? ""}</Td>
          </>
        )}

        {/* DWELL TIME (MIN - MAX - AVG) */}
        {showDwellTime && <EmptyTableDataBlock emptyBlocksCount={3} />}

        {/* TOTAL IN & OUT */}
        {showTotalInOut && (
          <>
            <Td>{row.capacityUsage?.entered ?? ""}</Td>
            <Td>{row.capacityUsage?.exited ?? ""}</Td>
          </>
        )}

        {/* DEVICES */}
        <Td>
          {row.devices.length < 1 ? null : (
            <HStack rowGap={1} alignItems="center">
              {row.devices.map((x) => (
                <HStack alignItems="center" key={x.type}>
                  <DeviceIcon type={x.type} size={24} />
                  <Typography>{x.count}</Typography>
                </HStack>
              ))}
            </HStack>
          )}
        </Td>
      </Tr>

      {/* CHILD ROWS */}
      {shouldShowChildren &&
        (row.spaceChildren ?? []).map((childRow, index) => (
          <OccupancyUtilisationChildTableRow
            key={index}
            row={childRow}
            parentRow={row}
            renderAsRoom={renderOnlyChildAsRoom}
            showDwellTime={showDwellTime}
            showTotalInOut={showTotalInOut}
            isLiveView={isLiveView}
          />
        ))}
    </>
  );
};
