import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { getColorByPercentage } from "../../occupancy.colors";

export interface OccupancyBarData {
  id?: number;
  label: string;
  value: number;
  tooltip?: string;
}

interface OccupancyBarChartProps {
  data: OccupancyBarData[];
  selectedBar?: number;
  max?: number;
  width: number;
  height: number;
  enableXLabels?: boolean;
}

const SMALL_BAR_MAX_ITEM = 7;
const SMALL_BAR_WIDTH = 18; //18px

const OccupancyBarChart = ({
  data,
  selectedBar,
  width,
  height,
  max = 110,
  enableXLabels = true,
}: OccupancyBarChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const margin = { top: 10, right: 0, bottom: enableXLabels ? 20 : 5, left: 0 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    let isOverMax = false;
    const sanitiseData = data.map((item) => {
      let value = item.value;
      if (value < 5) {
        value = 5; // For display purposes, no smaller than 5
      }
      if (value > max) {
        value = max;
        isOverMax = true;
      }
      return {
        ...item,
        value,
      };
    });

    const yMax = isOverMax ? max : Math.max(100, d3.max(sanitiseData, (d) => d.value) || 0);

    const x = d3
      .scaleBand<string>()
      .domain(sanitiseData.map((d) => d.label))
      .range([0, chartWidth])
      .padding(0.5);

    const y = d3.scaleLinear<number>().domain([0, yMax]).nice().range([chartHeight, 0]);

    svg.selectAll("*").remove();

    const chart = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    if (enableXLabels) {
      chart
        .append("g")
        .attr("transform", `translate(0,${chartHeight})`)
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")
        .style("font-family", "Inter")
        .style("line-height", "16")
        .style("font-weight", "400")
        .style("font-size", "12px")
        .style("fill", "#595F6A")
        .attr("transform", "translate(0,8)");

      chart.select(".domain").remove();
    }

    chart.append("g").call(
      d3
        .axisLeft(y)
        .tickSize(0)
        .tickFormat(() => "")
    );
    chart.select(".domain").remove();

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background-color", "#333")
      .style("color", "#fff")
      .style("padding", "5px")
      .style("border-radius", "4px")
      .style("font-size", "12px")
      .style("pointer-events", "none");

    const hasSmallDataSet = data.length <= SMALL_BAR_MAX_ITEM;
    const barWidth = hasSmallDataSet ? SMALL_BAR_WIDTH : Math.max(0, chartWidth / data.length - 5);

    // Add bars with animation
    const bars = chart
      .selectAll(".bar")
      .data(sanitiseData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.label)!)
      .attr("y", chartHeight) // Start bars at the bottom of the chart
      .attr("width", barWidth)
      .attr("height", 0) // Start with zero height
      .attr("fill", (d) => getColorByPercentage(d.value))
      .attr("rx", 8)
      .attr("ry", 8)
      .on("mouseover", function (event, d) {
        if (d.tooltip) {
          tooltip.style("visibility", "visible").text(`${d.tooltip}`);
        }
      })
      .on("mousemove", function (event) {
        tooltip.style("top", event.pageY + 10 + "px").style("left", event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        tooltip.style("visibility", "hidden");
      });

    // Animate bars to their final position and height
    bars
      .transition()
      .duration(500)
      .ease(d3.easeCubicOut)
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => chartHeight - y(d.value))
      .on("end", () => {
        if (selectedBar !== undefined) {
          chart.selectAll(".bar").each(function (_, i) {
            const bar = d3.select(this);
            if (i === selectedBar) {
              const barX = bar.attr("x");
              const barY = bar.attr("y");
              const barWidth = bar.attr("width");
              const barHeight = bar.attr("height");

              const addedSpace = hasSmallDataSet ? 8 : 6;

              let highlight: d3.Selection<SVGRectElement, unknown, null, undefined> = svg.select<
                SVGRectElement
              >("#selected-bar");
              if (highlight.empty()) {
                // Append only if it doesn't already exist
                highlight = svg.append<SVGRectElement>("rect").attr("id", "selected-bar");
              }

              highlight
                .attr("x", parseFloat(barX) - (hasSmallDataSet ? 4 : 3))
                .attr("y", parseFloat(barY) + (hasSmallDataSet ? 6 : 8))
                .attr("width", parseFloat(barWidth) + addedSpace)
                .attr("height", parseFloat(barHeight) + addedSpace)
                .attr("rx", 16)
                .attr("ry", 16)
                .attr("stroke", "#4045F8")
                .attr("stroke-width", 1.5)
                .attr("fill", "none");
            }
          });

          chart
            .selectAll("text")
            .filter((_, i) => i === selectedBar)
            .style("font-weight", "900")
            .style("font-size", "13px");
        }
      });
  }, [data, width, height, selectedBar, enableXLabels, max]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};

export default OccupancyBarChart;
