import { makeFloorName } from "@tether-web-portal/utils/makeFloorName";

interface LevelIndexable {
  floorIndex: number;
}

export const makeRoomsByLevel = <T extends LevelIndexable>(
  r: T[]
): { rooms: T[]; floorIndex: number; levelLabel: string }[] => {
  const rooms = [...r];
  const visibleRooms = rooms.sort((a, b) => {
    return a.floorIndex - b.floorIndex;
  });

  const floorsByRooms: { rooms: T[]; floorIndex: number; levelLabel: string }[] = [];

  const levels = Array.from(new Set(visibleRooms.map((r) => r.floorIndex)));

  levels.forEach((floorIndex) => {
    floorsByRooms.push({
      floorIndex,
      levelLabel: makeFloorName(floorIndex),
      rooms: visibleRooms.filter((r) => r.floorIndex === floorIndex),
    });
  });

  const sortedFloorsByRooms = floorsByRooms.sort((a, b) => (a.floorIndex > b.floorIndex ? 1 : -1)); //Sort the floors by by level

  return sortedFloorsByRooms.filter((r) => r.rooms.length);
};
