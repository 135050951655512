const PeakHourIcon = ({ size, color = "#595F6A" }: { size: number; color: string }) => (
  <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.219 10.007C21.7041 5.49216 14.384 5.49215 9.86914 10.007C5.35426 14.5218 5.35426 21.842 9.86913 26.3568C14.384 30.8717 21.7041 30.8717 26.2189 26.3568C30.7338 21.842 30.7338 14.5218 26.219 10.007ZM27.026 9.19989L26.6225 9.60343L27.026 9.1999C31.9865 14.1605 31.9866 22.2033 27.026 27.1638C22.0654 32.1245 14.0227 32.1245 9.06207 27.1638C4.10146 22.2033 4.10146 14.1605 9.06207 9.1999L9.06207 9.19989C14.0227 4.23936 22.0654 4.23936 27.026 9.19989ZM18.044 9.04103C18.3592 9.04103 18.6147 9.29653 18.6147 9.61171V16.8041L21.9762 13.4427C22.1991 13.2198 22.5604 13.2198 22.7833 13.4427C23.0061 13.6656 23.0061 14.0269 22.7833 14.2498L18.4476 18.5854C18.2247 18.8083 17.8634 18.8083 17.6405 18.5854C17.4993 18.4442 17.4476 18.2475 17.4853 18.0655C17.4775 18.0279 17.4734 17.9889 17.4734 17.9489V9.61171C17.4734 9.29653 17.7289 9.04103 18.044 9.04103Z"
      fill={color}
    />
  </svg>
);

export default PeakHourIcon;
