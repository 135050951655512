import { useRef, useEffect, RefObject } from "react";

const getScrollParent = (node: Element): Element | undefined => {
  const regex = /(auto|scroll)/;
  const parents = (_node: Element, ps: Element[]): Element[] => {
    if (_node.parentElement === null) { return ps; }
    return parents(_node.parentElement, ps.concat([_node]));
  };

  const style = (_node: Element, prop: string) => getComputedStyle(_node, null).getPropertyValue(prop);
  const scroll = (_node: Element) => {
    return regex.test(
      style(_node, 'overflow') +
      style(_node, 'overflow-y') +
      style(_node, 'overflow-x')
    );
  };

  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return;
  }
  if (!node.parentElement) {
    return;
  }

  const ps = parents(node.parentElement, []);

  for (let i = 0; i < ps.length; i += 1) {
    if (scroll(ps[i])) {
      return ps[i];
    }
  }

  return document.scrollingElement || document.documentElement;
};

export const useSVGWheelListener = (
  ref: RefObject<SVGElement>,
  {
    viewWidth,
    viewHeight,
    scrollLeft,
    scrollTop,
    scrollWidth,
    scrollHeight
  }: {
    viewWidth: number,
    viewHeight: number,
    scrollTop: number,
    scrollLeft: number,
    scrollWidth: number,
    scrollHeight: number
  },
  cb: (offsetX: number, offsetY: number) => void
) => {

  const xMax = scrollWidth - viewWidth;
  const yMax = scrollHeight - viewHeight;

  const scrollParent = useRef<Element | undefined>();
  const onWheel = useRef<(wheelEvent: WheelEvent) => void | undefined>();
  onWheel.current = (wheelEvent: WheelEvent) => {
    if (!ref.current) return;
    if (!scrollParent.current) scrollParent.current = getScrollParent(ref.current);

    wheelEvent.preventDefault();

    const { deltaX, deltaY } = wheelEvent;

    let offsetX = scrollLeft + deltaX;
    offsetX = Math.max(0, Math.min(offsetX, xMax));

    let offsetY = scrollTop + deltaY;
    offsetY = Math.max(0, Math.min(offsetY, yMax));

    if ((xMax == offsetX || offsetX == 0) && scrollParent.current) {
      scrollParent.current.scrollLeft += wheelEvent.deltaX;
    }
    if ((yMax == offsetY || offsetY == 0) && scrollParent.current) {
      scrollParent.current.scrollTop += wheelEvent.deltaY;
    }

    cb(offsetX, offsetY);
  };

  useEffect(
    () => {
      const wheel = (e: WheelEvent) => {
        return onWheel.current?.(e);
      };
      const node = ref.current;
      node?.addEventListener('wheel', wheel);
      return () => {
        node?.removeEventListener('wheel', wheel);
      }
    },
    [ref]
  );
}
