import { ScaleLinear } from "d3";
import { FC } from "react";

export class AxisConfig {
  title: number;
  gap: number;
  label: number;
  innerMargin: number;
  lineHeight: number;

  constructor(props: {
    title: number;
    gap: number;
    label: number;
    innerMargin: number;
    lineHeight?: number;
  }) {
    this.title = props.title;
    this.gap = props.gap;
    this.label = props.label;
    this.innerMargin = props.innerMargin;
    this.lineHeight = props.lineHeight || 0;
  }

  get total() {
    return this.title + this.gap + this.label + this.innerMargin;
  }
}

export type TooltipDisplayComponent<T> = FC<{
  data?: T;
  value?: number;
  colorScale: ScaleLinear<string, string, never>;
  maxValue?: number;
}>;

type ChartBenchmarkTypeBase = {
  label: string;
  color: string;
  labelColor?: string;
};
export type ChartBenchmarkTypeRange = ChartBenchmarkTypeBase & {
  type: "range";
  range: [number, number];
};
export type ChartBenchmarkTypeLine = ChartBenchmarkTypeBase & {
  type: "line";
  value: number;
};
export type ChartBenchmarkTypeAbove = ChartBenchmarkTypeBase & {
  type: "above";
  value: number;
};
export type ChartBenchmarkTypeBelow = ChartBenchmarkTypeBase & {
  type: "below";
  value: number;
};
export type ChartBenchmarkType =
  | ChartBenchmarkTypeLine
  | ChartBenchmarkTypeRange
  | ChartBenchmarkTypeAbove
  | ChartBenchmarkTypeBelow;
