import { RoomType } from "@tether-web-portal/components/create-room/room-icon/RoomIcon";
import { DeviceType } from "@tether-web-portal/types/DeviceType";

import {
  IOccupancyFloor,
  IOccupancySpace,
  IOccupancyWorkerBaseProps,
  RoomLocationAggregatedData,
} from "./OccupancyWorker";

export enum OccupancyUtilisationLevels {
  LOW = "LOW",
  OPTIMAL = "OPTIMAL",
  OVER = "OVER",
}

export enum OccupancyUtilisationRowType {
  ROOM = "ROOM",
  LEVEL = "LEVEL",
  BUILDING = "BUILDING",
}

export enum OccupancyUtilisationSpaceChildType {
  REGION = "REGION",
  LINE = "LINE",
}

export interface IOccupancyUtilisationUsage {
  percentage: number;
  utilisationLevel: OccupancyUtilisationLevels;
  isOccupied: boolean;
}

export interface IOccupancyUtilisationSpaceChildRow {
  name: string;
  childType: OccupancyUtilisationSpaceChildType;
  occupants: {
    min: number;
    max: number;
    mean: number;
    entered: number;
    exited: number;
  };
  dwellTime: {
    min: number;
    max: number;
    mean: number;
  };
  usage: IOccupancyUtilisationUsage;
}

export interface IOccupancyUtilisationRow {
  rowType: OccupancyUtilisationRowType;
  spaceName: string;
  spaceType?: RoomType;
  spaceId?: string;
  spaceFloorIndex?: number;
  usage: IOccupancyUtilisationUsage;
  spaceCapacity?: number;
  isCapacityAble?: boolean;
  capacityUsage?: {
    percentage: number | null;
    latestCount: number | null;
    entered: number | null;
    exited: number | null;
    footTraffic: number | null;
    utilisationLevel: OccupancyUtilisationLevels;
  };
  occupants: {
    min: number;
    max: number;
    mean: number;
  };
  devices: {
    type: DeviceType;
    count: number;
  }[];
  spaceChildren: IOccupancyUtilisationSpaceChildRow[];
}

export interface IOccupancyUtilisationTableFilters {
  displayBy: "ALL" | "LEVEL" | "ROOM";
  type?: RoomType;
  usage?: OccupancyUtilisationLevels;
  capacity?: OccupancyUtilisationLevels;
  sortBy: "DEFAULT" | "BUSIEST" | "LOWEST";
}

export interface IOccupancyUtilisationWorkerProps extends IOccupancyWorkerBaseProps {
  isLiveView: boolean;
  roomLocationAggregations: RoomLocationAggregatedData[];
  tableFilters?: IOccupancyUtilisationTableFilters;
}

export interface IOccupancyUtilisationWorkerOutput {
  tableData: IOccupancyUtilisationRow[];
  availableRoomTypes: RoomType[];
  availableSpaces: IOccupancySpace[];
  availableFloors: IOccupancyFloor[];
}
