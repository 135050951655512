import { HorizontalBarChartYScale } from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import {
  AxisConfig,
  ChartBenchmarkType,
  ChartBenchmarkTypeAbove,
  ChartBenchmarkTypeBelow,
  ChartBenchmarkTypeLine,
  ChartBenchmarkTypeRange,
} from "@tether-web-portal/components/charts/types";
import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text, getStringWidth } from "@visx/text";
import { extent, max, min } from "d3";
import { ComponentProps, useMemo } from "react";

interface BenchmarksProps {
  xMax: number;
  yMax: number;
  maxValue: number;
  yScale: HorizontalBarChartYScale;
  yAxisWidthConfig: AxisConfig;
  benchmarks?: ChartBenchmarkType[];
}

const labelStyles = {
  fontSize: "var(--chakra-fontSizes-xs)",
  fontWeight: 500,
  fontFamily: "var(--chakra-fonts-body)",
};

const Label = (props: ComponentProps<typeof Text>) => <Text {...labelStyles} {...props} />;

const BenchmarkLine = ({
  xMax,
  yMax,
  maxValue,
  yScale,
  yAxisWidthConfig,
  benchmark: { value, color, label, labelColor },
}: BenchmarksProps & { benchmark: ChartBenchmarkTypeLine }) => {
  const labelWidth = useMemo(
    () => getStringWidth(label, { ...labelStyles, lineHeight: yAxisWidthConfig.lineHeight }) || 0,
    [label, yAxisWidthConfig.lineHeight],
  );

  if (value == maxValue) return null;
  return (
    <Group top={yScale(value)}>
      <Group left={yAxisWidthConfig.total}>
        <Group left={8}>
          <Label
            lineHeight={yAxisWidthConfig.lineHeight}
            fill={labelColor}
            textAnchor={"start"}
            verticalAnchor={"middle"}
            width={yAxisWidthConfig.label}
          >
            {label}
          </Label>
        </Group>
        <Group left={labelWidth + 16}>
          <Line to={{ x: xMax }} stroke={color} strokeDasharray={4} />
        </Group>
      </Group>
    </Group>
  );
};

const BenchmarkRange = ({
  xMax,
  yMax,
  maxValue,
  yScale,
  yAxisWidthConfig,
  benchmark: { range, color, label, labelColor },
}: BenchmarksProps & { benchmark: ChartBenchmarkTypeRange }) => {
  const [min, max] = extent(range);
  if (!min || !max) return null;

  const y1 = yScale(max);
  const y2 = yScale(min);
  const height = y2 - y1;
  if (!height) return null;
  return (
    <Group top={y1}>
      <Group left={yAxisWidthConfig.total}>
        <Group left={8} top={6 + yAxisWidthConfig.lineHeight / 2}>
          <Label
            lineHeight={yAxisWidthConfig.lineHeight}
            fill={labelColor}
            textAnchor={"start"}
            verticalAnchor={"middle"}
            width={yAxisWidthConfig.label}
          >
            {label}
          </Label>
        </Group>
        <rect fill={color} opacity={0.1} height={height} width={xMax} />
      </Group>
    </Group>
  );
};

const BenchmarkAbove = ({
  yScale,
  benchmark,
  ...props
}: BenchmarksProps & { benchmark: ChartBenchmarkTypeAbove }) => {
  const { value, ...rest } = benchmark;
  const range: [number, number] = [value, max(yScale.domain()) || value];
  const benchmarkRange: ChartBenchmarkTypeRange = {
    ...rest,
    type: "range",
    range,
  };
  return <BenchmarkRange {...props} yScale={yScale} benchmark={benchmarkRange} />;
};

const BenchmarkBelow = ({
  yScale,
  benchmark,
  ...props
}: BenchmarksProps & { benchmark: ChartBenchmarkTypeBelow }) => {
  const { value, ...rest } = benchmark;
  const range: [number, number] = [min(yScale.domain()) || value, value];
  const benchmarkRange: ChartBenchmarkTypeRange = {
    ...rest,
    type: "range",
    range,
  };
  return <BenchmarkRange {...props} yScale={yScale} benchmark={benchmarkRange} />;
};

export const HorizontalBarChartBenchmarks = (props: BenchmarksProps) => {
  const { benchmarks } = props;
  return (
    <Group>
      {benchmarks?.map((benchmark, i) => {
        const { type } = benchmark;
        switch (type) {
          case "line":
            return <BenchmarkLine key={i} {...props} benchmark={benchmark} />;
          case "range":
            return <BenchmarkRange key={i} {...props} benchmark={benchmark} />;
          case "above":
            return <BenchmarkAbove key={i} {...props} benchmark={benchmark} />;
          case "below":
            return <BenchmarkBelow key={i} {...props} benchmark={benchmark} />;
        }
      })}
    </Group>
  );
};
