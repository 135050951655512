export enum DATE_FORMATS {
  HOUR_24 = "HH",
  HOUR_12 = "ha",

  TIME_24_HOUR = "HH:mm",
  TIME_12_HOUR = "h:mma",

  TIME_WITH_SECONDS_12_HOUR = "hh:mm:ssa",

  DAY_FULL = "dddd",
  DAY_SHORT = "ddd",
  DAY = "DD",

  MONTH_FULL = "MMMM",
  MONTH_SHORT = "MMM",
  MONTH = "MM",

  YEAR = "YYYY",

  MONTH_YEAR_SLASHED = "MM/YYYY",
  DAY_MONTH_YEAR_SLASHED = "DD/MM/YYYY",
  DAY_MONTH_YEAR_TIME_24_HOUR_SLASHED = "DD/MM/YYYY HH:mm",
  DAY_MONTH_YEAR_TIME_12_HOUR_SLASHED = "DD/MM/YYYY h:mma",
  DAY_MONTH_YEAR_HOUR_12_HOUR_SLASHED = "DD/MM/YYYY HH a",
  FULL_DATE_TIME_24_HOUR_SLASHED = "DD/MM/YYYY HH:mm:ss",

  YEAR_MONTH_DAY_TIME_24_HOUR_DASHED = "YYYY-MM-DD HH:mm",
  YEAR_MONTH_DAY_DASHED = "YYYY-MM-DD",
  YEAR_MONTH_DASHED = "YYYY-MM",
  FULL_DATE_TIME_24_HOUR_DASHED = "YYYY-MM-DD HH:mm:ss",
  DAY_MONTH_YEAR_DASHED = "DD-MM-YYYY",

  DAY_MONTH_YEAR_SPACED = "DD MM YYYY",
  DAY_MONTH_SHORT_YEAR_SPACED = "DD MMM YYYY",
  DAY_MONTH_FULL_YEAR_SPACED = "DD MMMM YYYY",
  DAY_MONTH_SHORT_SPACED = "DD MMM",
  MONTH_FULL_YEAR_SPACED = "MMMM YYYY",
  MONTH_SHORT_YEAR_SPACED = "MMM YYYY",
  MONTH_SHORT_YEAR_SHORT_SPACED = "MMM YY",

  DAY_FULL_MONTH_SHORT_TIME_12_HOUR_COMMA = "dddd, MMM D, h:mma",
  MONTH_FULL_DAY_YEAR_TIME_12_HOUR_COMMA = "MMMM DD, YYYY h:mma",
}
