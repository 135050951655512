import numeral from "numeral";
import { OrgSettings } from "@tether-web-portal/api/auth/OrgSettingService";
import { CurrencyCode } from "@tether-web-portal/types/CurrencyCode";
import { useAuth } from "../../lib/auth/useAuth";

interface CurrencyFormatProps {
  value?: number;
  emptyState?: string;
  maximumFractionDigits?: number;
  currencyOverride?: CurrencyCode;
}

export function getCurrency(orgSettings: OrgSettings) {
  const currency = (orgSettings.CURRENCY as CurrencyCode) || "NZD";
  return currency;
}

export function useGetCurrency() {
  const { orgSettings } = useAuth();
  const currency = (orgSettings.CURRENCY as CurrencyCode) || "NZD";
  return currency;
}

export function formatAsCurrency(
  value: number | string | undefined,
  currency: string,
  maximumFractionDigits = 2
) {
  if (value === undefined) return "--";

  const language = navigator?.languages.length > 0 ? navigator.languages[0] : "en-GB";

  return value.toLocaleString(language, {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 2,
    maximumFractionDigits,
    currency,
  });
}

export function getCurrencySymbol(currencyCode: CurrencyCode): string {
  const language = navigator?.languages.length > 0 ? navigator.languages[0] : "en-GB";

  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: currencyCode,
    minimumFractionDigits: 0,
  });

  // Use the formatToParts method to extract the currency symbol
  const parts = formatter.formatToParts(1);
  return parts.find((part) => part.type === "currency")?.value || "$";
}

export default function CurrencyFormat({
  value,
  emptyState,
  maximumFractionDigits,
  currencyOverride,
}: CurrencyFormatProps) {
  const companyCurrency = useGetCurrency();
  const currency = currencyOverride || companyCurrency;

  if (value === undefined || isNaN(value)) {
    return <>{emptyState !== undefined ? emptyState : "--"}</>;
  }

  const formatted = formatAsCurrency(value, currency, maximumFractionDigits);

  return <>{formatted}</>;
}
