import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  theme,
  Tooltip,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import SignalStrengthIndicator from "../../indicators/SignalStrengthIndicator";
import { GraphColor } from "@tether-web-portal/types/Room";
import {
  QueryKeys,
  useGetPropertyDetail,
  useUpdateCircuitMutation,
} from "@tether-web-portal/api/properties/hooks";
import { RoomColorItem, RoomColorPicker } from "../../create-room/RoomColorPicker";
import {
  CircuitWithReading,
  getCircuitDeviceConnectionStatus,
  CircuitDeviceConnectionStatus,
} from "@tether-web-portal/types/Circuit";
import { BatteryLevel } from "../room-location-reading/BatteryLevel";
import ChargingIcon from "./ChargingIcon";
import { useQueryClient } from "react-query";
import { PrimaryButton } from "../../button/PrimaryButton";
import { CircuitNode } from "@tether-web-portal/types/CircuitNode";
import { formatCircuitPhaseTitle } from "./formatCircuitPhaseTitle";
import DeviceBaseDetailTable from "../device/DeviceBaseDetailTable";
import { formatDeviceName } from "@tether-web-portal/utils/formatDeviceName";
import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { DeviceIcon } from "../../device/DeviceIcon";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

interface CircuitModalModalProps {
  isOpen: boolean;
  onClose: () => void;
  circuit: CircuitWithReading;
  circuitNode?: CircuitNode;
  propertyId: string;
}
const PADDING_HORIZONTAL = "24px";

export const CircuitModal = ({ isOpen, circuit, propertyId, onClose }: CircuitModalModalProps) => {

  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const updateLocationmutation = useUpdateCircuitMutation();

  const [shouldEditColor, setShouldEditColor] = useState(false);
  const [editedColor, setEditedColor] = useState<GraphColor>(circuit.color);

  const { propertyInfo } = useGetPropertyDetail(circuit.propertyId);

  const { image, deviceStatus, connectionStatus, offlineMessage, deviceType } = useMemo(() => {
    const image = circuit.image || propertyInfo?.circuits?.find((c) => c.id === circuit.id)?.image;
    const deviceStatus = circuit.device?.status;
    const deviceType = circuit.device?.type;
    const connectionStatus = deviceStatus ? getCircuitDeviceConnectionStatus(deviceStatus) : null;

    const deviceName = formatDeviceName(circuit.device?.type ?? "");
    let offlineMessage = `${deviceName} is offline`;
    if (deviceStatus?.signal && deviceStatus!.signal! < 0.2) {
      offlineMessage += " but also has low signal, messaging may be irregular";
    }

    return {
      image,
      deviceStatus,
      deviceType,
      connectionStatus,
      offlineMessage,
    };
  }, [circuit, propertyInfo]);

  const handleSaveColor = (color: GraphColor) => {
    updateLocationmutation.mutate({
      propertyId,
      input: {
        id: circuit.id,
        propertyId: circuit.propertyId,
        color,
        phase: circuit.phase,
        name: circuit.name,
      },
      onSuccess: () => {
        setShouldEditColor(false);
        setEditedColor(color);
        queryClient.invalidateQueries([QueryKeys.PROPERTY, propertyId]);
        queryClient.invalidateQueries([QueryKeys.PROPERTY_POWER, propertyId]);
        queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, propertyId]);
        queryClient.invalidateQueries([QueryKeys.CIRCUIT, circuit.id]);
      },
    });
  };

  return (
    <Modal size="xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton mt={"2.5"} />
        <Flex mt={"12px"} ml={PADDING_HORIZONTAL} mr={"50px"} flexDir="row" justifyContent="space-between">
          <HStack>
            <RoomColorItem
              color={editedColor}
              isSelected={shouldEditColor}
              onClick={() => {
                setShouldEditColor(!shouldEditColor);
              }}
            />
            <DeviceIcon type={deviceType} />
            <HStack ml="4px">
              <Text
                fontSize="16px"
                color="gray.500"
                fontWeight={"bold"}
                overflow="hidden"
                textOverflow={"ellipsis"}
                maxWidth={300}
              >
                {circuit.name +
                  " - " +
                  formatCircuitPhaseTitle(circuit, deviceType === DeviceType.MOKO_LW005)}
              </Text>
            </HStack>
          </HStack>
          <Flex alignItems="center">
            {Boolean(circuit.device?.status.battery) && (
              <Box pl={2} pr={2} borderRadius={10} mr="4px">
                <BatteryLevel battery={circuit.device!.status.battery!} />
              </Box>
            )}

            {Boolean(circuit.device?.status.signal) && (
              <SignalStrengthIndicator signalStrength={circuit.device!.status.signal!} />
            )}
            {Boolean(connectionStatus && connectionStatus === CircuitDeviceConnectionStatus.ONLINE) && (
              <Tooltip placement="bottom" hasArrow label={connectionStatus!} aria-label={connectionStatus!}>
                <Box pl={4}>
                  <ChargingIcon />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        {shouldEditColor && (
          <Box px={5}>
            <RoomColorPicker onSelectColor={handleSaveColor} selectedColor={circuit.color} />
          </Box>
        )}

        <ModalBody display="flex" pt="8px" flexDirection="column" alignItems="center" px={0}>
          {Boolean(connectionStatus === CircuitDeviceConnectionStatus.OFFLINE) && (
            <Box
              p={3}
              w={"100%"}
              backgroundColor={theme.colors.red[400]}
              textColor="white"
              textAlign={"center"}
              borderRadius={0}
              fontSize={12}
            >
              {offlineMessage}
            </Box>
          )}

          <DeviceBaseDetailTable
            imageUrl={image}
            name={circuit.name}
            {...deviceStatus}
            {...circuit.device}
            firmwareVersion={deviceStatus?.oldFirmware ? "v1" : "v2"}
          />

          {!circuit.device && (
            <Box p={1} display="flex" flexDirection="column" alignItems="center">
              <Text p={5} pb={8}>
                No device found for this circuit, use the mobile app to configure a device.
              </Text>
            </Box>
          )}

          <Box>
            {Boolean(location.pathname != `/area/circuitnode/${circuit.circuitNodeId}`) && (
              <PrimaryButton
                onClick={() => navigate(`/area/circuitnode/${circuit.circuitNodeId}`)}
                text="View Circuit"
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
