import React, { ReactNode } from "react";
import { SidebarLayout } from "@tether-web-portal/components/sidebar/SidebarLayout";

interface AppLayoutProps {
  children: ReactNode;
  sideBar?: boolean;
}

const AppLayout = ({ children, sideBar = true }: AppLayoutProps) => {
  if (sideBar) {
    return <SidebarLayout>{children}</SidebarLayout>
  }

  return <>{children}</>;
}

export default AppLayout;