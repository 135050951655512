import { useCallback, useMemo, useState } from "react";
import ExcelJS, { Row } from "exceljs";
import dayjs from "dayjs";
import { DATE_FORMATS } from "@tether-web-portal/constants/dateFormats";
import { useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { useMetricsInterval } from "./useMetricsInterval";
import { OccupancyExportRoom } from "@tether-web-portal/web-workers/occupancy";

const reportColumns = [
  {
    header: "Date",
    key: "date",
    width: 20,
  },
  {
    header: "Min People",
    key: "min",
  },
  {
    header: "Max People",
    key: "max",
  },
  {
    header: "Avg People",
    key: "mean",
  },
  {
    header: "People In",
    key: "in",
  },
  {
    header: "People Out",
    key: "out",
  },
  {
    header: "Foot-Traffic",
    key: "traffic",
  },
];

const addSheet = (workbook: ExcelJS.Workbook, roomExport: OccupancyExportRoom, daily: boolean) => {
  const sheet = workbook.addWorksheet(`${daily ? "Daily" : "Hourly"} - ${roomExport.name}`);
  sheet.properties.defaultRowHeight = 20;
  sheet.properties.defaultColWidth = 20;
  sheet.properties.showGridLines = false;
  sheet.columns = reportColumns;
  sheet.addRows(roomExport.rows);

  sheet.eachRow((row: Row, rowNumber: number) => {
    row.font = { size: rowNumber === 1 ? 16 : 14 };
  });
};

export const useOccupancyExportData = () => {
  const { dataLoading, exportData, isLiveView } = useOccupancyContext();
  const { metricsTimeStamps } = useMetricsInterval();
  const [loading, setLoading] = useState<boolean>(false);

  const isExportAble = useMemo(() => {
    const hasDailyData = (exportData?.dailyData?.length ?? 0) > 0;
    const hasHourlyData = (exportData?.hourlyData?.length ?? 0) > 0;
    return (hasDailyData || hasHourlyData) && !isLiveView;
  }, [exportData?.dailyData, exportData?.hourlyData, isLiveView]);

  const onExportData = useCallback(async () => {
    if (!isExportAble || dataLoading || loading) {
      return;
    }

    setLoading(true);

    const workbook = new ExcelJS.Workbook();

    for (const roomExport of exportData?.dailyData || []) {
      addSheet(workbook, roomExport, true);
    }
    for (const roomExport of exportData?.hourlyData || []) {
      addSheet(workbook, roomExport, false);
    }

    const startDate = dayjs(metricsTimeStamps.fromTimestamp).format(DATE_FORMATS.DAY_MONTH_YEAR_DASHED);
    const endDate = dayjs(metricsTimeStamps.toTimestamp).format(DATE_FORMATS.DAY_MONTH_YEAR_DASHED);

    try {
      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Occupancy-report-${startDate}-${endDate}.xlsx`;
      anchor.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    isExportAble,
    dataLoading,
    loading,
    metricsTimeStamps.fromTimestamp,
    metricsTimeStamps.toTimestamp,
    exportData?.dailyData,
    exportData?.hourlyData,
  ]);

  return {
    onExportData,
    isExportAble,
    loading,
  };
};
