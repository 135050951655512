const SpacesIcon = ({ size, color = "#595F6A" }: { size: number; color: string }) => (
  <svg style={{ color }} width={size} height={size} viewBox="0 0 27 28" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M14.288 4.514c0-.234.19-.424.425-.424H23.2c.234 0 .424.19.424.424v8.488c0 .235-.19.425-.424.425h-8.488a.424.424 0 0 1-.425-.425V4.514Zm.849.425v7.639h7.64v-7.64h-7.64ZM3.375 15.427c0-.234.19-.424.424-.424h8.488c.235 0 .425.19.425.424v8.488c0 .235-.19.425-.425.425H3.8a.424.424 0 0 1-.424-.425v-8.488Zm.849.425v7.639h7.639v-7.64h-7.64Zm10.064-.425c0-.234.19-.424.425-.424H23.2c.234 0 .424.19.424.424v8.488c0 .235-.19.425-.424.425h-8.488a.424.424 0 0 1-.425-.425v-8.488Zm.849.425v7.639h7.64v-7.64h-7.64Z"
      clip-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M3.375 4.526c0-.24.195-.436.436-.436h8.72c.24 0 .436.195.436.436v8.72c0 .24-.195.436-.436.436h-8.72a.436.436 0 0 1-.436-.436v-8.72Z"
    />
  </svg>
);

export default SpacesIcon;
