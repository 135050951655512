import { AuthPage } from "@tether-web-portal/components/auth/AuthPage";
import { useAuth } from "../lib/auth/useAuth";
import type { FC } from "react";
import { Loader } from "@tether-web-portal/components/loading/Loader";
import { useLocation } from "react-router";

export const AuthGuardPage: FC = ({ children }) => {
  const { user, isLoadingAuth } = useAuth();
  const location = useLocation();

  if (isLoadingAuth) {
    return <Loader height="100vh" />;
  }

  // check if we are running on previous domain.
  if (!user && typeof window !== "undefined" && window.location.hostname.indexOf("tether.co.nz") !== -1) {
    const currentUrl = new URL(window.location.href.replace("tether.co.nz", "tetherhq.com"));
    window.location.href = currentUrl.toString();

    return null;
  }

  if (!user || (location.pathname.startsWith("/auth/"))) {
    return <AuthPage />;
  }

  return <>{children}</>;
};
