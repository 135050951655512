import { ReactNode, useRef, createContext, useContext, useEffect, useState } from "react";

type SVGMeasurePayload = {
  width: number;
  height: number;
};

export const SVGMeasureStore = createContext<SVGMeasurePayload>({
  width: 0,
  height: 0,
});

export const useSVGMeasure = (): SVGMeasurePayload => {
  return useContext(SVGMeasureStore);
};

export const SVGMeasure = ({
  measureWidth = false,
  measureHeight = false,
  children,
}: {
  measureWidth?: boolean;
  measureHeight?: boolean;
  children: ReactNode;
}) => {
  const container = useRef<HTMLDivElement>(null);

  const [{ width, height }, setBounds] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const resize = () => {
      setBounds({
        width: container.current?.clientWidth || 0,
        height: container.current?.clientHeight || 0,
      });
    };
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [setBounds]);

  let render = true;
  if (measureWidth && !width) render = false;
  if (measureHeight && !height) render = false;

  return (
    <div
      ref={container}
      style={{
        width: measureWidth ? "100%" : "auto",
        height: measureHeight ? "100%" : "auto",
      }}
    >
      {render && (
        <SVGMeasureStore.Provider
          value={{
            width,
            height,
          }}
        >
          {children}
        </SVGMeasureStore.Provider>
      )}
    </div>
  );
};
