export interface InstallationDetail<T = number | boolean | string> {
  installationId: string;
  name: string;
  type: "string" | "boolean" | "number";
  updatedAt: string;
  value: T;
}

export enum InstallationDetailType {
  FLOOR_AREA = "FLOORAREA",
  OCCUPANCY_RESET_TIME = "OCCUPANCYRESETTIME",
  COST_PER_KWH = "COST_PER_KWH",
  CO2_PER_KWH = "CO2_PER_KWH",
}
