import { Box, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import OccupancyGaugeChart, { GaugeColor } from "./charts/OccupancyGaugeChart";
import { OccupancyStatusBadge } from "./OccupancyStatusBadge";
import { OCCUPANCY_LOW, OCCUPANCY_OPTIMAL, OCCUPANCY_OVER } from "../occupancy.colors";
import { useResizeObserver } from "@tether-web-portal/hooks";
import OccupancyBaseTile, {
  OccupancyBaseTileChildProps,
  Unavailable,
} from "@tether-web-portal/components/occupancy/tiles/OccupancyBaseTile";
import { Typography } from "@tether-web-portal/components/common";
import { OccupancyCapacityTileData } from "@tether-web-portal/web-workers/occupancy";

const colors: GaugeColor[] = [
  {
    offset: "0%",
    color: OCCUPANCY_LOW,
    label: "LOW",
  },
  {
    offset: "40%",
    color: OCCUPANCY_OPTIMAL,
  },
  {
    offset: "90%",
    color: OCCUPANCY_OPTIMAL,
  },
  {
    offset: "100%",
    color: OCCUPANCY_OVER,
    label: "OVER",
  },
];

interface OccupancyOverallTileProps extends OccupancyBaseTileChildProps {
  data?: OccupancyCapacityTileData;
}

const OccupancyOverallTile = ({ data, loading, ...rest }: OccupancyOverallTileProps) => {
  const { width, setRef } = useResizeObserver();

  const { usage, usageLabel, occupiedLabel, gaugePercentage, unavailable } = useMemo(() => {
    let usage = 0;
    let gaugePercentage = 0;
    let usageLabel = "Empty";
    let occupiedLabel = "";

    const { total = 0, occupied = 0 } = data || {};

    if (!loading) {
      if (total > 0) {
        usage = (occupied / total) * 100;
        occupiedLabel = `${occupied}/${total} people`;

        if (usage > 0) {
          usageLabel = `${usage.toFixed(0)}%`;
        }
      }

      gaugePercentage = usage;
      if (gaugePercentage > 100) {
        gaugePercentage = 100;
      } else if (gaugePercentage > 90 && gaugePercentage <= 100) {
        gaugePercentage = gaugePercentage - 10;
      }
    }

    return {
      unavailable: {
        icon: "overall",
        enabled: !loading && total <= 0,
        title: "No occupancy data available",
        subtitle: "Occupancy information will appear here once data is collected.",
      } as Unavailable,
      usage,
      gaugePercentage,
      occupiedLabel,
      usageLabel,
    };
  }, [data, loading]);

  return (
    <OccupancyBaseTile
      ref={setRef}
      title="Total Occupancy"
      unavailable={unavailable}
      loading={loading}
      {...rest}
    >
      <Box display="flex" justifyContent="center" mt={2}>
        <OccupancyGaugeChart width={width} height={150} percentage={gaugePercentage} colors={colors} />
      </Box>

      <VStack position="absolute" bottom="0" left="50%" transform="translateX(-50%)" spacing={2}>
        <Typography size={"3xl"} fontWeight="semibold">
          {usageLabel}
        </Typography>
        <Typography size={"md"} color={"#595F6A"}>
          {occupiedLabel}
        </Typography>
        <OccupancyStatusBadge value={usage} />
      </VStack>
    </OccupancyBaseTile>
  );
};

export default OccupancyOverallTile;
