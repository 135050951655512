import { VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import OccupancyBarChart, { OccupancyBarData } from "./charts/OccupancyBarChart";
import { useResizeObserver } from "@tether-web-portal/hooks";
import OccupancyBaseTile, {
  OccupancyBaseTileChildProps,
  Unavailable,
} from "@tether-web-portal/components/occupancy/tiles/OccupancyBaseTile";
import { Typography } from "@tether-web-portal/components/common";
import { OccupancyAggregate } from "@tether-web-portal/web-workers/occupancy";

const DAY_MAP: Map<string, string> = new Map([
  ["mon", "Monday"],
  ["tue", "Tuesday"],
  ["wed", "Wednesday"],
  ["thu", "Thursday"],
  ["fri", "Friday"],
  ["sat", "Saturday"],
  ["sun", "Sunday"],
]);

const sanitize = (week: OccupancyAggregate[] = []): OccupancyBarData[] => {
  // The days of the week in desired order
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const prepareLabel = (id: string) => id.charAt(0).toUpperCase() + id.slice(1);

  return daysOfWeek.map((dayId) => {
    const day = week.find((week) => week.id.trim().toLowerCase() === dayId);
    const value = day?.usage ?? 0;
    return {
      label: prepareLabel(day?.id ?? dayId),
      value,
      tooltip: `${DAY_MAP.get(dayId)}, Avg occupancy: ${value.toFixed(0)}%`,
    };
  });
};

const findTopThree = (data: OccupancyBarData[]): OccupancyBarData[] => {
  return data
    .filter((bar) => bar.value > 5)
    .sort((a, b) => b.value - a.value)
    .slice(0, 3);
};

export interface OccupancyPeakDaysTileProps extends OccupancyBaseTileChildProps {
  data?: OccupancyAggregate[];
}

const OccupancyPeakDaysTile = ({ data, loading, ...rest }: OccupancyPeakDaysTileProps) => {
  const { width, setRef } = useResizeObserver();

  const { peakLabel, busiestLabel, chartItems, selected, unavailable } = useMemo(() => {
    let peakLabel = "";
    let busiestLabel = "";
    let selected = undefined;

    if (loading) {
      return {
        peakLabel,
        busiestLabel,
        chartItems: [],
        selected,
      };
    }

    const sanitizedData = sanitize(data);
    const peakDays = findTopThree(sanitizedData);

    if (peakDays.length > 0) {
      peakLabel = peakDays.map((day) => day.label).join(", ");
      const busiestDay = peakDays[0];
      busiestLabel = `Busiest Day: ${busiestDay.tooltip}`;
      selected = sanitizedData.findIndex((item) => item.label === busiestDay.label);
    }

    return {
      unavailable: {
        icon: "peakDays",
        enabled: !loading && peakDays.length === 0,
        title: "No daily trend data available",
        subtitle: "Daily occupancy trends will be displayed here once data is collected.",
      } as Unavailable,
      peakLabel,
      busiestLabel,
      chartItems: sanitizedData,
      selected,
    };
  }, [loading, data]);

  return (
    <OccupancyBaseTile ref={setRef} title="Peak Days" unavailable={unavailable} loading={loading} {...rest}>
      <VStack alignItems="flex-start" spacing={0}>
        <Typography size={"3xl"} fontWeight="semibold">
          {peakLabel}
        </Typography>
        <Typography size={"sm"} color={"#595F6A"}>
          {busiestLabel}
        </Typography>
      </VStack>

      <OccupancyBarChart width={width} height={90} data={chartItems} selectedBar={selected} />
    </OccupancyBaseTile>
  );
};

export default OccupancyPeakDaysTile;
