export class NotFoundError extends Error {
  private is404: boolean;
  constructor(...args: any[]) {
    super(...args);
    this.is404 = true;
  }
}

export interface RefreshResponse {
  token: string;
  refreshToken: string;
  accessTokenExpiry: number;
  scope: string;
}

export interface APIError {
  message: string;
  statusCode: number;
}

export const baseURL = import.meta.env.VITE_API_HOST;

export const appClientDetails = {
  client_id: import.meta.env.VITE_CLIENT_ID,
  client_secret: import.meta.env.VITE_CLIENT_SECRET,
};
