import { useToast } from "@chakra-ui/react";
import { OrganisationFeatureFlag } from "@tether-web-portal/types/organisationFeatureFlag";
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { api } from "../api";
import { FieldUserProperty } from "../../types/FieldUserProperty";
import { Organisation } from "../../types/Organisation";
import { Property } from "../../types/Property";

export enum OrganisationQueryKeys {
  Organisations = "organisations",
  FieldUsers = "organisation-field-users",
  FeatureFlags = "organisation-feature-flags",
  Settings = "organisation-settings",
}

export const useGetOrganisation = (id: string) => {
  const { data: organisation, ...rest } = useQuery<Organisation>(
    [OrganisationQueryKeys.Organisations, id],
    () => api.get(`v2/organisation/${id}`),
    {
      staleTime: Infinity,
    }
  );
  return { organisation, ...rest };
};

export const useGetOrganisations = () => {
  const { data: organisations = [], ...rest } = useQuery<Organisation[]>(
    [OrganisationQueryKeys.Organisations],
    () => api.get(`/account/v2/organisation`),
    {
      staleTime: 1000 * 5,
    }
  );
  return { organisations, ...rest };
};

export const useGetFieldUserProperties = (organisationId: string, userId: string) => {
  const { data: properties, ...rest } = useQuery<FieldUserProperty[]>(
    [OrganisationQueryKeys.FieldUsers, organisationId, userId],
    () => api.get(`account/v2/fielduserassignment/?organisationId=${organisationId}&userId=${userId}`),
    {
      staleTime: Infinity,
    }
  );

  return { properties, ...rest };
};

export const useUpdateFieldUserPropertiesMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleUpdateFieldUser = async ({
    userId,
    existingProperties,
    newProperties,
  }: {
    userId: string;
    existingProperties: Property[];
    newProperties: Property[];
  }) => {
    const propertiesToBeRemoved = existingProperties?.filter(
      (property) => !newProperties?.includes(property)
    );

    try {
      const addPropertyPromises =
        newProperties && newProperties.length > 0
          ? newProperties.map((property: Property) => {
              return api.post(`account/v2/fielduserassignment/`, { userId, installationId: property.id });
            })
          : [];
      const deletePropertyPromises =
        propertiesToBeRemoved && propertiesToBeRemoved.length > 0
          ? propertiesToBeRemoved.map(async (property: Property) => {
              return await api.del(
                `account/v2/fielduserassignment/?installationId=${property.id}&userId=${userId}`
              );
            })
          : [];
      await Promise.all(addPropertyPromises.concat(deletePropertyPromises));
    } catch (e) {
      console.log("failed to update Field User");
    }
  };

  return useMutation(handleUpdateFieldUser, {
    onError: () => {
      toast({
        title: "Unable to updated Restricted User",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      queryClient.invalidateQueries([OrganisationQueryKeys.FieldUsers]);
    },

    onSuccess: () => {
      toast({
        title: "Successfully updated field user",
        status: "success",
        isClosable: true,
        position: "top-right",
      });

      queryClient.invalidateQueries([OrganisationQueryKeys.FieldUsers]);
    },
  });
};

const useGetOrganisationFeatureFlags = (enabled = true) => {
  return useQuery<OrganisationFeatureFlag[]>(
    [OrganisationQueryKeys.FeatureFlags],
    () => api.get(`v2/organisationfeatureflag`),
    { enabled: enabled }
  );
};

export const useGetOrganisationFeatureFlag = (featureFlag: string, enabled = true) => {
  const {
    data: featureFlags,
    isLoading: featureFlagsLoading,
    isError: featureFlagsError,
  } = useGetOrganisationFeatureFlags(enabled);

  const isEnabled = useMemo(() => {
    return (featureFlags ?? []).find((x) => x.name === featureFlag)?.value ?? false;
  }, [featureFlags, featureFlag]);

  return {
    value: isEnabled,
    isLoading: featureFlagsLoading,
    error: featureFlagsError,
  };
};

export const useGetOrganisationSetting = (
  settingName: string,
  options?: UseQueryOptions<number | string | boolean, Error>
) => {
  const { data: setting, ...rest } = useQuery<number | string | boolean, Error>(
    [OrganisationQueryKeys.Settings, settingName],
    () => api.get(`v2/organisationsetting/${settingName}`).then((r) => r.value),
    options
  );
  return { setting, ...rest };
};

export const useManageAccountDataMutation = () => {
  const toast = useToast();

  const sendRequest = (requestType: "delete" | "export") => {
    return api.post(`account/v2/managedata/${requestType}`);
  };

  return useMutation(sendRequest, {
    onError: () => {
      toast({
        title: "Unable to submit request",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },

    onSuccess: () => {
      toast({
        title: "Request was successfully submitted",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    },
  });
};
