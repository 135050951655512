import { FunctionComponent } from "react";
import styles from "./styling/Tag.module.scss";

interface ITagProps {
  label: string;
}
export const Tag: FunctionComponent<ITagProps> = (props: ITagProps) => {
  const { label } = props;

  return <div className={styles.container}>{label}</div>;
};
