import * as React from "react";

const ChargingIcon = ({ fill = "#6DC96E" }: { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} fill="none">
    <path
      d="M.734 13.082c0 .38.293.664.704.664h5.517l-2.91 7.91c-.38 1.006.664 1.543 1.318.723l8.877-11.094c.166-.205.254-.4.254-.625 0-.37-.293-.664-.703-.664H8.273l2.91-7.91c.382-1.006-.663-1.543-1.318-.713L.988 12.457c-.166.215-.254.41-.254.625Z"
      fill={fill}
    />
  </svg>
);

export default ChargingIcon;
