import { Box, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  LocationType,
  useDeleteLocationMutation,
  useRemoveDeviceMutation,
} from "@tether-web-portal/api/properties/hooks";
import { useAuth } from "../../../lib/auth/useAuth";
import { userRoleHasPermission } from "@tether-web-portal/services/PermissionService";
import { MenuIconButton } from "../../icons/MenuIconButton";
import { ConfirmationModal } from "../../modals/ConfirmationModal";
import { RouterLink } from "@tether-web-portal/components/common";
import { MenuButtonAsButton } from "@tether-web-portal/components/common";

interface ManageRoomLocationMenuProps {
  locationId: string;
  locationName: string;
  hasDevice: boolean;
  propertyId: string;
  showBorder?: boolean;
  locationType: LocationType;
  href?: string;
  color?: string;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  onViewDetailsClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ManageRoomLocationMenu = ({
  locationId,
  locationName,
  propertyId,
  hasDevice,
  showBorder,
  locationType,
  color,
  onEditClick,
  onViewDetailsClick,
}: ManageRoomLocationMenuProps) => {
  const [showConfirmRemoveDevice, setShowConfirmRemoveDevice] = useState(false);
  const [showConfirmDeleteLocation, setShowConfirmDeleteLocation] = useState(false);
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const removeDeviceMutation = useRemoveDeviceMutation(() => {
    setShowConfirmRemoveDevice(false);
  });

  const actuallyRemoveDevice = async () => {
    removeDeviceMutation.mutate({
      input: {
        locationId,
        locationType: locationType,
      },
    });
  };

  const deleteLocationMutation = useDeleteLocationMutation(() => {
    setShowConfirmDeleteLocation(false);
  });

  const actuallyDeleteLocation = async () => {
    deleteLocationMutation.mutate({
      input: {
        locationId: locationId,
        propertyId,
        locationType: locationType,
      },
    });
  };

  const modifyAreaPermission = userRoleHasPermission(user!.currentOrganisationRoleId, "MODIFY_AREA");

  const handleOpenClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  return (
    <>
      {Boolean(showConfirmRemoveDevice) && (
        <ConfirmationModal
          isOpen={Boolean(showConfirmRemoveDevice)}
          onClose={() => {
            setShowConfirmRemoveDevice(false);
          }}
          onConfirm={async () => {
            await actuallyRemoveDevice();
          }}
          confirmButtonColorScheme="red"
          title={"Remove Device from " + locationName}
          confirmationText={"Are you sure you want to remove this device?"}
          isLoading={removeDeviceMutation.isLoading}
        />
      )}
      {Boolean(showConfirmDeleteLocation) && (
        <ConfirmationModal
          isOpen={Boolean(showConfirmDeleteLocation)}
          onClose={() => {
            setShowConfirmDeleteLocation(false);
          }}
          onConfirm={async () => {
            await actuallyDeleteLocation();
          }}
          confirmButtonColorScheme="red"
          title={"Delete Location named: " + locationName}
          confirmationText={"Are you sure you want to delete this location?"}
          isLoading={deleteLocationMutation.isLoading}
        />
      )}
      <Box alignItems="center" display="flex" position="relative">
        <Menu isOpen={isOpen} onClose={() => setTimeout(() => setIsOpen(false))}>
          {!showBorder ? (
            <MenuButtonAsButton
              disabled={!modifyAreaPermission}
              variant="ghost"
              onClick={handleOpenClicked}
            >
              <BsThreeDots size={24} color={color ?? "var(--chakra-colors-blue-900)"} />
            </MenuButtonAsButton>
          ) : (
            <>
              <MenuButton
                zIndex={-1}
                position="absolute"
                bg="yellow"
                width={10}
                height={10}
                opacity={0}
              />
              <MenuIconButton disabled={!modifyAreaPermission} onClick={handleOpenClicked}>
                <BsThreeDots size={24} color={color ?? "var(--chakra-colors-blue-900)"} />
              </MenuIconButton>
            </>
          )}
          {/* Only show on isOpen or it can render invisibly off the side of the page and cause side scrolling */}
          {isOpen && (
            <MenuList borderWidth={1} boxShadow="lg" borderColor="gray.50">
              {locationType === "CIRCUIT" && onViewDetailsClick && (
                <MenuItem>
                  <RouterLink to={`/area/circuit/${locationId}`}>View Readings</RouterLink>
                </MenuItem>
              )}
              {locationType === "OUTSIDE" && onViewDetailsClick && (
                <MenuItem>
                  <RouterLink to={`/area/outside/${locationId}`}>View Readings</RouterLink>
                </MenuItem>
              )}
              {locationType !== "OUTSIDE" && onEditClick && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditClick(e);
                  }}
                >
                  Edit {locationType === LocationType.Circuit ? "Circuit" : "Location"}
                </MenuItem>
              )}

              {hasDevice && (
                <MenuItem
                  color="red.500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmRemoveDevice(true);
                  }}
                >
                  Remove Device
                </MenuItem>
              )}
              {!hasDevice && (
                <MenuItem
                  color="red.500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDeleteLocation(true);
                  }}
                >
                  Delete {locationType === LocationType.Circuit ? "Circuit" : "Location"}
                </MenuItem>
              )}
            </MenuList>
          )}
        </Menu>
      </Box>
    </>
  );
};
