import { HeatMapColumn, HeatMapXScale } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { Group } from "@visx/group";

const FadedBackground = ({
  leftRadius,
  rightRadius,
  xWidth,
  xGap,
  height,
  radius
}: {
  leftRadius: boolean,
  rightRadius: boolean,
  xWidth: number,
  xGap: number,
  height: number
  radius: number
}) => {
  return (
    <Group left={-xGap} >
      <rect
        fill={'#f8f8f8'}
        height={height}
        width={xWidth * 0.75}
        rx={leftRadius ? radius : 0}
      />
      <rect
        fill={'#f8f8f8'}
        height={height}
        x={xWidth / 4}
        width={xWidth * 0.75}
        rx={rightRadius ? radius : 0}
      />
    </Group>
  )
}

export const ColumnBackground = ({
  columns,
  backgroundHeight,
  xScale,
  columnXMax,
  xMax,
}: {
  columns: HeatMapColumn[],
  backgroundHeight: number,
  xScale: HeatMapXScale,
  columnXMax: number,
  xMax: number,
}) => {
  const xWidth = xScale.step();
  const xGap = (xScale.step() - xScale.bandwidth()) / 2;

  return (
    <Group data-name={'heat-map-column-background'} >
      {columns.length > 0 &&
      (xMax > columnXMax) && (
        <rect
          fill={'#f8f8f8'}
          height={backgroundHeight}
          width={xMax - columnXMax}
          x={columnXMax}
          rx={6}
        />
      )}

      {columns.map(
        ({ key, faded }, i, arr) => (
          <Group key={key} left={xScale(key)} >
            {faded && (
              <FadedBackground
                leftRadius={!arr[i -1]?.faded}
                rightRadius={!arr[i +1]?.faded}
                xWidth={xWidth}
                xGap={xGap}
                height={backgroundHeight}
                radius={6}
              />
            )}
          </Group>
        )
      )}
    </Group>
  );
}
