import { api } from "../api";
import { appClientDetails, baseURL } from "../api-types";
import { CurrentOrganisation } from "../../types/CurrentOrganisation";
import { LoginSuccessResponse } from "../../types/LoginSuccessResponse";
import { Me } from "../../types/Me";
import {
  AcceptInvitationResult,
  AuthorizationCodeLoginBody,
  CreateAccountBody,
  CreateAccountResponse,
  LoginBody,
  SignupBody,
  SignupSuccessResponse,
  UserInvitationByToken,
} from "./types";

export class AuthService {
  async login(input: LoginBody): Promise<LoginSuccessResponse> {
    return api.post(`oauth/token`, { grant_type: "password", ...input, ...appClientDetails });
  }
  async loginWithAccessCode(input: AuthorizationCodeLoginBody): Promise<LoginSuccessResponse> {
    return api.post(`oauth/token`, {
      grant_type: "authorization_code",
      authorization_code: input.code,
      ...appClientDetails,
    });
  }
  async requestPasswordReset(email: string): Promise<void> {
    return api.post(`/v2/auth/request-password`, { email });
  }
  async resetPassword(token: string, password: string): Promise<void> {
    // Don't use api.get as the server doesn't return anything and it'll break
    const response = await fetch(`${baseURL}/v2/auth/reset-password/${token}`, {
      method: "POST",
      body: JSON.stringify({ password }),
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin", // for westpac proxy
    });
    if (response.ok) {
      return;
    }
    // returning error message
    const responseJSON = await response.json();
    console.log({ responseJSON });
    throw new Error(responseJSON.message || responseJSON.error_description);
  }
  async verifyResetPasswordToken(token: string): Promise<void> {
    // Don't use api.get as the server doesn't return anything and it'll break
    const response = await fetch(`${baseURL}/v2/auth/verify/${token}`, {
      credentials: "same-origin", // for westpac proxy
    });
    if (response.ok) {
      return;
    }

    // returning error message
    const responseJSON = await response.json();
    throw new Error(responseJSON.message || responseJSON.error_description);
  }
  async me(): Promise<Me> {
    return api.get(`account/v2/user/me/`);
  }
  async currentOrganisation(): Promise<CurrentOrganisation> {
    return await api.get(`account/v2/organisation/current`);
  }
  async signup(input: SignupBody): Promise<SignupSuccessResponse> {
    return api.post(`account/v2/createaccount/signup`, input);
  }
  async createAccountFromInvite(
    input: CreateAccountBody,
    inviteToken: string
  ): Promise<CreateAccountResponse> {
    return api.post(`account/v2/createaccount/invite/${inviteToken}`, input);
  }
  async getInvitation(inviteToken: string): Promise<UserInvitationByToken> {
    return api.get(`account/v2/createaccount/invite/${inviteToken}`);
  }
  async acceptInvitation(
    invitation: UserInvitationByToken,
    inviteToken: string
  ): Promise<AcceptInvitationResult> {
    return api.post(`account/v2/invite/${invitation.id}/accept`, { token: inviteToken });
  }
  async acceptTermsAndConditions(): Promise<AcceptInvitationResult> {
    return api.post(`account/v2/user/me/accepttermsandconditions`);
  }
}

export default new AuthService();
