import { FunctionComponent } from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";

import { useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { OccupancyBreakdownCardWrapper } from "./OccupancyBreakdownCardWrapper";
import { IOccupancyBreakdownDataPoint } from "@tether-web-portal/web-workers/occupancy";

import { Typography } from "@tether-web-portal/components/common";
import { HorizontalBarChart } from "@tether-web-portal/components/charts/horizontal-bar-chart/HorizontalBarChart";
import { useOccupancyBarChartProps } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyBarChartProps";
import { TooltipDisplay } from "@tether-web-portal/components/occupancy/breakdown/tooltip/TooltipDisplay";
import { useOccupancyChartMetaData } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyChartMetaData";

export const OccupancyBreakdownBarChart: FunctionComponent = () => {
  const {
    breakdownLoading,
    lowResBreakdownData,
    breakdownSummary,
    breakdownFilters,
    property,
    isLiveView,
    monitoringHours,
  } = useOccupancyContext();

  const { metricsTimeStamps } = useMetricsInterval();

  const occupancyChartMetaData = useOccupancyChartMetaData({
    metricsTimeStamps,
    breakdownData: lowResBreakdownData,
    breakdownSummary,
    breakdownFilters,
    monitoringHours,
  });

  const barChartProps = useOccupancyBarChartProps(
    {
      lowResBreakdownData,
      breakdownFilters,
      timezone: property?.timezone,
    },
    occupancyChartMetaData,
  );

  if (isLiveView) {
    return <></>;
  }

  return (
    <>
      <Typography size="xl" fontWeight="bold">
        Breakdown Graph
      </Typography>
      <OccupancyBreakdownCardWrapper>
        <Box position="relative">
          <div style={{ height: 600 }}>
            <HorizontalBarChart<IOccupancyBreakdownDataPoint>
              {...barChartProps}
              TooltipDisplay={TooltipDisplay}
            />
          </div>

          {breakdownLoading && (
            <Center
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <Spinner mr={2} /> Loading...
            </Center>
          )}
        </Box>
      </OccupancyBreakdownCardWrapper>
    </>
  );
};
