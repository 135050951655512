import { HeatMapColumn, HeatMapRow, HeatMapColorScale, HeatMapYScale, HeatMapXScale, HeatMapDataMap } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { useMemo } from "react";

export const Cells = <T,>({
  columns,
  rows,
  dataMap,
  xScale,
  yScale,
  colorScale,
}: {
  columns: HeatMapColumn[],
  rows: HeatMapRow[],
  dataMap: HeatMapDataMap<T>,
  xScale: HeatMapXScale,
  yScale: HeatMapYScale,
  colorScale: HeatMapColorScale,
}) => {

  const cellWidth = xScale.bandwidth();
  const cellHeight = yScale.bandwidth();

  return useMemo(
    () => (
      <Group data-name={'heat-map-cells'} >
        {columns.map(
          ({ key: columnKey }) => (
            <Group
              key={columnKey}
              left={xScale(columnKey)}
              data-column={columnKey}
            >
              {rows.map(
                ({ key: rowKey }) => {
                  const {
                    value = 0
                  } = dataMap[columnKey]?.[rowKey] || {};
                  const color = colorScale(value);
                  return (
                    <Group
                      key={rowKey}
                      top={yScale(rowKey)}
                      data-row={rowKey}
                    >
                      <rect
                        width={cellWidth}
                        height={cellHeight}
                        rx={5}
                        fill={color}
                      />
                      {value && (
                        <Group left={cellWidth/2} top={cellHeight/2} >
                          <Text
                            textAnchor='middle'
                            verticalAnchor='middle'
                            fill={'#FFFFFF'}
                            fontSize={'var(--chakra-fontSizes-xs)'}
                            fontWeight={500}
                          >
                            {String(value)}
                          </Text>
                        </Group>
                      )}
                    </Group>
                  )
                }
              )}
            </Group>
          )
        )}
      </Group>
    ),
    [columns, rows, cellWidth, cellHeight, xScale, yScale, dataMap, colorScale]
  );
};
