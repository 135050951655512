import { useCallback, useMemo } from "react";
import { ManipulateType } from "dayjs";

import {
  HeatMapColorScale,
  HeatMapColumn,
  HeatMapDataPoint,
  HeatMapRow,
} from "@tether-web-portal/components/heat-map/v2/HeatMap";
import {
  IOccupancyBreakdownDataPoint,
  IOccupancyBreakdownFilters,
  IOccupancyBreakdownSummary,
} from "@tether-web-portal/web-workers/occupancy";

import {
  OCCUPANCY_HEATMAP_LOW_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OVER_COLOR_RANGE,
  OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE,
  OCCUPANCY_OPTIMAL_THRESHOLD,
} from "@tether-web-portal/components/occupancy/occupancy.colors";

import { DayOfWeek, MonitoringHours } from "@tether-web-portal/types/MonitoringHours";
import { dayjs, Dayjs } from "@tether-web-portal/utils/dayjs-setup";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";
import { scaleLinear } from "@visx/scale";
import { IOccupancyChartMetaDataPayload } from "@tether-web-portal/components/occupancy/breakdown/useOccupancyChartMetaData";

const dateStepBetweenRange = <T>(
  start: Dayjs,
  maxDate: Dayjs,
  step: number,
  stepUnit: ManipulateType,
  mapping: (currentDate: Dayjs) => T,
): T[] => {
  const ranges = [];
  let currentDate = start.clone();
  while (currentDate.isBefore(maxDate) || currentDate.isSame(maxDate)) {
    ranges.push(mapping(currentDate));
    // console.log(stepUnit, currentDate.utc().format('LLL'));
    currentDate = currentDate.add(step, stepUnit).startOf(stepUnit);
  }
  return ranges;
};

const generateColorRange = () => {
  const colorSteps = (from: number, to: number, colors: string[]) => {
    const step = 1 / (colors.length - 1);
    const offset = to - from;
    return colors.map((color, i) => ({
      color,
      value: from + offset * i * step,
    }));
  };

  return [
    {
      color: OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE[0],
      value: 0,
    },
    ...colorSteps(0 + Number.EPSILON, OCCUPANCY_OPTIMAL_THRESHOLD, OCCUPANCY_HEATMAP_LOW_COLOR_RANGE),
    ...colorSteps(OCCUPANCY_OPTIMAL_THRESHOLD + Number.EPSILON, 1, OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE),
    {
      value: 1 + Number.EPSILON,
      color: OCCUPANCY_HEATMAP_OVER_COLOR_RANGE[0],
    },
  ];
};

interface UseOccupancyHeatmapPropsPayload {
  columns: HeatMapColumn[];
  rows: HeatMapRow[];
  data: HeatMapDataPoint<IOccupancyBreakdownDataPoint>[];
  maxValue: number;
  colorScale: HeatMapColorScale;
  rowAxisLabel: string;
  columnsAxisLabel: string;
}

interface UseOccupancyHeatmapPropsProps {
  breakdownData: IOccupancyBreakdownDataPoint[];
  breakdownSummary: IOccupancyBreakdownSummary | null;
  breakdownFilters: IOccupancyBreakdownFilters;
  monitoringHours: MonitoringHours[] | null;
  timezone?: string;
}

export const useOccupancyHeatmapProps = (
  {
    breakdownData,
    breakdownSummary,
    breakdownFilters,
    timezone,
    monitoringHours,
  }: UseOccupancyHeatmapPropsProps,
  {
    daysOfWeekSet,
    daysBetween,
    maxValue,
    colorScale,
    startDateUTC,
    endDateUTC,
  }: IOccupancyChartMetaDataPayload,
): UseOccupancyHeatmapPropsPayload => {
  const { metricToDisplay } = breakdownFilters;
  const { occupancyLimit } = breakdownSummary || {};

  let columnStepType: ManipulateType = "day";
  if (daysBetween <= 1) {
    columnStepType = "hour";
  }
  const columns = useMemo<HeatMapColumn[]>((): HeatMapColumn[] => {
    let format = "D";
    if (daysBetween <= 7) {
      format = "dd";
    }
    if (daysBetween <= 1) {
      format = "HH:mm";
    }
    console.log(timezone);
    return dateStepBetweenRange<HeatMapColumn>(
      startDateUTC.tz(timezone).startOf(columnStepType),
      endDateUTC.tz(timezone).endOf(columnStepType),
      1,
      columnStepType,
      (currentDate): HeatMapColumn => ({
        key: currentDate.valueOf(),
        label: currentDate.format(format),
        timestamp: currentDate.valueOf(),
        ...(daysBetween > 1 && {
          faded: !daysOfWeekSet.has(currentDate.format("dddd").toLowerCase() as DayOfWeek),
        }),
      }),
    );
  }, [daysBetween, columnStepType, startDateUTC, endDateUTC, timezone, daysOfWeekSet]);

  const benchmarkingHours = useMemo(() => {
    const [{ startTime = undefined, endTime = undefined } = {}] = monitoringHours || [];

    if (!startTime || !endTime) return;

    const unixOffset = dayjs().startOf(columnStepType).valueOf();
    return {
      start: dayjs(startTime, "HH:mm").startOf("hour").valueOf() - unixOffset,
      end: dayjs(endTime, "HH:mm").startOf("hour").valueOf() - unixOffset,
    };
  }, [monitoringHours, columnStepType]);

  const rows = useMemo<HeatMapRow[]>(() => {
    let step = 1;
    let format = "ha";
    let stepBy: ManipulateType = "hour";
    if (daysBetween <= 1) {
      step = 15;
      format = "m";
      stepBy = "minute";
    }

    const showBenchmarks = daysBetween > 1;

    const now = dayjs().tz(timezone, true);
    const start = now.startOf(columnStepType);
    const unixOffset = start.valueOf();
    return dateStepBetweenRange<HeatMapRow>(start, now.endOf(columnStepType), step, stepBy, (currentDate) => {
      const key = currentDate.valueOf() - unixOffset;
      const start = benchmarkingHours?.start == key;
      const end = benchmarkingHours?.end == key;
      return {
        key,
        label: currentDate.format(format),
        timestamp: currentDate.valueOf(),
        ...(showBenchmarks &&
          (start || end) && {
            benchmark: {
              start,
              end,
            },
          }),
      };
    });
  }, [daysBetween, columnStepType, benchmarkingHours, timezone]);

  const monitoringHoursMap: Record<DayOfWeek, { start: number; end: number }> | undefined = useMemo(() => {
    const now = dayjs().tz(timezone, true);
    const start = now.startOf(columnStepType);
    const unixOffset = start.valueOf();

    return monitoringHours?.reduce(
      (obj, { dayOfWeek, startTime, endTime }) => ({
        ...obj,
        [dayOfWeek]: {
          start: dayjs(startTime, "HH:mm").startOf("hour").valueOf() - unixOffset,
          end: dayjs(endTime, "HH:mm").startOf("hour").valueOf() - unixOffset,
        },
      }),
      {} as Record<DayOfWeek, { start: number; end: number }>,
    );
  }, [monitoringHours, columnStepType, timezone]);

  const getPointValue = useCallback(
    (breakdown: IOccupancyBreakdownDataPoint["breakdown"]): number | undefined => {
      if (!breakdown) return;
      const value = breakdown[metricToDisplay] as number | null;
      if (value === null) return;

      switch (metricToDisplay) {
        case "capacityPercentage":
        case "usagePercentage":
          return Math.round(value * 100);
        default:
          return value;
      }
    },
    [metricToDisplay],
  );

  const data = useMemo<HeatMapDataPoint<IOccupancyBreakdownDataPoint>[]>(() => {
    return breakdownData.map((dataPoint) => {
      const { startTimestamp, breakdown } = dataPoint;
      const startTime = dayjs(startTimestamp);
      const column = startTime.tz(timezone).startOf(columnStepType).valueOf();
      const row = startTime.valueOf() - column;
      const { start, end } = monitoringHoursMap?.[startTime.format("dddd").toLowerCase() as DayOfWeek] || {};
      return {
        row,
        column,
        value: getPointValue(breakdown),
        data: dataPoint,
        ...(start &&
          end && {
            faded: row <= start || row >= end,
          }),
      };
    });
  }, [breakdownData, columnStepType, getPointValue, timezone, monitoringHoursMap]);

  return {
    colorScale,
    maxValue,
    columns,
    rows,
    data,
    rowAxisLabel: daysBetween > 1 ? "Hours of the day" : "Minutes of the hour",
    columnsAxisLabel: daysBetween > 1 ? "Days of the week" : "Hours of the day",
  };
};
