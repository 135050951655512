import { Dayjs, dayjs } from "@tether-web-portal/dayjs-setup";
import { MonitoringHours } from "../types/MonitoringHours";
import { Reading } from "../types/Reading";

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const getDateFrom24HourFormat = (d: Dayjs, timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  return dayjs(d)
    .hour(+hours)
    .minute(+minutes);
};

export const isWithinMonitoringHours = (d: Dayjs, monitoringHours: MonitoringHours[]) => {
  const dayOfWeek = daysOfWeek[d.day()];

  const m = monitoringHours.find((m) => m.dayOfWeek === dayOfWeek);

  if (!monitoringHours.length) {
    return true;
  }

  if (!m) {
    return false;
  }

  const startMonitoring = getDateFrom24HourFormat(d, m.startTime);
  const endMonitoring = getDateFrom24HourFormat(d, m.endTime);

  return dayjs(d).isAfter(startMonitoring) && dayjs(d).isBefore(endMonitoring);
};

export const getReadingsWithinMonitoringHours = (
  readings: Reading[],
  monitoringHours: MonitoringHours[]
): Reading[] => {
  return readings.filter((r) => isWithinMonitoringHours(dayjs(dayjs.unix(r[0])), monitoringHours));
};
