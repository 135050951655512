import React, { useMemo } from "react";
import { Box } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { NetworkConnectionIcon } from "../icons/NetworkConnectionIcon";
import numeral from "numeral";

interface SignalStrengthIndicatorProps {
  signalStrength: number;
  isOffline?: boolean;
}

function SignalStrengthIndicator({ signalStrength, isOffline }: SignalStrengthIndicatorProps) {
  const tooltipLabel = useMemo(() => {
    const formattedPercentage = numeral(signalStrength).format("0[.]00%");
    if (!isOffline) return `Signal: ${formattedPercentage}`;
    return `Last Known Signal: ${formattedPercentage}`;
  }, [signalStrength, isOffline]);

  return (
    <Tooltip hasArrow label={tooltipLabel} aria-label={tooltipLabel}>
      <Box>
        <NetworkConnectionIcon connectionPercentage={signalStrength} isOffline={isOffline} />
      </Box>
    </Tooltip>
  );
}

export default SignalStrengthIndicator;
