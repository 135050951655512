import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { Loader } from "@tether-web-portal/components/loading/Loader";
import DateRangeControls, { maxReadingDays } from "@tether-web-portal/components/property/DateRangeControls";
import { AreaPageHeader } from "@tether-web-portal/components/areas/AreaPageHeader";
import { AreaType, useAreaPageData } from "@tether-web-portal/hooks/useAreaPageData";
import { useGetRoomInsights } from "@tether-web-portal/api/insights/hooks";
import { useGetCircuitNodeTree } from "@tether-web-portal/api/properties/hooks";
import {
  ComfortIndexCategory,
  ComfortIndexCategoryMap,
  ComfortIndexPercentages,
} from "@tether-web-portal/types/Insights";
import { ComfortScore } from "@tether-web-portal/types/Room";
import AreaPageStatusCard from "@tether-web-portal/components/areas/AreaPageStatusCard";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { useParams } from "react-router-dom";
import { OccupancyDashboard } from "@tether-web-portal/components/occupancy/OccupancyDashboard";
import { IOccupancyPageFilters, OccupancyFilterType } from "@tether-web-portal/web-workers/occupancy";

const LocationDetailOccupancyPage = () => {
  const { id = "", areaType } = useParams<{ id: string; areaType: string }>();

  const {
    loading,
    error,
    area,
    room,
    outside,
    circuit,
    comfortScore,
    property,
    propertyInfo,
    roomLocations,
    pulseMeter,
  } = useAreaPageData(areaType as AreaType, id);

  const { circuitNodeTree } = useGetCircuitNodeTree(property?.id);

  const pageFilters = useMemo(
    (): IOccupancyPageFilters => ({
      spaceType: OccupancyFilterType.ROOM,
      floorIndex: null,
      spaceId: id,
    }),
    [id]
  );

  if (loading) {
    return <Loader mt={10} />;
  }
  if (error || !area || !property || !propertyInfo) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          There was a problem loading data for this area, please try again later.
        </Alert>
      </Box>
    );
  }

  return (
    <AreaPageHeader
      area={area}
      areaType={areaType as AreaType}
      comfortScore={comfortScore}
      property={property}
      propertyInfo={propertyInfo}
      circuitNodeTree={circuitNodeTree ? circuitNodeTree : []}
      dateRangeControl={<DateRangeControls maxSelectedDays={maxReadingDays} />}
      activeTab="occupancy"
    >
      {/* Date range spacer */}
      <Box mb="90px"></Box>

      <AreaPageStatusCard areaType={areaType as AreaType} id={id} />

      <Box p={5}>
        <OccupancyDashboard propertyId={property.id} pageFilters={pageFilters} />
      </Box>
    </AreaPageHeader>
  );
};

export default LocationDetailOccupancyPage;
