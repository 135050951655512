import { useEffect, useMemo, useState } from "react";

import { IOccupancyBreakdownWorkerOutput, IOccupancyBreakdownWorkerProps } from "./types/OccupancyBreakdown";
import { IWebWorkerResult, useWebWorker } from "@tether-web-portal/web-workers/useWebWorker";

export const useOccupancyBreakdownWebWorker = (
  inputProps: IOccupancyBreakdownWorkerProps | null
): IWebWorkerResult<IOccupancyBreakdownWorkerOutput> => {
  const [worker, setWorker] = useState<Worker>();

  // Destruct and watch each property to prevent unnecessary re-renders
  const webWorkerInput = useMemo<IOccupancyBreakdownWorkerProps | undefined>(
    () =>
      !inputProps
        ? undefined
        : {
            property: inputProps.property,
            propertyInfo: inputProps.propertyInfo,
            roomAggregations: inputProps.roomAggregations,
            monitoringHours: inputProps.monitoringHours,
            breakdownFilters: inputProps.breakdownFilters,
            filters: inputProps.filters,
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inputProps?.propertyInfo,
      inputProps?.roomAggregations,
      inputProps?.monitoringHours,
      inputProps?.property,
      inputProps?.breakdownFilters,
      inputProps?.filters,
    ]
  );

  useEffect(() => {
    // Webpack 5 limitation is you have to create Worker inline and cannot just pass the URL as input to another function....
    // https://webpack.js.org/guides/web-workers/
    // Because of this limitation this hook is responsible to cleanup the webworker and not the underlying `useWebWorker` hook
    const newWorker = new Worker(new URL("./workers/occupancyBreakdown.worker.ts", import.meta.url), {
      type: "module",
    });
    setWorker(newWorker);

    return () => {
      if (newWorker) {
        newWorker.terminate();
      }
    };
  }, []);

  return useWebWorker<IOccupancyBreakdownWorkerProps, IOccupancyBreakdownWorkerOutput>(
    worker,
    webWorkerInput
  );
};
