import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Icon, Link, Text } from "@chakra-ui/react";
import useNavigateBackOrHome from "@tether-web-portal/utils/useNavigateBack";

interface BackButtonProps {
  text: string;
  href: string;
  color?: string;
  className?: string;
}

export const BackButton = ({ text, href, color = "white" }: BackButtonProps) => {
  const { navigateBackOrHome } = useNavigateBackOrHome();
  return (
    <Link
      textColor={color}
      display="flex"
      alignItems="center"
      onClick={() => navigateBackOrHome(href || "/")}
    >
      <Icon boxSize={6} as={ChevronLeftIcon} />
      <Text>{text}</Text>
    </Link>
  );
};
