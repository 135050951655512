import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Image,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import * as React from "react";
import { AuthUser } from "../../lib/auth/AuthProvider";
import { getFullName } from "@tether-web-portal/utils/getFullName";
import { AccountSwitcherButton } from "./AccountSwitcherButton";
import { useHasPermission } from "../../lib/hooks/useHasPermission";
import { useAuth } from "../../lib/auth/useAuth";
import { useGetCurrentCustomer, useGetUserCustomers } from "@tether-web-portal/api/properties/hooks";
import { useNavigate } from "react-router-dom";

interface AccountSwitcherProps {
  organisationName?: string;
  user: AuthUser;
  onLogout: () => void;
  onClickChangeOrganisation: () => void;
  isCovidCareHeader?: boolean;
  isCollapsed?: boolean;
}

export const AccountSwitcher = ({
  organisationName,
  onLogout,
  onClickChangeOrganisation,
  isCovidCareHeader,
  user,
  isCollapsed,
}: AccountSwitcherProps) => {
  const navigate = useNavigate();
  const { switchToCustomer } = useAuth();

  const hasOrgSettingsAccess = useHasPermission("ORGANISATION_SETTINGS");
  const { userCustomers } = useGetUserCustomers();
  const { customer } = useGetCurrentCustomer();

  const hasCustomerAdminAccess = React.useMemo(() => {
    if (!userCustomers || !customer) return false;
    return userCustomers.some((userC) => customer?.id === userC.id);
  }, [userCustomers, customer]);

  const handleNav = (href: string) => {
    navigate(href);
  };

  const goToCompanySettings = async () => {
    try {
      if (user.currentOrganisationId) {
        localStorage.setItem("previousOrganisationId", user.currentOrganisationId);
      }
      await switchToCustomer();
      window.location.href = "/company-settings";
    } catch (error) {
      console.log("Could not load company settings", error);
    }
  };

  return (
    <Menu>
      <AccountSwitcherButton
        isCovidCareHeader={isCovidCareHeader}
        avatarUrl={user!.avatarUrl ? user!.avatarUrl : undefined}
        organisationName={organisationName}
        name={getFullName(user)}
        isCollapsed={isCollapsed}
      />
      <MenuList
        zIndex={2000}
        px={3}
        minW="332"
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
      >
        <MenuItem>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <Text fontWeight={600}>Hi {user.firstName} </Text>
              <Box alignSelf="center" width={4} height={4}>
                <Image src="/assets/waving-hand.png" alt="waving hand emoji" />
              </Box>
            </Box>
            <Text fontSize={14} fontWeight={400}>
              {user!.email}
            </Text>
          </Box>
        </MenuItem>

        <MenuDivider mx={3} />
        <MenuItem onClick={onClickChangeOrganisation} rounded="md">
          <Box flexGrow={1} display="flex" justifyContent="space-between" alignItems="center">
            {organisationName}
            <ChevronDownIcon />
          </Box>
        </MenuItem>
        <MenuDivider mx={3} />

        <Tooltip label="You do not have access to admin settings" isDisabled={hasOrgSettingsAccess}>
          <MenuItem
            style={{ opacity: hasOrgSettingsAccess ? 1 : 0.4 }}
            onClick={hasOrgSettingsAccess ? () => handleNav("/settings") : undefined}
          >
            Organisation Settings
          </MenuItem>
        </Tooltip>

        <MenuItem onClick={() => handleNav("/user-settings")}>User Settings</MenuItem>

        <MenuDivider mx={3} />

        <Tooltip label="You do not have access to manage organisations" isDisabled={hasCustomerAdminAccess}>
          <MenuItem
            style={{ opacity: hasCustomerAdminAccess ? 1 : 0.4 }}
            onClick={hasCustomerAdminAccess ? goToCompanySettings : undefined}
          >
            Manage Organisations
          </MenuItem>
        </Tooltip>

        <MenuItem textColor="red" onClick={onLogout} rounded="md">
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
