import { HeatMapColumn, HeatMapXScale } from "@tether-web-portal/components/heat-map/v2/HeatMap";
import { Group } from "@visx/group";
import { CSSProperties } from "react";

export const ColumnLabels = ({
  columns,
  xScale,
  labelLineHeight,
  labelTopMargin,
  labelStyle,
}: {
  columns: HeatMapColumn[],
  xScale: HeatMapXScale,
  labelLineHeight: number,
  labelTopMargin: number,
  labelStyle: CSSProperties
}) => {
  return (
    <Group data-name={'heat-map-column-labels'}>
      {columns.map(
        ({ label, key }) => (
          <Group key={key} left={xScale(key)} >
            <Group top={labelTopMargin} >
              <foreignObject
                x={0}
                y={0}
                height={labelLineHeight}
                width={xScale.bandwidth()}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // overflow: 'hidden',
                    alignContent: 'end',
                    textAlign: 'center',
                    ...labelStyle
                  }}
                >
                  {label}
                </div>
              </foreignObject>
            </Group>
          </Group>
        )
      )}
    </Group>
  );
}
