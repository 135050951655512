import { DeviceType } from "@tether-web-portal/types/DeviceType";
import { Circuit, CircuitWithReading } from "@tether-web-portal/types/Circuit";
import { formatDeviceName } from "@tether-web-portal/utils/formatDeviceName";
import { CircuitReading } from "../../property-graph/GraphUtils";

export const formatCircuitPhaseTitle = (
  circuit: Circuit | CircuitWithReading | CircuitReading,
  includeDeviceName = false
) => {
  if (!circuit) return "";

  const isMokoPlug = (circuit as CircuitWithReading).device?.type?.toUpperCase() === DeviceType.MOKO_LW005;
  const phaseDescriptor = isMokoPlug
    ? ""
    : Boolean(circuit?.phase === null && circuit?.type === "TOTAL")
    ? "Total"
    : "Phase " + circuit?.phase;

  if ((includeDeviceName || isMokoPlug) && (circuit as CircuitWithReading).device) {
    return [formatDeviceName((circuit as CircuitWithReading).device?.type ?? ""), phaseDescriptor]
      .filter((x) => !!x)
      .join(" - ");
  }

  return phaseDescriptor;
};
