import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTetherToast } from "@tether-web-portal/components/toast/useTetherToast";
import { snakeCaseToStartCase } from "../../utils/stringUtils";
import { api } from "../api";
import { UserPinnedEntity, UserPinnedEntityType } from "../../types/UserPinnedEntity";

export enum PinnedEntityQueryKeys {
  PinnedEntity = "user-pinned-entity",
}

export const useGetPinnedEntitiesForProperty = (propertyId: string) => {
  const key = [PinnedEntityQueryKeys.PinnedEntity, propertyId];
  const { data: pinnedEntities, ...rest } = useQuery<UserPinnedEntity[]>(
    key,
    () => api.get(`v2/userpinnedentity`, { propertyId }),
    {
      retry: false,
      staleTime: Infinity,
    }
  );

  return { pinnedEntities, ...rest };
};

export const useGetPinnedEntities = (entityType: UserPinnedEntityType) => {
  const { data: pinnedEntities, ...rest } = useQuery<UserPinnedEntity[]>(
    [PinnedEntityQueryKeys.PinnedEntity, entityType],
    () => api.get(`v2/userpinnedentity`, { entityType }),
    {
      retry: false,
      staleTime: Infinity,
    }
  );

  return { pinnedEntities, ...rest };
};

export const useCreatePinnedEntityMutation = (onCreated?: (result: UserPinnedEntity) => void) => {
  const queryClient = useQueryClient();
  const toast = useTetherToast();

  const createPin = async (input: {
    entityId: string;
    entityType: UserPinnedEntityType;
    propertyId?: string;
  }) => {
    return await api.post(`v2/userpinnedentity`, input);
  };

  return useMutation(createPin, {
    onSuccess: (data, input) => {
      queryClient.resetQueries([PinnedEntityQueryKeys.PinnedEntity]);

      if (onCreated) {
        onCreated(data);
      }
    },
    onError: (error: Error, input) => {
      toast.errorToast({
        title: `Unable to pin ${snakeCaseToStartCase(input.entityType)}`,
        description: error.message,
      });
    },
  });
};

export const useDeletePinnedEntityMutation = (onDeleted?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useTetherToast();

  const deletePin = async (input: { pinnedEntity: UserPinnedEntity }) => {
    return await api.del(`v2/userpinnedentity/${input.pinnedEntity.entityId}`);
  };

  return useMutation(deletePin, {
    onSuccess: (_, input) => {
      queryClient.resetQueries([PinnedEntityQueryKeys.PinnedEntity]);

      if (onDeleted) {
        onDeleted();
      }
    },
    onError: (error: Error, input) => {
      toast.errorToast({
        title: `Unable to unpin ${snakeCaseToStartCase(input.pinnedEntity.entityType)}`,
        description: error.message,
      });
    },
  });
};
