import React, { FunctionComponent, useMemo } from "react";
import styles from "./styling/OccupancyBreakdownCardWrapper.module.scss";
import { Flex, Stack } from "@chakra-ui/react";

import { OccupancyContextActions, useOccupancyContext } from "@tether-web-portal/contexts/OccupancyContext";
import { Select, Typography } from "@tether-web-portal/components/common";

import ProgressBar from "../tiles/charts/ProgressBar";
import {
  OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE,
  OCCUPANCY_HEATMAP_LOW_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE,
  OCCUPANCY_HEATMAP_OVER_COLOR_RANGE,
} from "../occupancy.colors";

import {
  IOccupancyBreakdownFilters,
  IOccupancyBreakdownDataPointValue,
  OccupancyFilterType,
} from "@tether-web-portal/web-workers/occupancy";
import { IOptionItem } from "@tether-web-portal/types/common";
import { kebabCaseToStartCase } from "@tether-web-portal/utils/stringUtils";

const legendItem = (id: string, title: string, colors: string[]): JSX.Element => (
  <Flex direction="column" rowGap={0.5}>
    <Typography size="sm">{title}</Typography>
    <ProgressBar id={id} width={100} height={8} progress={100} colors={colors} />
  </Flex>
);

type TBreakdownDataPointValueKeys = keyof IOccupancyBreakdownDataPointValue;

interface IOccupancyBreakdownCardWrapperProps {
  children: React.ReactNode;
}
export const OccupancyBreakdownCardWrapper: FunctionComponent<IOccupancyBreakdownCardWrapperProps> = (
  props: IOccupancyBreakdownCardWrapperProps
) => {
  const { children } = props;

  const {
    availableSpaces,
    availableFloors,
    availableRoomTypes,
    breakdownFilters,
    pageFilters,
    dispatcher,
  } = useOccupancyContext();

  const floorFilterOptions = useMemo<IOptionItem[]>(() => {
    return [
      { value: "-1", label: "Total Property" },
      ...availableFloors.map(
        (floor) =>
          ({
            value: floor.floorIndex.toString(),
            label: floor.name,
          } as IOptionItem)
      ),
    ];
  }, [availableFloors]);

  const spacesFilterOptions = useMemo<IOptionItem[]>(() => {
    return [
      { value: "ALL", label: "All Areas" },
      ...availableRoomTypes.flatMap((roomType) => {
        return [
          { value: roomType, label: `All ${kebabCaseToStartCase(roomType)} Rooms` },
          ...availableSpaces
            .filter((x) => x.type === roomType)
            .map(
              (space) =>
                ({
                  value: space.id,
                  label: space.name,
                } as IOptionItem)
            ),
        ] as IOptionItem[];
      }),
    ];
  }, [availableSpaces, availableRoomTypes]);

  const displayByOptions = useMemo<IOptionItem[]>(
    () => [
      { value: "occupancyCount" as TBreakdownDataPointValueKeys, label: "Occupancy" },
      { value: "capacityPercentage" as TBreakdownDataPointValueKeys, label: "Capacity %" },
      { value: "usagePercentage" as TBreakdownDataPointValueKeys, label: "Usage %" },
      { value: "footTraffic" as TBreakdownDataPointValueKeys, label: "Foot-traffic" },
      { value: "entered" as TBreakdownDataPointValueKeys, label: "Entered" },
      { value: "exited" as TBreakdownDataPointValueKeys, label: "Exited" },
    ],
    []
  );

  const handleFloorFilterChanged = (option: IOptionItem | null) => {
    const newFloorIndexFilter = parseInt(option?.value ?? "");
    const updatedFilters: IOccupancyBreakdownFilters = {
      ...breakdownFilters,
      floorIndexToDisplay: !isNaN(newFloorIndexFilter) ? newFloorIndexFilter : -1,
    };
    dispatcher(OccupancyContextActions.SET_BREAKDOWN_FILTERS(updatedFilters));
  };

  const handleFilterChanged = (filter: keyof IOccupancyBreakdownFilters, option: IOptionItem | null) => {
    const updatedFilters = {
      ...breakdownFilters,
      [filter]: option?.value ?? "",
    };
    dispatcher(OccupancyContextActions.SET_BREAKDOWN_FILTERS(updatedFilters));
  };

  return (
    <Stack className={styles.container}>
      <Stack
        className={styles.header}
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        columnGap={2}
        rowGap={2}
        alignItems="center"
      >
        <Stack direction="row" alignItems="center">
          {!pageFilters?.spaceType && (
            <>
              <Typography noWrap>Display:</Typography>
              <Select
                width={150}
                options={floorFilterOptions}
                onChange={handleFloorFilterChanged}
                value={breakdownFilters.floorIndexToDisplay.toString()}
              />
            </>
          )}

          {pageFilters?.spaceType !== OccupancyFilterType.ROOM && (
            <Select
              width={200}
              options={spacesFilterOptions}
              onChange={(option) => handleFilterChanged("spaceToDisplay", option)}
              value={breakdownFilters.spaceToDisplay}
            />
          )}

          <Select
            width={150}
            options={displayByOptions}
            onChange={(option) => handleFilterChanged("metricToDisplay", option)}
            value={breakdownFilters.metricToDisplay}
          />
        </Stack>

        <Stack direction="row" columnGap={1}>
          {legendItem("none", "None", OCCUPANCY_HEATMAP_EMPTY_COLOR_RANGE)}
          {legendItem("low", "Low 0 - 40%", OCCUPANCY_HEATMAP_LOW_COLOR_RANGE)}
          {legendItem("optimal", "Optimal 40 - 100%", OCCUPANCY_HEATMAP_OPTIMAL_COLOR_RANGE)}
          {legendItem("over", "Over 100+", OCCUPANCY_HEATMAP_OVER_COLOR_RANGE)}
        </Stack>
      </Stack>

      <Stack rowGap={4} className={styles.content}>
        {children}
      </Stack>
    </Stack>
  );
};
