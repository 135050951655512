import { ScaleBand } from "d3";

export const scaleBandInvert = <T extends { toString(): string }>(
  scale: ScaleBand<T>,
): ((value: number) => T | undefined) => {
  const domain = scale.domain();
  const paddingOuter = scale(domain[0]) || 0;
  const eachBand = scale.step();

  return (value: number): T | undefined => {
    const valueX = value - paddingOuter;
    const index = Math.floor(valueX / eachBand);
    const indexX = index * eachBand;
    const outsideOfBandArea = valueX - indexX > scale.bandwidth();
    if (outsideOfBandArea) return;
    return domain[index];
  };
};

export const scaleBandWidth = <T extends { toString(): string }>(scale: ScaleBand<T>): number => {
  const domain = scale.domain();
  const lastPosition = scale(domain[domain.length - 1]) || 0;
  return scale.step() + lastPosition;
};
